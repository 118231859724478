/*
Name: 			Theme Base
Written by: 	Okler Themes - (http://www.okler.net)
Theme Version: 	3.1.0
*/
html,
body {
  background: #ecedf0;
  width: 100%;
}

html {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}

.h4-listado {
  font-size: 1.2em;
}

.h4-dashboard {
  font-size: 1em;
}

.h6-listado {
  font-size: 0.9em;
}

.h6-dashboard {
  font-size: 0.7em;
}

body {
  color: #777 !important;
  font-family: "Poppins", Arial, sans-serif;
  line-height: 22px;
  margin: 0;
  font-size: 13px;
}

a {
  color: #ccc;
}

a:hover,
a:focus {
  color: #d9d9d9;
}

a:active {
  color: #bfbfbf;
}

/* Layout Base - Main Wrapper	*/
.body {
  min-height: 100vh;
  width: 100%;
}

/* Layout Base - Header */
.header {
  height: 60px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

/* Layout Base - Inner Wrapper */
.inner-wrapper {
  display: table;
  min-height: 100vh;
  padding-top: 60px;
  table-layout: fixed;
  overflow: hidden;
  width: 100%;
}

/* Layout Base - Content Body */
.content-body {
  display: table-cell;
  padding: 20px;
  position: relative;
  vertical-align: top;
}

.content-body:not(.card-margin) > .row + .row {
  padding-top: 40px;
}

@media (max-width: 767px) {
  .content-body:not(.card-margin) > .row + .row {
    padding-top: 15px;
  }
}

.content-body.content-body-modern:not(.card-margin) > .row + .row,
.content-body.content-body-modern:not(.card-margin) > form > .row + .row,
.content-body.content-body-modern:not(.card-margin)
  > .row
  > [class*="col"]
  > .row
  + .row {
  padding-top: 31px;
}

@media (max-width: 767px) {
  .content-body.content-body-modern:not(.card-margin) > .row + .row,
  .content-body.content-body-modern:not(.card-margin) > form > .row + .row,
  .content-body.content-body-modern:not(.card-margin)
    > .row
    > [class*="col"]
    > .row
    + .row {
    padding-top: 31px;
  }
}

.content-body.card-margin {
  padding-top: 0;
}

.content-body.card-margin .page-header {
  margin-bottom: 5px;
}

.content-body.card-margin .card {
  margin-top: 40px;
}

@media (max-width: 767px) {
  .content-body.card-margin .card {
    margin-top: 15px;
  }
}

/* Layout Base - Page Header */
.page-header {
  background: #5a5c63;
  border-bottom: none;
  border-left: 1px solid #e1e1e6;
  box-shadow: 1px 3px 0 1px #cccccc;
  height: 50px;
  margin: -40px -40px 40px -40px;
  padding: 0;
}

/* Layout Base - Sidebar Left */
.sidebar-left {
  color: #777;
  display: table-cell;
  position: relative;
  vertical-align: top;
  width: 300px;
  z-index: 1010;
}

/* Layout Base - Sidebar Left Opened ( Larger than mobile ) */
@media only screen and (min-width: 768px) {
  html.sidebar-left-collapsed .sidebar-left {
    width: 73px;
  }
}

/* Layout Base - Sidebar Right */
.sidebar-right {
  background: #5a5c63;
  bottom: 0;
  margin-right: -300px;
  min-height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
}

/* Layout Base - Sidebar Right Opened ( Larger than mobile ) */
@media only screen and (min-width: 768px) {
  html.sidebar-right-opened .header {
    margin-left: -300px;
    margin-right: 300px;
  }

  html.sidebar-right-opened .inner-wrapper {
    margin-left: -300px;
  }

  html.sidebar-right-opened .sidebar-right {
    margin-right: 0;
  }

  html.sidebar-right-opened.has-top-menu:not(.has-left-sidebar-half)
    .inner-wrapper {
    margin-left: 0;
    padding-right: 300px;
  }
}

/* Layout Base - Sidebar Right Opened - Has Top Horizontal Menu ( Ipad ) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  html.sidebar-right-opened.has-top-menu:not(.has-left-sidebar-half)
    .content-body {
    right: 300px;
  }
}

/* Layout Base - Flexbox supported */
@media only screen and (min-width: 768px) {
  html.flexbox,
  html.flexboxlegacy {
    /* Tab Navigation */
  }

  html.flexbox .inner-wrapper,
  html.flexboxlegacy .inner-wrapper {
    display: flex;
  }

  html.flexbox .sidebar-left,
  html.flexbox .content-body,
  html.flexboxlegacy .sidebar-left,
  html.flexboxlegacy .content-body {
    display: block;
    flex-shrink: 0;
  }

  html.flexbox .content-body,
  html.flexboxlegacy .content-body {
    flex: 2;
    min-width: 1px;
  }

  html.flexbox.has-tab-navigation .inner-wrapper,
  html.flexboxlegacy.has-tab-navigation .inner-wrapper {
    flex-direction: column;
  }
}

/* Layout Fixed */
@media only screen and (min-width: 768px) {
  /* Layout Fixed - Reseting Styles */
  html.fixed .inner-wrapper,
  html.fixed .sidebar-left,
  html.fixed .content-body {
    display: block;
  }

  /* Layout Fixed - Header */
  html.fixed .header {
    position: fixed;
    z-index: 1020;
  }

  /* Layout Fixed - Inner Wrapper */
  html.fixed .inner-wrapper {
    padding-top: 110px;
  }

  /* Layout Fixed - Content Body */
  html.fixed .content-body {
    margin-left: 300px;
  }

  html.fixed .content-body.has-toolbar {
    padding-top: 92px;
  }

  html.fixed.has-top-menu:not(.has-left-sidebar-half) .content-body {
    margin-left: 0;
  }

  /* Layout Fixed - Page header */
  html.fixed .page-header {
    left: 300px;
    margin: 0;
    position: fixed;
    right: 0;
    top: 60px;
  }

  html.fixed.has-top-menu:not(.has-left-sidebar-half) .page-header {
    left: 0;
  }

  /* Layout Fixed - Sidebar Left */
  html.fixed .sidebar-left {
    bottom: 0;
    left: 0;
    padding-bottom: 50px;
    position: fixed;
    top: 60px;
  }

  html.fixed .sidebar-left .nano-content {
    padding-bottom: 50px;
  }

  /* Layout Fixed - Sidebar Left Collapsed */
  html.fixed.sidebar-left-collapsed .page-header {
    left: 73px;
  }

  html.fixed.sidebar-left-collapsed .content-body {
    margin-left: 73px;
  }

  /* Layout Fixed - Sidebar Right Opened */
  html.fixed.sidebar-right-opened .page-header {
    left: 0;
    margin-right: 300px;
  }

  html.fixed.sidebar-right-opened .sidebar-left {
    left: -300px;
  }

  /* Layout Fixed - Sidebar Left Collapsed & Sidebar Right Opened */
  html.fixed.sidebar-left-collapsed.sidebar-right-opened .page-header {
    left: -300px;
  }
}

/* Layout Boxed - small than min-width */
@media only screen and (max-width: 1199px) {
  html.boxed .header {
    border-color: #ccc;
  }
}

/* Layout Boxed - larger or equal min width */
@media only screen and (min-width: 1200px) {
  /* Layout Boxed - Body Tag */
  html.boxed body {
    /* background: url(../img/patterns/gray_jean.png) repeat; */
    padding-bottom: 5px;
  }

  /* Layout Boxed - Main Wrapper */
  html.boxed .body {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 25px;
    background-color: transparent;
  }

  /* Layout Boxed - Header */
  html.boxed .header {
    border-top: 3px solid #ccc;
    height: 60px;
    position: absolute;
    top: 25px;
  }

  /* Layout Boxed - Inner Wrapper */
  html.boxed .inner-wrapper {
    padding-top: 60px;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  }

  /* Layout Boxed - Content Body */
  html.boxed .content-body {
    background-color: #ecedf0;
  }

  /* Layout Boxed - Base */
  html.boxed .header {
    border-radius: 5px 5px 0 0;
  }

  html.boxed .sidebar-left {
    border-radius: 0 0 0 5px;
    position: relative;
    margin-bottom: 0;
  }

  html.boxed .content-body {
    border-radius: 0 0 5px 0;
  }

  html.boxed .sidebar-right {
    border-radius: 0 5px 5px 0;
  }

  /* Layout Boxed - Sidebar Right */
  html.boxed .sidebar-right {
    border-top: 3px solid #ccc;
    min-height: 0;
  }

  /* Layout Boxed - Sidebar Right Opened */
  html.boxed.sidebar-right-opened .body {
    overflow: hidden;
  }

  html.boxed.sidebar-right-opened .header {
    border-radius: 5px 0 0 0;
  }

  html.boxed.sidebar-right-opened .content-body {
    border-radius: 0 0 0 5px;
  }

  html.boxed.sidebar-right-opened .sidebar-right {
    bottom: 20px;
    position: absolute;
    top: 25px;
    border-radius: 0 5px 5px 5px;
  }
}

/* Layout Boxed - larger or equal min width */
@media only screen and (min-width: 768px) {
  /* Layout Boxed - Content Body */
  html.boxed:not(.sidebar-left-big-icons):not(.has-top-menu):not(
      .has-tab-navigation
    ):not(.left-sidebar-panel).sidebar-left-collapsed
    .content-body {
    width: calc(100% - 73px);
  }

  html.boxed:not(.sidebar-left-big-icons):not(.has-top-menu):not(
      .has-tab-navigation
    ):not(.left-sidebar-panel).left-sidebar-panel
    .content-body {
    width: calc(100% - 375px);
  }

  html.boxed:not(.sidebar-left-big-icons):not(.has-top-menu):not(
      .has-tab-navigation
    ):not(.left-sidebar-panel).left-sidebar-panel.sidebar-right-opened
    .content-body {
    width: calc(100% - 350px);
  }

  html.boxed:not(.sidebar-left-big-icons):not(.has-top-menu):not(
      .has-tab-navigation
    ):not(.left-sidebar-panel)
    .content-body {
    width: calc(100% - 300px);
    flex: none;
  }
}

@media only screen and (max-width: 767px) {
  html,
  body {
    background: #ecedf0;
  }

  html.mobile-device .sidebar-left,
  html.mobile-device .sidebar-right {
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  body {
    min-height: 100vh;
  }

  .inner-wrapper,
  .sidebar-left,
  .content-body {
    display: block;
  }

  .body {
    min-height: 0;
    overflow: visible;
  }

  .header {
    background: none;
    border: none;
    height: auto;
    position: static;
  }

  .header .logo-container {
    height: 60px;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
  }

  .header .header-right {
    background: #fff;
    float: none !important;
    height: 60px;
    margin-top: 60px;
    width: 100%;
  }

  .inner-wrapper {
    min-height: 0;
    padding-top: 0;
  }

  .content-body {
    padding: 0 15px 15px;
  }

  .page-header {
    margin: 0 -15px 20px;
  }

  .sidebar-left {
    bottom: 0;
    left: -100%;
    min-height: 0;
    min-width: 100%;
    min-width: 100vw;
    padding-top: 60px;
    padding-bottom: 50px;
    position: fixed;
    overflow: hidden;
    top: 0;
    z-index: 98 !important;
  }

  .sidebar-right {
    bottom: 0;
    left: auto;
    right: -100%;
    min-height: 0;
    margin-right: 0;
    min-width: 100%;
    min-width: 100vw;
    top: 0;
    z-index: 100;
  }

  html.csstransforms .sidebar-left,
  html.csstransforms .sidebar-right {
    transition-property: margin;
    transition-duration: 0.25s;
    transition-timing-function: ease-out;
    transition-delay: 0;
  }

  html.csstransforms .sidebar-left {
    margin-left: -25px;
  }

  html.csstransforms .sidebar-right {
    margin-right: -25px;
  }

  /* If desktop is seeing mobile res, fix scrollbars */
  html.no-mobile-device body {
    min-height: 0;
  }

  html.no-mobile-device .body {
    min-height: 100vh;
    overflow: hidden;
  }

  html.no-mobile-device .inner-wrapper {
    overflow-y: auto;
  }

  html.no-mobile-device.sidebar-left-opened,
  html.no-mobile-device.sidebar-left-opened body,
  html.no-mobile-device.sidebar-right-opened,
  html.no-mobile-device.sidebar-right-opened body {
    overflow: hidden;
  }

  /* Layout Mobile - Sidebar Left Opened */
  html.sidebar-left-opened.no-csstransforms .sidebar-left {
    left: 0;
  }

  html.sidebar-left-opened.csstransforms .sidebar-left {
    margin-left: 100%;
  }

  /* Layout Mobile - Sidebar Right Opened */
  html.sidebar-right-opened.no-csstransforms .sidebar-right {
    right: 0;
  }

  html.sidebar-right-opened.csstransforms .sidebar-right {
    margin-right: 100%;
  }

  /* Layout Mobile - Sidebar Left Collapsed & Sidebar Right Opened */
  html.sidebar-left-collapsed.sidebar-right-opened .sidebar-left {
    margin-left: -300px;
  }
}

/* iOS10 Content Width Fix */
@media (min-width: 768px) {
  html.mobile-device.flexbox:not(.has-tab-navigation) .content-body {
    width: calc(100vw - 300px) !important;
  }
}

/* Content With Menu - Boxed Layout Fixing Spacement on Bottom */
@media only screen and (min-width: 1200px) {
  html.boxed .content-with-menu {
    margin-bottom: -40px;
  }
}

/* Content With Menu - Container */
@media only screen and (min-width: 768px) {
  .content-with-menu-container {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
}

/* Content With Menu - Menu Faux Column for Scroll and Boxed Layouts */
@media only screen and (min-width: 768px) {
  html.scroll .content-with-menu:before,
  html.boxed .content-with-menu:before {
    bottom: -47px;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    top: 54px;
    width: 300px;
  }

  html.scroll .content-with-menu:after,
  html.boxed .content-with-menu:after {
    bottom: -46px;
    content: "";
    display: block;
    left: -1px;
    position: absolute;
    top: 54px;
    width: 1px;
    z-index: 3;
  }

  html.boxed .content-with-menu:before {
    bottom: 0;
  }

  html.boxed .content-with-menu:after {
    bottom: 2px;
  }
}

.content-with-menu {
  margin: -20px -15px 0;
}

/* Content With Menu - Responsive */
@media only screen and (max-width: 767px) {
  .content-with-menu {
    clear: both;
  }

  .inner-body {
    padding: 40px 15px 0;
  }
}

/* Content With Menu - Menu and Body */
@media only screen and (min-width: 768px) {
  .content-with-menu {
    border-top: 110px solid transparent;
    margin: -150px -40px -53px -40px;
    min-height: 100vh;
  }

  .inner-menu {
    display: table-cell;
    vertical-align: top;
  }

  .inner-body {
    display: table-cell;
    vertical-align: top;
    padding: 40px;
  }

  .inner-toolbar {
    height: 52px;
    overflow: hidden;
  }

  .content-with-menu-has-toolbar .inner-menu-toggle {
    border-radius: 0;
  }

  .content-with-menu-has-toolbar .inner-toolbar {
    padding-left: 140px;
  }
}

/* Content With Menu - Flexbox supported */
@media only screen and (min-width: 768px) {
  html.flexbox .content-with-menu-container,
  html.flexboxlegacy .content-with-menu-container {
    display: flex;
  }

  html.flexbox .inner-menu,
  html.flexbox .inner-body,
  html.flexboxlegacy .inner-menu,
  html.flexboxlegacy .inner-body {
    display: block;
    flex-shrink: 0;
  }

  html.flexbox .inner-body,
  html.flexboxlegacy .inner-body {
    flex: 2;
  }
}

/* Content With Menu + Layout Fixed */
@media only screen and (min-width: 768px) {
  html.fixed .content-with-menu-container,
  html.fixed .inner-menu,
  html.fixed .inner-body {
    display: block;
  }

  html.fixed .content-with-menu-container {
    position: relative;
  }

  html.fixed .inner-menu-toggle {
    position: absolute;
    top: 114px;
    border-radius: 0 0 5px 0;
    width: 140px;
    z-index: 1002;
  }

  html.fixed .inner-menu {
    bottom: 0;
    display: block;
    left: 300px;
    position: fixed;
    margin: 0;
    top: 114px;
    width: 300px;
    padding: 35px;
    z-index: 1002;
  }

  html.fixed .inner-menu-content {
    display: block;
  }

  html.fixed .inner-body {
    margin-left: 300px;
    border-top: 113px solid transparent;
    margin-top: -110px;
    min-height: 100vh;
    position: relative;
  }

  html.fixed .content-with-menu-has-toolbar .inner-body {
    border-top-width: 165px;
  }
}

/* Content With Menu + Layout Scroll & Boxed */
@media only screen and (min-width: 768px) {
  html.scroll .inner-menu,
  html.scroll .inner-body,
  html.boxed .inner-menu,
  html.boxed .inner-body {
    display: block;
  }

  html.scroll .content-with-menu-container,
  html.boxed .content-with-menu-container {
    position: relative;
  }

  html.scroll .inner-menu-toggle,
  html.boxed .inner-menu-toggle {
    position: absolute;
    top: 0;
    border-radius: 0 0 5px 0;
    width: 140px;
    z-index: 3;
  }

  html.scroll .inner-menu,
  html.boxed .inner-menu {
    display: block;
    position: relative;
    margin: 0;
    width: 300px;
    padding: 35px;
  }

  html.scroll .inner-menu-content,
  html.boxed .inner-menu-content {
    display: block;
  }

  html.scroll .inner-body,
  html.boxed .inner-body {
    margin-left: 0;
    min-height: 100vh;
    position: relative;
  }

  html.scroll.flexbox .content-with-menu-container,
  html.scroll.flexboxlegacy .content-with-menu-container,
  html.boxed.flexbox .content-with-menu-container,
  html.boxed.flexboxlegacy .content-with-menu-container {
    display: flex;
  }
}

/* Content With Menu + Layout Fixed + Sidebar Left Collapsed */
@media only screen and (min-width: 768px) {
  html.fixed.sidebar-left-collapsed .inner-menu,
  html.fixed.sidebar-left-collapsed .inner-menu-toggle,
  html.fixed.sidebar-left-collapsed .inner-toolbar {
    left: 73px;
  }

  html.fixed.sidebar-left-collapsed.inner-menu-opened .inner-menu-toggle,
  html.fixed.sidebar-left-collapsed.inner-menu-opened .inner-toolbar {
    left: 373px;
  }
}

/* Content With Menu + Layout Fixed + Sidebar Right Opened */
@media only screen and (min-width: 768px) {
  html.fixed.sidebar-right-opened .inner-menu,
  html.fixed.sidebar-right-opened .inner-menu-toggle,
  html.fixed.sidebar-right-opened .inner-toolbar {
    left: 0px;
  }

  html.fixed.sidebar-right-opened .inner-toolbar {
    margin-right: 300px;
  }

  html.fixed.sidebar-right-opened.inner-menu-opened .inner-menu-toggle,
  html.fixed.sidebar-right-opened.inner-menu-opened .inner-toolbar {
    left: -300px;
  }
}

/* Content With Menu + Layout Fixed + Sidebar Left Collapsed + Sidebar Right Opened */
@media only screen and (min-width: 768px) {
  html.fixed.sidebar-left-collapsed.sidebar-right-opened .inner-menu,
  html.fixed.sidebar-left-collapsed.sidebar-right-opened .inner-menu-toggle,
  html.fixed.sidebar-left-collapsed.sidebar-right-opened .inner-toolbar {
    left: -227px;
  }

  html.fixed.sidebar-left-collapsed.sidebar-right-opened.inner-menu-opened
    .inner-menu-toggle,
  html.fixed.sidebar-left-collapsed.sidebar-right-opened.inner-menu-opened
    .inner-toolbar {
    left: -527px;
  }
}

/* Resolution gt 767 and lt 1366 - Hide Inner Menu */
@media only screen and (min-width: 768px) and (max-width: 1365px) {
  html.fixed .inner-menu,
  html.scroll .inner-menu,
  html.boxed .inner-menu {
    display: none;
  }

  html.fixed .inner-menu-toggle,
  html.scroll .inner-menu-toggle,
  html.boxed .inner-menu-toggle {
    display: block;
  }

  html.fixed .inner-body,
  html.scroll .inner-body,
  html.boxed .inner-body {
    margin-left: 0;
  }

  html.fixed .content-with-menu-has-toolbar .inner-toolbar,
  html.scroll .content-with-menu-has-toolbar .inner-toolbar,
  html.boxed .content-with-menu-has-toolbar .inner-toolbar {
    padding-left: 140px;
  }

  html.fixed.inner-menu-opened .inner-menu,
  html.scroll.inner-menu-opened .inner-menu,
  html.boxed.inner-menu-opened .inner-menu {
    display: block;
  }

  html.fixed.inner-menu-opened .inner-menu-toggle,
  html.scroll.inner-menu-opened .inner-menu-toggle,
  html.boxed.inner-menu-opened .inner-menu-toggle {
    display: none;
  }

  html.fixed.inner-menu-opened .inner-body,
  html.scroll.inner-menu-opened .inner-body,
  html.boxed.inner-menu-opened .inner-body {
    margin-right: -300px;
  }

  html.fixed.inner-menu-opened .content-with-menu-has-toolbar .inner-toolbar,
  html.scroll.inner-menu-opened .content-with-menu-has-toolbar .inner-toolbar,
  html.boxed.inner-menu-opened .content-with-menu-has-toolbar .inner-toolbar {
    padding-left: 0;
  }

  html.fixed.inner-menu-opened .inner-body {
    margin-left: 300px;
  }

  html.scroll .content-with-menu:before,
  html.boxed .content-with-menu:before {
    display: none;
  }

  html.scroll.inner-menu-opened:before,
  html.boxed.inner-menu-opened:before {
    display: block;
  }
}

/* Resolution gt 1366 - Show Inner Menu */
@media only screen and (min-width: 1366px) {
  html.fixed .inner-menu,
  html.scroll .inner-menu,
  html.boxed .inner-menu {
    display: block;
  }

  html.fixed .inner-menu-toggle,
  html.fixed .inner-menu-toggle-inside,
  html.scroll .inner-menu-toggle,
  html.scroll .inner-menu-toggle-inside,
  html.boxed .inner-menu-toggle,
  html.boxed .inner-menu-toggle-inside {
    display: none;
  }

  html.fixed .inner-body,
  html.scroll .inner-body,
  html.boxed .inner-body {
    margin-right: 0;
  }

  html.fixed .content-with-menu-has-toolbar .inner-toolbar,
  html.scroll .content-with-menu-has-toolbar .inner-toolbar,
  html.boxed .content-with-menu-has-toolbar .inner-toolbar {
    padding-left: 0;
  }

  html.fixed.inner-menu-opened .inner-body {
    margin-left: 300px;
  }

  html.fixed .content-with-menu .inner-toolbar,
  html.fixed.inner-menu-opened .content-with-menu .inner-toolbar {
    left: 600px;
  }

  html.fixed .inner-menu-toggle,
  html.fixed .inner-menu,
  html.fixed.inner-menu-opened .inner-menu-toggle,
  html.fixed.inner-menu-opened .inner-menu {
    left: 300px;
  }

  html.fixed.sidebar-right-opened .content-with-menu .inner-toolbar {
    left: 300px;
  }

  html.fixed.sidebar-right-opened .inner-menu,
  html.fixed.sidebar-right-opened .inner-menu-toggle {
    left: 0px;
  }

  html.fixed.sidebar-left-collapsed .content-with-menu .inner-toolbar,
  html.fixed.sidebar-left-collapsed.sidebar-right-opened.inner-menu-opened
    .content-with-menu
    .inner-toolbar,
  html.fixed.sidebar-left-collapsed.inner-menu-opened
    .content-with-menu
    .inner-toolbar {
    left: 373px;
  }

  html.fixed.sidebar-left-collapsed .inner-menu-toggle,
  html.fixed.sidebar-left-collapsed .inner-menu,
  html.fixed.sidebar-left-collapsed.sidebar-right-opened.inner-menu-opened
    .inner-menu-toggle,
  html.fixed.sidebar-left-collapsed.sidebar-right-opened.inner-menu-opened
    .inner-menu,
  html.fixed.sidebar-left-collapsed.inner-menu-opened .inner-menu-toggle,
  html.fixed.sidebar-left-collapsed.inner-menu-opened .inner-menu {
    left: 73px;
  }

  html.fixed.sidebar-left-collapsed.sidebar-right-opened
    .content-with-menu
    .inner-toolbar {
    left: 73px;
  }

  html.fixed.sidebar-left-collapsed.sidebar-right-opened .inner-menu,
  html.fixed.sidebar-left-collapsed.sidebar-right-opened .inner-menu-toggle {
    left: -227px;
  }
}

/* Fix IE Scrollbar Overlaying content */
@-ms-viewport {
  width: auto !important;
}

/* Sidebar Left Sizing */
@media only screen and (max-width: 767px) {
  /* Layout Mobile - Sidebar Left Collapsed & Sidebar Right Opened */
  html.sidebar-left-sm.sidebar-left-collapsed.sidebar-right-opened
    .sidebar-left {
    margin-left: -250px;
  }
}

@media only screen and (min-width: 768px) {
  /* Layout Base - Sidebar Left */
  html.sidebar-left-sm .sidebar-left {
    width: 250px;
    font-size: 0.75rem;
  }

  html.sidebar-left-sm .sidebar-left ul.nav-main li i {
    font-size: 0.9rem;
  }

  html.sidebar-left-sm .sidebar-left ul.nav-main li a {
    font-size: 0.75rem;
  }

  html.sidebar-left-sm .sidebar-left .sidebar-widget .widget-header h6 {
    font-size: 0.75rem;
  }

  html.sidebar-left-sm.sidebar-left-collapsed .sidebar-left .sidebar-title {
    margin-left: -250px;
  }

  html.sidebar-left-sm.sidebar-left-collapsed.fixed .sidebar-left .nano:hover {
    width: 250px;
  }

  /* Layout Base - Sidebar Left Opened ( Larger than mobile ) */
  html.sidebar-left-sm.sidebar-left-collapsed .sidebar-left {
    width: 73px;
  }

  /* Layout Fixed - Content Body */
  html.fixed.sidebar-left-sm .content-body {
    margin-left: 250px;
  }

  /* Layout Fixed - Page header */
  html.fixed.sidebar-left-sm .page-header {
    left: 250px;
  }

  /* Layout Fixed - Sidebar Right Opened */
  html.fixed.sidebar-left-sm.sidebar-right-opened .page-header {
    left: 0;
  }

  html.fixed.sidebar-left-sm.sidebar-right-opened .sidebar-left {
    left: -250px;
  }

  /* Layout Fixed - Sidebar Left Collapsed */
  html.fixed.sidebar-left-collapsed .page-header {
    left: 73px;
  }

  html.fixed.sidebar-left-collapsed .content-body {
    margin-left: 73px;
  }

  /* Layout Fixed - Sidebar Left Collapsed & Sidebar Right Opened */
  html.fixed.sidebar-left-sm.sidebar-left-collapsed.sidebar-right-opened
    .page-header {
    left: -250px;
  }

  /* Content With Menu + Layout Fixed */
  html.fixed.sidebar-left-sm .inner-menu {
    left: 250px;
  }

  /* Content With Menu + Layout Fixed + Sidebar Left Collapsed */
  html.fixed.sidebar-left-sm.sidebar-left-collapsed .inner-menu,
  html.fixed.sidebar-left-sm.sidebar-left-collapsed .inner-menu-toggle,
  html.fixed.sidebar-left-sm.sidebar-left-collapsed .inner-toolbar {
    left: 73px;
  }

  html.fixed.sidebar-left-sm.sidebar-left-collapsed.inner-menu-opened
    .inner-menu-toggle,
  html.fixed.sidebar-left-sm.sidebar-left-collapsed.inner-menu-opened
    .inner-toolbar {
    left: 373px;
  }

  /* Content With Menu + Layout Fixed + Sidebar Right Opened */
  html.fixed.sidebar-left-sm.sidebar-right-opened .inner-menu,
  html.fixed.sidebar-left-sm.sidebar-right-opened .inner-menu-toggle,
  html.fixed.sidebar-left-sm.sidebar-right-opened .inner-toolbar {
    left: -50px;
  }

  html.fixed.sidebar-left-sm.sidebar-right-opened.inner-menu-opened
    .inner-menu-toggle,
  html.fixed.sidebar-left-sm.sidebar-right-opened.inner-menu-opened
    .inner-toolbar {
    left: -350px;
  }

  /* Content With Menu - Toolbar + Layout Fixed */
  html.fixed.sidebar-left-sm.inner-menu-opened {
    left: 550px;
  }

  html.fixed.sidebar-left-sm .inner-menu-toggle {
    left: 250px;
  }
}

/* Resolution gt 1366 - Show Inner Menu */
@media only screen and (min-width: 1366px) {
  html.fixed.sidebar-left-sm .content-with-menu .inner-toolbar,
  html.fixed.sidebar-left-sm.inner-menu-opened
    .content-with-menu
    .inner-toolbar {
    left: 550px;
  }

  html.fixed.sidebar-left-sm .inner-menu-toggle,
  html.fixed.sidebar-left-sm .inner-menu,
  html.fixed.sidebar-left-sm.inner-menu-opened .inner-menu-toggle,
  html.fixed.sidebar-left-sm.inner-menu-opened .inner-menu {
    left: 250px;
  }

  html.fixed.sidebar-left-sm.sidebar-right-opened
    .content-with-menu
    .inner-toolbar {
    left: 250px;
  }

  html.fixed.sidebar-left-sm.sidebar-right-opened .inner-menu,
  html.fixed.sidebar-left-sm.sidebar-right-opened .inner-menu-toggle {
    left: -50px;
  }

  html.fixed.sidebar-left-sm.sidebar-left-collapsed
    .content-with-menu
    .inner-toolbar,
  html.fixed.sidebar-left-sm.sidebar-left-collapsed.sidebar-right-opened.inner-menu-opened
    .content-with-menu
    .inner-toolbar,
  html.fixed.sidebar-left-sm.sidebar-left-collapsed.inner-menu-opened
    .content-with-menu
    .inner-toolbar {
    left: 373px;
  }

  html.fixed.sidebar-left-sm.sidebar-left-collapsed .inner-menu-toggle,
  html.fixed.sidebar-left-sm.sidebar-left-collapsed .inner-menu,
  html.fixed.sidebar-left-sm.sidebar-left-collapsed.sidebar-right-opened.inner-menu-opened
    .inner-menu-toggle,
  html.fixed.sidebar-left-sm.sidebar-left-collapsed.sidebar-right-opened.inner-menu-opened
    .inner-menu,
  html.fixed.sidebar-left-sm.sidebar-left-collapsed.inner-menu-opened
    .inner-menu-toggle,
  html.fixed.sidebar-left-sm.sidebar-left-collapsed.inner-menu-opened
    .inner-menu {
    left: 73px;
  }

  html.fixed.sidebar-left-sm.sidebar-left-collapsed.sidebar-right-opened
    .content-with-menu
    .inner-toolbar {
    left: 73px;
  }

  html.fixed.sidebar-left-sm.sidebar-left-collapsed.sidebar-right-opened
    .inner-menu,
  html.fixed.sidebar-left-sm.sidebar-left-collapsed.sidebar-right-opened
    .inner-menu-toggle {
    left: -227px;
  }
}

@media only screen and (max-width: 767px) {
  /* Layout Mobile - Sidebar Left Collapsed & Sidebar Right Opened */
  html.sidebar-left-xs.sidebar-left-collapsed.sidebar-right-opened
    .sidebar-left {
    margin-left: -200px;
  }
}

@media only screen and (min-width: 768px) {
  /* Layout Base - Sidebar Left */
  html.sidebar-left-xs .sidebar-left {
    width: 200px;
    font-size: 0.7rem;
  }

  html.sidebar-left-xs .sidebar-left ul.nav-main li i {
    font-size: 0.85rem;
  }

  html.sidebar-left-xs .sidebar-left ul.nav-main li a {
    font-size: 0.7rem;
  }

  html.sidebar-left-xs .sidebar-left ul.nav-main li .nav-children li a {
    padding-left: 52px;
  }

  html.sidebar-left-xs .sidebar-left .sidebar-widget .widget-header h6 {
    font-size: 0.7rem;
  }

  html.sidebar-left-xs.sidebar-left-collapsed .sidebar-left .sidebar-title {
    margin-left: -200px;
  }

  html.sidebar-left-xs.sidebar-left-collapsed.fixed .sidebar-left .nano:hover {
    width: 200px;
  }

  /* Layout Base - Sidebar Left Opened ( Larger than mobile ) */
  html.sidebar-left-xs.sidebar-left-collapsed .sidebar-left {
    width: 73px;
  }

  /* Layout Fixed - Content Body */
  html.fixed.sidebar-left-xs .content-body {
    margin-left: 200px;
  }

  /* Layout Fixed - Page header */
  html.fixed.sidebar-left-xs .page-header {
    left: 200px;
  }

  /* Layout Fixed - Sidebar Right Opened */
  html.fixed.sidebar-left-xs.sidebar-right-opened .page-header {
    left: 0;
  }

  html.fixed.sidebar-left-xs.sidebar-right-opened .sidebar-left {
    left: -200px;
  }

  /* Layout Fixed - Sidebar Left Collapsed */
  html.fixed.sidebar-left-collapsed .page-header {
    left: 73px;
  }

  html.fixed.sidebar-left-collapsed .content-body {
    margin-left: 73px;
  }

  /* Layout Fixed - Sidebar Left Collapsed & Sidebar Right Opened */
  html.fixed.sidebar-left-xs.sidebar-left-collapsed.sidebar-right-opened
    .page-header {
    left: -200px;
  }

  /* Content With Menu + Layout Fixed */
  html.fixed.sidebar-left-xs .inner-menu {
    left: 200px;
  }

  /* Content With Menu + Layout Fixed + Sidebar Left Collapsed */
  html.fixed.sidebar-left-xs.sidebar-left-collapsed .inner-menu,
  html.fixed.sidebar-left-xs.sidebar-left-collapsed .inner-menu-toggle,
  html.fixed.sidebar-left-xs.sidebar-left-collapsed .inner-toolbar {
    left: 73px;
  }

  html.fixed.sidebar-left-xs.sidebar-left-collapsed.inner-menu-opened
    .inner-menu-toggle,
  html.fixed.sidebar-left-xs.sidebar-left-collapsed.inner-menu-opened
    .inner-toolbar {
    left: 373px;
  }

  /* Content With Menu + Layout Fixed + Sidebar Right Opened */
  html.fixed.sidebar-left-xs.sidebar-right-opened .inner-menu,
  html.fixed.sidebar-left-xs.sidebar-right-opened .inner-menu-toggle,
  html.fixed.sidebar-left-xs.sidebar-right-opened .inner-toolbar {
    left: -100px;
  }

  html.fixed.sidebar-left-xs.sidebar-right-opened.inner-menu-opened
    .inner-menu-toggle,
  html.fixed.sidebar-left-xs.sidebar-right-opened.inner-menu-opened
    .inner-toolbar {
    left: -400px;
  }

  /* Content With Menu - Toolbar + Layout Fixed */
  html.fixed.sidebar-left-xs.inner-menu-opened {
    left: 500px;
  }

  html.fixed.sidebar-left-xs .inner-menu-toggle {
    left: 200px;
  }
}

/* Resolution gt 1366 - Show Inner Menu */
@media only screen and (min-width: 1366px) {
  html.fixed.sidebar-left-xs .content-with-menu .inner-toolbar,
  html.fixed.sidebar-left-xs.inner-menu-opened
    .content-with-menu
    .inner-toolbar {
    left: 500px;
  }

  html.fixed.sidebar-left-xs .inner-menu-toggle,
  html.fixed.sidebar-left-xs .inner-menu,
  html.fixed.sidebar-left-xs.inner-menu-opened .inner-menu-toggle,
  html.fixed.sidebar-left-xs.inner-menu-opened .inner-menu {
    left: 200px;
  }

  html.fixed.sidebar-left-xs.sidebar-right-opened
    .content-with-menu
    .inner-toolbar {
    left: 200px;
  }

  html.fixed.sidebar-left-xs.sidebar-right-opened .inner-menu,
  html.fixed.sidebar-left-xs.sidebar-right-opened .inner-menu-toggle {
    left: -100px;
  }

  html.fixed.sidebar-left-xs.sidebar-left-collapsed
    .content-with-menu
    .inner-toolbar,
  html.fixed.sidebar-left-xs.sidebar-left-collapsed.sidebar-right-opened.inner-menu-opened
    .content-with-menu
    .inner-toolbar,
  html.fixed.sidebar-left-xs.sidebar-left-collapsed.inner-menu-opened
    .content-with-menu
    .inner-toolbar {
    left: 373px;
  }

  html.fixed.sidebar-left-xs.sidebar-left-collapsed .inner-menu-toggle,
  html.fixed.sidebar-left-xs.sidebar-left-collapsed .inner-menu,
  html.fixed.sidebar-left-xs.sidebar-left-collapsed.sidebar-right-opened.inner-menu-opened
    .inner-menu-toggle,
  html.fixed.sidebar-left-xs.sidebar-left-collapsed.sidebar-right-opened.inner-menu-opened
    .inner-menu,
  html.fixed.sidebar-left-xs.sidebar-left-collapsed.inner-menu-opened
    .inner-menu-toggle,
  html.fixed.sidebar-left-xs.sidebar-left-collapsed.inner-menu-opened
    .inner-menu {
    left: 73px;
  }

  html.fixed.sidebar-left-xs.sidebar-left-collapsed.sidebar-right-opened
    .content-with-menu
    .inner-toolbar {
    left: 73px;
  }

  html.fixed.sidebar-left-xs.sidebar-left-collapsed.sidebar-right-opened
    .inner-menu,
  html.fixed.sidebar-left-xs.sidebar-left-collapsed.sidebar-right-opened
    .inner-menu-toggle {
    left: -227px;
  }
}

/* Sidebar Left */
.sidebar-left {
  background: #e6e6ea;
  z-index: 1010;
}

.sidebar-left .sidebar-header {
  position: relative;
  color: #777;
  height: 50px;
}

.sidebar-left .sidebar-header .sidebar-title {
  background: #e6e6ea;
  color: #5a5c63;
  padding: 15px;
  font-size: 12.8px;
  font-size: 0.8rem;
}

.sidebar-left .sidebar-header .sidebar-toggle {
  position: absolute;
  top: 0;
  right: 0;
  width: 73px;
  height: 50px;
  background-color: #5a5c63;
  border-radius: 0 0 0 5px;
  text-align: center;
  cursor: pointer;
}

.sidebar-left .sidebar-header .sidebar-toggle i {
  color: #c3c3c3;
  font-size: 14.4px;
  font-size: 0.9rem;
  line-height: 50px;
  transition: all 0.15s ease-in-out;
}

.sidebar-left .sidebar-header .sidebar-toggle:hover i {
  color: #ccc;
}

.sidebar-left hr.separator {
  background: none;
  margin: 20px 10px 20px;
}

@media only screen and (max-width: 767px) {
  .sidebar-left {
    background: #e6e6ea;
  }
}

html.mobile-device .sidebar-left {
  background: #e6e6ea;
}

/* Unstyle nano for non fixed layouts */
@media only screen and (min-width: 768px) {
  html.scroll .sidebar-left,
  html.boxed .sidebar-left,
  html.sidebar-left-big-icons .sidebar-left {
    min-height: 100vh;
  }

  html.scroll .sidebar-left .sidebar-header,
  html.boxed .sidebar-left .sidebar-header,
  html.sidebar-left-big-icons .sidebar-left .sidebar-header {
    margin-bottom: -3px;
  }

  html.scroll .sidebar-left .nano,
  html.boxed .sidebar-left .nano,
  html.sidebar-left-big-icons .sidebar-left .nano {
    position: static;
    overflow: visible;
    width: 100%;
  }

  html.scroll .sidebar-left .nano .nano-content,
  html.boxed .sidebar-left .nano .nano-content,
  html.sidebar-left-big-icons .sidebar-left .nano .nano-content {
    margin-right: 0 !important;
    position: relative;
    overflow: visible;
    margin-top: 3px;
  }

  html.scroll .sidebar-left .nano .nano-pane,
  html.boxed .sidebar-left .nano .nano-pane,
  html.sidebar-left-big-icons .sidebar-left .nano .nano-pane {
    display: none !important;
  }

  html.boxed .sidebar-left .nano > .nano-content,
  html.scroll .sidebar-left .nano > .nano-content,
  html.sidebar-left-big-icons .sidebar-left .nano > .nano-content {
    overflow: visible !important;
  }

  html.boxed .sidebar-left .nano {
    padding-bottom: 10px;
  }

  html.scroll .sidebar-left .nano,
  html.sidebar-left-big-icons .sidebar-left .nano {
    padding-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) {
  html.sidebar-left-collapsed .sidebar-left .nano {
    background: #e6e6ea;
    box-shadow: -5px 0 0 #5a5c63 inset;
  }

  html.sidebar-left-collapsed .sidebar-left .sidebar-title {
    margin-left: -300px;
    opacity: 0;
  }

  html.sidebar-left-collapsed .sidebar-left .sidebar-toggle {
    border-radius: 0;
  }

  html.sidebar-left-collapsed .sidebar-left .search-sidebar {
    display: none;
  }

  html.sidebar-left-collapsed .sidebar-left .search-sidebar-language {
    display: none;
  }

  html.sidebar-left-collapsed .sidebar-left .nav-main > li > a {
    overflow: hidden;
    text-overflow: clip;
  }

  html.sidebar-left-collapsed .sidebar-left .nav-main li.nav-parent a:after {
    display: none;
  }

  html.sidebar-left-collapsed
    .sidebar-left
    .nav-main
    li.nav-parent
    > ul.nav-children {
    display: none;
  }

  html.sidebar-left-collapsed .sidebar-left .nav-main a span {
    visibility: hidden;
  }

  html.sidebar-left-collapsed .sidebar-left .sidebar-widget,
  html.sidebar-left-collapsed .sidebar-left .separator {
    display: none;
  }

  html.sidebar-left-collapsed .sidebar-left .nano:hover {
    width: 300px;
  }

  html.sidebar-left-collapsed
    .sidebar-left
    .nano:hover
    .nav-main
    .nav-expanded
    > ul.nav-children {
    display: block;
  }

  html.sidebar-left-collapsed
    .sidebar-left
    .nano:hover
    .nav-main
    li.nav-parent
    a:after {
    display: inline-block;
  }

  html.sidebar-left-collapsed .sidebar-left .nano:hover .nav-main li a span {
    visibility: visible;
  }

  html.sidebar-left-collapsed .sidebar-left .nano:hover .sidebar-widget,
  html.sidebar-left-collapsed .sidebar-left .nano:hover .separator {
    display: block;
  }

  html.sidebar-left-collapsed .sidebar-left .nano.hovered {
    width: 300px;
  }

  html.sidebar-left-collapsed .sidebar-left .nano.hovered .nav-main li a span {
    visibility: visible;
  }

  html.sidebar-left-collapsed
    .sidebar-left
    .nano.hovered
    .nav-main
    li.nav-parent
    a:after {
    display: inline-block;
  }

  html.sidebar-left-collapsed
    .sidebar-left
    .nano.hovered
    .nav-main
    .nav-expanded
    > ul.nav-children {
    display: block;
  }

  html.sidebar-left-collapsed.sidebar-left-opened .sidebar-left .nano {
    width: 300px;
  }

  html.sidebar-left-collapsed.sidebar-left-opened
    .sidebar-left
    .nano
    .nav-main
    .nav-expanded
    > ul.nav-children {
    display: block;
  }

  html.sidebar-left-collapsed.sidebar-left-opened
    .sidebar-left
    .nano
    .nav-main
    li.nav-parent
    a:after {
    display: inline-block;
  }

  html.sidebar-left-collapsed.sidebar-left-opened
    .sidebar-left
    .nano
    .nav-main
    li
    a
    span {
    visibility: visible;
  }

  html.sidebar-left-collapsed.sidebar-left-opened
    .sidebar-left
    .nano
    .sidebar-widget,
  html.sidebar-left-collapsed.sidebar-left-opened
    .sidebar-left
    .nano
    .separator {
    display: block;
  }
}

html.sidebar-light:not(.dark) .sidebar-left {
  background: #fff;
}

html.sidebar-light:not(.dark) .sidebar-left .sidebar-header .sidebar-title {
  background: #fff;
}

html.sidebar-light:not(.dark) .sidebar-left .sidebar-header .sidebar-toggle {
  background: #f6f6f6;
}

html.sidebar-light:not(.dark) .sidebar-left .sidebar-header .sidebar-toggle i {
  color: #333;
}

html.sidebar-light:not(.dark) .sidebar-left .nano {
  box-shadow: -5px 0 0 #f6f6f6 inset;
  background: #fff;
}

html.sidebar-light:not(.dark).sidebar-left-collapsed .sidebar-left .nano {
  box-shadow: -5px 0 0 #f6f6f6 inset;
  background: #fff;
}

@media only screen and (max-width: 767px) {
  html.sidebar-light .sidebar-left {
    background: #fff;
  }
}

html.mobile-device.sidebar-light .sidebar-left {
  background: #fff;
}

@media only screen and (min-width: 768px) {
  html.sidebar-left-big-icons .sidebar-left {
    width: 152px;
  }

  html.sidebar-left-big-icons .sidebar-left .sidebar-header .sidebar-toggle {
    width: 55px;
    border-radius: 0;
  }

  html.sidebar-left-big-icons .sidebar-left .nano {
    box-shadow: none !important;
  }

  html.sidebar-left-big-icons .sidebar-left .nano .nav-main {
    margin-right: 0;
  }

  html.sidebar-left-big-icons
    .sidebar-left
    .nano
    .nav-main
    > li:hover
    > ul.nav-children {
    display: block;
  }

  html.sidebar-left-big-icons .sidebar-left .nano .nav-main > li:hover > a {
    background: #5a5c63;
  }

  html.sidebar-left-big-icons
    .sidebar-left
    .nano
    .nav-main
    > li:last-child
    > a {
    border-top: 1px solid #5a5c63;
    border-bottom: 1px solid #5a5c63;
  }

  html.sidebar-left-big-icons
    .sidebar-left
    .nano
    .nav-main
    > li.nav-active
    > a {
    background: #5a5c63;
  }

  html.sidebar-left-big-icons .sidebar-left .nano .nav-main > li > a {
    position: relative;
    text-align: center;
    padding: 12px 10px;
    border-top: 1px solid #5a5c63;
  }

  html.sidebar-left-big-icons .sidebar-left .nano .nav-main > li > a:after {
    content: none;
  }

  html.sidebar-left-big-icons .sidebar-left .nano .nav-main > li > a i {
    margin-right: 0;
    font-size: 1.8rem;
  }

  html.sidebar-left-big-icons .sidebar-left .nano .nav-main > li > a span {
    display: block;
  }

  html.sidebar-left-big-icons
    .sidebar-left
    .nano
    .nav-main
    > li
    > a
    span.badge {
    position: absolute;
    top: 2px;
    left: 60%;
    transform: translateX(-50%);
  }

  html.sidebar-left-big-icons
    .sidebar-left
    .nano
    .nav-main
    > li
    > a
    .not-included {
    display: block;
  }

  html.sidebar-left-big-icons
    .sidebar-left
    .nano
    .nav-main
    > li
    ul.nav-children {
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 210px;
    border-left: 3px solid #5a5c63;
    background: #5a5c63;
  }

  html.sidebar-left-big-icons
    .sidebar-left
    .nano
    .nav-main
    > li
    ul.nav-children
    li:hover
    > ul.nav-children {
    display: block;
  }

  html.sidebar-left-big-icons
    .sidebar-left
    .nano
    .nav-main
    > li
    ul.nav-children
    li:hover
    > a {
    color: #fff;
  }

  html.sidebar-left-big-icons
    .sidebar-left
    .nano
    .nav-main
    > li
    ul.nav-children
    li:hover
    > a:hover {
    background: transparent;
  }

  html.sidebar-left-big-icons
    .sidebar-left
    .nano
    .nav-main
    > li
    ul.nav-children
    li
    a {
    padding: 6px 15px;
    overflow: visible;
  }

  html.sidebar-left-big-icons
    .sidebar-left
    .nano
    .nav-main
    > li
    ul.nav-children
    li.nav-parent
    > a {
    padding-right: 30px;
  }

  html.sidebar-left-big-icons
    .sidebar-left
    .nano
    .nav-main
    > li
    ul.nav-children
    ul.nav-children {
    padding: 10px 0;
  }

  html.sidebar-left-big-icons
    .sidebar-left
    .nano
    .nav-main
    li.nav-parent:hover
    > a:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: -3px;
    bottom: 0;
    border-right: 4px solid #5a5c63;
    z-index: 1;
  }

  html.sidebar-left-big-icons
    .sidebar-left
    .nano
    .nav-main
    li.nav-parent.nav-expanded
    > ul.nav-children {
    display: none;
  }

  html.sidebar-left-big-icons
    .sidebar-left
    .nano
    .nav-main
    li.nav-parent.nav-expanded:hover
    > ul.nav-children {
    display: block;
  }

  html.sidebar-left-big-icons .sidebar-left .nano .sidebar-widget {
    display: none;
  }

  html.sidebar-left-big-icons.sidebar-left-collapsed .sidebar-left {
    width: 55px;
  }

  html.sidebar-left-big-icons.sidebar-left-collapsed .sidebar-left .nano:hover {
    width: 55px;
  }

  html.sidebar-left-big-icons.sidebar-left-collapsed
    .sidebar-left
    .nano:hover
    .sidebar-widget {
    display: none;
  }

  html.sidebar-left-big-icons.sidebar-left-collapsed
    .sidebar-left
    .nano
    .nav-main
    > li
    > a {
    overflow: visible;
  }

  html.sidebar-left-big-icons.sidebar-left-collapsed
    .sidebar-left
    .nano
    .nav-main
    > li
    > a
    span {
    display: none;
  }

  html.sidebar-left-big-icons.sidebar-left-collapsed
    .sidebar-left
    .nano
    .nav-main
    > li
    > a
    > i {
    font-size: 1.2rem;
  }

  html.sidebar-left-big-icons.sidebar-light
    .sidebar-left
    .nano
    .nav-main
    > li:hover
    > a {
    background: #fafafa;
  }

  html.sidebar-left-big-icons.sidebar-light
    .sidebar-left
    .nano
    .nav-main
    > li:last-child
    > a {
    border-top: 1px solid #fafafa;
    border-bottom: 1px solid #fafafa;
  }

  html.sidebar-left-big-icons.sidebar-light
    .sidebar-left
    .nano
    .nav-main
    > li.nav-active
    > a {
    background: #fafafa;
  }

  html.sidebar-left-big-icons.sidebar-light
    .sidebar-left
    .nano
    .nav-main
    > li
    > a {
    border-top: 1px solid #fafafa;
  }

  html.sidebar-left-big-icons.sidebar-light
    .sidebar-left
    .nano
    .nav-main
    > li
    ul.nav-children {
    border-left: 3px solid #f1f1f1;
    background: #fafafa;
  }

  html.sidebar-left-big-icons.sidebar-light
    .sidebar-left
    .nano
    .nav-main
    > li
    ul.nav-children
    li:hover
    > a {
    color: #000;
  }

  html.sidebar-left-big-icons.sidebar-light
    .sidebar-left
    .nano
    .nav-main
    > li
    ul.nav-children
    li:hover
    > a:hover {
    background: transparent;
  }

  html.sidebar-left-big-icons.sidebar-light
    .sidebar-left
    .nano
    .nav-main
    li.nav-parent:hover
    > a:before {
    border-right: 4px solid #fafafa;
  }

  html.sidebar-left-big-icons.sidebar-light.sidebar-left-with-menu.boxed:after,
  html.sidebar-left-big-icons.sidebar-light.sidebar-left-with-menu
    .content-with-menu:after {
    box-shadow: none;
  }

  html.sidebar-left-big-icons.sidebar-light.sidebar-left-with-menu:not(
      .sidebar-right-opened
    )
    .inner-menu {
    border-left: 2px solid #e2e3e6;
  }

  html.sidebar-left-big-icons.sidebar-light.sidebar-left-with-menu.no-overflowscrolling:not(
      .dark
    )
    .inner-menu
    .nano {
    box-shadow: none;
  }

  html.sidebar-left-big-icons.sidebar-left-with-menu {
    /* Sidebar Left Opened - Sidebar Right Closed / Not Scroll */
    /* Sidebar Left Opened - Sidebar Right Closed */
    /* Sidebar Left Collapsed */
    /* Scroll Layout */
    /* min 768px & max 1365px */
  }

  html.sidebar-left-big-icons.sidebar-left-with-menu:not(
      .sidebar-right-opened
    ):not(.scroll)
    .inner-body {
    margin-left: 153px;
  }

  html.sidebar-left-big-icons.sidebar-left-with-menu:not(
      .sidebar-right-opened
    ):not(.scroll)
    .page-header,
  html.sidebar-left-big-icons.sidebar-left-with-menu:not(
      .sidebar-right-opened
    ):not(.scroll)
    .inner-menu {
    left: 152px;
  }

  html.sidebar-left-big-icons.sidebar-left-with-menu:not(
      .sidebar-right-opened
    ):not(.scroll)
    .content-with-menu
    .inner-toolbar {
    left: 450px;
  }

  html.sidebar-left-big-icons.sidebar-left-with-menu:not(.sidebar-right-opened)
    .inner-menu {
    border-left: 2px solid #282d36;
  }

  html.sidebar-left-big-icons.sidebar-left-with-menu.sidebar-left-collapsed:not(
      .sidebar-right-opened
    ):not(.scroll)
    .content-body {
    margin-left: 55px;
  }

  html.sidebar-left-big-icons.sidebar-left-with-menu.sidebar-left-collapsed:not(
      .sidebar-right-opened
    ):not(.scroll)
    .content-with-menu
    .inner-toolbar {
    left: 355px;
  }

  html.sidebar-left-big-icons.sidebar-left-with-menu.sidebar-left-collapsed:not(
      .sidebar-right-opened
    ):not(.scroll)
    .page-header,
  html.sidebar-left-big-icons.sidebar-left-with-menu.sidebar-left-collapsed:not(
      .sidebar-right-opened
    ):not(.scroll)
    .inner-menu,
  html.sidebar-left-big-icons.sidebar-left-with-menu.sidebar-left-collapsed:not(
      .sidebar-right-opened
    ):not(.scroll)
    .inner-menu-toggle {
    left: 55px;
  }

  html.sidebar-left-big-icons.sidebar-left-with-menu.sidebar-left-collapsed:not(
      .scroll
    )
    .inner-body {
    margin-left: 300px;
  }

  html.sidebar-left-big-icons.sidebar-left-with-menu.scroll .content-with-menu {
    display: flex;
    width: calc(100% + 80px);
    height: calc(100% + 40px);
    border-top: 0;
    margin: -40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1365px) {
  html.sidebar-left-big-icons.sidebar-left-with-menu {
    /* Sidebar Left Opened - Sidebar Right Closed / Not Scroll */
    /* Sidebar Left Collapsed */
    /* Inner Menu Opened */
  }

  html.sidebar-left-big-icons.sidebar-left-with-menu .inner-body {
    width: calc(100% - 153px);
  }

  html.sidebar-left-big-icons.sidebar-left-with-menu .content-body {
    margin-left: 0;
  }

  html.sidebar-left-big-icons.sidebar-left-with-menu:not(
      .sidebar-right-opened
    ):not(.scroll)
    .inner-menu-toggle {
    left: 152px;
  }

  html.sidebar-left-big-icons.sidebar-left-with-menu:not(
      .sidebar-right-opened
    ):not(.scroll)
    .content-with-menu
    .inner-toolbar {
    left: 152px;
  }

  html.sidebar-left-big-icons.sidebar-left-with-menu.sidebar-left-collapsed
    .inner-body {
    width: 100% !important;
    margin-left: 0 !important;
  }

  html.sidebar-left-big-icons.sidebar-left-with-menu.sidebar-left-collapsed.inner-menu-opened:not(
      .sidebar-right-opened
    ):not(.scroll)
    .content-body {
    margin-left: 355px;
  }

  html.sidebar-left-big-icons.sidebar-left-with-menu.sidebar-left-collapsed.inner-menu-opened:not(
      .sidebar-right-opened
    ):not(.scroll)
    .content-with-menu
    .inner-toolbar {
    left: 355px;
  }

  html.sidebar-left-big-icons.sidebar-left-with-menu.sidebar-left-collapsed:not(
      .sidebar-right-opened
    ):not(.scroll)
    .content-with-menu
    .inner-toolbar {
    left: 55px;
  }

  html.sidebar-left-big-icons.sidebar-left-with-menu.inner-menu-opened:not(
      .sidebar-right-opened
    ):not(.scroll)
    .content-body {
    margin-left: 300px;
  }

  html.sidebar-left-big-icons.sidebar-left-with-menu.inner-menu-opened:not(
      .sidebar-right-opened
    ):not(.scroll)
    .content-with-menu
    .inner-toolbar {
    left: 452px;
  }
}

@media only screen and (min-width: 768px) {
  html.left-sidebar-panel {
    /* Sidebar Right Opened */
    /* Fixed */
    /* Boxed */
    /* ie9 */
  }

  html.left-sidebar-panel .inner-wrapper {
    padding-top: 85px;
  }

  html.left-sidebar-panel .content-body {
    padding: 0;
    margin-right: 25px;
  }

  html.left-sidebar-panel .page-header {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;
  }

  html.left-sidebar-panel .sidebar-left {
    margin: 0 25px 25px;
    border-radius: 5px;
    overflow: hidden;
  }

  html.left-sidebar-panel.sidebar-right-opened .sidebar-left {
    margin: 0 25px 0 0;
  }

  html.left-sidebar-panel.fixed {
    /* Fixed & Sidebar Right Opened */
  }

  html.left-sidebar-panel.fixed .page-header {
    position: relative;
    left: 0;
    top: 0;
  }

  html.left-sidebar-panel.fixed .content-body {
    margin-left: 350px;
  }

  html.left-sidebar-panel.fixed .sidebar-left {
    margin: 25px;
    padding-bottom: 0;
  }

  html.left-sidebar-panel.fixed.sidebar-right-opened .page-header {
    margin-right: 0;
  }

  html.left-sidebar-panel.fixed.sidebar-right-opened .sidebar-left {
    margin-left: 0;
  }

  html.left-sidebar-panel.fixed.sidebar-right-opened .content-body {
    margin-left: 325px;
  }

  html.left-sidebar-panel.ie9.no-overflowscrolling .nano {
    min-height: 100vh;
  }

  html.left-sidebar-panel.ie9.no-overflowscrolling .nano > .nano-content {
    position: static;
  }

  html.left-sidebar-panel.ie9 .sidebar-left {
    left: 25px;
  }

  html.left-sidebar-panel.ie9 .content-body {
    padding-right: 70px;
    left: 50px;
  }
}

/* Sidebar Right */
.sidebar-right {
  z-index: 1010;
}

.sidebar-right .sidebar-right-wrapper {
  padding: 20px;
}

.sidebar-right h6 {
  margin: 0;
  color: #777;
  text-transform: uppercase;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 600;
}

.sidebar-right .mobile-close {
  display: block;
  background: #000;
  color: #999;
  left: 0;
  line-height: 20px;
  padding: 10px 10px 12px 20px;
  position: relative;
  overflow: hidden;
  width: 100%;
  text-align: left;
  text-decoration: none;
  border-radius: 5px;
  border-bottom: 1px solid #292929;
}

.sidebar-right .mobile-close i {
  margin-left: 5px;
  vertical-align: middle;
}

/* If desktop is seeing mobile res, fix scrollbars */
@media only screen and (max-width: 767px) {
  html.no-mobile-device.custom-scroll .sidebar-right .nano > .nano-content {
    overflow: scroll;
    overflow-x: hidden;
  }
}

/* Nano Scroller Plugin */
html.no-overflowscrolling .nano {
  height: 100%;
  position: relative;
  overflow: hidden;
  width: 100%;
}

html.no-overflowscrolling .nano > .nano-content {
  bottom: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
  right: 0;
  top: 0;
}

html.no-overflowscrolling .nano > .nano-content:focus {
  outline: none;
}

html.no-overflowscrolling .nano > .nano-content::-webkit-scrollbar {
  display: none;
  visibility: hidden;
}

html.no-overflowscrolling
  .nano.has-scrollbar
  > .nano-content::-webkit-scrollbar {
  display: block;
  visibility: visible;
}

html.no-overflowscrolling .nano > .nano-pane {
  bottom: 0;
  position: absolute;
  opacity: 0.01;
  right: 0;
  top: 0;
  visibility: hidden\9;
  /* Target only IE7 and IE8 with this hack */
  width: 4px;
  transition: 0.2s;
}

html.no-overflowscrolling .nano > .nano-pane > .nano-slider {
  background: #ccc;
  margin: 0;
  position: relative;
  visibility: hidden;
}

html.no-overflowscrolling .nano:hover > .nano-pane,
html.no-overflowscrolling .nano .nano-pane.active,
html.no-overflowscrolling .nano .nano-pane.flashed {
  opacity: 0.99;
}

html.no-overflowscrolling .nano:hover > .nano-pane > .nano-slider {
  visibility: visible;
}

html.no-overflowscrolling.custom-scroll .nano > .nano-content {
  overflow: scroll;
  overflow-x: hidden;
}

html.no-overflowscrolling .sidebar-left .nano {
  background: #e6e6ea;
  box-shadow: -5px 0 0 #5a5c63 inset;
}

html.no-overflowscrolling.sidebar-light:not(.dark) .sidebar-left .nano {
  background: #fff;
  box-shadow: -5px 0 0 #f6f6f6 inset;
}

html.no-overflowscrolling.sidebar-light:not(.dark) .sidebar-right .nano {
  background: #f6f6f6;
  box-shadow: -5px 0 0 #f6f6f6 inset;
}

html.no-overflowscrolling.sidebar-light:not(.dark) .inner-menu .nano {
  background: #fff;
  box-shadow: -5px 0 0 #e2e3e6 inset;
}

@media only screen and (max-width: 767px) {
  html.no-overflowscrolling .sidebar-left .nano > .nano-content,
  html.no-overflowscrolling .sidebar-right .nano > .nano-content,
  html.no-overflowscrolling .inner-menu .nano > .nano-content {
    overflow: scroll !important;
    overflow-x: hidden !important;
  }
}

@media only screen and (min-width: 768px) {
  html.overflowscrolling.fixed .sidebar-left .nano,
  html.overflowscrolling.fixed .sidebar-right .nano,
  html.overflowscrolling.fixed .inner-menu .nano {
    height: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }

  html.overflowscrolling.fixed .sidebar-left .nano > .nano-pane > .nano-slider,
  html.overflowscrolling.fixed .sidebar-right .nano > .nano-pane > .nano-slider,
  html.overflowscrolling.fixed .inner-menu .nano > .nano-pane > .nano-slider {
    visibility: visible;
  }

  html.overflowscrolling.fixed.custom-scroll
    .sidebar-left
    .nano
    > .nano-content,
  html.overflowscrolling.fixed.custom-scroll
    .sidebar-right
    .nano
    > .nano-content,
  html.overflowscrolling.fixed.custom-scroll .inner-menu .nano > .nano-content {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  /* Safari fix */
  html.safari.no-mobile-device.no-overflowscrolling .nano {
    position: absolute !important;
  }
}

/* Page Header */
.page-header {
  z-index: 1001;
  /* Left Breadcumb */
  /* Left Inline Breadcrumb */
  /* Mobile */
}

.page-header h2 {
  color: #e1e1e6;
  border-bottom: 4px solid #ccc;
  box-sizing: content-box;
  display: inline-block;
  float: left;
  height: 50px;
  font-size: 19.2px;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 50px;
  margin: 0 0 0 -1px;
  padding: 0 22px 0 20px;
}

.page-header .breadcrumbs {
  display: inline-block;
  font-size: 0;
  line-height: 50px;
  margin: 0;
  padding: 0;
}

.page-header .breadcrumbs li {
  color: #e1e1e6;
  display: inline-block;
  font-weight: 300;
}

.page-header .breadcrumbs li:after {
  content: "/";
  display: inline-block;
  font-size: 13.6px;
  font-size: 0.85rem;
  margin: 0 10px;
  vertical-align: middle;
}

.page-header .breadcrumbs li:last-child:after {
  display: none;
}

.page-header .breadcrumbs .fa-home,
.page-header .breadcrumbs .bx-home-alt {
  font-size: 22.4px;
  font-size: 1.4rem;
}

.page-header .breadcrumbs i {
  vertical-align: middle;
}

.page-header .breadcrumbs a,
.page-header .breadcrumbs span {
  color: #e1e1e6;
  display: inline-block;
  font-size: 13.6px;
  font-size: 0.85rem;
  line-height: 20px;
  vertical-align: middle;
}

.page-header .sidebar-right-toggle {
  cursor: pointer;
  color: #c3c3c3;
  display: inline-block;
  font-size: 17px;
  margin: 0 0 0 10px;
  height: 50px;
  width: 50px;
  vertical-align: top;
  text-align: center;
  transition: all 0.15s ease-in-out;
}

.page-header .sidebar-right-toggle i {
  line-height: 53px;
  vertical-align: middle;
}

.page-header .sidebar-right-toggle:hover {
  color: #ccc;
}

.page-header.page-header-left-breadcrumb {
  position: relative;
  background: transparent !important;
  height: auto;
  top: 3px;
  margin: 0 0 15px;
  padding-left: 8px;
  border: 0 !important;
  box-shadow: none !important;
  z-index: 0;
}

.page-header.page-header-left-breadcrumb h2 {
  float: none;
  height: 40px;
  line-height: 30px;
  color: #33353f;
  padding: 0;
  border: 0;
}

.page-header.page-header-left-breadcrumb .right-wrapper {
  float: none;
  padding-left: 0;
}

.page-header.page-header-left-breadcrumb .breadcrumbs {
  margin-top: -10px;
  line-height: 30px;
}

.page-header.page-header-left-breadcrumb .breadcrumbs a,
.page-header.page-header-left-breadcrumb .breadcrumbs span {
  color: #757677;
}

.page-header.page-header-left-breadcrumb .sidebar-right-toggle {
  position: absolute;
  top: 30%;
  right: -25px;
  color: #33353f;
  transform: translateY(-50%);
}

.page-header.page-header-left-inline-breadcrumb {
  display: flex;
  align-items: center;
  background: transparent;
  height: auto;
  padding: 25px 25px 0;
  margin-bottom: 20px;
  border: 0;
  box-shadow: none;
}

.page-header.page-header-left-inline-breadcrumb h2 {
  flex: 0 0 auto;
  border: 0;
  color: #33353f;
}

.page-header.page-header-left-inline-breadcrumb .breadcrumbs a,
.page-header.page-header-left-inline-breadcrumb .breadcrumbs span {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  color: #757677;
  text-transform: uppercase;
}

.page-header.page-header-left-inline-breadcrumb .right-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .page-header.page-header-left-inline-breadcrumb {
    flex-wrap: wrap;
  }

  .page-header.page-header-left-inline-breadcrumb .right-wrapper {
    padding-left: 20px;
  }
}

@media (max-width: 767px) {
  .page-header .page-header-left-breadcrumb {
    margin-top: 15px;
  }

  .page-header .page-header-left-breadcrumb .sidebar-right-toggle {
    right: -15px;
  }

  .page-header.page-header-left-inline-breadcrumb {
    padding-left: 0;
  }
}

html.modern.fixed .content-body {
  margin-top: 10px;
}

html.modern.fixed .page-header.page-header-left-inline-breadcrumb {
  background: #f5f5f8;
  padding-bottom: 12px;
}

@media (max-width: 991px) and (min-width: 768px) {
  html.fixed .page-header.page-header-left-inline-breadcrumb + .ecommerce-form,
  html.fixed .page-header.page-header-left-inline-breadcrumb + .row {
    margin-top: 50px;
  }
}

/* Header Dark - Page Header */
html.dark .page-header,
html.header-dark .page-header {
  border-left-color: #5a5c63;
  box-shadow: 1px 3px 0 1px #5a5c63;
}

/* Sidebar Light- Page Header */
html.sidebar-light:not(.dark) .page-header {
  border-left-color: #e6e6e6;
  background: #f6f6f6;
  box-shadow: 1px 3px 0 1px #e2e3e6;
}

html.sidebar-light:not(.dark) .page-header h2 {
  color: #333;
}

html.sidebar-light:not(.dark) .page-header .breadcrumbs a,
html.sidebar-light:not(.dark) .page-header .breadcrumbs span {
  color: #333;
}

html.sidebar-light:not(.dark) .page-header .sidebar-right-toggle i {
  color: #333;
}

/* Page Header - Mobile */
@media only screen and (max-width: 767px) {
  .page-header {
    padding-right: 80px;
  }

  .page-header .breadcrumbs {
    display: none;
  }

  .page-header h2 {
    font-size: 16px;
    padding: 0 15px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }

  .page-header .sidebar-right-toggle {
    position: absolute;
    right: 0;
    top: 0;
  }
}

html.ie {
  /* Page header */
}

html.ie .page-header {
  /* Left Breadcumb */
}

html.ie .page-header.page-header-left-breadcrumb h2 {
  float: left;
}

/* Margin to show the menu button on mobile */
@media (max-width: 991px) {
  html.has-tab-navigation .header-right {
    margin-right: 50px;
  }
}

html.has-tab-navigation .toggle-menu {
  background: #ccc;
  border-radius: 100px;
  color: #fff;
  height: 30px;
  line-height: 30px;
  position: absolute;
  right: 15px;
  text-align: center;
  top: 14px;
  width: 30px;
}

html.has-tab-navigation .inner-wrapper {
  padding: 80px 15px 15px;
  background-color: #e6e6ea;
}

@media (max-width: 991px) {
  html.has-tab-navigation .inner-wrapper {
    padding: 75px 15px 15px;
  }
}

@media (max-width: 767px) {
  html.has-tab-navigation .inner-wrapper {
    padding: 15px;
  }
}

html.has-tab-navigation {
  /* Mobile */
  /* IE */
}

html.has-tab-navigation .content-body {
  padding: 25px 25px;
  background-color: #eee;
  border-radius: 0 7px 7px 7px;
  transition: ease padding 300ms;
  /* Only for desktop */
}

@media (min-width: 992px) {
  html.has-tab-navigation .content-body.tab-menu-opened {
    padding: 70px 25px 25px;
  }
}

html.has-tab-navigation.dark .content-body,
html.has-tab-navigation.dark.boxed .content-body {
  background-color: #17191d;
}

@media (max-width: 991px) {
  html.has-tab-navigation .content-body {
    border-radius: 5px;
  }
}

html.has-tab-navigation.ie .content-body {
  flex: none;
}

html.has-tab-navigation .page-header {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0 0 10px 0;
}

html.has-tab-navigation .page-header .breadcrumbs a,
html.has-tab-navigation .page-header .breadcrumbs span {
  font-size: 13px;
}

html.has-tab-navigation.dark .page-header h2 {
  color: #fff;
}

html.has-tab-navigation .not-included {
  color: #b7b7b7;
  display: block;
  font-size: 0.8em;
  font-style: normal;
  margin: -4px 0;
  padding: 0;
}

html.has-tab-navigation .not-included.custom-pos-1 {
  margin-top: 1px;
  margin-left: 8px;
}

@media (max-width: 991px) {
  html.has-tab-navigation .not-included.custom-pos-1 {
    float: none !important;
    display: inline-block;
  }
}

html.has-tab-navigation .tip {
  display: inline-block;
  padding: 0 5px;
  background: #5a5c63;
  color: #fff;
  text-shadow: none;
  border-radius: 3px;
  margin-left: 8px;
  position: relative;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
}

html.has-tab-navigation .tip:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(23, 23, 23, 0);
  border-right-color: #5a5c63;
  border-width: 5px;
  margin-top: -5px;
}

html.has-tab-navigation .tip.skin {
  color: #5a5c63;
}

/* Desktop */
@media (min-width: 992px) {
  .tab-navigation {
    height: initial !important;
    display: block !important;
    z-index: 3;
  }

  .tab-navigation nav > ul {
    position: relative;
  }

  .tab-navigation nav > ul > li {
    position: static;
  }

  .tab-navigation nav > ul > li.active a {
    background: transparent;
  }

  .tab-navigation nav > ul > li.active a:hover,
  .tab-navigation nav > ul > li.active a:focus {
    background: transparent;
  }

  .tab-navigation nav > ul > li.active > a {
    background: #17191d;
  }

  .tab-navigation nav > ul > li.active > a:hover,
  .tab-navigation nav > ul > li.active > a:focus {
    background: #17191d;
  }

  .tab-navigation nav > ul > li.nav-expanded > a {
    color: #33353f;
    background: #fff;
  }

  .tab-navigation nav > ul > li.nav-expanded > a:hover,
  .tab-navigation nav > ul > li.nav-expanded > a:focus {
    color: #33353f;
    background: #fff;
  }

  .tab-navigation nav > ul > li.nav-expanded > ul {
    display: block;
  }

  .tab-navigation nav > ul > li:hover:not(.nav-expanded) > a {
    color: #fff;
    background: #ccc;
  }

  .tab-navigation nav > ul > li > a {
    background: #17191d none repeat scroll 0 0;
    border-radius: 4px 4px 0 0 !important;
    color: #fff;
    font-weight: 600;
    min-width: 130px;
    padding: 13px 30px;
    text-align: center;
    text-transform: uppercase;
    font-size: 11px;
  }

  .tab-navigation nav > ul > li > a .fa,
  .tab-navigation nav > ul > li > a .fab,
  .tab-navigation nav > ul > li > a .fal,
  .tab-navigation nav > ul > li > a .far,
  .tab-navigation nav > ul > li > a .fas {
    font-size: 14px;
    margin-right: 7px;
    position: relative;
    top: 1px;
  }

  .tab-navigation nav > ul > li a {
    cursor: pointer;
  }

  .tab-navigation nav > ul > li a.dropdown-toggle:after {
    border: none;
  }

  .tab-navigation nav > ul > li a .badge {
    margin-top: 4px;
    margin-left: 10px;
  }

  .tab-navigation nav > ul > li > ul {
    margin: 0;
    border: 0;
    border-radius: 0 7px 0px 0px;
    left: 0;
    right: 0;
    box-shadow: none;
  }

  .tab-navigation nav > ul > li > ul > li {
    position: relative;
    float: left;
  }

  .tab-navigation nav > ul > li > ul > li.nav-active > a {
    color: #ccc !important;
  }

  .tab-navigation nav > ul > li > ul > li:hover > a {
    color: #33353f;
  }

  .tab-navigation nav > ul > li > ul > li .dropdown-menu {
    padding: 2px 0;
    margin: 0;
    border: none;
  }

  .tab-navigation nav > ul > li > ul > li .dropdown-menu li a {
    padding: 7px 10px;
    min-width: 230px;
    color: #777;
    white-space: initial;
    font-size: 12px;
    border-bottom: 1px solid #f4f4f4;
  }

  .tab-navigation nav > ul > li > ul > li .dropdown-menu li a:hover {
    background-color: transparent;
    color: #33353f;
  }

  .tab-navigation nav > ul > li > ul > li .dropdown-menu li.nav-active > a {
    color: #ccc !important;
  }

  .tab-navigation nav > ul > li > ul > li .dropdown-menu li:hover > a {
    color: #33353f;
  }

  .tab-navigation
    nav
    > ul
    > li
    > ul
    > li
    .dropdown-menu
    li:hover.dropdown-submenu {
    position: relative;
  }

  .tab-navigation
    nav
    > ul
    > li
    > ul
    > li
    .dropdown-menu
    li:hover.dropdown-submenu
    > .dropdown-menu {
    display: block;
    opacity: 1;
    box-shadow: 0 20px 45px rgba(0, 0, 0, 0.08);
    left: 100%;
    top: 0;
    margin-top: -2px;
    margin-left: 0;
  }

  .tab-navigation nav > ul > li > ul > li .dropdown-menu li:last-child > a {
    border-bottom: none;
  }

  .tab-navigation nav > ul > li > ul > li > a {
    background-color: transparent !important;
    color: #777;
    font-size: 11px;
    font-weight: 700;
    padding: 10px 5px 10px 25px;
    text-transform: uppercase;
    white-space: initial;
  }

  .tab-navigation nav > ul > li > ul > li > a:hover,
  .tab-navigation nav > ul > li > ul > li > a:focus {
    color: #33353f;
  }

  .tab-navigation
    nav
    > ul
    > li
    > ul
    > li:hover.dropdown-submenu
    > .dropdown-menu {
    top: auto;
    display: block;
    opacity: 1;
    box-shadow: 0 20px 45px rgba(0, 0, 0, 0.08);
    margin-left: 12px;
    padding: 2px 5px;
  }
}

/* Mobile */
@media (max-width: 991px) {
  .tab-navigation {
    margin-bottom: 25px;
    border-radius: 5px;
    overflow: hidden;
  }

  .tab-navigation nav > ul > li {
    width: 100%;
    margin-left: 0;
  }

  .tab-navigation nav > ul > li.expanding > a {
    color: #33353f !important;
    background: #fff !important;
  }

  .tab-navigation nav > ul > li.nav-expanded > a {
    color: #33353f !important;
    background: #fff !important;
  }

  .tab-navigation nav > ul > li.nav-expanded > ul {
    display: block;
  }

  .tab-navigation nav > ul > li.active > a {
    background: #17191d;
  }

  .tab-navigation nav > ul > li.active > a:hover,
  .tab-navigation nav > ul > li.active > a:focus {
    background: #17191d;
  }

  .tab-navigation nav > ul > li.active ul li a {
    background: transparent !important;
  }

  .tab-navigation nav > ul > li.active ul li a:hover {
    background: #f5f5f5 !important;
  }

  .tab-navigation nav > ul > li:hover:not(.nav-expanded) > a {
    background: #17191d;
  }

  .tab-navigation nav > ul > li:hover:not(.nav-expanded) > a:hover,
  .tab-navigation nav > ul > li:hover:not(.nav-expanded) > a:focus {
    background: #17191d;
  }

  .tab-navigation nav > ul > li > a {
    border-radius: 0;
    color: #fff;
    background: #17191d;
  }

  .tab-navigation nav > ul > li a {
    cursor: pointer;
  }

  .tab-navigation nav > ul > li .fa,
  .tab-navigation nav > ul > li .fab,
  .tab-navigation nav > ul > li .fal,
  .tab-navigation nav > ul > li .far,
  .tab-navigation nav > ul > li .fas {
    margin-right: 10px;
  }

  .tab-navigation nav > ul > li > ul {
    position: static;
    width: 100%;
    margin: 0;
    border: 0;
    border-radius: 0;
    background: #fff;
    padding-left: 23px;
    padding-top: 0;
  }

  .tab-navigation nav > ul > li > ul > li.nav-expanded > ul {
    display: block;
  }

  .tab-navigation nav > ul > li > ul > li.dropdown-submenu:hover > a {
    color: #33353f;
  }

  .tab-navigation nav > ul > li > ul > li.active a {
    color: #777;
  }

  .tab-navigation nav > ul > li > ul > li.active a:hover,
  .tab-navigation nav > ul > li > ul > li.active a:focus {
    color: #33353f;
  }

  .tab-navigation nav > ul > li > ul > li > a {
    color: #777;
    padding: 10px 15px;
    white-space: initial;
  }

  .tab-navigation nav > ul > li > ul > li > a:hover,
  .tab-navigation nav > ul > li > ul > li > a:focus {
    color: #33353f;
    background-color: transparent;
  }

  .tab-navigation nav > ul > li > ul > li a {
    font-size: 13px;
  }

  .tab-navigation nav > ul > li > ul > li .dropdown-menu {
    position: static;
    float: none;
    box-shadow: none;
    border: none;
    padding-left: 15px;
    background-color: #fff;
    border-radius: 0;
  }

  .tab-navigation nav > ul > li > ul > li .dropdown-menu li.nav-expanded > ul {
    display: block;
  }

  .tab-navigation nav > ul > li > ul > li .dropdown-menu li:hover > a {
    color: #33353f;
  }

  .tab-navigation nav > ul > li > ul > li .dropdown-menu li > a {
    padding: 10px 15px;
    color: #777;
    white-space: initial;
  }

  .tab-navigation nav > ul > li > ul > li .dropdown-menu li > a:hover,
  .tab-navigation nav > ul > li > ul > li .dropdown-menu li > a:focus {
    color: #33353f;
    background-color: transparent;
  }
}

html.dark .tab-navigation nav > ul > li.nav-expanded > a {
  color: #fff;
  background: #282d36;
}

html.dark .tab-navigation nav > ul > li > a {
  color: #fff;
  background: #17191d;
}

html.dark .tab-navigation nav > ul > li > ul {
  background: #282d36;
}

html.dark .tab-navigation nav > ul > li > ul > li:hover > a {
  color: #ccc;
}

html.dark .tab-navigation nav > ul > li > ul > li .dropdown-menu {
  background-color: #282d36;
  border-radius: 0;
}

html.dark .tab-navigation nav > ul > li > ul > li .dropdown-menu li > a {
  color: #777;
  border-bottom-color: #35393d;
}

html.dark .tab-navigation nav > ul > li > ul > li .dropdown-menu li:hover > a {
  color: #ccc;
}

html.dark .tab-navigation nav > ul > li > ul > li > a {
  color: #777;
}

html.dark .tab-navigation nav > ul > li > ul > li > a:hover,
html.dark .tab-navigation nav > ul > li > ul > li > a:focus {
  background-color: transparent;
  color: #ccc;
}

/* Tab Navigation Mobile - Dark Colors */
@media (max-width: 991px) {
  html.dark .tab-navigation nav > ul > li.expanding > a {
    color: #fff !important;
    background: #282d36 !important;
  }

  html.dark .tab-navigation nav > ul > li.nav-expanded > a {
    color: #fff !important;
    background: #282d36 !important;
  }

  html.dark .tab-navigation nav > ul > li.active > a {
    background: #17191d;
  }

  html.dark .tab-navigation nav > ul > li.active > a:hover,
  html.dark .tab-navigation nav > ul > li.active > a:focus {
    background: #17191d;
  }

  html.dark .tab-navigation nav > ul > li.active ul li a {
    background: transparent !important;
  }

  html.dark .tab-navigation nav > ul > li.active ul li a:hover {
    background: #282d36 !important;
  }

  html.dark .tab-navigation nav > ul > li:hover:not(.nav-expanded) > a {
    background: #17191d;
  }

  html.dark .tab-navigation nav > ul > li:hover:not(.nav-expanded) > a:hover,
  html.dark .tab-navigation nav > ul > li:hover:not(.nav-expanded) > a:focus {
    background: #17191d;
  }

  html.dark .tab-navigation nav > ul > li > a {
    color: #fff;
    background: #17191d;
  }

  html.dark .tab-navigation nav > ul > li > ul {
    background: #282d36;
  }

  html.dark .tab-navigation nav > ul > li > ul > li.dropdown-submenu:hover > a {
    color: #ccc;
  }

  html.dark .tab-navigation nav > ul > li > ul > li .dropdown-menu {
    background-color: #282d36;
    border-radius: 0;
  }

  html.dark .tab-navigation nav > ul > li > ul > li .dropdown-menu li > a {
    color: #777;
    background-color: transparent;
  }

  html.dark
    .tab-navigation
    nav
    > ul
    > li
    > ul
    > li
    .dropdown-menu
    li:hover
    > a {
    color: #ccc;
  }

  html.dark .tab-navigation nav > ul > li > ul > li > a {
    color: #777;
  }

  html.dark .tab-navigation nav > ul > li > ul > li > a:hover,
  html.dark .tab-navigation nav > ul > li > ul > li > a:focus {
    background-color: transparent;
    color: #ccc;
  }
}

html.boxed.has-tab-navigation body {
  background: #e6e6ea;
}

html.boxed.has-tab-navigation .inner-wrapper {
  box-shadow: none;
}

@media (min-width: 992px) {
  html.boxed.has-tab-navigation .header {
    margin-top: -5px;
    border-top-color: transparent;
    border-bottom-color: transparent;
  }

  html.boxed.has-tab-navigation .header .separator {
    width: 1px;
    background-image: linear-gradient(#e6e6ea 0%, #121518, #e6e6ea 100%);
  }
}

html.ie .tab-navigation nav > ul > li a .badge {
  float: none !important;
}

html.ie .tab-navigation nav > ul > li a .not-included {
  float: none !important;
  display: inline;
}

/* ie9 */
html.ie9 .tab-navigation {
  display: table-row !important;
}

/* Content With Menu - Menu Faux Column for Scroll and Boxed Layouts Colors */
@media only screen and (min-width: 768px) {
  html.scroll .content-with-menu:before,
  html.boxed .content-with-menu:before {
    background: #e6e6ea;
  }

  html.scroll .content-with-menu:after,
  html.boxed .content-with-menu:after {
    background: #000;
    box-shadow: 0px 0 4px 2px rgba(0, 0, 0, 0.5);
  }
}

/* Unstyle nano for non fixed layouts */
html.scroll .inner-menu .nano,
html.scroll.no-overflowscrolling.custom-scroll .inner-menu .nano,
html.boxed .inner-menu .nano,
html.boxed.no-overflowscrolling.custom-scroll .inner-menu .nano {
  position: static;
  height: auto;
  overflow: visible;
  width: auto;
}

html.scroll .inner-menu .nano > .nano-content,
html.scroll.no-overflowscrolling.custom-scroll
  .inner-menu
  .nano
  > .nano-content,
html.boxed .inner-menu .nano > .nano-content,
html.boxed.no-overflowscrolling.custom-scroll
  .inner-menu
  .nano
  > .nano-content {
  position: static;
  overflow: visible;
}

@media only screen and (max-width: 767px) {
  html.fixed .inner-menu .nano {
    position: static;
    height: auto;
    overflow: visible;
    width: auto;
  }

  html.fixed .inner-menu .nano .nano-content {
    margin-right: 0;
    position: static;
    overflow: visible;
  }
}

/* Fix padding when fixed */
@media only screen and (min-width: 768px) {
  html.fixed .inner-menu {
    padding: 0;
  }

  html.fixed .inner-menu .nano-content {
    padding: 35px;
  }

  html.fixed .inner-menu .nano-content:after {
    display: block;
    content: "";
    height: 35px;
  }
}

/* Content With Menu - Inner Menu Style */
.inner-menu {
  background: #e6e6ea;
  border-right: 1px solid #242830;
  color: #5a5c63;
  padding: 0;
  margin: 0;
}

.inner-menu .title {
  color: #465162;
  font-weight: 600;
  margin: 10px 0;
  padding: 0;
  text-transform: uppercase;
}

.inner-menu hr.separator {
  background-image: linear-gradient(
    to left,
    transparent,
    rgba(0, 0, 0, 0.4),
    transparent
  );
  margin: 20px -35px 20px;
}

.inner-menu a,
.inner-menu a:hover {
  color: #5a5c63;
}

.inner-menu a.menu-item {
  color: #5a5c63;
  display: block;
  margin: 0 -35px 0 -35px;
  padding: 10px 50px 10px 50px;
  text-decoration: none;
}

.inner-menu a.menu-item:hover {
  background: #5a5c63;
  color: #5a5c63;
  text-decoration: none;
}

.inner-menu a.menu-item.active {
  background: #282d36;
}

.inner-menu a.menu-item .label {
  font-weight: normal;
  font-size: 10px;
  font-size: 16px;
  font-size: 1rem;
  padding: 0.3em 0.7em 0.4em;
  margin: 0.2em -1em 0 0;
}

html.sidebar-light:not(.dark) .inner-menu {
  background: #fff;
  border-right-color: #e2e3e6;
  color: #777;
}

html.sidebar-light:not(.dark) .inner-menu .title {
  color: #465162;
}

html.sidebar-light:not(.dark) .inner-menu hr.separator {
  background-image: linear-gradient(
    to left,
    transparent,
    rgba(0, 0, 0, 0.1),
    transparent
  );
}

html.sidebar-light:not(.dark) .inner-menu a.menu-item {
  color: #777;
}

html.sidebar-light:not(.dark) .inner-menu a.menu-item:hover {
  background: #e2e3e6;
  color: #777;
}

html.sidebar-light:not(.dark) .inner-menu a.menu-item.active {
  background: #e2e3e6;
}

/* Content With Menu - Toggle */
.inner-menu-toggle,
.inner-menu .inner-menu-toggle-inside {
  background: #000;
  color: #999;
  left: 0;
  line-height: 52px;
  position: relative;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
}

.inner-menu-toggle:after,
.inner-menu .inner-menu-toggle-inside:after {
  box-shadow: 0 0px 3px 0 rgba(255, 255, 255, 0.7);
  bottom: -1px;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
}

.inner-menu-toggle a,
.inner-menu .inner-menu-toggle-inside a {
  display: block;
  padding-left: 20px;
  text-decoration: none;
}

.inner-menu-toggle a i,
.inner-menu .inner-menu-toggle-inside a i {
  vertical-align: middle;
}

.inner-menu-toggle .inner-menu-collapse,
.inner-menu .inner-menu-toggle-inside .inner-menu-collapse {
  display: none;
}

html.sidebar-light:not(.dark) .inner-menu-toggle,
html.sidebar-light:not(.dark) .inner-menu .inner-menu-toggle-inside {
  background: #e2e3e6;
  color: #777;
}

html.sidebar-light:not(.dark) .inner-menu-toggle > a,
html.sidebar-light:not(.dark) .inner-menu .inner-menu-toggle-inside > a {
  color: #777;
}

.inner-menu-toggle a i {
  margin-left: 5px;
}

.inner-menu-toggle-inside {
  margin: -35px -35px 15px -35px;
}

.inner-menu-toggle-inside .inner-menu-collapse i {
  margin-right: 5px;
}

.inner-menu-toggle-inside .inner-menu-expand i {
  margin-left: 5px;
}

/* Content With Menu - Toggle - Outside */
.inner-menu-toggle {
  display: none;
}

/* Content With Menu - Inner Menu Content */
.inner-menu-content {
  display: none;
}

html.inner-menu-opened
  .inner-menu
  .inner-menu-toggle-inside
  .inner-menu-collapse {
  display: block;
}

html.inner-menu-opened .inner-menu-expand {
  display: none;
}

html.inner-menu-opened .inner-menu-content {
  display: block;
}

/* Content With Menu - Responsive */
@media only screen and (max-width: 767px) {
  .inner-menu .hidden-xs-inline {
    display: none;
  }

  .inner-menu .inner-menu-content {
    padding: 20px;
  }

  .inner-menu-toggle-inside {
    margin: 0;
  }
}

/* Content With Menu - Toolbar + Layout Fixed */
@media only screen and (min-width: 768px) {
  html.fixed.inner-menu-opened {
    left: 600px;
  }

  html.fixed .inner-menu-toggle {
    position: fixed;
    left: 300px;
  }
}

html.dark .inner-menu-toggle:after,
html.dark .inner-menu .inner-menu-toggle-inside:after {
  box-shadow: none;
}

/* Header Nav Main Mobile */
@media (max-width: 991px) {
  .header-nav {
    clear: both;
    float: none;
  }

  .header-nav-main {
    background: #fff;
    padding: 10px;
    max-height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .header-nav-main {
    position: relative;
    top: 9px;
  }
}

@media (max-width: 991px) {
  .header-nav-main.collapsing {
    overflow: hidden;
  }

  .header-nav-main nav {
    margin: 0 0 6px;
  }

  .header-nav-main nav > ul {
    flex-direction: column;
  }

  .header-nav-main nav > ul li {
    border-bottom: 1px solid #e8e8e8;
    clear: both;
    display: block;
    float: none;
    margin: 0;
    padding: 0;
    position: relative;
  }

  .header-nav-main nav > ul li a {
    font-size: 13px;
    font-style: normal;
    line-height: 20px;
    padding: 7px 8px;
    margin: 1px 0;
    border-radius: 4px;
    white-space: initial;
  }

  .header-nav-main nav > ul li a .fa-caret-down {
    line-height: 35px;
    min-height: 38px;
    min-width: 30px;
    position: absolute;
    right: 5px;
    text-align: center;
    top: 0;
  }

  .header-nav-main nav > ul li a.dropdown-toggle:after {
    content: none;
  }

  .header-nav-main nav > ul li.dropdown .dropdown-menu {
    background: transparent;
    padding: 0;
    margin: 0;
    font-size: 13px;
    box-shadow: none;
    border-radius: 0;
    border: 0;
    clear: both;
    display: none;
    float: none;
    position: static;
    border-top: 0 !important;
  }

  .header-nav-main nav > ul li.dropdown .dropdown-menu li a {
    color: #333;
  }

  .header-nav-main
    nav
    > ul
    li.dropdown
    .dropdown-menu
    li.dropdown-submenu.showed
    > .dropdown-menu {
    display: block;
    margin-left: 20px;
  }

  .header-nav-main nav > ul li.dropdown.showed > .dropdown-menu {
    display: block;
    margin-left: 20px;
  }

  .header-nav-main nav > ul li.dropdown-mega .dropdown-mega-sub-title {
    margin-top: 10px;
    display: block;
  }

  .header-nav-main nav > ul li.dropdown-mega .dropdown-mega-sub-nav {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .header-nav-main nav > ul li.dropdown-mega .dropdown-mega-sub-nav > li > a {
    display: block;
    text-decoration: none;
    color: #333;
  }

  .header-nav-main nav > ul li.dropdown-mega .mega-sub-nav-toggle {
    width: 20px;
    text-align: center;
  }

  .header-nav-main nav > ul li:last-child {
    border-bottom: 0;
  }

  .header-nav-main nav > ul > li > a {
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 1px;
    margin-bottom: 1px;
    white-space: initial;
  }

  .header-nav-main nav > ul > li.active > a,
  .header-nav-main nav > ul > li.active > a:focus,
  .header-nav-main nav > ul > li.active > a:hover {
    color: #fff;
  }

  .header-nav-main nav .not-included {
    margin: 0;
  }

  .header-nav-main a > .thumb-info-preview {
    display: none !important;
  }

  .header-btn-collapse-nav {
    outline: 0;
    float: right;
    margin-top: 10px;
    margin-right: 15px;
  }

  .header-btn-collapse-nav:hover,
  .header-btn-collapse-nav:focus {
    color: #fff;
  }

  .header-nav-bar {
    margin: 0 auto;
  }

  .header-nav-bar .header-btn-collapse-nav {
    margin-top: 14px;
  }

  .header-transparent .header-nav-main {
    padding: 10px;
    margin-bottom: 10px;
    background: #fff;
    border-radius: 4px;
  }

  .header-semi-transparent .header-nav-main {
    padding: 10px;
    margin-bottom: 10px;
    background: #fff;
    border-radius: 4px;
  }

  .header-semi-transparent-light .header-nav-main {
    padding: 10px;
    margin-bottom: 10px;
    background: #fff;
    border-radius: 4px;
  }
}

/* Header Nav Main */
@media (min-width: 992px) {
  .header-nav-main {
    float: right;
    margin: 8px 0 0;
    min-height: 45px;
  }

  .header-nav-main nav > ul > li {
    margin-left: 2px;
  }

  .header-nav-main nav > ul > li:first-child {
    margin-left: 10px;
  }

  .header-nav-main nav > ul > li > a {
    display: inline-block;
    border-radius: 4px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    padding: 10px;
    text-transform: uppercase;
    white-space: initial;
  }

  .header-nav-main nav > ul > li > a:focus {
    background: transparent;
    color: #ccc;
  }

  .header-nav-main nav > ul > li > a.dropdown-toggle .fa-caret-down {
    display: none;
  }

  .header-nav-main nav > ul > li > a.dropdown-toggle:after {
    border-color: #ccc transparent transparent transparent;
    border-style: solid;
    border-width: 4px;
    content: " ";
    float: right;
    margin-top: 7px;
    margin-left: 4px;
  }

  .header-nav-main nav > ul > li.show > a,
  .header-nav-main nav > ul > li:hover > a,
  .header-nav-main nav > ul > li.active > a {
    background: #ccc;
    color: #fff;
  }

  .header-nav-main nav > ul > li.dropdown .dropdown-menu {
    top: -10000px;
    display: block;
    opacity: 0;
    left: auto;
    border-radius: 0 4px 4px;
    border: 0;
    border-top: 5px solid #ccc;
    box-shadow: 0 20px 45px rgba(0, 0, 0, 0.08);
    margin: -3px 0 0 0;
    min-width: 200px;
    padding: 5px;
    text-align: left;
  }

  .header-nav-main nav > ul > li.dropdown .dropdown-menu li a {
    border-bottom: 1px solid #f4f4f4;
    color: #777;
    font-size: 0.8em;
    font-weight: 400;
    padding: 6px 20px 6px 8px;
    position: relative;
    text-transform: none;
    white-space: initial;
  }

  .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu {
    position: relative;
  }

  .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu
    > a
    .fa-caret-down {
    display: none;
  }

  .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu
    > a:after {
    border-color: transparent transparent transparent #ccc;
    border-style: solid;
    border-width: 4px 0 4px 4px;
    content: " ";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu
    > .dropdown-menu {
    display: block;
    margin-top: -10px;
    margin-left: -1px;
    border-radius: 4px;
    opacity: 0;
  }

  .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu:hover
    > .dropdown-menu {
    top: 0;
    opacity: 1;
  }

  .header-nav-main nav > ul > li.dropdown .dropdown-menu li:last-child a {
    border-bottom: 0;
  }

  .header-nav-main
    nav
    > ul
    > li.dropdown.show
    li.dropdown-submenu
    > .dropdown-menu,
  .header-nav-main
    nav
    > ul
    > li.dropdown:hover
    li.dropdown-submenu
    > .dropdown-menu {
    left: 100%;
  }

  .header-nav-main nav > ul > li.dropdown.show > a,
  .header-nav-main nav > ul > li.dropdown:hover > a {
    padding-bottom: 15px;
  }

  .header-nav-main nav > ul > li.dropdown.show > .dropdown-menu,
  .header-nav-main nav > ul > li.dropdown:hover > .dropdown-menu {
    top: auto;
    display: block;
    opacity: 1;
  }

  .header-nav-main nav > ul > li.dropdown-reverse .dropdown-menu li a {
    padding-right: 8px;
    padding-left: 20px;
  }

  .header-nav-main
    nav
    > ul
    > li.dropdown-reverse
    .dropdown-menu
    li.dropdown-submenu
    > a:after {
    border-width: 4px 4px 4px 0;
  }

  .header-nav-main nav > ul > li.dropdown-mega {
    position: static;
  }

  .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu {
    border-radius: 4px;
    left: 15px;
    right: 15px;
    width: auto;
  }

  .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-content {
    padding: 20px 30px;
  }

  .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-sub-title {
    color: #333333;
    display: block;
    font-size: 1em;
    font-weight: 600;
    margin-top: 20px;
    padding-bottom: 5px;
    text-transform: uppercase;
  }

  .header-nav-main
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-title:first-child {
    margin-top: 0;
  }

  .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-sub-nav {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-sub-nav > li > a {
    border: 0 none;
    border-radius: 4px;
    color: #777;
    display: block;
    font-size: 0.9em;
    font-weight: normal;
    margin: 0 0 0 -8px;
    padding: 3px 8px;
    text-shadow: none;
    text-transform: none;
    text-decoration: none;
  }

  .header-nav-main
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-nav
    > li:hover
    > a {
    background: #f4f4f4;
  }

  .header-nav-main
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-nav
    .mega-sub-nav-toggle {
    width: 20px;
    text-align: center;
  }

  .header-nav-main
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-nav
    .dropdown-mega-sub-nav {
    padding-left: 15px;
  }

  .header-nav-main nav > ul > li .badge {
    margin-right: -16px;
    margin-top: 4px;
  }

  .header-nav-main.header-nav-main-square nav > ul > li > a {
    border-radius: 0;
  }

  .header-nav-main.header-nav-main-square
    nav
    > ul
    > li.dropdown
    .dropdown-menu {
    margin-top: 0;
    border-radius: 0;
  }

  .header-nav-main.header-nav-main-square
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu
    > .dropdown-menu {
    border-radius: 0;
  }

  .header-nav-main.header-nav-main-square
    nav
    > ul
    > li.dropdown-mega
    > .dropdown-menu {
    border-radius: 0;
  }

  .header-nav-main.header-nav-main-square
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-nav
    > li
    > a {
    border-radius: 0;
  }

  .header-nav-main .dropdown-reverse a > .thumb-info-preview {
    transform: translate3d(20px, 0, 0);
    right: 100%;
    left: auto;
    padding-left: 0;
    margin-right: 10px;
  }

  .header-nav-main .dropdown-reverse a:hover > .thumb-info-preview {
    transform: translate3d(0, 0, 0);
  }

  .header-nav {
    float: left;
  }

  .header-nav.header-nav-dark-dropdown {
    margin-bottom: -9px;
  }

  .header-nav.header-nav-dark-dropdown nav > ul > li > a,
  .header-nav.header-nav-dark-dropdown nav > ul > li:hover > a {
    background: transparent;
    color: #444;
    padding: 65px 13px 24px;
    margin: 0;
  }

  .header-nav.header-nav-dark-dropdown nav > ul > li > a.dropdown-toggle:after {
    border-color: #444 transparent transparent transparent;
  }

  .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown li a {
    border-bottom-color: #2a2a2a;
  }

  .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown .dropdown-menu {
    background: #1e1e1e;
  }

  .header-nav.header-nav-dark-dropdown
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    > li
    > a {
    color: #969696;
  }

  .header-nav.header-nav-dark-dropdown
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    > li
    > a:hover,
  .header-nav.header-nav-dark-dropdown
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    > li
    > a:focus {
    background: #282828;
  }

  .header-nav.header-nav-dark-dropdown
    nav
    > ul
    > li.dropdown.dropdown-mega
    .dropdown-mega-sub-title {
    color: #ababab;
  }

  .header-nav.header-nav-dark-dropdown
    nav
    > ul
    > li.dropdown.dropdown-mega
    .dropdown-mega-sub-nav
    > li:hover
    > a {
    background: #282828;
  }

  .header-nav.header-nav-dark-dropdown .header-social-icons {
    margin-top: 70px;
  }

  .header-nav {
    display: block !important;
  }

  .header-nav-main {
    display: block !important;
    height: auto !important;
  }

  .header-nav-bar {
    background: #f4f4f4;
    padding: 0 10px 5px;
    margin-bottom: 0;
  }

  .header-nav-bar .header-nav-main {
    float: left;
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .header-nav-main.header-nav-main-light nav > ul > li > a {
    color: #fff;
  }

  .header-nav-main.header-nav-main-light
    nav
    > ul
    > li
    > a.dropdown-toggle:after {
    border-color: #fff transparent transparent transparent;
  }

  .header-nav-main.header-nav-main-light nav > ul > li.show > a,
  .header-nav-main.header-nav-main-light nav > ul > li:hover > a {
    background: #fff;
  }

  .header-nav-main.header-nav-main-light
    nav
    > ul
    > li.show
    > a.dropdown-toggle:after,
  .header-nav-main.header-nav-main-light
    nav
    > ul
    > li:hover
    > a.dropdown-toggle:after {
    border-color: #ccc transparent transparent transparent;
  }

  .header-nav-main.header-nav-main-light nav > ul > li.show > .dropdown-menu,
  .header-nav-main.header-nav-main-light nav > ul > li:hover > .dropdown-menu {
    border-top-color: #fff;
    box-shadow: 0 20px 25px rgba(0, 0, 0, 0.05);
  }

  .header-nav-main.header-nav-main-light
    nav
    > ul
    > li.show
    > .dropdown-menu
    .dropdown-submenu:hover
    > .dropdown-menu,
  .header-nav-main.header-nav-main-light
    nav
    > ul
    > li:hover
    > .dropdown-menu
    .dropdown-submenu:hover
    > .dropdown-menu {
    border-top-color: #fff;
  }

  .header-nav-main.header-nav-main-light nav > ul > li.active > a {
    background: #fff;
  }

  .header-nav-main.header-nav-main-light
    nav
    > ul
    > li.active
    > a.dropdown-toggle:after {
    border-color: #ccc transparent transparent transparent;
  }

  .header-nav-main.header-nav-main-light .dropdown-menu > li > a:hover,
  .header-nav-main.header-nav-main-light .dropdown-menu > li > a:focus {
    background: #f5f5f5;
  }
}

@media (min-width: 992px) {
  .header-nav-main-effect-1 nav > ul > li.dropdown .dropdown-menu li a,
  .header-nav-main-effect-1 nav > ul > li.dropdown .dropdown-mega-sub-nav li a {
    transition: transform 0.2s ease-out;
    transform: translate3d(0, -5px, 0);
  }

  .header-nav-main-effect-1 nav > ul > li.dropdown:hover > .dropdown-menu li a,
  .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown:hover
    .dropdown-mega-sub-nav
    li
    a {
    transform: translate3d(0, 0, 0);
  }

  .header-nav-main-effect-1 nav > ul > li.dropdown .dropdown-menu {
    transition: transform 0.2s ease-out;
    transform: translate3d(0, -5px, 0);
  }

  .header-nav-main-effect-1 nav > ul > li.dropdown:hover > .dropdown-menu {
    transform: translate3d(0, 0, 0);
  }
}

@media (min-width: 992px) {
  .header-nav-main-effect-2 nav > ul > li.dropdown .dropdown-menu {
    transition:
      transform 0.2s ease-out,
      opacity 0.2s ease-out;
    transform: translate3d(0, -5px, 0);
    opacity: 0;
  }

  .header-nav-main-effect-2 nav > ul > li.dropdown:hover > .dropdown-menu {
    transform: translate3d(0, -1px, 0);
    opacity: 1;
  }
}

@media (min-width: 992px) {
  .header-nav-main-effect-3 nav > ul > li.dropdown .dropdown-menu {
    transition: transform 0.2s ease-out;
    transform: translate3d(0, 10px, 0);
  }

  .header-nav-main-effect-3 nav > ul > li.dropdown:hover > .dropdown-menu {
    transform: translate3d(0, 0, 0);
  }
}

@media (min-width: 992px) {
  .header-nav-main-sub-effect-1
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu
    > .dropdown-menu {
    transition:
      transform 0.2s ease-out,
      opacity 0.2s ease-out;
    transform: translate3d(-20px, 0, 0);
    opacity: 0;
  }

  .header-nav-main-sub-effect-1
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu:hover
    > .dropdown-menu {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  .header-nav-main-sub-effect-1
    nav
    > ul
    > li.dropdown.dropdown-reverse
    .dropdown-menu
    li.dropdown-submenu
    > .dropdown-menu {
    transition:
      transform 0.2s ease-out,
      opacity 0.2s ease-out;
    transform: translate3d(20px, 0, 0);
    left: auto;
    right: 100%;
    opacity: 0;
  }

  .header-nav-main-sub-effect-1
    nav
    > ul
    > li.dropdown.dropdown-reverse
    .dropdown-menu
    li.dropdown-submenu:hover
    > .dropdown-menu {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

/* Header */
.header {
  background: #fff;
  border-bottom: 1px solid #e9e9e6;
  border-top: 3px solid #ededed;
  z-index: 1000;
}

.header .logo {
  float: left;
  margin: 10px 0 0 15px;
}

.header .logo img {
  color: transparent;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
}

.header .separator {
  background-image: linear-gradient(#f6f6f6 60%, #ededed);
  display: inline-block;
  height: 100%;
  margin: 0 25px 0;
  width: 1px;
  vertical-align: middle;
}

.header .search {
  width: 170px;
  display: inline-block;
  vertical-align: middle;
}

.header .search .form-control,
.header .search .btn {
  border-color: #ededed;
  box-shadow: none !important;
}

.header .search .btn i {
  position: relative;
  top: 3px;
  font-size: 16px;
  font-size: 1rem;
}

.header .toggle-sidebar-left {
  background: #ccc;
  border-radius: 100px;
  color: #fff;
  height: 30px;
  line-height: 30px;
  position: absolute;
  right: 15px;
  text-align: center;
  top: 14px;
  width: 30px;
}

.header-right {
  float: right;
  height: 56px;
}

html.has-left-sidebar-half .header {
  z-index: 1011;
}

/* Header Mobile */
@media only screen and (max-width: 767px) {
  .header .logo-container {
    background-image: linear-gradient(#f6f6f6 0%, #ffffff 45%);
    border-bottom: 1px solid #e9e9e6;
    border-top: 3px solid #ededed;
  }

  .header .logo-container .logo {
    float: none;
    display: inline-block;
    line-height: 57px;
    margin-top: 0;
  }

  .header .search,
  .header .separator {
    display: none;
  }
}

/* Header Dark */
html.dark .header,
html.header-dark .header {
  background: #e6e6ea;
  border-bottom-color: #161a1e;
  border-top-color: #e6e6ea;
}

@media only screen and (max-width: 767px) {
  html.dark .header .logo-container,
  html.header-dark .header .logo-container {
    background: #e6e6ea;
    border-bottom-color: #161a1e;
    border-top-color: #e6e6ea;
  }

  html.dark .header .header-right,
  html.header-dark .header .header-right {
    background: #e6e6ea;
  }
}

html.dark .header .search .form-control,
html.header-dark .header .search .form-control {
  background-color: #282d36;
  border-color: #282d36;
  color: #eee;
}

html.dark .header .search .btn-default,
html.header-dark .header .search .btn-default {
  background-color: #282d36;
  border-color: #282d36;
  color: #eee;
}

html.dark .header .separator,
html.header-dark .header .separator {
  background-image: linear-gradient(#e6e6ea 10%, #121518);
}

@media only screen and (min-width: 768px) {
  html.header-fixed .header {
    border-radius: 0;
    border-top-color: transparent;
    left: 0;
    position: fixed;
    right: 0;
    top: -3px;
    z-index: 2000;
    margin: 0;
  }

  html.header-fixed .inner-wrapper {
    padding-top: 0;
    margin-top: 60px;
  }
}

/* Header Nav Menu */
.header.header-nav-menu {
  /* Header Nav Main */
  /* Header Nav Main Mobile */
}

@media only screen and (min-width: 768px) {
  .header.header-nav-menu .logo {
    position: relative;
    padding: 0 20px 0 5px;
  }

  .header.header-nav-menu .logo:after {
    content: "";
    display: block;
    position: absolute;
    top: -13px;
    right: 0;
    height: 60px;
    border-right: 1px solid #e9e9e6;
  }
}

@media (min-width: 992px) {
  .header.header-nav-menu .header-nav-main {
    float: right;
    margin: 8px 0 0;
    min-height: 45px;
  }

  .header.header-nav-menu .header-nav-main nav > ul > li {
    margin-left: 2px;
  }

  .header.header-nav-menu .header-nav-main nav > ul > li:first-child {
    margin-left: 10px;
  }

  .header.header-nav-menu .header-nav-main nav > ul > li > a {
    display: inline-block;
    border-radius: 4px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    padding: 10px;
    text-transform: uppercase;
    white-space: initial;
  }

  .header.header-nav-menu .header-nav-main nav > ul > li > a:focus {
    background: transparent;
    color: #ccc;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li
    > a.dropdown-toggle
    .fa-caret-down {
    display: none;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li
    > a.dropdown-toggle:after {
    border-color: #ccc transparent transparent transparent;
    border-style: solid;
    border-width: 4px;
    content: " ";
    float: right;
    margin-top: 7px;
    margin-left: 4px;
  }

  .header.header-nav-menu .header-nav-main nav > ul > li.show > a,
  .header.header-nav-menu .header-nav-main nav > ul > li:hover > a,
  .header.header-nav-menu .header-nav-main nav > ul > li.active > a {
    background: #ccc;
    color: #fff;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu {
    top: -10000px;
    display: block;
    opacity: 0;
    left: auto;
    border-radius: 0 4px 4px;
    border: 0;
    border-top: 5px solid #ccc;
    box-shadow: 0 20px 45px rgba(0, 0, 0, 0.08);
    margin: -3px 0 0 0;
    min-width: 200px;
    padding: 5px;
    text-align: left;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li
    a {
    border-bottom: 1px solid #f4f4f4;
    color: #777;
    font-size: 0.8em;
    font-weight: 400;
    padding: 6px 20px 6px 8px;
    position: relative;
    text-transform: none;
    white-space: initial;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu {
    position: relative;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu
    > a
    .fa-caret-down {
    display: none;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu
    > a:after {
    border-color: transparent transparent transparent #ccc;
    border-style: solid;
    border-width: 4px 0 4px 4px;
    content: " ";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu
    > .dropdown-menu {
    display: block;
    margin-top: -10px;
    margin-left: -1px;
    border-radius: 4px;
    opacity: 0;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu:hover
    > .dropdown-menu {
    top: 0;
    opacity: 1;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li:last-child
    a {
    border-bottom: 0;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown.show
    li.dropdown-submenu
    > .dropdown-menu,
  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown:hover
    li.dropdown-submenu
    > .dropdown-menu {
    left: 100%;
  }

  .header.header-nav-menu .header-nav-main nav > ul > li.dropdown.show > a,
  .header.header-nav-menu .header-nav-main nav > ul > li.dropdown:hover > a {
    padding-bottom: 15px;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown.show
    > .dropdown-menu,
  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown:hover
    > .dropdown-menu {
    top: auto;
    display: block;
    opacity: 1;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown-reverse
    .dropdown-menu
    li
    a {
    padding-right: 8px;
    padding-left: 20px;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown-reverse
    .dropdown-menu
    li.dropdown-submenu
    > a:after {
    border-width: 4px 4px 4px 0;
  }

  .header.header-nav-menu .header-nav-main nav > ul > li.dropdown-mega {
    position: static;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown-mega
    > .dropdown-menu {
    border-radius: 4px;
    left: 15px;
    right: 15px;
    width: auto;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-content {
    padding: 20px 30px;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-title {
    color: #333333;
    display: block;
    font-size: 1em;
    font-weight: 600;
    margin-top: 20px;
    padding-bottom: 5px;
    text-transform: uppercase;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-title:first-child {
    margin-top: 0;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-nav {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-nav
    > li
    > a {
    border: 0 none;
    border-radius: 4px;
    color: #777;
    display: block;
    font-size: 0.9em;
    font-weight: normal;
    margin: 0 0 0 -8px;
    padding: 3px 8px;
    text-shadow: none;
    text-transform: none;
    text-decoration: none;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-nav
    > li:hover
    > a {
    background: #f4f4f4;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-nav
    .mega-sub-nav-toggle {
    width: 20px;
    text-align: center;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-nav
    .dropdown-mega-sub-nav {
    padding-left: 15px;
  }

  .header.header-nav-menu .header-nav-main nav > ul > li .badge {
    margin-right: -16px;
    margin-top: 4px;
  }

  .header.header-nav-menu
    .header-nav-main.header-nav-main-square
    nav
    > ul
    > li
    > a {
    border-radius: 0;
  }

  .header.header-nav-menu
    .header-nav-main.header-nav-main-square
    nav
    > ul
    > li.dropdown
    .dropdown-menu {
    margin-top: 0;
    border-radius: 0;
  }

  .header.header-nav-menu
    .header-nav-main.header-nav-main-square
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu
    > .dropdown-menu {
    border-radius: 0;
  }

  .header.header-nav-menu
    .header-nav-main.header-nav-main-square
    nav
    > ul
    > li.dropdown-mega
    > .dropdown-menu {
    border-radius: 0;
  }

  .header.header-nav-menu
    .header-nav-main.header-nav-main-square
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-nav
    > li
    > a {
    border-radius: 0;
  }

  .header.header-nav-menu
    .header-nav-main
    .dropdown-reverse
    a
    > .thumb-info-preview {
    transform: translate3d(20px, 0, 0);
    right: 100%;
    left: auto;
    padding-left: 0;
    margin-right: 10px;
  }

  .header.header-nav-menu
    .header-nav-main
    .dropdown-reverse
    a:hover
    > .thumb-info-preview {
    transform: translate3d(0, 0, 0);
  }

  .header.header-nav-menu .header-nav {
    float: left;
  }

  .header.header-nav-menu .header-nav.header-nav-dark-dropdown {
    margin-bottom: -9px;
  }

  .header.header-nav-menu
    .header-nav.header-nav-dark-dropdown
    nav
    > ul
    > li
    > a,
  .header.header-nav-menu
    .header-nav.header-nav-dark-dropdown
    nav
    > ul
    > li:hover
    > a {
    background: transparent;
    color: #444;
    padding: 65px 13px 24px;
    margin: 0;
  }

  .header.header-nav-menu
    .header-nav.header-nav-dark-dropdown
    nav
    > ul
    > li
    > a.dropdown-toggle:after {
    border-color: #444 transparent transparent transparent;
  }

  .header.header-nav-menu
    .header-nav.header-nav-dark-dropdown
    nav
    > ul
    > li.dropdown
    li
    a {
    border-bottom-color: #2a2a2a;
  }

  .header.header-nav-menu
    .header-nav.header-nav-dark-dropdown
    nav
    > ul
    > li.dropdown
    .dropdown-menu {
    background: #1e1e1e;
  }

  .header.header-nav-menu
    .header-nav.header-nav-dark-dropdown
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    > li
    > a {
    color: #969696;
  }

  .header.header-nav-menu
    .header-nav.header-nav-dark-dropdown
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    > li
    > a:hover,
  .header.header-nav-menu
    .header-nav.header-nav-dark-dropdown
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    > li
    > a:focus {
    background: #282828;
  }

  .header.header-nav-menu
    .header-nav.header-nav-dark-dropdown
    nav
    > ul
    > li.dropdown.dropdown-mega
    .dropdown-mega-sub-title {
    color: #ababab;
  }

  .header.header-nav-menu
    .header-nav.header-nav-dark-dropdown
    nav
    > ul
    > li.dropdown.dropdown-mega
    .dropdown-mega-sub-nav
    > li:hover
    > a {
    background: #282828;
  }

  .header.header-nav-menu
    .header-nav.header-nav-dark-dropdown
    .header-social-icons {
    margin-top: 70px;
  }

  .header.header-nav-menu .header-nav {
    display: block !important;
  }

  .header.header-nav-menu .header-nav-main {
    display: block !important;
    height: auto !important;
  }

  .header.header-nav-menu .header-nav-bar {
    background: #f4f4f4;
    padding: 0 10px 5px;
    margin-bottom: 0;
  }

  .header.header-nav-menu .header-nav-bar .header-nav-main {
    float: left;
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .header.header-nav-menu
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li
    > a {
    color: #fff;
  }

  .header.header-nav-menu
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li
    > a.dropdown-toggle:after {
    border-color: #fff transparent transparent transparent;
  }

  .header.header-nav-menu
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li.show
    > a,
  .header.header-nav-menu
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li:hover
    > a {
    background: #fff;
  }

  .header.header-nav-menu
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li.show
    > a.dropdown-toggle:after,
  .header.header-nav-menu
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li:hover
    > a.dropdown-toggle:after {
    border-color: #ccc transparent transparent transparent;
  }

  .header.header-nav-menu
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li.show
    > .dropdown-menu,
  .header.header-nav-menu
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li:hover
    > .dropdown-menu {
    border-top-color: #fff;
    box-shadow: 0 20px 25px rgba(0, 0, 0, 0.05);
  }

  .header.header-nav-menu
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li.show
    > .dropdown-menu
    .dropdown-submenu:hover
    > .dropdown-menu,
  .header.header-nav-menu
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li:hover
    > .dropdown-menu
    .dropdown-submenu:hover
    > .dropdown-menu {
    border-top-color: #fff;
  }

  .header.header-nav-menu
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li.active
    > a {
    background: #fff;
  }

  .header.header-nav-menu
    .header-nav-main.header-nav-main-light
    nav
    > ul
    > li.active
    > a.dropdown-toggle:after {
    border-color: #ccc transparent transparent transparent;
  }

  .header.header-nav-menu
    .header-nav-main.header-nav-main-light
    .dropdown-menu
    > li
    > a:hover,
  .header.header-nav-menu
    .header-nav-main.header-nav-main-light
    .dropdown-menu
    > li
    > a:focus {
    background: #f5f5f5;
  }
}

@media (min-width: 992px) {
  .header.header-nav-menu
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li
    a,
  .header.header-nav-menu
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown
    .dropdown-mega-sub-nav
    li
    a {
    transition: transform 0.2s ease-out;
    transform: translate3d(0, -5px, 0);
  }

  .header.header-nav-menu
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown:hover
    > .dropdown-menu
    li
    a,
  .header.header-nav-menu
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown:hover
    .dropdown-mega-sub-nav
    li
    a {
    transform: translate3d(0, 0, 0);
  }

  .header.header-nav-menu
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown
    .dropdown-menu {
    transition: transform 0.2s ease-out;
    transform: translate3d(0, -5px, 0);
  }

  .header.header-nav-menu
    .header-nav-main-effect-1
    nav
    > ul
    > li.dropdown:hover
    > .dropdown-menu {
    transform: translate3d(0, 0, 0);
  }
}

@media (min-width: 992px) {
  .header.header-nav-menu
    .header-nav-main-effect-2
    nav
    > ul
    > li.dropdown
    .dropdown-menu {
    transition:
      transform 0.2s ease-out,
      opacity 0.2s ease-out;
    transform: translate3d(0, -5px, 0);
    opacity: 0;
  }

  .header.header-nav-menu
    .header-nav-main-effect-2
    nav
    > ul
    > li.dropdown:hover
    > .dropdown-menu {
    transform: translate3d(0, -1px, 0);
    opacity: 1;
  }
}

@media (min-width: 992px) {
  .header.header-nav-menu
    .header-nav-main-effect-3
    nav
    > ul
    > li.dropdown
    .dropdown-menu {
    transition: transform 0.2s ease-out;
    transform: translate3d(0, 10px, 0);
  }

  .header.header-nav-menu
    .header-nav-main-effect-3
    nav
    > ul
    > li.dropdown:hover
    > .dropdown-menu {
    transform: translate3d(0, 0, 0);
  }
}

@media (min-width: 992px) {
  .header.header-nav-menu
    .header-nav-main-sub-effect-1
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu
    > .dropdown-menu {
    transition:
      transform 0.2s ease-out,
      opacity 0.2s ease-out;
    transform: translate3d(-20px, 0, 0);
    opacity: 0;
  }

  .header.header-nav-menu
    .header-nav-main-sub-effect-1
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li.dropdown-submenu:hover
    > .dropdown-menu {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  .header.header-nav-menu
    .header-nav-main-sub-effect-1
    nav
    > ul
    > li.dropdown.dropdown-reverse
    .dropdown-menu
    li.dropdown-submenu
    > .dropdown-menu {
    transition:
      transform 0.2s ease-out,
      opacity 0.2s ease-out;
    transform: translate3d(20px, 0, 0);
    left: auto;
    right: 100%;
    opacity: 0;
  }

  .header.header-nav-menu
    .header-nav-main-sub-effect-1
    nav
    > ul
    > li.dropdown.dropdown-reverse
    .dropdown-menu
    li.dropdown-submenu:hover
    > .dropdown-menu {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@media (max-width: 991px) {
  .header.header-nav-menu .header-nav {
    clear: both;
    float: none;
  }

  .header.header-nav-menu .header-nav-main {
    background: #fff;
    padding: 10px;
    max-height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .header.header-nav-menu .header-nav-main {
    position: relative;
    top: 9px;
  }
}

@media (max-width: 991px) {
  .header.header-nav-menu .header-nav-main.collapsing {
    overflow: hidden;
  }

  .header.header-nav-menu .header-nav-main nav {
    margin: 0 0 6px;
  }

  .header.header-nav-menu .header-nav-main nav > ul {
    flex-direction: column;
  }

  .header.header-nav-menu .header-nav-main nav > ul li {
    border-bottom: 1px solid #e8e8e8;
    clear: both;
    display: block;
    float: none;
    margin: 0;
    padding: 0;
    position: relative;
  }

  .header.header-nav-menu .header-nav-main nav > ul li a {
    font-size: 13px;
    font-style: normal;
    line-height: 20px;
    padding: 7px 8px;
    margin: 1px 0;
    border-radius: 4px;
    white-space: initial;
  }

  .header.header-nav-menu .header-nav-main nav > ul li a .fa-caret-down {
    line-height: 35px;
    min-height: 38px;
    min-width: 30px;
    position: absolute;
    right: 5px;
    text-align: center;
    top: 0;
  }

  .header.header-nav-menu .header-nav-main nav > ul li a.dropdown-toggle:after {
    content: none;
  }

  .header.header-nav-menu .header-nav-main nav > ul li.dropdown .dropdown-menu {
    background: transparent;
    padding: 0;
    margin: 0;
    font-size: 13px;
    box-shadow: none;
    border-radius: 0;
    border: 0;
    clear: both;
    display: none;
    float: none;
    position: static;
    border-top: 0 !important;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    li.dropdown
    .dropdown-menu
    li
    a {
    color: #333;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    li.dropdown
    .dropdown-menu
    li.dropdown-submenu.showed
    > .dropdown-menu {
    display: block;
    margin-left: 20px;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    li.dropdown.showed
    > .dropdown-menu {
    display: block;
    margin-left: 20px;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    li.dropdown-mega
    .dropdown-mega-sub-title {
    margin-top: 10px;
    display: block;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    li.dropdown-mega
    .dropdown-mega-sub-nav {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    li.dropdown-mega
    .dropdown-mega-sub-nav
    > li
    > a {
    display: block;
    text-decoration: none;
    color: #333;
  }

  .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    li.dropdown-mega
    .mega-sub-nav-toggle {
    width: 20px;
    text-align: center;
  }

  .header.header-nav-menu .header-nav-main nav > ul li:last-child {
    border-bottom: 0;
  }

  .header.header-nav-menu .header-nav-main nav > ul > li > a {
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 1px;
    margin-bottom: 1px;
    white-space: initial;
  }

  .header.header-nav-menu .header-nav-main nav > ul > li.active > a,
  .header.header-nav-menu .header-nav-main nav > ul > li.active > a:focus,
  .header.header-nav-menu .header-nav-main nav > ul > li.active > a:hover {
    color: #fff;
  }

  .header.header-nav-menu .header-nav-main nav .not-included {
    margin: 0;
  }

  .header.header-nav-menu .header-nav-main a > .thumb-info-preview {
    display: none !important;
  }

  .header.header-nav-menu .header-btn-collapse-nav {
    outline: 0;
    float: right;
    margin-top: 10px;
    margin-right: 15px;
  }

  .header.header-nav-menu .header-btn-collapse-nav:hover,
  .header.header-nav-menu .header-btn-collapse-nav:focus {
    color: #fff;
  }

  .header.header-nav-menu .header-nav-bar {
    margin: 0 auto;
  }

  .header.header-nav-menu .header-nav-bar .header-btn-collapse-nav {
    margin-top: 14px;
  }

  .header.header-nav-menu .header-transparent .header-nav-main {
    padding: 10px;
    margin-bottom: 10px;
    background: #fff;
    border-radius: 4px;
  }

  .header.header-nav-menu .header-semi-transparent .header-nav-main {
    padding: 10px;
    margin-bottom: 10px;
    background: #fff;
    border-radius: 4px;
  }

  .header.header-nav-menu .header-semi-transparent-light .header-nav-main {
    padding: 10px;
    margin-bottom: 10px;
    background: #fff;
    border-radius: 4px;
  }
}

.header.header-nav-menu
  .header-nav-main
  nav
  > ul
  > li
  ul.dropdown-menu
  li:hover
  > a {
  background-color: #f4f4f4;
}

.header.header-nav-menu .header-nav-main nav > ul > li ul.dropdown-menu li a {
  background: transparent;
}

.header.header-nav-menu
  .header-nav-main
  nav
  > ul
  > li.dropdown-mega.active
  ul.dropdown-mega-sub-nav
  li:hover
  a {
  background-color: #f4f4f4;
}

.header.header-nav-menu
  .header-nav-main
  nav
  > ul
  > li.dropdown-mega.active
  ul.dropdown-mega-sub-nav
  li
  a {
  background: transparent;
}

.header.header-nav-menu .not-included {
  color: #b7b7b7;
  display: block;
  font-size: 0.8em;
  font-style: normal;
  margin: -4px 0;
  padding: 0;
}

.header.header-nav-menu .tip {
  display: inline-block;
  padding: 0 5px;
  background: #5a5c63;
  color: #fff;
  text-shadow: none;
  border-radius: 3px;
  margin-left: 8px;
  position: relative;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
}

.header.header-nav-menu .tip:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(23, 23, 23, 0);
  border-right-color: #5a5c63;
  border-width: 5px;
  margin-top: -5px;
}

.header.header-nav-menu .tip.skin {
  color: #5a5c63;
}

.header.header-nav-menu .search-toggle {
  color: #ccc;
}

.header.header-nav-menu .search-toggle:focus,
.header.header-nav-menu .search-toggle:active {
  box-shadow: none;
}

@media (min-width: 992px) {
  .header.header-nav-menu.header-nav-stripe {
    height: initial;
    border-bottom: 0;
  }

  .header.header-nav-menu.header-nav-stripe nav > ul > li > a,
  .header.header-nav-menu.header-nav-stripe nav > ul > li:hover > a {
    background: transparent;
    padding: 18px 13px 19px;
    margin: 0;
  }

  .header.header-nav-menu.header-nav-stripe nav > ul > li > a {
    color: #444;
  }

  .header.header-nav-menu.header-nav-stripe
    nav
    > ul
    > li
    > a.dropdown-toggle:after {
    border-color: #444 transparent transparent transparent;
  }

  .header.header-nav-menu.header-nav-stripe nav > ul > li:hover > a {
    color: #fff;
  }

  .header.header-nav-menu.header-nav-stripe nav > ul > li.dropdown:hover > a,
  .header.header-nav-menu.header-nav-stripe nav > ul > li.dropdown.show > a {
    padding-bottom: 19px;
  }

  .header.header-nav-menu.header-nav-top-line {
    height: initial;
    border-bottom: 0;
  }

  .header.header-nav-menu.header-nav-top-line nav > ul > li > a,
  .header.header-nav-menu.header-nav-top-line nav > ul > li:hover > a {
    position: relative;
    background: transparent !important;
    color: #444;
    padding: 18px 13px 19px;
    margin: 0;
  }

  .header.header-nav-menu.header-nav-top-line nav > ul > li > a:before,
  .header.header-nav-menu.header-nav-top-line nav > ul > li:hover > a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 5px;
    top: -5px;
    left: -5px;
    opacity: 0;
    background: #ccc;
  }

  .header.header-nav-menu.header-nav-top-line nav > ul > li.active > a,
  .header.header-nav-menu.header-nav-top-line nav > ul > li:hover > a {
    color: #ccc;
  }

  .header.header-nav-menu.header-nav-top-line nav > ul > li.active > a:before,
  .header.header-nav-menu.header-nav-top-line nav > ul > li:hover > a:before {
    opacity: 1;
  }

  .header.header-nav-menu.header-nav-top-line
    nav
    > ul
    > li.active
    > a.dropdown-toggle:after,
  .header.header-nav-menu.header-nav-top-line
    nav
    > ul
    > li:hover
    > a.dropdown-toggle:after {
    border-color: #ccc transparent transparent transparent;
  }

  .header.header-nav-menu.header-nav-top-line
    nav
    > ul
    > li
    > a.dropdown-toggle:after {
    border-color: #444 transparent transparent transparent;
  }

  .header.header-nav-menu.header-nav-top-line nav > ul > li.dropdown:hover > a,
  .header.header-nav-menu.header-nav-top-line nav > ul > li.dropdown.show > a {
    padding-bottom: 19px;
  }

  .header.header-nav-menu.header-nav-stripe .header-nav-main,
  .header.header-nav-menu.header-nav-top-line .header-nav-main {
    margin-top: 0;
  }

  .header.header-nav-menu.header-nav-links nav > ul > li > a {
    display: flex;
    align-items: center;
    color: #444;
    background: transparent !important;
    height: 62px;
  }

  .header.header-nav-menu.header-nav-links nav > ul > li:hover > a {
    color: #ccc;
  }

  .header.header-nav-menu.header-nav-links nav > ul > li.dropdown:hover > a,
  .header.header-nav-menu.header-nav-links nav > ul > li.dropdown.show > a {
    padding-bottom: 10px;
  }

  .header.header-nav-menu.header-nav-links
    nav
    > ul
    > li.dropdown
    .dropdown-menu {
    border-top: 0 !important;
  }

  .header.header-nav-menu.header-nav-links
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li
    a {
    border-bottom: 0;
  }

  .header.header-nav-menu.header-nav-links
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li:hover
    > a {
    color: #ccc;
    background: transparent;
  }

  .header.header-nav-menu.header-nav-links
    nav
    > ul
    > li.dropdown-mega
    > .dropdown-menu {
    max-width: calc(100% - 300px);
    margin-left: 300px;
  }

  .header.header-nav-menu.header-nav-links
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-nav
    > li
    > a {
    font-size: 0.8em;
  }
}

@media only screen and (max-width: 1199px) {
  .header.header-nav-menu .separator {
    margin: 0px 14px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .header.header-nav-menu .search {
    position: absolute;
    top: 50px;
    left: -66px;
  }

  .header.header-nav-menu .search.active {
    display: block !important;
  }

  .header.header-nav-menu .search:before {
    content: "";
    display: block;
    position: absolute;
    top: -7px;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    transform: translateX(-50%);
  }
}

@media only screen and (min-width: 992px) {
  .header.header-nav-menu .header-right {
    position: relative;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header.header-nav-menu .header-right {
    position: absolute;
    top: 0;
    right: 60px;
  }
}

@media (min-width: 992px) {
  html.sidebar-left-collapsed
    .header.header-nav-menu.header-nav-links
    nav
    > ul
    > li.dropdown-mega
    > .dropdown-menu {
    max-width: calc(100% - 75px);
    margin-left: 75px;
  }
}

/* Header Nav Menu Dark */
html.dark .header.header-nav-menu,
html.header-dark .header.header-nav-menu {
  /* Header Nav Main */
  /* Header Nav Stripe & Header Nav Top Line */
  /* Header Nav Main Mobile */
}

@media only screen and (min-width: 768px) {
  html.dark .header.header-nav-menu .logo:after,
  html.header-dark .header.header-nav-menu .logo:after {
    border-color: #343a44;
  }
}

@media (min-width: 992px) {
  html.dark
    .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu,
  html.header-dark
    .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu {
    background-color: #282d36;
  }

  html.dark
    .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li
    a,
  html.header-dark
    .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li
    a {
    border-color: #343a44;
  }

  html.dark
    .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li
    a:hover,
  html.dark
    .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li
    a:focus,
  html.header-dark
    .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li
    a:hover,
  html.header-dark
    .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown
    .dropdown-menu
    li
    a:focus {
    background-color: #e6e6ea;
  }

  html.dark
    .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-nav
    > li:hover
    > a,
  html.header-dark
    .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li.dropdown-mega
    .dropdown-mega-sub-nav
    > li:hover
    > a {
    background: #e6e6ea;
  }
}

@media (min-width: 992px) {
  html.dark
    .header.header-nav-menu.header-nav-stripe
    .header-nav-main
    nav
    > ul
    > li:not(.active):not(:hover)
    > a,
  html.dark
    .header.header-nav-menu.header-nav-top-line
    .header-nav-main
    nav
    > ul
    > li:not(.active):not(:hover)
    > a,
  html.header-dark
    .header.header-nav-menu.header-nav-stripe
    .header-nav-main
    nav
    > ul
    > li:not(.active):not(:hover)
    > a,
  html.header-dark
    .header.header-nav-menu.header-nav-top-line
    .header-nav-main
    nav
    > ul
    > li:not(.active):not(:hover)
    > a {
    color: #fff;
  }

  html.dark
    .header.header-nav-menu.header-nav-stripe
    .header-nav-main
    nav
    > ul
    > li:not(.active):not(:hover)
    > a.dropdown-toggle::after,
  html.dark
    .header.header-nav-menu.header-nav-top-line
    .header-nav-main
    nav
    > ul
    > li:not(.active):not(:hover)
    > a.dropdown-toggle::after,
  html.header-dark
    .header.header-nav-menu.header-nav-stripe
    .header-nav-main
    nav
    > ul
    > li:not(.active):not(:hover)
    > a.dropdown-toggle::after,
  html.header-dark
    .header.header-nav-menu.header-nav-top-line
    .header-nav-main
    nav
    > ul
    > li:not(.active):not(:hover)
    > a.dropdown-toggle::after {
    border-color: #fff transparent transparent transparent;
  }
}

html.dark
  .header.header-nav-menu
  .header-nav-main
  nav
  > ul
  > li:not(.dropdown-mega).active
  ul.dropdown-menu
  li:hover
  > a,
html.header-dark
  .header.header-nav-menu
  .header-nav-main
  nav
  > ul
  > li:not(.dropdown-mega).active
  ul.dropdown-menu
  li:hover
  > a {
  background-color: #e6e6ea;
}

html.dark
  .header.header-nav-menu
  .header-nav-main
  nav
  > ul
  > li:not(.dropdown-mega).active
  ul.dropdown-menu
  li
  a,
html.header-dark
  .header.header-nav-menu
  .header-nav-main
  nav
  > ul
  > li:not(.dropdown-mega).active
  ul.dropdown-menu
  li
  a {
  background: transparent;
}

html.dark
  .header.header-nav-menu
  .header-nav-main
  nav
  > ul
  > li.dropdown-mega.active
  ul.dropdown-mega-sub-nav
  li:hover
  a,
html.header-dark
  .header.header-nav-menu
  .header-nav-main
  nav
  > ul
  > li.dropdown-mega.active
  ul.dropdown-mega-sub-nav
  li:hover
  a {
  background-color: #e6e6ea;
}

html.dark
  .header.header-nav-menu
  .header-nav-main
  nav
  > ul
  > li.dropdown-mega.active
  ul.dropdown-mega-sub-nav
  li
  a,
html.header-dark
  .header.header-nav-menu
  .header-nav-main
  nav
  > ul
  > li.dropdown-mega.active
  ul.dropdown-mega-sub-nav
  li
  a {
  background: transparent;
}

@media (max-width: 991px) {
  html.dark .header.header-nav-menu .header-nav-main,
  html.header-dark .header.header-nav-menu .header-nav-main {
    background: #282d36;
  }

  html.dark .header.header-nav-menu .header-nav-main nav > ul > li a:hover,
  html.header-dark
    .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li
    a:hover {
    background: #e6e6ea;
  }

  html.dark .header.header-nav-menu .header-nav-main nav > ul > li ul li a,
  html.header-dark
    .header.header-nav-menu
    .header-nav-main
    nav
    > ul
    > li
    ul
    li
    a {
    color: #777;
  }

  html.dark .header.header-nav-menu .header-nav-main nav ul li,
  html.header-dark .header.header-nav-menu .header-nav-main nav ul li {
    border-color: #343a44;
  }

  html.dark .header.header-nav-menu .header-nav-main nav ul li a:hover,
  html.dark .header.header-nav-menu .header-nav-main nav ul li a:focus,
  html.header-dark .header.header-nav-menu .header-nav-main nav ul li a:hover,
  html.header-dark .header.header-nav-menu .header-nav-main nav ul li a:focus {
    background-color: #e6e6ea;
  }

  html.dark
    .header.header-nav-menu
    .header-nav-main
    nav
    ul
    li.dropdown-mega
    .dropdown-mega-sub-nav
    > li
    > a,
  html.header-dark
    .header.header-nav-menu
    .header-nav-main
    nav
    ul
    li.dropdown-mega
    .dropdown-mega-sub-nav
    > li
    > a {
    color: #777;
  }
}

/* Position */
.p-relative {
  position: relative !important;
}

.p-absolute {
  position: absolute !important;
}

.p-fixed {
  position: fixed !important;
}

.p-static {
  position: static !important;
}

.hidden {
  display: none !important;
}

/* Pull */
.pull-none {
  float: none !important;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

@media (max-width: 767px) {
  .pull-xs-left {
    float: left;
  }

  .pull-xs-right {
    float: right;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .pull-sm-left {
    float: left;
  }

  .pull-sm-right {
    float: right;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .pull-md-left {
    float: left;
  }

  .pull-md-right {
    float: right;
  }
}

@media (min-width: 1200px) {
  .pull-lg-left {
    float: left;
  }

  .pull-lg-right {
    float: right;
  }
}

/* Opacity */
.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 0.1 !important;
}

.opacity-2 {
  opacity: 0.2 !important;
}

.opacity-3 {
  opacity: 0.3 !important;
}

.opacity-4 {
  opacity: 0.4 !important;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.opacity-6 {
  opacity: 0.6 !important;
}

.opacity-7 {
  opacity: 0.7 !important;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.opacity-9 {
  opacity: 0.9 !important;
}

.opacity-10 {
  opacity: 1 !important;
}

/* Top / Bottom / Left / Right */
.top-auto {
  top: auto !important;
}

.bottom-auto {
  top: auto !important;
}

.top-0 {
  top: 0px !important;
}

.bottom-0 {
  bottom: 0px !important;
}

.left-0 {
  left: 0px !important;
}

.right-0 {
  right: 0px !important;
}

.top-1 {
  top: 1px !important;
}

.bottom-1 {
  bottom: 1px !important;
}

.left-1 {
  left: 1px !important;
}

.right-1 {
  right: 1px !important;
}

.top-2 {
  top: 2px !important;
}

.bottom-2 {
  bottom: 2px !important;
}

.left-2 {
  left: 2px !important;
}

.right-2 {
  right: 2px !important;
}

.top-3 {
  top: 3px !important;
}

.bottom-3 {
  bottom: 3px !important;
}

.left-3 {
  left: 3px !important;
}

.right-3 {
  right: 3px !important;
}

.top-4 {
  top: 4px !important;
}

.bottom-4 {
  bottom: 4px !important;
}

.left-4 {
  left: 4px !important;
}

.right-4 {
  right: 4px !important;
}

.top-5 {
  top: 5px !important;
}

.bottom-5 {
  bottom: 5px !important;
}

.left-5 {
  left: 5px !important;
}

.right-5 {
  right: 5px !important;
}

.top-6 {
  top: 6px !important;
}

.bottom-6 {
  bottom: 6px !important;
}

.left-6 {
  left: 6px !important;
}

.right-6 {
  right: 6px !important;
}

.top-7 {
  top: 7px !important;
}

.bottom-7 {
  bottom: 7px !important;
}

.left-7 {
  left: 7px !important;
}

.right-7 {
  right: 7px !important;
}

.top-8 {
  top: 8px !important;
}

.bottom-8 {
  bottom: 8px !important;
}

.left-8 {
  left: 8px !important;
}

.right-8 {
  right: 8px !important;
}

.top-9 {
  top: 9px !important;
}

.bottom-9 {
  bottom: 9px !important;
}

.left-9 {
  left: 9px !important;
}

.right-9 {
  right: 9px !important;
}

.top-10 {
  top: 10px !important;
}

.bottom-10 {
  bottom: 10px !important;
}

.left-10 {
  left: 10px !important;
}

.right-10 {
  right: 10px !important;
}

/* Inverted */
.inverted {
  color: #fff;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
}

h1 .inverted {
  padding-left: 10px;
  padding-right: 10px;
}

h2 .inverted {
  padding-left: 7px;
  padding-right: 7px;
}

h3 .inverted {
  padding-left: 2px;
  padding-right: 2px;
}

h4 .inverted {
  padding-left: 4px;
  padding-right: 4px;
}

h5 .inverted {
  padding-left: 2px;
  padding-right: 2px;
}

h6 .inverted {
  padding-left: 2px;
  padding-right: 2px;
}

/* Cursor */
.cur-pointer {
  cursor: pointer;
}

/* Font Size */
.text-xxs {
  font-size: 14.4px;
  font-size: 0.9rem;
}

.text-xs {
  font-size: 16px;
  font-size: 1rem;
}

.text-sm {
  font-size: 20.8px;
  font-size: 1.3rem;
}

.text-md {
  font-size: 25.6px;
  font-size: 1.6rem;
}

.text-lg {
  font-size: 30.4px;
  font-size: 1.9rem;
}

.text-xl {
  font-size: 35.2px;
  font-size: 2.2rem;
}

/* Font Size */
.text-1 {
  font-size: 0.8em !important;
}

.text-2 {
  font-size: 0.9em !important;
}

.text-3 {
  font-size: 1em !important;
}

.text-4-1 {
  font-size: 1.1em !important;
}

.text-4 {
  font-size: 1.2em !important;
}

.text-5 {
  font-size: 1.5em !important;
}

.text-6 {
  font-size: 1.8em !important;
}

.text-7 {
  font-size: 2em !important;
}

.text-8 {
  font-size: 2.3em !important;
}

.text-9 {
  font-size: 2.5em !important;
}

.text-10 {
  font-size: 2.75em !important;
}

.text-11 {
  font-size: 3em !important;
}

.text-12 {
  font-size: 3.5em !important;
}

.text-13 {
  font-size: 4em !important;
}

.text-14 {
  font-size: 4.5em !important;
}

.text-15 {
  font-size: 5em !important;
}

@media (min-width: 576px) {
  .text-sm-1 {
    font-size: 0.8em !important;
  }

  .text-sm-2 {
    font-size: 0.9em !important;
  }

  .text-sm-3 {
    font-size: 1em !important;
  }

  .text-sm-4-1 {
    font-size: 1.1em !important;
  }

  .text-sm-4 {
    font-size: 1.2em !important;
  }

  .text-sm-5 {
    font-size: 1.5em !important;
  }

  .text-sm-6 {
    font-size: 1.8em !important;
  }

  .text-sm-7 {
    font-size: 2em !important;
  }

  .text-sm-8 {
    font-size: 2.3em !important;
  }

  .text-sm-9 {
    font-size: 2.5em !important;
  }

  .text-sm-10 {
    font-size: 2.75em !important;
  }

  .text-sm-11 {
    font-size: 3em !important;
  }

  .text-sm-12 {
    font-size: 3.5em !important;
  }

  .text-sm-13 {
    font-size: 4em !important;
  }

  .text-sm-14 {
    font-size: 4.5em !important;
  }

  .text-sm-15 {
    font-size: 5em !important;
  }
}

@media (min-width: 768px) {
  .text-md-1 {
    font-size: 0.8em !important;
  }

  .text-md-2 {
    font-size: 0.9em !important;
  }

  .text-md-3 {
    font-size: 1em !important;
  }

  .text-md-4-1 {
    font-size: 1.1em !important;
  }

  .text-md-4 {
    font-size: 1.2em !important;
  }

  .text-md-5 {
    font-size: 1.5em !important;
  }

  .text-md-6 {
    font-size: 1.8em !important;
  }

  .text-md-7 {
    font-size: 2em !important;
  }

  .text-md-8 {
    font-size: 2.3em !important;
  }

  .text-md-9 {
    font-size: 2.5em !important;
  }

  .text-md-10 {
    font-size: 2.75em !important;
  }

  .text-md-11 {
    font-size: 3em !important;
  }

  .text-md-12 {
    font-size: 3.5em !important;
  }

  .text-md-13 {
    font-size: 4em !important;
  }

  .text-md-14 {
    font-size: 4.5em !important;
  }

  .text-md-15 {
    font-size: 5em !important;
  }
}

@media (min-width: 992px) {
  .text-lg-1 {
    font-size: 0.8em !important;
  }

  .text-lg-2 {
    font-size: 0.9em !important;
  }

  .text-lg-3 {
    font-size: 1em !important;
  }

  .text-lg-4-1 {
    font-size: 1.1em !important;
  }

  .text-lg-4 {
    font-size: 1.2em !important;
  }

  .text-lg-5 {
    font-size: 1.5em !important;
  }

  .text-lg-6 {
    font-size: 1.8em !important;
  }

  .text-lg-7 {
    font-size: 2em !important;
  }

  .text-lg-8 {
    font-size: 2.3em !important;
  }

  .text-lg-9 {
    font-size: 2.5em !important;
  }

  .text-lg-10 {
    font-size: 2.75em !important;
  }

  .text-lg-11 {
    font-size: 3em !important;
  }

  .text-lg-12 {
    font-size: 3.5em !important;
  }

  .text-lg-13 {
    font-size: 4em !important;
  }

  .text-lg-14 {
    font-size: 4.5em !important;
  }

  .text-lg-15 {
    font-size: 5em !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-1 {
    font-size: 0.8em !important;
  }

  .text-xl-2 {
    font-size: 0.9em !important;
  }

  .text-xl-3 {
    font-size: 1em !important;
  }

  .text-xl-4-1 {
    font-size: 1.1em !important;
  }

  .text-xl-4 {
    font-size: 1.2em !important;
  }

  .text-xl-5 {
    font-size: 1.5em !important;
  }

  .text-xl-6 {
    font-size: 1.8em !important;
  }

  .text-xl-7 {
    font-size: 2em !important;
  }

  .text-xl-8 {
    font-size: 2.3em !important;
  }

  .text-xl-9 {
    font-size: 2.5em !important;
  }

  .text-xl-10 {
    font-size: 2.75em !important;
  }

  .text-xl-11 {
    font-size: 3em !important;
  }

  .text-xl-12 {
    font-size: 3.5em !important;
  }

  .text-xl-13 {
    font-size: 4em !important;
  }

  .text-xl-14 {
    font-size: 4.5em !important;
  }

  .text-xl-15 {
    font-size: 5em !important;
  }
}

/* Line Height */
.line-height-xs {
  line-height: 16px !important;
}

.line-height-sm {
  line-height: 20px !important;
}

.line-height-md {
  line-height: 24px !important;
}

.line-height-lg {
  line-height: 28px !important;
}

.line-height-xl {
  line-height: 32px !important;
}

/* Letter Spacing */
.ls-0 {
  letter-spacing: 0 !important;
}

.negative-ls-05 {
  letter-spacing: -0.05em;
}

.negative-ls-1 {
  letter-spacing: -1px;
}

.negative-ls-2 {
  letter-spacing: -2px;
}

.negative-ls-3 {
  letter-spacing: -2.5px;
}

.positive-ls-1 {
  letter-spacing: 1px;
}

.positive-ls-2 {
  letter-spacing: 2px;
}

.positive-ls-3 {
  letter-spacing: 2.5px;
}

/* Z-Index */
.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

/* Outline */
.outline-none {
  outline: 0 !important;
}

/* Overflow */
.overflow-visible {
  overflow: visible !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

/* Text Decoration */
.text-decoration-none {
  text-decoration: none !important;
}

/* Text Transform */
.text-uppercase {
  text-transform: uppercase !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

/* States */
.text-muted {
  color: #999 !important;
}

html.dark .text-muted {
  color: #505461 !important;
}

/* Colors */
.text-dark {
  color: #5a5c63 !important;
}

.text-red-promo {
  color: #d2312d !important;
}

.text-light {
  color: #fff !important;
}

.text-color-success {
  color: #34c38f !important;
}

.text-color-danger {
  color: #ea5455 !important;
}

.text-color-grey {
  color: #999 !important;
}

.text-color-inherit {
  color: inherit;
}

.text-center {
  text-align: center;
}

.text-espacios {
  font-size: 12px;
}

.text-padding {
  padding-left: 8rem;
  padding-right: 8rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

/* Weights */
.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.font-weight-bold,
b,
strong {
  font-weight: 700 !important;
}

.font-weight-bold-center,
b,
strong {
  font-weight: 700 !important;
  text-align: center;
}

.font-weight-extra-bold {
  font-weight: 900 !important;
}

/* Line Height */
.line-height-1 {
  line-height: 1 !important;
}

.line-height-2 {
  line-height: 1.2 !important;
}

.line-height-3 {
  line-height: 1.3 !important;
}

.line-height-4 {
  line-height: 1.4 !important;
}

.line-height-5 {
  line-height: 1.5 !important;
}

.line-height-6 {
  line-height: 1.6 !important;
}

.line-height-7 {
  line-height: 1.7 !important;
}

.line-height-8 {
  line-height: 1.8 !important;
}

.line-height-9 {
  line-height: 1.9 !important;
}

/* Borders */
.no-borders {
  border: none !important;
}

.rounded {
  border-radius: 5px !important;
}

.b-thin {
  border-width: 3px !important;
}

.b-normal {
  border-width: 5px !important;
}

.b-thick {
  border-width: 7px !important;
}

/* Border Width */
.border-width-1 {
  border-width: 1px !important;
}

.border-width-2 {
  border-width: 2px !important;
}

.border-width-3 {
  border-width: 3px !important;
}

.border-width-4 {
  border-width: 4px !important;
}

.border-width-5 {
  border-width: 5px !important;
}

.border-width-6 {
  border-width: 6px !important;
}

.border-width-7 {
  border-width: 7px !important;
}

.border-width-8 {
  border-width: 8px !important;
}

.border-width-9 {
  border-width: 9px !important;
}

.border-width-10 {
  border-width: 10px !important;
}

/* Border Color */
.border-color-light-grey {
  border-color: #f4f4f4 !important;
}

.border-color-light-grey-2 {
  border-color: #ececec !important;
}

/* General Helpers */
.ib {
  display: inline-block;
  vertical-align: top;
}

.va-middle {
  vertical-align: middle;
}

.ws-nowrap {
  white-space: nowrap;
}

.ws-normal {
  white-space: normal;
}

/* Grid */
.col-1-5,
.col-sm-1-5,
.col-md-1-5,
.col-lg-1-5,
.col-xl-1-5,
.col-2-5,
.col-sm-2-5,
.col-md-2-5,
.col-lg-2-5,
.col-xl-2-5,
.col-3-5,
.col-sm-3-5,
.col-md-3-5,
.col-lg-3-5,
.col-xl-3-5,
.col-4-5,
.col-sm-4-5,
.col-md-4-5,
.col-lg-4-5,
.col-xl-4-5 {
  position: relative;
  min-height: 1px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-1-5 {
  flex: 0 0 20%;
  max-width: 20%;
}

.col-2-5 {
  flex: 0 0 40%;
  max-width: 40%;
}

.col-3-5 {
  flex: 0 0 60%;
  max-width: 60%;
}

.col-4-5 {
  flex: 0 0 80%;
  max-width: 80%;
}

@media (min-width: 576px) {
  .col-sm-1-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-sm-2-5 {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .col-sm-3-5 {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .col-sm-4-5 {
    flex: 0 0 80%;
    max-width: 80%;
  }
}

@media (min-width: 768px) {
  .col-md-1-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-md-2-5 {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .col-md-3-5 {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .col-md-4-5 {
    flex: 0 0 80%;
    max-width: 80%;
  }
}

@media (min-width: 992px) {
  .col-lg-1-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-lg-2-5 {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .col-lg-3-5 {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .col-lg-4-5 {
    flex: 0 0 80%;
    max-width: 80%;
  }
}

@media (min-width: 1200px) {
  .col-xl-1-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-xl-2-5 {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .col-xl-3-5 {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .col-xl-4-5 {
    flex: 0 0 80%;
    max-width: 80%;
  }
}

/* Menu */
ul.nav-main {
  margin-right: 5px;
}

ul.nav-main a {
  text-decoration: none;
}

ul.nav-main > li > a {
  padding: 12px 25px;
}

ul.nav-main > li > a:hover,
ul.nav-main > li > a:focus {
  background-color: #d5d5d8;
  color: #db052c;
}

ul.nav-main > li > a:hover i div svg *,
ul.nav-main > li > a:focus i div svg * {
  fill: #db052c;
}

ul.nav-main > li.nav-active > a {
  box-shadow: 2px 0 0 #ccc inset;
}

ul.nav-main > li.nav-active > a:hover {
  color: #5a5c63;
}

ul.nav-main > li.nav-active > i {
  color: #ccc;
}

ul.nav-main > li.nav-expanded > a {
  background: #d5d5d8;
}

ul.nav-main li {
  width: 100%;
}

ul.nav-main li a {
  display: block;
  color: #5a5c63;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12.8px;
  font-size: 0.8rem;
}

ul.nav-main li a span.badge {
  font-weight: normal;
  padding: 0.3em 0.7em 0.4em;
  margin: 0.4em -1em 0 0;
}

ul.nav-main li a .not-included {
  font-style: normal;
  color: #505b67;
  display: inline-block;
  padding: 0 0 0 6px;
}

ul.nav-main li span {
  vertical-align: middle;
}

ul.nav-main li i {
  font-size: 18.4px;
  font-size: 1.15rem;
  width: 1.1em;
  margin-right: 0.5em;
  text-align: center;
  vertical-align: middle;
}

ul.nav-main li.nav-parent {
  position: relative;
}

ul.nav-main li.nav-parent > a {
  cursor: pointer;
}

ul.nav-main li.nav-parent.nav-expanded > ul.nav-children {
  display: block;
}

ul.nav-main li .nav-children {
  background: #d5d5d8;
  box-shadow: 0px -3px 3px -3px rgba(0, 0, 0, 0.7) inset;
  display: none;
  padding: 10px 0;
}

ul.nav-main li .nav-children li a {
  padding: 6px 15px 6px 57px;
  overflow: hidden;
}

ul.nav-main li .nav-children li a:hover,
ul.nav-main li .nav-children li a:focus {
  background: #d5d5d8;
}

ul.nav-main li .nav-children li a:after {
  padding: 6px 25px;
}

ul.nav-main li .nav-children li.nav-active > a {
  color: #ccc;
}

ul.nav-main li .nav-children .nav-children {
  box-shadow: none;
  padding: 0;
}

ul.nav-main li .nav-children .nav-children li a {
  padding: 6px 15px 6px 82px;
}

ul.nav-main li .nav-children .nav-children .nav-children li a {
  padding: 6px 15px 6px 97px;
}

/* Sidebar Light - Menu */
html.sidebar-light:not(.dark) ul.nav-main {
  margin-top: 3px;
}

html.sidebar-light:not(.dark) ul.nav-main li a {
  color: #777;
}

html.sidebar-light:not(.dark) ul.nav-main > li > a:hover,
html.sidebar-light:not(.dark) ul.nav-main > li > a:focus {
  background-color: #fafafa;
}

html.sidebar-light:not(.dark) ul.nav-main > li.nav-expanded > a {
  background: #fafafa;
}

html.sidebar-light:not(.dark) ul.nav-main li .nav-children {
  background: #f6f6f6;
  box-shadow: 0 -3px 3px -3px rgba(0, 0, 0, 0.1) inset;
}

html.sidebar-light:not(.dark) ul.nav-main li .nav-children li a:hover,
html.sidebar-light:not(.dark) ul.nav-main li .nav-children li a:focus {
  background: #f1f1f1;
}

/* Accordion */
.accordion a:hover {
  text-decoration: none !important;
}

.accordion .card {
  margin-top: 5px !important;
}

.accordion .card:first-child {
  margin-top: 0;
}

.accordion .card-title {
  font-size: 16px;
}

.accordion .card-header {
  padding: 0;
  border-radius: 3px !important;
  border-bottom: 0;
}

.accordion .card-header a {
  display: block;
  padding: 10px 20px;
}

.accordion .card-header a:hover,
.accordion .card-header a:focus {
  text-decoration: none;
}

.accordion .card-header a [class^="icon-"] {
  margin-right: 4px;
  position: relative;
  top: 1px;
}

.accordion .card-header a .fa,
.accordion .card-header a .fab,
.accordion .card-header a .fal,
.accordion .card-header a .far,
.accordion .card-header a .fas,
.accordion .card-header a .icons {
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: -1px;
}

.accordion .card-body {
  border-radius: 0 0 5px 5px;
}

.accordion.accordion-sm .card-header a {
  padding: 5px 15px;
  font-size: 0.9em;
}

.accordion.accordion-sm .card-body {
  font-size: 0.9em;
}

.accordion.accordion-lg .card-header a {
  padding: 15px 15px;
  font-size: 1em;
  font-weight: 600;
}

.accordion.without-bg .card-default {
  background: transparent !important;
}

.accordion.without-bg .card-default > .card-header {
  background: transparent !important;
}

.accordion.without-bg .card-body {
  padding-top: 0;
  padding-bottom: 0;
}

.accordion.without-borders .card {
  border: 0;
  box-shadow: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
}

.accordion.without-borders .card-header a {
  padding-left: 0;
  padding-right: 0;
}

.accordion.without-borders .card-body {
  padding: 0;
}

.card-accordion {
  margin-top: 5px !important;
  border: 0 !important;
}

.card-accordion a:hover {
  text-decoration: none !important;
}

.card-accordion.card-accordion-first {
  margin-top: 0 !important;
}

.card-accordion .card-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-accordion .card-title {
  font-size: 16px;
  font-size: 1rem;
}

/* Alerts */
.alert-default {
  background-color: #ebebeb;
  border-color: #e3e3e3;
  color: #6c6c6c;
}

.alert-default .alert-link {
  color: #454545;
}

.alert-primary {
  background-color: #ccc;
  border-color: #c4c4c4;
  color: #fff;
}

.alert-primary .alert-link {
  color: #999999;
}

.alert-dark {
  background-color: #313131;
  border-color: black;
  color: #cacaca;
}

.alert-dark .alert-link {
  color: #f0f0f0;
}

/* Animations */
.appear-animation {
  opacity: 0;
}

.appear-animation-visible {
  opacity: 1;
}

/* Fade In Up Shorter */
@-webkit-keyframes fadeInUpShorter {
  from {
    opacity: 0;
    transform: translate(0, 50px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInUpShorter {
  from {
    opacity: 0;
    transform: translate(0, 50px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUpShorter {
  -webkit-animation-name: fadeInUpShorter;
  animation-name: fadeInUpShorter;
}

/* Fade In Left Shorter */
@-webkit-keyframes fadeInLeftShorter {
  from {
    opacity: 0;
    transform: translate(50px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInLeftShorter {
  from {
    opacity: 0;
    transform: translate(50px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeftShorter {
  -webkit-animation-name: fadeInLeftShorter;
  animation-name: fadeInLeftShorter;
}

/* Fade In Right Shorter */
@-webkit-keyframes fadeInRightShorter {
  from {
    opacity: 0;
    transform: translate(-50px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInRightShorter {
  from {
    opacity: 0;
    transform: translate(-50px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRightShorter {
  -webkit-animation-name: fadeInRightShorter;
  animation-name: fadeInRightShorter;
}

/* Fade In Down Shorter */
@-webkit-keyframes fadeInDownShorter {
  from {
    opacity: 0;
    transform: translate(0, -50px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInDownShorter {
  from {
    opacity: 0;
    transform: translate(0, -50px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInDownShorter {
  -webkit-animation-name: fadeInDownShorter;
  animation-name: fadeInDownShorter;
}

/* Dot Pulse */
@-webkit-keyframes dotPulse {
  from {
    opacity: 1;
    transform: scale(0.2);
  }

  to {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes dotPulse {
  from {
    opacity: 1;
    transform: scale(0.2);
  }

  to {
    opacity: 0;
    transform: scale(1);
  }
}

.dotPulse {
  -webkit-animation-name: dotPulse;
  animation-name: dotPulse;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
}

/* Blur In */
@-webkit-keyframes blurIn {
  from {
    opacity: 0;
    -webkit-filter: blur(20px);
    filter: blur(20px);
    transform: scale(1.3);
  }

  to {
    opacity: 1;
    -webkit-filter: blur(0);
    filter: blur(0);
    transform: none;
  }
}

@keyframes blurIn {
  from {
    opacity: 0;
    -webkit-filter: blur(20px);
    filter: blur(20px);
    transform: scale(1.3);
  }

  to {
    opacity: 1;
    -webkit-filter: blur(0);
    filter: blur(0);
    transform: none;
  }
}

.blurIn {
  -webkit-animation-name: blurIn;
  animation-name: blurIn;
}

/* Mask Up */
@-webkit-keyframes maskUp {
  from {
    transform: translate(0, 100%);
  }

  to {
    transform: translate(0, 0);
  }
}

@keyframes maskUp {
  from {
    transform: translate(0, 100%);
  }

  to {
    transform: translate(0, 0);
  }
}

/* Mask Right */
@-webkit-keyframes maskRight {
  from {
    transform: translate(-100%, 0);
  }

  to {
    transform: translate(0, 0);
  }
}

@keyframes maskRight {
  from {
    transform: translate(-100%, 0);
  }

  to {
    transform: translate(0, 0);
  }
}

/* Mask Down */
@-webkit-keyframes maskDown {
  from {
    transform: translate(0, -100%);
  }

  to {
    transform: translate(0, 0);
  }
}

@keyframes maskDown {
  from {
    transform: translate(0, -100%);
  }

  to {
    transform: translate(0, 0);
  }
}

/* Mask Left */
@-webkit-keyframes maskLeft {
  from {
    transform: translate(100%, 0);
  }

  to {
    transform: translate(0, 0);
  }
}

@keyframes maskLeft {
  from {
    transform: translate(100%, 0);
  }

  to {
    transform: translate(0, 0);
  }
}

.maskUp {
  -webkit-animation-name: maskUp;
  animation-name: maskUp;
}

.maskRight {
  -webkit-animation-name: maskRight;
  animation-name: maskRight;
}

.maskDown {
  -webkit-animation-name: maskDown;
  animation-name: maskDown;
}

.maskLeft {
  -webkit-animation-name: maskLeft;
  animation-name: maskLeft;
}

/* Badges */
.badge-default {
  background: #ebebeb;
  color: #777;
}

.badge-sm {
  font-size: 50%;
}

.badge-md {
  padding: 6px 11px;
}

.badge-ecommerce {
  font-size: 9.6px;
  font-size: 0.6rem;
  font-weight: 600;
  border-radius: 0;
  padding: 6.4px 8px;
  padding: 0.4rem 0.5rem;
}

.badge-pvr {
  font-size: 9.6px;
  font-size: 0.6rem;
  font-weight: 600;
  border-radius: 0;
  padding: 6.4px 8px;
  padding: 0.4rem 0.7rem;
}

.badge-zero {
  font-size: 9.6px;
  font-size: 0.6rem;
  font-weight: 800;
  border-radius: 0;
  padding: 6.4px 8px;
}

.badge-descuento {
  font-size: 9.6px;
  font-size: 0.6rem;
  font-weight: 600;
  border-radius: 0;
  padding: 6.4px 8px;
  padding: 0.4rem 0.46rem;
}

.badge-descuento-outlet {
  font-size: 9.6px;
  font-size: 0.6rem;
  font-weight: 600;
  border-radius: 0;
  padding: 6.4px 8px;
  padding: 0.4rem 0.66rem;
}

.badge-primary {
  background: #ccc;
  color: #fff;
}

.badge-success {
  background: #47a447;
  color: #fff;
}

.badge-warning {
  background: #ed9c28;
  color: #fff;
}

.badge-danger {
  background: #d2322d;
  color: #fff;
}

.badge-info {
  background: #5a5c63;
  color: #fff;
}

.badge-dark {
  background: #5a5c63;
  color: #fff;
}

/* Buttons */
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  box-shadow: none !important;
}

.btn-default:hover,
.btn-default:active,
.btn-default:focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn {
  font-size: 14.4px;
  font-size: 0.9rem;
  padding: 7.52px 12px;
  padding: 0.47rem 0.75rem;
  cursor: pointer;
}

.btn.btn-xs {
  font-size: 11.2px;
  font-size: 0.7rem;
  padding: 3.2px 8px;
  padding: 0.2rem 0.5rem;
}

.btn.btn-sm {
  font-size: 12.8px;
  font-size: 0.8rem;
  padding: 4.8px 10.4px;
  padding: 0.3rem 0.65rem;
}

.btn.btn-lg {
  font-size: 16px;
  font-size: 1rem;
  padding: 8px 16px;
  padding: 0.5rem 1rem;
}

.btn.btn-xl {
  font-size: 16px;
  font-size: 1rem;
  padding: 12.8px 32px;
  padding: 0.8rem 2rem;
}

.btn.btn-px-1 {
  padding-left: 5.6px;
  padding-left: 0.35rem;
  padding-right: 5.6px;
  padding-right: 0.35rem;
}

.btn.btn-px-2 {
  padding-left: 12px;
  padding-left: 0.75rem;
  padding-right: 12px;
  padding-right: 0.75rem;
}

.btn.btn-px-3 {
  padding-left: 20px;
  padding-left: 1.25rem;
  padding-right: 20px;
  padding-right: 1.25rem;
}

.btn.btn-px-4 {
  padding-left: 28px;
  padding-left: 1.75rem;
  padding-right: 28px;
  padding-right: 1.75rem;
}

.btn.btn-px-5 {
  padding-left: 38.4px;
  padding-left: 2.4rem;
  padding-right: 38.4px;
  padding-right: 2.4rem;
}

.btn.btn-py-1 {
  padding-top: 5.6px;
  padding-top: 0.35rem;
  padding-bottom: 5.6px;
  padding-bottom: 0.35rem;
}

.btn.btn-py-2 {
  padding-top: 12px;
  padding-top: 0.75rem;
  padding-bottom: 12px;
  padding-bottom: 0.75rem;
}

.btn.btn-py-3 {
  padding-top: 20px;
  padding-top: 1.25rem;
  padding-bottom: 20px;
  padding-bottom: 1.25rem;
}

.btn.btn-py-4 {
  padding-top: 28px;
  padding-top: 1.75rem;
  padding-bottom: 28px;
  padding-bottom: 1.75rem;
}

.btn.btn-py-5 {
  padding-top: 32px;
  padding-top: 2rem;
  padding-bottom: 32px;
  padding-bottom: 2rem;
}

/* Rounded */
.btn-rounded {
  border-radius: 35px;
}

/* Modern */
.btn-modern {
  text-transform: uppercase;
  font-size: 0.8em;
  padding: 12.8px 24px;
  padding: 0.8rem 1.5rem;
  font-weight: 600;
}

/* Icons	*/
.btn-icon i {
  margin-right: 10px;
}

.btn-icon-right i {
  margin-right: 0;
  margin-left: 10px;
}

/* Colors */
.btn-primary {
  background-color: #ccc;
  border-color: #ccc #ccc #b3b3b3;
  color: #fff;
}

.btn-primary:hover,
.btn-primary.hover {
  background-color: #dfdfdf;
  border-color: #e6e6e6 #e6e6e6 #ccc;
  color: #fff;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 3px rgba(204, 204, 204, 0.5);
  color: #fff;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #ccc;
  border-color: #ccc #ccc #b3b3b3;
}

.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  background-color: #b9b9b9;
  background-image: none;
  border-color: #b3b3b3 #b3b3b3 #999999;
}

.show > .btn-primary.dropdown-toggle {
  background-color: #b9b9b9;
  background-image: none;
  border-color: #b3b3b3 #b3b3b3 #999999;
}

.btn-success {
  background-color: #47a447;
  border-color: #47a447 #47a447 #388038;
  color: #fff;
}

.btn-success:hover,
.btn-success.hover {
  background-color: #5ab75a;
  border-color: #63bb63 #63bb63 #47a447;
  color: #fff;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 3px rgba(71, 164, 71, 0.5);
  color: #fff;
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: #47a447;
  border-color: #47a447 #47a447 #388038;
}

.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
  background-color: #3b893b;
  background-image: none;
  border-color: #388038 #388038 #285d28;
}

.show > .btn-success.dropdown-toggle {
  background-color: #3b893b;
  background-image: none;
  border-color: #388038 #388038 #285d28;
}

.btn-warning {
  background-color: #ed9c28;
  border-color: #ed9c28 #ed9c28 #d18211;
  color: #fff;
}

.btn-warning:hover,
.btn-warning.hover {
  background-color: #f0ac4b;
  border-color: #f1b257 #f1b257 #ed9c28;
  color: #fff;
}

.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 3px rgba(237, 156, 40, 0.5);
  color: #fff;
}

.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #ed9c28;
  border-color: #ed9c28 #ed9c28 #d18211;
}

.btn-warning:active,
.btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  background-color: #dc8912;
  background-image: none;
  border-color: #d18211 #d18211 #a1650e;
}

.show > .btn-warning.dropdown-toggle {
  background-color: #dc8912;
  background-image: none;
  border-color: #d18211 #d18211 #a1650e;
}

.btn-danger {
  background-color: #d2322d;
  border-color: #d2322d #d2322d #a82824;
  color: #fff;
}

.btn-danger:hover,
.btn-danger.hover {
  background-color: #d9514d;
  border-color: #db5b57 #db5b57 #d2322d;
  color: #fff;
}

.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 0 0 3px rgba(210, 50, 45, 0.5);
  color: #fff;
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #d2322d;
  border-color: #d2322d #d2322d #a82824;
}

.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  background-color: #b32b26;
  background-image: none;
  border-color: #a82824 #a82824 #7e1e1b;
}

.show > .btn-danger.dropdown-toggle {
  background-color: #b32b26;
  background-image: none;
  border-color: #a82824 #a82824 #7e1e1b;
}

.btn-info {
  background-color: #5a5c63;
  border-color: #5a5c63 #5a5c63 #31b0d5;
  color: #fff;
}

.btn-info:hover,
.btn-info.hover {
  background-color: #7bcce4;
  border-color: #85d0e7 #85d0e7 #5a5c63;
  color: #fff;
}

.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 3px rgba(91, 192, 222, 0.5);
  color: #fff;
}

.btn-info.disabled,
.btn-info:disabled {
  background-color: #5a5c63;
  border-color: #5a5c63 #5a5c63 #31b0d5;
}

.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
  background-color: #3bb4d8;
  background-image: none;
  border-color: #31b0d5 #31b0d5 #2390b0;
}

.show > .btn-info.dropdown-toggle {
  background-color: #3bb4d8;
  background-image: none;
  border-color: #31b0d5 #31b0d5 #2390b0;
}

.btn-dark {
  background-color: #5a5c63;
  border-color: #5a5c63 #5a5c63 black;
  color: #fff;
}

.btn-dark:hover,
.btn-dark.hover {
  background-color: #2a2a2a;
  border-color: #313131 #313131 #5a5c63;
  color: #fff;
}

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 0 0 3px rgba(23, 23, 23, 0.5);
  color: #fff;
}

.btn-dark.disabled,
.btn-dark:disabled {
  background-color: #5a5c63;
  border-color: #5a5c63 #5a5c63 black;
}

.btn-dark:active,
.btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  background-color: #040404;
  background-image: none;
  border-color: black black black;
}

.show > .btn-dark.dropdown-toggle {
  background-color: #040404;
  background-image: none;
  border-color: black black black;
}

/* Gradient */
.btn-gradient:not(.btn-outline) {
  border: 0;
}

.btn-gradient:not(.btn-outline):hover,
.btn-gradient:not(.btn-outline).hover {
  border: 0;
}

.btn-gradient:not(.btn-outline):focus,
.btn-gradient:not(.btn-outline).focus {
  border: 0;
}

.btn-gradient:not(.btn-outline).disabled,
.btn-gradient:not(.btn-outline):disabled {
  border: 0;
}

.btn-gradient:not(.btn-outline):active,
.btn-gradient:not(.btn-outline).active {
  border: 0;
}

.btn-gradient.btn-outline {
  border: 2px solid transparent;
  background: transparent;
  border-image-slice: 1 !important;
}

.btn-gradient.btn-outline:hover,
.btn-gradient.btn-outline.hover {
  border: 2px solid transparent;
}

.btn-gradient.btn-outline.disabled,
.btn-gradient.btn-outline:disabled {
  border: 2px solid transparent;
}

.btn-gradient.btn-outline:active,
.btn-gradient.btn-outline.active {
  border: 2px solid transparent;
}

html {
  /* Buttons - Social */
}

html .btn-facebook,
html .btn-facebook:active,
html .btn-facebook:hover,
html .btn-facebook:focus,
html .btn-twitter,
html .btn-twitter:active,
html .btn-twitter:hover,
html .btn-twitter:focus,
html .btn-gplus,
html .btn-gplus:active,
html .btn-gplus:hover,
html .btn-gplus:focus {
  color: #fff;
  font-weight: 300;
  padding-left: 30px;
  padding-right: 30px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
}

html .btn-facebook,
html .btn-facebook:focus {
  background: #3b5998;
  border: 1px solid #37538d;
}

html .btn-facebook:hover {
  background: #4162a7;
  border-color: #3d5c9c;
}

html .btn-facebook:active {
  background: #37538d;
  border-color: #334d82;
}

html .btn-twitter,
html .btn-twitter:focus {
  background: #55acee;
  border: 1px solid #47a5ed;
}

html .btn-twitter:hover {
  background: #63b3ef;
  border-color: #55acee;
}

html .btn-twitter:active {
  background: #47a5ed;
  border-color: #399eec;
}

html .btn-gplus,
html .btn-gplus:focus {
  background: #d95232;
  border: 1px solid #d44927;
}

html .btn-gplus:hover {
  background: #dc6143;
  border-color: #da5635;
}

html .btn-gplus:active {
  background: #d04727;
  border-color: #c34324;
}

/* Buttons Icon */
.btn-icon i {
  margin-right: 10px;
}

.btn-icon-right i {
  margin-right: 0;
  margin-left: 10px;
}

html.dark .btn-default {
  background-color: #282d36;
  border-color: #282d36;
  color: #eee;
}

html.dark .btn-default:hover {
  background-color: #2a3039;
  border-color: #2a3039;
}

html.dark .btn-default:focus,
html.dark .btn-default:active {
  background-color: #242830;
  border-color: #242830;
}

html.dark .btn-default:hover,
html.dark .btn-default:focus,
html.dark .btn-default:active,
html.dark .btn-default.active,
html.dark .show > .dropdown-toggle.btn-default {
  color: #eee;
  background-color: #242830;
  border-color: #242830;
}

/* Call To Action */
.call-to-action {
  padding: 25px;
  border-radius: 5px;
}

.call-to-action.call-to-action-primary {
  background-color: #ccc;
}

.call-to-action .call-to-action-content {
  text-align: left;
}

.call-to-action .call-to-action-content h2 {
  color: #fff;
  font-weight: 100;
}

.call-to-action .call-to-action-content p {
  color: #fff;
  font-size: 16px;
  font-weight: 100;
}

.call-to-action .call-to-action-btn {
  margin-top: 45px;
}

.call-to-action .call-to-action-btn a.btn:not(.btn-primary) {
  border-radius: 6px;
  font-size: 16px;
  padding: 12px 33px;
  margin-right: 15px;
}

.call-to-action .call-to-action-btn > span {
  position: relative;
  color: #fff;
}

.call-to-action .call-to-action-btn > span .arrow {
  position: absolute;
  top: -55px;
  left: -70px;
}

@media (max-width: 1276px) {
  .call-to-action .call-to-action-btn > span {
    display: none;
  }
}

@media (max-width: 767px) {
  .call-to-action .call-to-action-btn {
    margin-top: 0;
    float: none !important;
  }
}

.call-to-action.call-to-action-grey {
  position: relative;
  background-color: #ecedf0;
}

.call-to-action.call-to-action-grey:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  height: 100%;
  background-color: #ecedf0;
  z-index: 0;
  transform: translateX(-50%);
}

.call-to-action.call-to-action-grey .call-to-action-content h2 {
  color: #5a5c63;
}

.call-to-action.call-to-action-grey .call-to-action-content p {
  color: #777;
}

.call-to-action.call-to-action-top {
  margin: -40px;
  border-radius: 0;
  padding: 40px 25px;
}

@media (max-width: 767px) {
  .call-to-action.call-to-action-top {
    margin-top: -16px;
  }
}

/* Responsive */
html:not(.sidebar-left-collapsed) {
  /* Boxed Layout */
}

@media (max-width: 1400px) {
  html:not(.sidebar-left-collapsed) .call-to-action .call-to-action-btn > span {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  html:not(.sidebar-left-collapsed) .call-to-action .call-to-action-btn {
    margin-top: 0;
    float: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  html:not(.sidebar-left-collapsed) .call-to-action .col-sm-4,
  html:not(.sidebar-left-collapsed) .call-to-action .col-sm-8,
  html:not(.sidebar-left-collapsed) .call-to-action .col-sm-3,
  html:not(.sidebar-left-collapsed) .call-to-action .col-sm-9 {
    width: 100%;
  }
}

html:not(.sidebar-left-collapsed).boxed .call-to-action .call-to-action-btn {
  margin-top: 0;
  float: none !important;
}

html:not(.sidebar-left-collapsed).boxed
  .call-to-action
  .call-to-action-btn
  > span {
  display: none;
}

/* Dark */
html.dark .call-to-action.call-to-action-grey {
  background-color: #e6e6ea;
}

html.dark .call-to-action.call-to-action-grey:before {
  background-color: #e6e6ea;
}

/* Cards */
.card {
  background: transparent;
  box-shadow: none;
  border: none;
}

.card + .card {
  margin-top: 35.2px;
  margin-top: 2.2rem;
}

.card.card-admin {
  margin-bottom: 48px;
  margin-bottom: 3rem;
}

.card-header {
  background: #f6f6f6;
  border-radius: 5px 5px 0 0 !important;
  border-bottom: 1px solid #dadada;
  padding: 18px;
  position: relative;
}

.card-header.bg-white {
  background: #fff;
  border-bottom: 0 none;
  border-right: 0 none;
}

.card-header.bg-primary {
  background: #ccc;
  color: #fff;
  border-bottom: 0 none;
  border-right: 0 none;
}

.card-header.bg-success {
  background: #47a447;
  color: #fff;
  border-bottom: 0 none;
  border-right: 0 none;
}

.card-header.bg-warning {
  background: #ed9c28;
  color: #fff;
  border-bottom: 0 none;
  border-right: 0 none;
}

.card-header.bg-danger {
  background: #d2322d;
  color: #fff;
  border-bottom: 0 none;
  border-right: 0 none;
}

.card-header.bg-info {
  background: #5a5c63;
  color: #fff;
  border-bottom: 0 none;
  border-right: 0 none;
}

.card-header.bg-dark {
  background: #5a5c63;
  color: #fff;
  border-bottom: 0 none;
  border-right: 0 none;
}

.card-header.bg-white {
  background: #fff;
  border-bottom: 0 none;
  border-right: 0 none;
}

.card-actions {
  right: 15px;
  position: absolute;
  top: 15px;
}

.card-actions a,
.card-actions .card-action {
  background-color: transparent;
  border-radius: 2px;
  color: #b4b4b4;
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  width: 24px;
}

.card-actions a:hover,
.card-actions .card-action:hover {
  background-color: #eeeeee;
  color: #b4b4b4;
  text-decoration: none;
}

.card-actions a,
.card-actions a:focus,
.card-actions a:hover,
.card-actions a:active,
.card-actions a:visited,
.card-actions .card-action,
.card-actions .card-action:focus,
.card-actions .card-action:hover,
.card-actions .card-action:active,
.card-actions .card-action:visited {
  outline: none !important;
  text-decoration: none !important;
}

.card-title {
  color: #33353f;
  font-size: 18px;
  line-height: 18px;
  padding: 0;
  text-transform: none;
  margin: 0;
  font-weight: 600;
  letter-spacing: -1px;
}

.card-title.families-masvendidos-title {
  color: #94969d;
  font-size: 23px;
  line-height: 1.3em;
  padding: 0;
  margin: 23px 0;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
}

.card-title.families-masvendidos-title span {
  font-weight: 800;
}

.card-subtitle {
  color: #808697;
  font-size: 13px;
  line-height: 1.2em;
  margin: 7px 0 0;
  padding: 0;
}

.card-body {
  background: #fdfdfd;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.card-body-nopadding {
  padding: 0;
}

.card-header + .card-body {
  border-radius: 0 0 5px 5px;
}

.card-footer {
  border-radius: 0 0 5px 5px;
  margin-top: -5px;
  background: #fff;
}

.card-footer::after {
  clear: both;
  content: "";
  display: block;
}

.card-footer .pager {
  margin: 0;
  padding: 5px 0;
}

.card-footer .pager::after {
  clear: both;
  content: "";
  display: block;
}

.card-footer-btn-group {
  display: table;
  width: 100%;
  padding: 0;
  border-radius: 0 0 5px 5px !important;
  overflow: hidden;
}

.card-footer-btn-group a {
  background-color: #f5f5f5;
  display: table-cell;
  border-left: 1px solid #ddd;
  padding: 10px 15px;
  text-decoration: none;
}

.card-footer-btn-group a:hover {
  background-color: #f0f0f0;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1) inset;
}

.card-footer-btn-group a:first-child {
  border-left: none;
}

.card-body.bg-primary {
  background: #ccc;
  color: #fff;
}

.card-body.bg-success {
  background: #47a447;
  color: #fff;
}

.card-body.bg-warning {
  background: #ed9c28;
  color: #fff;
}

.card-body.bg-danger {
  background: #d2322d;
  color: #fff;
}

.card-body.bg-info {
  background: #5a5c63;
  color: #fff;
}

.card-body.bg-dark {
  background: #5a5c63;
  color: #fff;
}

.card-featured {
  border-top: 3px solid #33353f;
}

.card-featured .card-header {
  border-radius: 0;
}

.card-featured-top {
  border-top: 3px solid #ccc;
}

.card-featured-right {
  border-right: 3px solid #ccc;
}

.card-featured-bottom {
  border-bottom: 3px solid #ccc;
}

.card-featured-left {
  border-left: 3px solid #ccc;
}

.card-featured-primary {
  border-color: #ccc;
}

.card-featured-primary .card-title {
  color: #ccc;
}

.card-featured-success {
  border-color: #47a447;
}

.card-featured-success .card-title {
  color: #47a447;
}

.card-featured-warning {
  border-color: #ed9c28;
}

.card-featured-warning .card-title {
  color: #ed9c28;
}

.card-featured-danger {
  border-color: #d2322d;
}

.card-featured-danger .card-title {
  color: #d2322d;
}

.card-featured-info {
  border-color: #5a5c63;
}

.card-featured-info .card-title {
  color: #5a5c63;
}

.card-featured-dark {
  border-color: #5a5c63;
}

.card-featured-dark .card-title {
  color: #5a5c63;
}

.card-highlight .card-header {
  background-color: #ccc;
  border-color: #ccc;
  color: #fff;
}

.card-highlight .card-title {
  color: #fff;
}

.card-highlight .card-subtitle {
  color: #fff;
  color: rgba(255, 255, 255, 0.7);
}

.card-highlight .card-actions a {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
}

.card-highlight .card-body {
  background-color: #ccc;
  color: #fff;
}

.card-highlight-title .card-header {
  background-color: #2baab1;
}

.card-highlight-title .card-title {
  color: #fff;
}

.card-highlight-title .card-subtitle {
  color: #fff;
  color: rgba(255, 255, 255, 0.7);
}

.card-highlight-title .card-actions a {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
}

.card-header-icon {
  margin: 0 auto;
  font-size: 44.8px;
  font-size: 2.8rem;
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 55px;
}

.card-header-icon.bg-primary {
  background: #ccc;
  color: #fff;
}

.card-header-icon.bg-success {
  background: #47a447;
  color: #fff;
}

.card-header-icon.bg-warning {
  background: #ed9c28;
  color: #fff;
}

.card-header-icon.bg-danger {
  background: #d2322d;
  color: #fff;
}

.card-header-icon.bg-info {
  background: #5a5c63;
  color: #fff;
}

.card-header-icon.bg-dark {
  background: #5a5c63;
  color: #fff;
}

.card-header-profile-picture img {
  display: block;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  border: 4px solid #fff;
  border-radius: 50px;
}

.card-icon {
  color: #fff;
  font-size: 42px;
  float: left;
}

.card-icon ~ .card-title,
.card-icon ~ .card-subtitle {
  margin-left: 64px;
}

/* Dark - Cards */
html.dark .card-header {
  background: #282d36;
  border-bottom-color: #e6e6ea;
}

html.dark .card-actions a:hover {
  background-color: #242830;
}

html.dark .card-body {
  background: #2e353e;
}

html.dark .card-footer {
  background: #242830;
  border-top-color: #d5d5d8;
}

html .card-primary .card-header {
  background: #ccc;
}

html .card-primary .card-subtitle {
  opacity: 0.8;
  color: #fff;
}

html .card-primary .card-title {
  color: #fff;
}

html .card-primary .card-actions a {
  background-color: transparent !important;
  color: #fff;
}

html .card-success .card-header {
  background: #47a447;
}

html .card-success .card-subtitle {
  opacity: 0.8;
  color: #fff;
}

html .card-success .card-title {
  color: #fff;
}

html .card-success .card-actions a {
  background-color: transparent !important;
  color: #fff;
}

html .card-warning .card-header {
  background: #ed9c28;
}

html .card-warning .card-subtitle {
  opacity: 0.8;
  color: #fff;
}

html .card-warning .card-title {
  color: #fff;
}

html .card-warning .card-actions a {
  background-color: transparent !important;
  color: #fff;
}

html .card-danger .card-header {
  background: #d2322d;
}

html .card-danger .card-subtitle {
  opacity: 0.8;
  color: #fff;
}

html .card-danger .card-title {
  color: #fff;
}

html .card-danger .card-actions a {
  background-color: transparent !important;
  color: #fff;
}

html .card-info .card-header {
  background: #5a5c63;
}

html .card-info .card-subtitle {
  opacity: 0.8;
  color: #fff;
}

html .card-info .card-title {
  color: #fff;
}

html .card-info .card-actions a {
  background-color: transparent !important;
  color: #fff;
}

html .card-dark .card-header {
  background: #5a5c63;
}

html .card-dark .card-subtitle {
  opacity: 0.8;
  color: #fff;
}

html .card-dark .card-title {
  color: #fff;
}

html .card-dark .card-actions a {
  background-color: transparent !important;
  color: #fff;
}

html .card-transparent > .card-header {
  background: none;
  border: 0;
  padding-left: 0;
  padding-right: 0;
}

html .card-transparent > .card-header .card-actions {
  right: 0;
}

html .card-transparent > .card-header + .card-body {
  border-radius: 5px;
}

html .card-transparent > .card-body {
  padding: 0;
  border-radius: 0;
  background: transparent;
  box-shadow: none;
}

html .card .card-header-transparent {
  background: none;
  border: 0;
  padding-left: 0;
  padding-right: 0;
}

html .card .card-header-transparent .card-actions {
  right: 0;
}

html .card .card-header-transparent + .card-body {
  border-radius: 5px;
}

.card-horizontal {
  display: table;
  width: 100%;
}

.card-horizontal .card-header,
.card-horizontal .card-body,
.card-horizontal .card-footer {
  display: table-cell;
  vertical-align: middle;
}

.card-horizontal .card-header {
  border-radius: 5px 0 0 5px !important;
}

.card-horizontal .card-header + .card-body {
  border-radius: 0 5px 5px 0 !important;
}

.card-horizontal .card-footer {
  border-radius: 0 5px 5px 0 !important;
  margin-top: 0;
}

.card.card-modern {
  border-radius: 7px;
  box-shadow: 0px 0px 37px -36px rgba(0, 0, 0, 0.4);
}

.card-no-shadow {
  border-radius: 7px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}

.card.card-modern .card-header {
  background: #fff;
  border-bottom: 0;
  padding: 25px;
}

.card.card-modern .card-header.families-masvendidos-header {
  background: #ecedf0;
  border-bottom: 0;
  padding: 25px;
  border: none;
}

.card.card-modern .card-header .card-title {
  font-size: 18.4px;
  font-size: 1.15rem;
  font-weight: 700;
  line-height: 1.2;
}

.card.card-modern .card-header + .card-body {
  padding-top: 5px;
}

.card.card-modern .card-actions {
  right: 20px;
  top: 23px;
}

.card.card-modern .card-actions a,
.card.card-modern .card-actions .card-action {
  color: #717171;
  font-size: 10px;
}

.card.card-modern .card-body {
  background: #fff;
  padding: 25px;
  box-shadow: none;
}

.card.card-modern .card-body.families-masvendidos-body {
  background: #ecedf0;
  padding: 25px;
  box-shadow: none;
  border: none;
}

.card.card-modern .widget-user-info .widget-user-info-header {
  border-radius: 7px 7px 0 0;
}

.card.card-modern .widget-user-info .widget-user-info-body {
  border-radius: 0 0 7px 7px;
}

.card.card-modern.card-modern-table-over-header
  .datatables-header-footer-wrapper {
  margin-top: -60px;
}

.card.card-modern.card-modern-table-over-header
  .datatables-header-footer-wrapper
  .datatable-header {
  padding-right: 40px;
}

.card.card-modern.card-modern-alt-padding .card-body {
  padding: 20px;
}

@media (max-width: 767px) {
  .card.card-modern .card-actions {
    position: absolute;
  }
}

.card.card-big-info .card-body {
  padding: 0;
  background: #fff;
}

.card.card-big-info .card-body > .row {
  margin-right: 0;
  margin-left: 0;
}

.card.card-big-info .card-body > .row > div[class*="col-"] {
  padding: 40px 32px;
  padding: 2.5rem 2rem;
}

.card.card-big-info .card-body > .row > div[class*="col-"]:first-child {
  background: #f9f9f9;
}

.card.card-big-info
  .card-body
  > .row
  > div[class*="col-"]:first-child
  .card-big-info-title {
  color: #222529;
  font-size: 20.8px;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 0 0 10px;
}

.card.card-big-info
  .card-body
  > .row
  > div[class*="col-"]:first-child
  .card-big-info-icon {
  position: relative;
  left: -6px;
  color: #e7e7e7;
  font-size: 83.2px;
  font-size: 5.2rem;
  margin-bottom: 10px;
}

.card-collapsed .card-body,
.card-collapsed .card-footer {
  display: none;
}

html .card-primary .card-actions a,
html .card-primary .card-title {
  color: #fff;
}

html .card-secondary .card-actions a,
html .card-secondary .card-title {
  color: #fff;
}

html .card-tertiary .card-actions a,
html .card-tertiary .card-title {
  color: #fff;
}

html .card-quaternary .card-actions a,
html .card-quaternary .card-title {
  color: #fff;
}

html .card-success .card-actions a,
html .card-success .card-title {
  color: #fff;
}

html .card-warning .card-actions a,
html .card-warning .card-title {
  color: #fff;
}

html .card-danger .card-actions a,
html .card-danger .card-title {
  color: #fff;
}

html .card-info .card-actions a,
html .card-info .card-title {
  color: #fff;
}

html .card-dark .card-actions a,
html .card-dark .card-title {
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .card-actions {
    float: none;
    margin-bottom: 15px;
    position: static;
    text-align: right;
  }

  .card-actions a {
    vertical-align: top;
  }
}

@media (min-width: 576px) {
  .card-group .card + .card {
    border-left: 1px solid #ddd;
  }
}

/* Owl Carousel */
.owl-carousel {
  margin-bottom: 20px;
}

.owl-carousel .owl-item img {
  transform-style: unset;
}

.owl-carousel .thumbnail {
  margin-right: 1px;
}

.owl-carousel .item-video {
  height: 300px;
}

.owl-carousel .owl-nav {
  top: 50%;
  position: absolute;
  margin-top: -20px;
  width: 100%;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  display: inline-block;
  position: absolute;
  width: 30px;
  height: 30px;
  outline: 0;
}

.owl-carousel .owl-nav button.owl-prev {
  left: -5px;
}

.owl-carousel .owl-nav button.owl-next {
  right: -5px;
}

.owl-carousel button.owl-dot {
  outline: 0;
}

.owl-carousel.stage-margin .owl-stage-outer {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.owl-carousel.stage-margin .owl-stage-outer .owl-stage {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.owl-carousel.show-nav-hover .owl-nav {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.owl-carousel.show-nav-hover .owl-nav button.owl-prev {
  left: -20px;
}

.owl-carousel.show-nav-hover .owl-nav button.owl-next {
  right: -20px;
}

.owl-carousel.show-nav-hover:hover .owl-nav {
  opacity: 1;
}

.owl-carousel.show-nav-title .owl-nav {
  top: 0;
  right: 0;
  margin-top: -45px;
  width: auto;
}

.owl-carousel.show-nav-title .owl-nav button[class*="owl-"],
.owl-carousel.show-nav-title .owl-nav button[class*="owl-"]:hover,
.owl-carousel.show-nav-title .owl-nav button[class*="owl-"]:active {
  font-size: 18px;
  background: transparent !important;
  width: 18px;
  font-size: 18px;
}

.owl-carousel.show-nav-title .owl-nav button.owl-prev {
  left: -45px;
}

.owl-carousel.rounded-nav .owl-nav button[class*="owl-"] {
  padding: 3px 7px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid #999 !important;
  color: #999;
}

.owl-carousel.rounded-nav .owl-nav button[class*="owl-"]:hover,
.owl-carousel.rounded-nav .owl-nav button[class*="owl-"].hover {
  background: transparent !important;
  border: 1px solid #a1a1a1 !important;
  color: #a1a1a1 !important;
}

.owl-carousel.rounded-nav .owl-nav button[class*="owl-"]:active,
.owl-carousel.rounded-nav .owl-nav button[class*="owl-"].active {
  background: transparent !important;
  border: 1px solid #666 !important;
  color: #666 !important;
}

.owl-carousel.nav-bottom .owl-nav {
  position: static;
  margin: 0;
  padding: 0;
  width: auto;
}

.owl-carousel.nav-bottom .owl-nav button.owl-prev,
.owl-carousel.nav-bottom .owl-nav button.owl-next {
  position: static;
}

.owl-carousel.nav-bottom-inside .owl-nav {
  position: relative;
  margin: -68.8px 0 0 0;
  margin: -4.3rem 0 0 0;
  padding: 0;
  width: auto;
}

.owl-carousel.nav-bottom-inside .owl-nav button.owl-prev,
.owl-carousel.nav-bottom-inside .owl-nav button.owl-next {
  position: static;
}

.owl-carousel.nav-inside .owl-nav,
.owl-carousel.nav-inside .owl-dots {
  bottom: 2px;
  margin-top: 10px;
  position: absolute;
  right: 6px;
}

.owl-carousel.nav-style-1 .owl-nav .owl-next,
.owl-carousel.nav-style-1 .owl-nav .owl-prev {
  width: 20px;
  background: transparent !important;
  color: #000;
}

.owl-carousel.nav-style-1 .owl-nav .owl-next:hover,
.owl-carousel.nav-style-1 .owl-nav .owl-next:active,
.owl-carousel.nav-style-1 .owl-nav .owl-prev:hover,
.owl-carousel.nav-style-1 .owl-nav .owl-prev:active {
  color: #ccc;
}

.owl-carousel.nav-style-1.nav-style-1-light .owl-nav .owl-next,
.owl-carousel.nav-style-1.nav-style-1-light .owl-nav .owl-prev {
  color: #fff !important;
}

.owl-carousel.nav-center-outside .owl-nav {
  width: calc(100% + 90px);
  left: 49.9%;
  transform: translate3d(-50%, 0, 0);
}

.owl-carousel.full-width .owl-nav,
.owl-carousel.big-nav .owl-nav {
  margin-top: -30px;
}

.owl-carousel.full-width .owl-nav button[class*="owl-"],
.owl-carousel.full-width .owl-nav button[class*="owl-"]:hover,
.owl-carousel.big-nav .owl-nav button[class*="owl-"],
.owl-carousel.big-nav .owl-nav button[class*="owl-"]:hover {
  height: auto;
  padding: 20px 0 !important;
}

.owl-carousel.full-width .owl-nav button.owl-prev,
.owl-carousel.big-nav .owl-nav button.owl-prev {
  border-radius: 0 4px 4px 0;
}

.owl-carousel.full-width .owl-nav button.owl-next,
.owl-carousel.big-nav .owl-nav button.owl-next {
  border-radius: 4px 0 0 4px;
}

.owl-carousel.top-border {
  border-top: 1px solid #dbdbdb;
  padding-top: 18px;
}

/* Carousel Sync */
.owl-carousel.carousel-sync-style-1 {
  position: absolute;
  top: 50%;
  left: -30px;
  max-width: 355px;
  transform: translate3d(0, -50%, 0);
}

@media (max-width: 575px) {
  .owl-carousel.carousel-sync-style-1 {
    left: 0;
    max-width: none;
  }
}

/* Spaced */
.owl-carousel-spaced {
  margin-left: -5px;
}

.owl-carousel-spaced .owl-item > div {
  margin: 5px;
}

/* Testimonials */
.owl-carousel.testimonials img {
  display: inline-block;
  height: 70px;
  width: 70px;
}

/* Responsive */
@media (max-width: 575px) {
  .owl-carousel-spaced {
    margin-left: 0;
  }
}

/* Carousel Areas */
.carousel-areas {
  /* background: transparent url(../img/patterns/stripes.png) repeat 0 0; */
  margin-bottom: -10px;
  padding: 10px 10px 0 10px;
  border-radius: 6px 6px 0 0;
}

.carousel-areas .owl-carousel {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}

.carousel-areas .owl-carousel .owl-nav button.owl-prev {
  left: -55px;
}

.carousel-areas .owl-carousel .owl-nav button.owl-next {
  right: -55px;
}

.carousel-areas .owl-carousel:first-child img {
  border-radius: 6px 6px 0 0;
}

/* Responsive */
@media (max-width: 991px) {
  .carousel-areas .owl-carousel .owl-nav {
    display: none;
  }
}

/* Charts */
.chart {
  width: 100%;
}

.chart.chart-xs {
  height: 150px;
}

.chart.chart-sm {
  height: 184px;
}

.chart.chart-md {
  height: 350px;
}

.chart.chart-lg {
  height: 500px;
}

#flotTip {
  padding: 4px 8px;
  background-color: #000;
  z-index: 100;
  color: #fff;
  opacity: 0.7;
  font-size: 11px;
  border-radius: 5px;
}

.jqstooltip {
  min-width: 30px;
  min-height: 25px;
  border: 0 !important;
  height: auto !important;
  width: auto !important;
}

.circular-bar {
  margin: 25px 0;
}

.circular-bar .circular-bar-chart {
  position: relative;
}

.circular-bar strong {
  display: block;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  position: absolute;
  top: 35%;
  width: 80%;
  left: 10%;
  text-align: center;
}

.circular-bar label {
  display: block;
  font-weight: 100;
  font-size: 17px;
  line-height: 20px;
  position: absolute;
  top: 50%;
  width: 80%;
  left: 10%;
  text-align: center;
}

.circular-bar.circular-bar-xs {
  width: 50px;
}

.circular-bar.circular-bar-xs strong {
  display: none;
}

.circular-bar.circular-bar-xs label {
  font-size: 11px;
  left: 0;
  text-align: center;
  top: 28%;
  width: 100%;
}

.gauge-chart {
  margin: 25px 0;
}

.gauge-chart strong {
  display: block;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  margin-top: 10px;
}

.gauge-chart label {
  display: block;
  font-weight: 100;
  font-size: 17px;
  line-height: 20px;
  padding-bottom: 5px;
  text-align: center;
}

.small-chart-wrapper {
  display: inline-block;
  margin: 10px 15px;
  width: 100%;
}

.small-chart-wrapper .small-chart {
  display: inline-block;
  float: left;
  margin-bottom: 8px;
  min-width: 80px;
}

.small-chart-wrapper .small-chart-info {
  display: inline-block;
  padding: 0 0 0 10px;
}

.small-chart-wrapper .small-chart-info label {
  display: block;
  font-size: 11px;
  text-transform: uppercase;
  color: #a0a0a0;
  padding: 0;
  margin: 0;
  line-height: 15px;
}

.small-chart-wrapper .small-chart-info strong {
  display: block;
  font-size: 13px;
  padding: 0;
  margin: 0;
  line-height: 15px;
}

.small-chart-wrapper + .small-chart-wrapper {
  margin-left: 25px;
}

@media only screen and (max-width: 767px) {
  .small-chart-wrapper {
    margin-right: 0;
    margin-left: 0;
    text-align: center;
  }

  .small-chart-wrapper .small-chart {
    width: 100%;
    text-align: center;
  }

  .small-chart-wrapper .small-chart-info {
    padding-left: 0;
  }
}

.liquid-meter {
  position: relative;
  max-height: 200px;
  max-width: 200px;
  margin: 0 auto;
  width: 100%;
  padding-bottom: 50%;
}

.liquid-meter meter {
  display: none;
}

.liquid-meter.liquid-meter-loaded {
  width: auto;
  padding-bottom: 0;
}

.liquid-meter.liquid-meter-loaded:before {
  display: none;
}

.liquid-meter-wrapper.liquid-meter-xs .liquid-meter {
  max-height: 120px;
  max-width: 120px;
}

.liquid-meter-wrapper.liquid-meter-sm .liquid-meter {
  max-height: 150px;
  max-width: 150px;
}

.liquid-meter-wrapper.liquid-meter-md .liquid-meter {
  max-height: 200px;
  max-width: 200px;
}

.liquid-meter-wrapper.liquid-meter-lg .liquid-meter {
  max-height: 230px;
  max-width: 230px;
}

.liquid-meter-wrapper .liquid-meter-selector {
  margin-top: 3px;
}

.liquid-meter-wrapper .liquid-meter-selector a {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 11px;
  color: #9b9b9b;
  border-right: 1px solid #e4e4e4;
  display: inline-block;
  padding-right: 8px;
  margin-right: 8px;
}

.liquid-meter-wrapper .liquid-meter-selector a.active {
  color: #ccc;
}

.liquid-meter-wrapper .liquid-meter-selector a:last-child {
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
}

.chart-bar-stacked-sm rect {
  width: 10px;
  transform: translateX(25px);
}

.chart-data-selector {
  visibility: hidden;
  max-height: 255px;
  padding: 6px;
}

.chart-data-selector h2 {
  color: #33353f;
  font-size: 20px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  text-transform: none;
  letter-spacing: -1px;
  font-weight: 600;
}

.chart-data-selector h2 .multiselect {
  background: #f6f6f6;
  border: 0 none;
  font-size: 18px;
  font-weight: 600;
  margin: -4px 0 0 4px !important;
  padding: 3px 12px 3px 8px;
  letter-spacing: -1px;
}

.chart-data-selector h2 .multiselect:hover,
.chart-data-selector h2 .multiselect:focus,
.chart-data-selector h2 .multiselect:active {
  text-decoration: none;
}

.chart-data-selector h2 .multiselect-container {
  margin: 0 0 0 4px;
  min-width: 120%;
}

.chart-data-selector .chart-data-selector-items {
  position: relative;
  overflow: hidden;
}

.chart-data-selector .chart-data-selector-items .chart-active {
  visibility: hidden;
}

.chart-data-selector .chart-data-selector-items .chart-hidden {
  visibility: hidden;
}

.chart-data-selector.ready {
  max-height: none;
  visibility: visible;
}

.chart-data-selector.ready .chart-active {
  visibility: visible;
  position: relative;
  z-index: 2;
}

.chart-data-selector.ready .chart-hidden {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  top: -9999px;
}

.ct-chart .ct-label,
.ct-chart .ct-label.ct-horizontal,
.ct-chart .ct-label.ct-vertical {
  font-size: 12px;
  font-size: 0.75rem;
}

.ct-chart .ct-series.ct-series-a .ct-bar,
.ct-chart .ct-series.ct-series-a .ct-line,
.ct-chart .ct-series.ct-series-a .ct-point,
.ct-chart .ct-series.ct-series-a .ct-slice.ct-donut {
  stroke: #ccc;
}

.ct-chart .ct-series.ct-series-a .ct-area,
.ct-chart .ct-series.ct-series-a .ct-slice:not(.ct-donut) {
  fill: #ccc;
}

.ct-chart .ct-series.ct-series-b .ct-bar,
.ct-chart .ct-series.ct-series-b .ct-line,
.ct-chart .ct-series.ct-series-b .ct-point,
.ct-chart .ct-series.ct-series-b .ct-slice.ct-donut {
  stroke: #a6a6a6;
}

.ct-chart .ct-series.ct-series-b .ct-area,
.ct-chart .ct-series.ct-series-b .ct-slice:not(.ct-donut) {
  fill: #a6a6a6;
}

.ct-chart .ct-series.ct-series-c .ct-bar,
.ct-chart .ct-series.ct-series-c .ct-line,
.ct-chart .ct-series.ct-series-c .ct-point,
.ct-chart .ct-series.ct-series-c .ct-slice.ct-donut {
  stroke: #e36159;
}

.ct-chart .ct-series.ct-series-c .ct-area,
.ct-chart .ct-series.ct-series-c .ct-slice:not(.ct-donut) {
  fill: #e36159;
}

.ct-chart .ct-series.ct-series-d .ct-bar,
.ct-chart .ct-series.ct-series-d .ct-line,
.ct-chart .ct-series.ct-series-d .ct-point,
.ct-chart .ct-series.ct-series-d .ct-slice.ct-donut {
  stroke: #cd2c23;
}

.ct-chart .ct-series.ct-series-d .ct-area,
.ct-chart .ct-series.ct-series-d .ct-slice:not(.ct-donut) {
  fill: #cd2c23;
}

.ct-chart .ct-series.ct-series-e .ct-bar,
.ct-chart .ct-series.ct-series-e .ct-line,
.ct-chart .ct-series.ct-series-e .ct-point,
.ct-chart .ct-series.ct-series-e .ct-slice.ct-donut {
  stroke: #2baab1;
}

.ct-chart .ct-series.ct-series-e .ct-area,
.ct-chart .ct-series.ct-series-e .ct-slice:not(.ct-donut) {
  fill: #2baab1;
}

.ct-chart .ct-series.ct-series-f .ct-bar,
.ct-chart .ct-series.ct-series-f .ct-line,
.ct-chart .ct-series.ct-series-f .ct-point,
.ct-chart .ct-series.ct-series-f .ct-slice.ct-donut {
  stroke: #1c6f73;
}

.ct-chart .ct-series.ct-series-f .ct-area,
.ct-chart .ct-series.ct-series-f .ct-slice:not(.ct-donut) {
  fill: #1c6f73;
}

.ct-chart .ct-series.ct-series-g .ct-bar,
.ct-chart .ct-series.ct-series-g .ct-line,
.ct-chart .ct-series.ct-series-g .ct-point,
.ct-chart .ct-series.ct-series-g .ct-slice.ct-donut {
  stroke: #734ba9;
}

.ct-chart .ct-series.ct-series-g .ct-area,
.ct-chart .ct-series.ct-series-g .ct-slice:not(.ct-donut) {
  fill: #734ba9;
}

.ct-chart .ct-series.ct-series-h .ct-bar,
.ct-chart .ct-series.ct-series-h .ct-line,
.ct-chart .ct-series.ct-series-h .ct-point,
.ct-chart .ct-series.ct-series-h .ct-slice.ct-donut {
  stroke: #4f3374;
}

.ct-chart .ct-series.ct-series-h .ct-area,
.ct-chart .ct-series.ct-series-h .ct-slice:not(.ct-donut) {
  fill: #4f3374;
}

.ct-chart .ct-series.ct-series-i .ct-bar,
.ct-chart .ct-series.ct-series-i .ct-line,
.ct-chart .ct-series.ct-series-i .ct-point,
.ct-chart .ct-series.ct-series-i .ct-slice.ct-donut {
  stroke: #a6a6a6;
}

.ct-chart .ct-series.ct-series-i .ct-area,
.ct-chart .ct-series.ct-series-i .ct-slice:not(.ct-donut) {
  fill: #a6a6a6;
}

.ct-chart .ct-series.ct-series-j .ct-bar,
.ct-chart .ct-series.ct-series-j .ct-line,
.ct-chart .ct-series.ct-series-j .ct-point,
.ct-chart .ct-series.ct-series-j .ct-slice.ct-donut {
  stroke: #f2f2f2;
}

.ct-chart .ct-series.ct-series-j .ct-area,
.ct-chart .ct-series.ct-series-j .ct-slice:not(.ct-donut) {
  fill: #f2f2f2;
}

.ct-chart .ct-series.ct-series-k .ct-bar,
.ct-chart .ct-series.ct-series-k .ct-line,
.ct-chart .ct-series.ct-series-k .ct-point,
.ct-chart .ct-series.ct-series-k .ct-slice.ct-donut {
  stroke: #ee9f9a;
}

.ct-chart .ct-series.ct-series-k .ct-area,
.ct-chart .ct-series.ct-series-k .ct-slice:not(.ct-donut) {
  fill: #ee9f9a;
}

.ct-chart .ct-series.ct-series-l .ct-bar,
.ct-chart .ct-series.ct-series-l .ct-line,
.ct-chart .ct-series.ct-series-l .ct-point,
.ct-chart .ct-series.ct-series-l .ct-slice.ct-donut {
  stroke: #53ced5;
}

.ct-chart .ct-series.ct-series-l .ct-area,
.ct-chart .ct-series.ct-series-l .ct-slice:not(.ct-donut) {
  fill: #53ced5;
}

.ct-chart .ct-series.ct-series-m .ct-bar,
.ct-chart .ct-series.ct-series-m .ct-line,
.ct-chart .ct-series.ct-series-m .ct-point,
.ct-chart .ct-series.ct-series-m .ct-slice.ct-donut {
  stroke: #9b7cc5;
}

.ct-chart .ct-series.ct-series-m .ct-area,
.ct-chart .ct-series.ct-series-m .ct-slice:not(.ct-donut) {
  fill: #9b7cc5;
}

.ct-chart .ct-series.ct-series-n .ct-bar,
.ct-chart .ct-series.ct-series-n .ct-line,
.ct-chart .ct-series.ct-series-n .ct-point,
.ct-chart .ct-series.ct-series-n .ct-slice.ct-donut {
  stroke: #737373;
}

.ct-chart .ct-series.ct-series-n .ct-area,
.ct-chart .ct-series.ct-series-n .ct-slice:not(.ct-donut) {
  fill: #737373;
}

.ct-chart .ct-series.ct-series-o .ct-bar,
.ct-chart .ct-series.ct-series-o .ct-line,
.ct-chart .ct-series.ct-series-o .ct-point,
.ct-chart .ct-series.ct-series-o .ct-slice.ct-donut {
  stroke: white;
}

.ct-chart .ct-series.ct-series-o .ct-area,
.ct-chart .ct-series.ct-series-o .ct-slice:not(.ct-donut) {
  fill: white;
}

.ct-chart .tooltip {
  opacity: 1;
  position: absolute;
  display: inline-block;
  min-width: 115px;
  padding: 8px;
  background: #ccc;
  color: #fff;
  font-weight: 500;
  text-align: center;
  pointer-events: none;
  z-index: 1;
}

.ct-chart .tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -15px;
  border: 15px solid transparent;
  border-top-color: #ccc;
}

html.dark .chart-data-selector h2 .multiselect {
  background-color: #282d36;
}

/* CodeMirror */
html.dark .CodeMirror pre {
  background: transparent;
  border: none;
}

/* Colorpicker */
.colorpicker {
  width: 100%;
  min-width: 115px !important;
}

.colorpicker.colorpicker-with-alpha {
  width: auto;
}

.colorpicker.colorpicker-horizontal {
  width: auto !important;
  min-width: 0 !important;
}

.colorpicker.colorpicker-horizontal .colorpicker-bar {
  width: auto;
}

/* Dark */
html.dark .colorpicker {
  background-color: #282d36;
}

html.dark .colorpicker:before {
  border-bottom-color: #e6e6ea;
}

html.dark .colorpicker:after {
  border-bottom-color: #282d36;
}

/* Data Tables */
.dataTables_wrapper {
  position: relative;
  padding: 0;
  margin: 0;
}

.dataTables_wrapper .DTTT_container {
  margin-bottom: 5px;
}

.dataTables_wrapper .DTTT_container .btn-group {
  display: inline-block !important;
}

.dataTables_wrapper .DTTT_container .btn {
  margin-left: 5px;
}

.dataTables_wrapper .datatables-header {
  margin-bottom: 15px;
}

.dataTables_wrapper .datatables-header label {
  font-weight: normal;
  margin: 0;
}

.dataTables_wrapper table thead th {
  padding-right: 21px !important;
}

.dataTables_wrapper table.dataTable {
  border-collapse: collapse !important;
  margin: 0 !important;
  width: 100% !important;
}

.dataTables_wrapper div.dataTables_info {
  font-size: 11.2px;
  font-size: 0.7rem;
}

.dataTables_wrapper .pagination {
  margin-top: 20px !important;
}

.datatables-header-footer-wrapper .pagination {
  margin-bottom: 0;
}

.dataTables_wrapper .select2-container {
  display: inline-block;
  margin-right: 10px;
  width: 75px;
}

@media only screen and (max-width: 991px) {
  .dataTables_wrapper .dataTables_length {
    margin-bottom: 15px;
  }

  .dataTables_wrapper .dataTables_length label {
    float: none;
    width: 100%;
  }
}

/* Filter */
.dataTables_wrapper .dataTables_filter label {
  width: 50%;
}

.dataTables_wrapper .dataTables_filter input {
  width: 100% !important;
}

@media only screen and (max-width: 991px) {
  .dataTables_wrapper .dataTables_filter label {
    width: 100% !important;
  }
}

/* Empty Row */
.dataTables_wrapper .dataTables_empty {
  padding: 50px 0;
  text-align: center;
}

.dataTables_processing {
  background: #ccc;
  border-radius: 100px;
  box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.3);
  color: #fff;
  left: 50%;
  margin-left: -36px;
  padding: 5px 10px;
  position: absolute;
  top: 3px;
}

@media only screen and (max-width: 991px) {
  .dataTables_processing {
    left: auto;
    margin-left: 0;
    right: 0;
  }
}

.DTTT_Print,
.DTTT_Print .inner-wrapper,
.DTTT_Print .content-body,
.DTTT_Print .card {
  background: #fff !important;
  margin: 0 !important;
  padding: 0 !important;
  top: 0 !important;
}

.DTTT_Print .dataTables_wrapper .DTTT.btn-group {
  display: none !important;
}

.DTTT_Print .DTTT_print_info {
  background: rgba(255, 255, 255, 0.9);
  display: block;
  left: 0;
  height: 100px;
  line-height: 100px;
  position: fixed;
  font-size: 14px;
  text-align: center;
  top: 0;
  width: 100%;
}

html.dark div.DTTT_container .btn {
  color: #eee !important;
}

/* Datepicker - Base */
.datepicker {
  padding: 10px;
  margin: 0 auto;
  line-height: 1.1em;
}

.datepicker.datepicker-inline {
  line-height: 1.7em;
  width: 100%;
}

.datepicker table {
  width: 100%;
}

.datepicker table tr td {
  border-radius: 0;
}

.datepicker table thead tr th {
  cursor: pointer;
  font-size: 12.8px;
  font-size: 0.8rem;
  text-align: center;
  font-weight: normal;
}

.datepicker table thead tr th:hover {
  color: #fff;
}

.datepicker table thead tr:last-child th {
  font-weight: 600;
}

.datepicker table thead tr:last-child th:hover {
  color: #ccc;
}

.datepicker table td {
  text-align: center;
  font-size: 12px;
  font-size: 0.75rem;
}

.datepicker table td.day {
  transition:
    background-color 0.1s ease-in 0.1s,
    color 0.1s ease-in 0.1s;
  cursor: pointer;
}

/* Datepicker - Skin Default */
.datepicker thead tr:first-child th:hover,
.datepicker tfoot tr th:hover,
.datepicker table tr td span:hover {
  background: #ccc;
  color: #fff;
}

.datepicker table tbody tr td span.old,
.datepicker table tbody tr td span.new {
  color: #ccc;
}

.datepicker table tbody tr td span.old:hover,
.datepicker table tbody tr td span.new:hover {
  color: #fff;
}

.datepicker table tbody tr td.day:hover {
  background: #ccc;
  color: #fff;
}

.datepicker table tbody tr td.day.active {
  background: #b3b3b3;
  color: #fff;
}

.datepicker table tbody tr td.day.new {
  color: #777;
}

.datepicker table tbody tr td.day.new:hover {
  color: #fff;
}

/* Datepicker - Skin Dark */
html:not(.sidebar-light) .datepicker.datepicker-dark {
  background: transparent;
}

html:not(.sidebar-light)
  .datepicker.datepicker-dark
  table
  thead
  tr
  th.datepicker-switch {
  color: #fff;
}

html:not(.sidebar-light) .datepicker.datepicker-dark table thead tr th.dow {
  color: #777;
}

html:not(.sidebar-light) .datepicker.datepicker-dark table tbody tr td span.old,
html:not(.sidebar-light)
  .datepicker.datepicker-dark
  table
  tbody
  tr
  td
  span.new {
  color: #444;
}

html:not(.sidebar-light)
  .datepicker.datepicker-dark
  table
  tbody
  tr
  td
  span.old:hover,
html:not(.sidebar-light)
  .datepicker.datepicker-dark
  table
  tbody
  tr
  td
  span.new:hover {
  color: #fff;
}

html:not(.sidebar-light) .datepicker.datepicker-dark table tbody tr td.day {
  color: #fff;
}

html:not(.sidebar-light)
  .datepicker.datepicker-dark
  table
  tbody
  tr
  td.day:hover {
  background: #ccc;
  color: #fff;
}

html:not(.sidebar-light)
  .datepicker.datepicker-dark
  table
  tbody
  tr
  td.day.active {
  background: #b3b3b3;
  color: #fff;
}

html:not(.sidebar-light) .datepicker.datepicker-dark table tbody tr td.day.new {
  color: #777;
}

html:not(.sidebar-light)
  .datepicker.datepicker-dark
  table
  tbody
  tr
  td.day.new:hover {
  color: #fff;
}

/* Datepicker - Skin Primary */
.datepicker.datepicker-primary {
  min-width: 255px;
}

.datepicker.datepicker-primary.datepicker-inline {
  background: #fff;
  border: 1px solid #eee;
}

.datepicker.datepicker-primary table thead tr:first-child {
  background-color: #ccc;
  color: #fff;
}

.datepicker.datepicker-primary table thead tr:first-child th:hover {
  background-color: #b3b3b3;
}

.datepicker.datepicker-primary table thead tr:first-child th:first-child {
  border-radius: 4px 0 0 0;
}

.datepicker.datepicker-primary table thead tr:first-child th:last-child {
  border-radius: 0 4px 0 0;
}

.datepicker.datepicker-primary table thead tr:last-child {
  background-color: #d9d9d9;
  color: #fff;
}

.datepicker.datepicker-primary table thead tr:last-child th:hover {
  background-color: #ccc;
}

.datepicker.datepicker-primary table thead tr th {
  border-radius: 0;
}

html.dark .input-daterange .input-group-addon {
  text-shadow: none;
}

html.dark .datepicker-dropdown {
  color: #eee;
  background-color: #282d36;
}

html.dark .datepicker-dropdown:after {
  border-bottom-color: #282d36;
}

html.dark .datepicker-dropdown.datepicker-orient-bottom:before {
  border-top-color: rgba(0, 0, 0, 0.2);
}

html.dark .datepicker-dropdown.datepicker-orient-bottom:after {
  border-top-color: #282d36;
}

html.dark .datepicker.datepicker-primary {
  border-color: #282d36;
  background: #282d36;
}

/* Dividers */
hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0)
  );
  margin: 22px 0 22px 0;
}

hr.short {
  margin: 11px 0 11px 0;
}

hr.tall {
  margin: 44px 0 44px 0;
}

hr.taller {
  margin: 66px 0 66px 0;
}

hr.light {
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0)
  );
}

hr.dotted {
  height: 0;
  border-bottom: 1px dotted #ddd;
}

hr.solid {
  height: 0;
  border-bottom: 1px solid #ddd;
}

/* Dropdown Menu */
.dropdown-menu .dropdown-item {
  font-size: 14.4px;
  font-size: 0.9rem;
}

/* Dark */
html.dark .dropdown-menu .dropdown-item {
  color: #eee;
}

html.dark .dropdown-menu .dropdown-item:hover,
html.dark .dropdown-menu .dropdown-item:focus,
html.dark .dropdown-menu .dropdown-item:active {
  background-color: #5a5c63;
}

html.dark .dropdown-menu .dropdown-divider {
  opacity: 0.2;
}

/* Dropzone */
.dropzone {
  background: rgba(0, 0, 0, 0.03) none repeat scroll 0 0;
  border: 1px solid rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  min-height: 122px;
  padding: 23px;
}

.dropzone .dz-default span {
  transition: color 0.1s ease-in;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.2);
}

.dropzone:hover .dz-default span {
  color: rgba(0, 0, 0, 0.3);
}

.dropzone-modern {
  opacity: 0;
  visibility: hidden;
  background: transparent;
  border: 2px dashed #e7e7e7;
  min-height: 200px;
  transition: ease opacity 300ms;
}

.dropzone-modern .dropzone-upload-message {
  position: absolute;
  top: 50%;
  left: 50%;
  pointer-events: none;
  transform: translate3d(-50%, -50%, 0);
}

.dropzone-modern .dropzone-upload-message > i {
  display: block;
  text-align: center;
  font-size: 48px;
  font-size: 3rem;
  color: #e7e7e7;
}

.dropzone-modern.initialized {
  opacity: 1;
  visibility: visible;
}

.dropzone-modern.dz-started .dropzone-upload-message {
  display: none;
}

.dropzone-modern:hover .dropzone-upload-message > i {
  color: #ccc;
}

html.dark .dropzone .dz-default span {
  color: rgba(255, 255, 255, 0.2);
}

html.dark .dropzone:hover .dz-default span {
  color: rgba(255, 255, 255, 0.3);
}

/* eCommerce */
.product-price {
  display: flex;
  align-items: center;
}

.product-price .regular-price,
.product-price .sale-price {
  font-size: 17.6px;
  font-size: 1.1rem;
  color: #444444;
  font-weight: 600;
  line-height: 1.4;
}

.product-price .regular-price.on-sale {
  color: #999;
  font-size: 14.4px;
  font-size: 0.9rem;
  text-decoration: line-through;
}

.product-price .regular-price + .sale-price {
  margin-left: 7px;
}

/* eCommerce - Products Form Sidebar Overlay */
.ecommerce-form-sidebar-overlay-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  visibility: hidden;
  z-index: 1041;
  transition: ease opacity 300ms;
}

.ecommerce-form-sidebar-overlay-wrapper .ecommerce-form-sidebar-overlay-body {
  position: absolute;
  right: 0;
  top: 0;
  width: 63%;
  height: 100%;
  padding: 40px 24px 40px 40px;
  padding: 2.5rem 1.5rem 2.5rem 2.5rem;
  background: #f5f5f8;
  transform: translate3d(100%, 0, 0);
  transition: ease transform 0ms;
}

.ecommerce-form-sidebar-overlay-wrapper
  .ecommerce-form-sidebar-overlay-body.scrollable
  .scrollable-pane {
  top: 40px;
  top: 2.5rem;
  bottom: 40px;
  bottom: 2.5rem;
  right: 35px;
}

.ecommerce-form-sidebar-overlay-wrapper
  .ecommerce-form-sidebar-overlay-body
  .ecommerce-form-sidebar-overlay-close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  color: #fff;
  font-size: 33.6px;
  font-size: 2.1rem;
  transform: translate3d(-100%, 0, 0);
}

.ecommerce-form-sidebar-overlay-wrapper
  .ecommerce-form-sidebar-overlay-body
  .ecommerce-form-sidebar-overlay-content {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.ecommerce-form-sidebar-overlay-wrapper
  .ecommerce-form-sidebar-overlay-body
  .ecommerce-form-sidebar-overlay-content.scrollable-content {
  width: 100%;
  padding-right: 0;
}

.ecommerce-form-sidebar-overlay-wrapper.show {
  opacity: 1;
  visibility: visible;
}

.ecommerce-form-sidebar-overlay-wrapper.show
  .ecommerce-form-sidebar-overlay-body {
  transition-duration: 300ms;
  transform: translate3d(0, 0, 0);
}

.ecommerce-form-sidebar-overlay-wrapper .action-buttons {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: #f5f5f8;
  padding: 20px 10px;
  z-index: 10;
}

@media (min-width: 768px) {
  .ecommerce-form-sidebar-overlay-wrapper .action-buttons {
    width: 100%;
    left: auto;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin: 0;
  }
}

.ecommerce-form-sidebar-overlay-wrapper .ecommerce-form {
  padding-bottom: 48px;
  padding-bottom: 3rem;
}

.ecommerce-form-sidebar-overlay-wrapper .ecommerce-form > .row + .row {
  margin-top: 35px;
}

@media (max-width: 767px) {
  .ecommerce-form-sidebar-overlay-wrapper .ecommerce-form {
    padding-bottom: 170px;
  }
}

.ecommerce-form-sidebar-overlay-wrapper .loading-overlay {
  z-index: 1;
}

.ecommerce-form-sidebar-overlay-wrapper .dropzone .dz-preview .dz-image {
  z-index: 0;
}

@media (max-width: 1199px) {
  .ecommerce-form-sidebar-overlay-wrapper .ecommerce-form-sidebar-overlay-body {
    width: 83%;
  }
}

@media (max-width: 767px) {
  .ecommerce-form-sidebar-overlay-wrapper .ecommerce-form-sidebar-overlay-body {
    width: 100%;
  }

  .ecommerce-form-sidebar-overlay-wrapper
    .ecommerce-form-sidebar-overlay-body
    .ecommerce-form-sidebar-overlay-close {
    color: #444;
    font-size: 1.5rem;
    transform: none;
  }
}

/* Action Buttons */
@media (max-width: 991px) {
  .action-buttons .btn {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
}

/* Action Buttons Fixed */
@media (min-height: 800px) and (min-width: 992px) {
  .action-buttons-fixed {
    padding-bottom: 90px;
  }

  .action-buttons-fixed .action-buttons {
    position: fixed;
    bottom: 0;
    right: 0;
    width: calc(100% - 300px);
    background: #f5f5f8;
    padding: 40px 25px;
    margin: 0;
    z-index: 3;
  }

  html.sidebar-left-collapsed .action-buttons-fixed .action-buttons {
    width: calc(100% - 75px);
  }
}

/* Order Status */
.ecommerce-status {
  display: flex;
  align-items: center;
}

.ecommerce-status:before {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: #ccc;
  margin-right: 8px;
  margin-right: 0.5rem;
}

.ecommerce-status.on-hold:before {
  background-color: #f8dda7;
}

.ecommerce-status.pending:before {
  background-color: #b2b9ff;
}

.ecommerce-status.processing:before,
.ecommerce-status.active:before {
  background-color: #c6e1c6;
}

.ecommerce-status.completed:before {
  background-color: #5fbe5f;
}

.ecommerce-status.cancelled:before,
.ecommerce-status.no-active:before {
  background-color: #d76969;
}

.ecommerce-status.refunded:before {
  background-color: #7ac0ca;
}

.ecommerce-status.failed:before {
  background-color: #ce4545;
}

/* eCommerce Timeline */
.ecommerce-timeline {
  position: relative;
  overflow: hidden;
}

.ecommerce-timeline:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0px;
  box-shadow: 0px 0px 13px 15px #fff;
  z-index: 1;
}

.ecommerce-timeline:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0px;
  box-shadow: 0px 0px 13px 15px #fff;
  z-index: 1;
}

.ecommerce-timeline .ecommerce-timeline-items-wrapper {
  position: relative;
  padding: 32px 0 32px 36.8px;
  padding: 2rem 0 2rem 2.3rem;
}

.ecommerce-timeline .ecommerce-timeline-items-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 10px;
  bottom: 0;
  width: 4px;
  background: #e7e8e8;
}

.ecommerce-timeline .ecommerce-timeline-items-wrapper .ecommerce-timeline-item {
  position: relative;
  background: #f9f9f9;
  padding: 16px;
  padding: 1rem;
  border-radius: 4px;
}

.ecommerce-timeline
  .ecommerce-timeline-items-wrapper
  .ecommerce-timeline-item:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #f9f9f9;
  transform: translate3d(0, -50%, 0);
}

.ecommerce-timeline
  .ecommerce-timeline-items-wrapper
  .ecommerce-timeline-item:after {
  content: "";
  position: absolute;
  top: 50%;
  left: -32px;
  left: -2rem;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #ccc;
  border: 3px solid #fff;
  box-shadow: 0px 0px 0px 2px #ccc;
  transform: translate3d(0, -50%, 0);
  box-sizing: content-box;
}

.ecommerce-timeline
  .ecommerce-timeline-items-wrapper
  .ecommerce-timeline-item
  + .ecommerce-timeline-item {
  margin-top: 30px;
}

.ecommerce-timeline
  .ecommerce-timeline-items-wrapper
  .ecommerce-timeline-item
  p {
  margin-bottom: 0;
}

.ecommerce-timeline
  .ecommerce-timeline-items-wrapper
  .ecommerce-timeline-item
  p
  + p {
  margin-top: 20px;
}

/* Errors */
.body-error {
  margin: 0 auto;
  max-width: 900px;
  width: 100%;
}

.body-error.error-outside {
  display: table;
  height: 100vh;
}

.body-error.error-outside .center-error {
  display: table-cell;
  vertical-align: middle;
}

.body-error.error-inside {
  margin-top: 150px;
}

/* Error Pages - header */
.body-error .error-header {
  border-bottom: 1px solid #dadada;
  margin-bottom: 50px;
  padding-bottom: 15px;
}

.body-error .error-header .form {
  margin-top: 12px;
}

/* Error Pages - typo */
.body-error .error-code {
  font-size: 160px;
  font-size: 10rem;
  line-height: 160px;
  line-height: 10rem;
  letter-spacing: -10px;
}

.body-error .error-explanation {
  font-size: 19.2px;
  font-size: 1.2rem;
  line-height: 57.6px;
  line-height: 3.6rem;
  margin-top: 25px;
}

/* Error Pages - Responsive */
@media only screen and (max-width: 1150px) {
  .body-error.error-inside {
    margin-top: 50px;
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1150px) {
  .body-error.error-inside .error-code {
    font-size: 10rem;
    line-height: 10rem;
    letter-spacing: -7px;
  }

  .body-error.error-inside .error-explanation {
    font-size: 1rem;
    line-height: 3.2rem;
  }
}

@media only screen and (max-width: 767px) {
  .body-error .error-code {
    font-size: 9rem;
    line-height: 9rem;
    letter-spacing: -7px;
  }

  .body-error .error-explanation {
    font-size: 0.9rem;
    line-height: 2.8rem;
  }

  .body-error.error-outside {
    height: auto;
    padding: 20px;
  }
}

/* File Upload */
.fileupload .uneditable-input .fa,
.fileupload .uneditable-input .fab,
.fileupload .uneditable-input .fal,
.fileupload .uneditable-input .far,
.fileupload .uneditable-input .fas {
  position: absolute;
  top: 12px;
  left: 26px;
}

.fileupload .uneditable-input .fileupload-preview {
  display: inline-block;
  float: left;
  overflow: hidden;
  padding: 0 0 0 17px;
  text-overflow: ellipsis;
  width: 100%;
}

.fileupload .btn {
  border-radius: 0;
}

@media only screen and (max-width: 479px) {
  .fileupload .uneditable-input {
    width: 170px;
  }
}

.flag {
  width: 16px;
  height: 11px;
  /* background: url(../img/flags.png) no-repeat; */
}

.flag.flag-ad {
  background-position: -16px 0;
}

.flag.flag-ae {
  background-position: -32px 0;
}

.flag.flag-af {
  background-position: -48px 0;
}

.flag.flag-ag {
  background-position: -64px 0;
}

.flag.flag-ai {
  background-position: -80px 0;
}

.flag.flag-al {
  background-position: -96px 0;
}

.flag.flag-am {
  background-position: -112px 0;
}

.flag.flag-an {
  background-position: -128px 0;
}

.flag.flag-ao {
  background-position: -144px 0;
}

.flag.flag-ar {
  background-position: -160px 0;
}

.flag.flag-as {
  background-position: -176px 0;
}

.flag.flag-at {
  background-position: -192px 0;
}

.flag.flag-au {
  background-position: -208px 0;
}

.flag.flag-aw {
  background-position: -224px 0;
}

.flag.flag-az {
  background-position: -240px 0;
}

.flag.flag-ba {
  background-position: 0 -11px;
}

.flag.flag-bb {
  background-position: -16px -11px;
}

.flag.flag-bd {
  background-position: -32px -11px;
}

.flag.flag-be {
  background-position: -48px -11px;
}

.flag.flag-bf {
  background-position: -64px -11px;
}

.flag.flag-bg {
  background-position: -80px -11px;
}

.flag.flag-bh {
  background-position: -96px -11px;
}

.flag.flag-bi {
  background-position: -112px -11px;
}

.flag.flag-bj {
  background-position: -128px -11px;
}

.flag.flag-bm {
  background-position: -144px -11px;
}

.flag.flag-bn {
  background-position: -160px -11px;
}

.flag.flag-bo {
  background-position: -176px -11px;
}

.flag.flag-br {
  background-position: -192px -11px;
}

.flag.flag-bs {
  background-position: -208px -11px;
}

.flag.flag-bt {
  background-position: -224px -11px;
}

.flag.flag-bv {
  background-position: -240px -11px;
}

.flag.flag-bw {
  background-position: 0 -22px;
}

.flag.flag-by {
  background-position: -16px -22px;
}

.flag.flag-bz {
  background-position: -32px -22px;
}

.flag.flag-ca {
  background-position: -48px -22px;
}

.flag.flag-catalonia {
  background-position: -64px -22px;
}

.flag.flag-cd {
  background-position: -80px -22px;
}

.flag.flag-cf {
  background-position: -96px -22px;
}

.flag.flag-cg {
  background-position: -112px -22px;
}

.flag.flag-ch {
  background-position: -128px -22px;
}

.flag.flag-ci {
  background-position: -144px -22px;
}

.flag.flag-ck {
  background-position: -160px -22px;
}

.flag.flag-cl {
  background-position: -176px -22px;
}

.flag.flag-cm {
  background-position: -192px -22px;
}

.flag.flag-cn {
  background-position: -208px -22px;
}

.flag.flag-co {
  background-position: -224px -22px;
}

.flag.flag-cr {
  background-position: -240px -22px;
}

.flag.flag-cu {
  background-position: 0 -33px;
}

.flag.flag-cv {
  background-position: -16px -33px;
}

.flag.flag-cw {
  background-position: -32px -33px;
}

.flag.flag-cy {
  background-position: -48px -33px;
}

.flag.flag-cz {
  background-position: -64px -33px;
}

.flag.flag-de {
  background-position: -80px -33px;
}

.flag.flag-dj {
  background-position: -96px -33px;
}

.flag.flag-dk {
  background-position: -112px -33px;
}

.flag.flag-dm {
  background-position: -128px -33px;
}

.flag.flag-do {
  background-position: -144px -33px;
}

.flag.flag-dz {
  background-position: -160px -33px;
}

.flag.flag-ec {
  background-position: -176px -33px;
}

.flag.flag-ee {
  background-position: -192px -33px;
}

.flag.flag-eg {
  background-position: -208px -33px;
}

.flag.flag-eh {
  background-position: -224px -33px;
}

.flag.flag-england {
  background-position: -240px -33px;
}

.flag.flag-er {
  background-position: 0 -44px;
}

.flag.flag-es {
  background-position: -16px -44px;
}

.flag.flag-et {
  background-position: -32px -44px;
}

.flag.flag-eu {
  background-position: -48px -44px;
}

.flag.flag-fi {
  background-position: -64px -44px;
}

.flag.flag-fj {
  background-position: -80px -44px;
}

.flag.flag-fk {
  background-position: -96px -44px;
}

.flag.flag-fm {
  background-position: -112px -44px;
}

.flag.flag-fo {
  background-position: -128px -44px;
}

.flag.flag-fr {
  background-position: -144px -44px;
}

.flag.flag-ga {
  background-position: -160px -44px;
}

.flag.flag-gb {
  background-position: -176px -44px;
}

.flag.flag-gd {
  background-position: -192px -44px;
}

.flag.flag-ge {
  background-position: -208px -44px;
}

.flag.flag-gf {
  background-position: -224px -44px;
}

.flag.flag-gg {
  background-position: -240px -44px;
}

.flag.flag-gh {
  background-position: 0 -55px;
}

.flag.flag-gi {
  background-position: -16px -55px;
}

.flag.flag-gl {
  background-position: -32px -55px;
}

.flag.flag-gm {
  background-position: -48px -55px;
}

.flag.flag-gn {
  background-position: -64px -55px;
}

.flag.flag-gp {
  background-position: -80px -55px;
}

.flag.flag-gq {
  background-position: -96px -55px;
}

.flag.flag-gr {
  background-position: -112px -55px;
}

.flag.flag-gs {
  background-position: -128px -55px;
}

.flag.flag-gt {
  background-position: -144px -55px;
}

.flag.flag-gu {
  background-position: -160px -55px;
}

.flag.flag-gw {
  background-position: -176px -55px;
}

.flag.flag-gy {
  background-position: -192px -55px;
}

.flag.flag-hk {
  background-position: -208px -55px;
}

.flag.flag-hm {
  background-position: -224px -55px;
}

.flag.flag-hn {
  background-position: -240px -55px;
}

.flag.flag-hr {
  background-position: 0 -66px;
}

.flag.flag-ht {
  background-position: -16px -66px;
}

.flag.flag-hu {
  background-position: -32px -66px;
}

.flag.flag-ic {
  background-position: -48px -66px;
}

.flag.flag-id {
  background-position: -64px -66px;
}

.flag.flag-ie {
  background-position: -80px -66px;
}

.flag.flag-il {
  background-position: -96px -66px;
}

.flag.flag-im {
  background-position: -112px -66px;
}

.flag.flag-in {
  background-position: -128px -66px;
}

.flag.flag-io {
  background-position: -144px -66px;
}

.flag.flag-iq {
  background-position: -160px -66px;
}

.flag.flag-ir {
  background-position: -176px -66px;
}

.flag.flag-is {
  background-position: -192px -66px;
}

.flag.flag-it {
  background-position: -208px -66px;
}

.flag.flag-je {
  background-position: -224px -66px;
}

.flag.flag-jm {
  background-position: -240px -66px;
}

.flag.flag-jo {
  background-position: 0 -77px;
}

.flag.flag-jp {
  background-position: -16px -77px;
}

.flag.flag-ke {
  background-position: -32px -77px;
}

.flag.flag-kg {
  background-position: -48px -77px;
}

.flag.flag-kh {
  background-position: -64px -77px;
}

.flag.flag-ki {
  background-position: -80px -77px;
}

.flag.flag-km {
  background-position: -96px -77px;
}

.flag.flag-kn {
  background-position: -112px -77px;
}

.flag.flag-kp {
  background-position: -128px -77px;
}

.flag.flag-kr {
  background-position: -144px -77px;
}

.flag.flag-kurdistan {
  background-position: -160px -77px;
}

.flag.flag-kw {
  background-position: -176px -77px;
}

.flag.flag-ky {
  background-position: -192px -77px;
}

.flag.flag-kz {
  background-position: -208px -77px;
}

.flag.flag-la {
  background-position: -224px -77px;
}

.flag.flag-lb {
  background-position: -240px -77px;
}

.flag.flag-lc {
  background-position: 0 -88px;
}

.flag.flag-li {
  background-position: -16px -88px;
}

.flag.flag-lk {
  background-position: -32px -88px;
}

.flag.flag-lr {
  background-position: -48px -88px;
}

.flag.flag-ls {
  background-position: -64px -88px;
}

.flag.flag-lt {
  background-position: -80px -88px;
}

.flag.flag-lu {
  background-position: -96px -88px;
}

.flag.flag-lv {
  background-position: -112px -88px;
}

.flag.flag-ly {
  background-position: -128px -88px;
}

.flag.flag-ma {
  background-position: -144px -88px;
}

.flag.flag-mc {
  background-position: -160px -88px;
}

.flag.flag-md {
  background-position: -176px -88px;
}

.flag.flag-me {
  background-position: -192px -88px;
}

.flag.flag-mg {
  background-position: -208px -88px;
}

.flag.flag-mh {
  background-position: -224px -88px;
}

.flag.flag-mk {
  background-position: -240px -88px;
}

.flag.flag-ml {
  background-position: 0 -99px;
}

.flag.flag-mm {
  background-position: -16px -99px;
}

.flag.flag-mn {
  background-position: -32px -99px;
}

.flag.flag-mo {
  background-position: -48px -99px;
}

.flag.flag-mp {
  background-position: -64px -99px;
}

.flag.flag-mq {
  background-position: -80px -99px;
}

.flag.flag-mr {
  background-position: -96px -99px;
}

.flag.flag-ms {
  background-position: -112px -99px;
}

.flag.flag-mt {
  background-position: -128px -99px;
}

.flag.flag-mu {
  background-position: -144px -99px;
}

.flag.flag-mv {
  background-position: -160px -99px;
}

.flag.flag-mw {
  background-position: -176px -99px;
}

.flag.flag-mx {
  background-position: -192px -99px;
}

.flag.flag-my {
  background-position: -208px -99px;
}

.flag.flag-mz {
  background-position: -224px -99px;
}

.flag.flag-na {
  background-position: -240px -99px;
}

.flag.flag-nc {
  background-position: 0 -110px;
}

.flag.flag-ne {
  background-position: -16px -110px;
}

.flag.flag-nf {
  background-position: -32px -110px;
}

.flag.flag-ng {
  background-position: -48px -110px;
}

.flag.flag-ni {
  background-position: -64px -110px;
}

.flag.flag-nl {
  background-position: -80px -110px;
}

.flag.flag-no {
  background-position: -96px -110px;
}

.flag.flag-np {
  background-position: -112px -110px;
}

.flag.flag-nr {
  background-position: -128px -110px;
}

.flag.flag-nu {
  background-position: -144px -110px;
}

.flag.flag-nz {
  background-position: -160px -110px;
}

.flag.flag-om {
  background-position: -176px -110px;
}

.flag.flag-pa {
  background-position: -192px -110px;
}

.flag.flag-pe {
  background-position: -208px -110px;
}

.flag.flag-pf {
  background-position: -224px -110px;
}

.flag.flag-pg {
  background-position: -240px -110px;
}

.flag.flag-ph {
  background-position: 0 -121px;
}

.flag.flag-pk {
  background-position: -16px -121px;
}

.flag.flag-pl {
  background-position: -32px -121px;
}

.flag.flag-pm {
  background-position: -48px -121px;
}

.flag.flag-pn {
  background-position: -64px -121px;
}

.flag.flag-pr {
  background-position: -80px -121px;
}

.flag.flag-ps {
  background-position: -96px -121px;
}

.flag.flag-pt {
  background-position: -112px -121px;
}

.flag.flag-pw {
  background-position: -128px -121px;
}

.flag.flag-py {
  background-position: -144px -121px;
}

.flag.flag-qa {
  background-position: -160px -121px;
}

.flag.flag-re {
  background-position: -176px -121px;
}

.flag.flag-ro {
  background-position: -192px -121px;
}

.flag.flag-rs {
  background-position: -208px -121px;
}

.flag.flag-ru {
  background-position: -224px -121px;
}

.flag.flag-rw {
  background-position: -240px -121px;
}

.flag.flag-sa {
  background-position: 0 -132px;
}

.flag.flag-sb {
  background-position: -16px -132px;
}

.flag.flag-sc {
  background-position: -32px -132px;
}

.flag.flag-scotland {
  background-position: -48px -132px;
}

.flag.flag-sd {
  background-position: -64px -132px;
}

.flag.flag-se {
  background-position: -80px -132px;
}

.flag.flag-sg {
  background-position: -96px -132px;
}

.flag.flag-sh {
  background-position: -112px -132px;
}

.flag.flag-si {
  background-position: -128px -132px;
}

.flag.flag-sk {
  background-position: -144px -132px;
}

.flag.flag-sl {
  background-position: -160px -132px;
}

.flag.flag-sm {
  background-position: -176px -132px;
}

.flag.flag-sn {
  background-position: -192px -132px;
}

.flag.flag-so {
  background-position: -208px -132px;
}

.flag.flag-somaliland {
  background-position: -224px -132px;
}

.flag.flag-sr {
  background-position: -240px -132px;
}

.flag.flag-ss {
  background-position: 0 -143px;
}

.flag.flag-st {
  background-position: -16px -143px;
}

.flag.flag-sv {
  background-position: -32px -143px;
}

.flag.flag-sx {
  background-position: -48px -143px;
}

.flag.flag-sy {
  background-position: -64px -143px;
}

.flag.flag-sz {
  background-position: -80px -143px;
}

.flag.flag-tc {
  background-position: -96px -143px;
}

.flag.flag-td {
  background-position: -112px -143px;
}

.flag.flag-tf {
  background-position: -128px -143px;
}

.flag.flag-tg {
  background-position: -144px -143px;
}

.flag.flag-th {
  background-position: -160px -143px;
}

.flag.flag-tibet {
  background-position: -176px -143px;
}

.flag.flag-tj {
  background-position: -192px -143px;
}

.flag.flag-tk {
  background-position: -208px -143px;
}

.flag.flag-tl {
  background-position: -224px -143px;
}

.flag.flag-tm {
  background-position: -240px -143px;
}

.flag.flag-tn {
  background-position: 0 -154px;
}

.flag.flag-to {
  background-position: -16px -154px;
}

.flag.flag-tr {
  background-position: -32px -154px;
}

.flag.flag-tt {
  background-position: -48px -154px;
}

.flag.flag-tv {
  background-position: -64px -154px;
}

.flag.flag-tw {
  background-position: -80px -154px;
}

.flag.flag-tz {
  background-position: -96px -154px;
}

.flag.flag-ua {
  background-position: -112px -154px;
}

.flag.flag-ug {
  background-position: -128px -154px;
}

.flag.flag-um {
  background-position: -144px -154px;
}

.flag.flag-us {
  background-position: -160px -154px;
}

.flag.flag-uy {
  background-position: -176px -154px;
}

.flag.flag-uz {
  background-position: -192px -154px;
}

.flag.flag-va {
  background-position: -208px -154px;
}

.flag.flag-vc {
  background-position: -224px -154px;
}

.flag.flag-ve {
  background-position: -240px -154px;
}

.flag.flag-vg {
  background-position: 0 -165px;
}

.flag.flag-vi {
  background-position: -16px -165px;
}

.flag.flag-vn {
  background-position: -32px -165px;
}

.flag.flag-vu {
  background-position: -48px -165px;
}

.flag.flag-wales {
  background-position: -64px -165px;
}

.flag.flag-wf {
  background-position: -80px -165px;
}

.flag.flag-ws {
  background-position: -96px -165px;
}

.flag.flag-xk {
  background-position: -112px -165px;
}

.flag.flag-ye {
  background-position: -128px -165px;
}

.flag.flag-yt {
  background-position: -144px -165px;
}

.flag.flag-za {
  background-position: -160px -165px;
}

.flag.flag-zanzibar {
  background-position: -176px -165px;
}

.flag.flag-zm {
  background-position: -192px -165px;
}

.flag.flag-zw {
  background-position: -208px -165px;
}

/* Forms */
form label {
  font-weight: normal;
}

textarea {
  resize: vertical;
}

select {
  border: 1px solid #e5e7e9;
  border-radius: 6px;
  outline: none;
}

select:not([multiple]) {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-size: 0.6em;
  background-position: calc(100% - 1em) center;
  background-repeat: no-repeat;
  padding-right: 2em;
}

select.select-style-1 {
  background-color: #f4f4f4;
  border: 0;
  color: #333;
  font-weight: 700;
}

select.select-style-1:not(.form-control-sm):not(.form-control-lg) {
  line-height: 2.7;
}

select.select-style-1:focus {
  background-color: #f4f4f4;
  box-shadow:
    0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(109, 109, 109, 0.3);
}

.label {
  font-weight: normal;
}

.form-group:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.form-group + .form-group {
  border-top: 1px solid #f3f3f3;
  padding-top: 16px;
  padding-top: 1rem;
}

.form-control {
  height: auto;
}

.form-control:not(.form-control-lg) {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.3;
}

.form-control:not(.form-control-sm):not(.form-control-lg) {
  font-size: 13.6px;
  font-size: 0.85rem;
  line-height: 1.85;
  min-height: 38.4px;
  min-height: 2.4rem;
}

.form-control.form-control-focused {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  border-color: #ccc;
}

.form-control:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  border-color: #ccc;
}

.form-control.error {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.form-control.error:focus {
  border-color: #843534;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 6px #ce8483;
}

.form-control.form-control-modern {
  border-color: #e7e7e7;
}

.form-control.form-control-modern:not(.form-control-sm):not(.form-control-lg) {
  line-height: 2.3;
}

.form-control.form-control-modern
  + .select2-container--bootstrap
  .select2-selection--single,
.form-control.form-control-modern
  + .select2-container--bootstrap
  .select2-selection--multiple {
  border-color: #e7e7e7;
  line-height: 2.8;
  height: 50px;
  padding: 6px 24px 6px 17px;
}

.form-control.form-control-modern
  + .select2-container--bootstrap
  .select2-selection--multiple
  .select2-search__field {
  width: 100% !important;
  line-height: 2.8;
  height: 39px;
  padding: 6px 24px 6px 6px;
}

.form-control.form-control-modern
  + .select2-container--bootstrap
  .select2-selection--single
  .select2-selection__arrow {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23777%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23777%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-size: 0.6em;
  background-position: calc(100% - 1.3em) center;
  background-repeat: no-repeat;
  width: 27px;
  right: -2px;
}

.form-control.form-control-modern
  + .select2-container--bootstrap
  .select2-selection--single
  .select2-selection__arrow
  b {
  display: none;
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
  font-size: 12px;
  font-size: 0.75rem;
}

/* Form - iOS Override */
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
textarea {
  -webkit-appearance: none;
}

.form-control::-webkit-input-placeholder,
input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="datetime"]::-webkit-input-placeholder,
input[type="datetime-local"]::-webkit-input-placeholder,
input[type="date"]::-webkit-input-placeholder,
input[type="month"]::-webkit-input-placeholder,
input[type="time"]::-webkit-input-placeholder,
input[type="week"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="url"]::-webkit-input-placeholder,
input[type="search"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="color"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #bdbdbd;
}

.form-control::-moz-placeholder,
input[type="text"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="datetime"]::-moz-placeholder,
input[type="datetime-local"]::-moz-placeholder,
input[type="date"]::-moz-placeholder,
input[type="month"]::-moz-placeholder,
input[type="time"]::-moz-placeholder,
input[type="week"]::-moz-placeholder,
input[type="number"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="url"]::-moz-placeholder,
input[type="search"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
input[type="color"]::-moz-placeholder,
textarea::-moz-placeholder {
  color: #bdbdbd;
}

.form-control:-ms-input-placeholder,
input[type="text"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="datetime"]:-ms-input-placeholder,
input[type="datetime-local"]:-ms-input-placeholder,
input[type="date"]:-ms-input-placeholder,
input[type="month"]:-ms-input-placeholder,
input[type="time"]:-ms-input-placeholder,
input[type="week"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="url"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="color"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #bdbdbd;
}

.multiselect-container {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.multiselect-container > li > a > label {
  padding: 5px 20px 5px 12px;
  font-size: 12.8px;
  font-size: 0.8rem;
}

.multiselect-container > li > a > label input {
  position: relative;
  top: 2px;
  margin-right: 3px;
}

.multiselect-container > li.active > a > label {
  color: #fff;
}

.multiselect-container > li.multiselect-group label {
  font-size: 12.8px;
  font-size: 0.8rem;
  padding: 5px 20px 5px 12px;
}

.multiselect-container .input-group input {
  max-width: 78%;
}

html.dark .form-control {
  background-color: #282d36;
  border-color: #282d36;
  color: #eee;
}

html.dark .form-control[disabled],
html.dark .form-control[readonly],
html.dark fieldset[disabled] .form-control {
  background-color: #5a5c63;
}

/* Form - Bootstrap Override */
.btn-lg,
.btn-group-lg > .btn {
  line-height: 1.334;
}

.input-group-btn .btn {
  padding-top: 8px;
  padding-top: 0.5rem;
  padding-bottom: 8px;
  padding-bottom: 0.5rem;
}

select.form-control-sm,
select.form-control-lg {
  line-height: 1;
  height: auto !important;
}

.bootstrap-timepicker-widget input {
  border: 0;
}

.bootstrap-timepicker-widget table td input {
  width: 40px;
}

.fileupload .btn {
  line-height: 20px;
  margin-left: -5px;
}

.date-time-field {
  display: flex;
  flex-direction: column;
}

.date-time-field .date {
  width: 100%;
}

.date-time-field .time {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.date-time-field .time > input {
  width: 50%;
}

@media (min-width: 992px) {
  .date-time-field {
    flex-direction: row;
  }

  .date-time-field .date {
    width: 60%;
  }

  .date-time-field .time {
    width: 40%;
    margin-top: 0;
  }
}

/* Form - Custom Fields */
.required {
  display: inline-block;
  color: #d2322d;
  font-size: 0.8em;
  font-weight: bold;
  position: relative;
  top: -0.2em;
}

label.error {
  color: #b94a48;
  margin-top: 2px;
}

/* Form - Group Override */
.form-group:after {
  clear: both;
  display: block;
  content: "";
}

.form-group:last-child,
.form-group:last-of-type {
  margin-bottom: 0;
}

/* Dark - Form - Bordered */
html.dark .form-bordered .form-group {
  border-bottom: 1px solid #242830;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

/* Form - Vertical Group / Stacked */
.form-group-vertical {
  position: relative;
  white-space: nowrap;
}

.form-group-vertical .form-control {
  border-radius: 0;
  margin-top: -1px;
  z-index: 1;
}

.form-group-vertical .form-control:first-child,
.form-group-vertical .form-control:first-of-type {
  border-radius: 4px 4px 0 0;
}

.form-group-vertical .form-control:last-child,
.form-group-vertical .form-control:last-of-type {
  border-radius: 0 0 4px 4px;
}

.form-group-vertical .form-control:focus {
  position: relative;
  z-index: 2;
}

.form-group-vertical .input-group {
  margin-top: -1px;
}

.form-group-vertical .input-group .form-control {
  margin-top: 0;
}

.form-group-vertical .input-group:first-child .form-control,
.form-group-vertical .input-group:first-of-type .form-control {
  border-radius: 0 4px 0 0;
}

.form-group-vertical .input-group:last-child .form-control,
.form-group-vertical .input-group:last-of-type .form-control {
  border-radius: 0 0 4px 0;
}

.form-group-vertical .input-group.input-group-icon:first-child .form-control,
.form-group-vertical .input-group.input-group-icon:first-of-type .form-control {
  border-radius: 4px 4px 0 0;
}

.form-group-vertical .input-group.input-group-icon:last-child .form-control,
.form-group-vertical .input-group.input-group-icon:last-of-type .form-control {
  border-radius: 0 0 4px 4px;
}

/* Form - Input Override */
.form-control-lg {
  border-radius: 4px;
}

/* Form - Round Input */
input.input-rounded {
  border-radius: 500px;
}

.input-group-rounded input.form-control {
  border-radius: 500px;
}

.input-group-rounded input.form-control:first-child,
.input-group-rounded input.form-control:last-child {
  border-radius: 500px;
}

/* Form - Checkbox */
.checkbox > label {
  display: flex;
  line-height: 1.6;
}

.checkbox > label > input[type="checkbox"] {
  margin-right: 5px;
  margin-top: 3px;
}

input[type="checkbox"].checkbox-style-1 {
  width: 17px;
  height: 17px;
  opacity: 0.3;
}

input[type="checkbox"].checkbox-style-1:checked {
  opacity: 1;
}

/* Form - Custom Checkbox */
.checkbox-custom {
  position: relative;
  padding: 0 0 0 25px;
  margin-bottom: 7px;
  margin-top: 0;
}

.checkbox-custom.checkbox-inline {
  display: inline-block;
  vertical-align: middle;
}

.form-group .checkbox-custom.checkbox-inline {
  margin-top: 7px;
  padding-top: 0;
}

.checkbox-custom:last-child,
.checkbox-custom:last-of-type {
  margin-bottom: 0;
}

.checkbox-custom input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 3px;
  margin: -6px 0 0 0;
  z-index: 2;
  cursor: pointer;
}

.checkbox-custom input[type="checkbox"]:disabled {
  cursor: not-allowed;
}

.checkbox-custom input[type="checkbox"]:disabled:checked + label:after {
  color: #999;
}

.checkbox-custom input[type="checkbox"]:disabled + label {
  cursor: not-allowed;
}

.checkbox-custom input[type="checkbox"]:disabled + label:before {
  background-color: #eee;
}

.checkbox-custom label {
  cursor: pointer;
  margin-bottom: 0;
  text-align: left;
  line-height: 1.5;
}

.checkbox-custom label:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -9px;
  width: 19px;
  height: 18px;
  display: inline-block;
  border-radius: 2px;
  border: 1px solid #bbb;
  background: #fff;
}

.checkbox-custom label + label.error {
  display: block;
}

html.dark .checkbox-custom label:before {
  background: #282d36;
  border-color: #5a5c63;
}

html.dark .checkbox-custom input[type="checkbox"]:checked + label:after {
  color: #fff;
}

html.dark .checkbox-custom input[type="checkbox"]:disabled + label:before {
  background: #242830;
  border-color: #242830;
}

html.dark .checkbox-primary input[type="checkbox"]:checked + label:after,
.checkbox-primary input[type="checkbox"]:checked + label:after {
  color: #fff;
}

html.dark .checkbox-primary label:before,
.checkbox-primary label:before {
  background: #ccc;
  border-color: #bfbfbf;
}

html.dark .checkbox-text-primary input[type="checkbox"]:checked + label:after,
.checkbox-text-primary input[type="checkbox"]:checked + label:after {
  color: #ccc;
}

html.dark .checkbox-success input[type="checkbox"]:checked + label:after,
.checkbox-success input[type="checkbox"]:checked + label:after {
  color: #fff;
}

html.dark .checkbox-success label:before,
.checkbox-success label:before {
  background: #47a447;
  border-color: #3f923f;
}

html.dark .checkbox-text-success input[type="checkbox"]:checked + label:after,
.checkbox-text-success input[type="checkbox"]:checked + label:after {
  color: #47a447;
}

html.dark .checkbox-warning input[type="checkbox"]:checked + label:after,
.checkbox-warning input[type="checkbox"]:checked + label:after {
  color: #fff;
}

html.dark .checkbox-warning label:before,
.checkbox-warning label:before {
  background: #ed9c28;
  border-color: #e89113;
}

html.dark .checkbox-text-warning input[type="checkbox"]:checked + label:after,
.checkbox-text-warning input[type="checkbox"]:checked + label:after {
  color: #ed9c28;
}

html.dark .checkbox-danger input[type="checkbox"]:checked + label:after,
.checkbox-danger input[type="checkbox"]:checked + label:after {
  color: #fff;
}

html.dark .checkbox-danger label:before,
.checkbox-danger label:before {
  background: #d2322d;
  border-color: #bd2d29;
}

html.dark .checkbox-text-danger input[type="checkbox"]:checked + label:after,
.checkbox-text-danger input[type="checkbox"]:checked + label:after {
  color: #d2322d;
}

html.dark .checkbox-info input[type="checkbox"]:checked + label:after,
.checkbox-info input[type="checkbox"]:checked + label:after {
  color: #fff;
}

html.dark .checkbox-info label:before,
.checkbox-info label:before {
  background: #5a5c63;
  border-color: #46b8da;
}

html.dark .checkbox-text-info input[type="checkbox"]:checked + label:after,
.checkbox-text-info input[type="checkbox"]:checked + label:after {
  color: #5a5c63;
}

html.dark .checkbox-dark input[type="checkbox"]:checked + label:after,
.checkbox-dark input[type="checkbox"]:checked + label:after {
  color: #fff;
}

html.dark .checkbox-dark label:before,
.checkbox-dark label:before {
  background: #5a5c63;
  border-color: #0a0a0a;
}

html.dark .checkbox-text-dark input[type="checkbox"]:checked + label:after,
.checkbox-text-dark input[type="checkbox"]:checked + label:after {
  color: #5a5c63;
}

/* Form - Custom Radio */
.radio-custom {
  position: relative;
  padding: 0 0 0 25px;
  margin-bottom: 7px;
  margin-top: 0;
}

.radio-custom.radio-inline {
  display: inline-block;
  vertical-align: middle;
}

.form-group .radio-custom.radio-inline {
  margin-top: 7px;
  padding-top: 0;
}

.radio-custom:last-child,
.radio-custom:last-of-type {
  margin-bottom: 0;
}

.radio-custom input[type="radio"] {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 3px;
  margin: -6px 0 0 0;
  z-index: 2;
  cursor: pointer;
}

.radio-custom input[type="radio"]:checked + label:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 4px;
  margin-top: -5px;
  display: inline-block;
  font-size: 11px;
  line-height: 1;
  width: 10px;
  height: 10px;
  background-color: #444;
  border-radius: 50px;
  box-shadow: 0px 0px 1px #444;
}

.radio-custom input[type="radio"]:disabled {
  cursor: not-allowed;
}

.radio-custom input[type="radio"]:disabled:checked + label:after {
  color: #999;
}

.radio-custom input[type="radio"]:disabled + label {
  cursor: not-allowed;
}

.radio-custom input[type="radio"]:disabled + label:before {
  background-color: #eee;
}

.radio-custom label {
  cursor: pointer;
  margin-bottom: 0;
  text-align: left;
  line-height: 1.2;
}

.radio-custom label:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -9px;
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 50px;
  border: 1px solid #bbb;
  background: #fff;
}

.radio-custom label + label.error {
  display: block;
}

html.dark .radio-custom label:before {
  background: #282d36;
  border-color: #5a5c63;
}

html.dark .radio-custom input[type="radio"]:checked + label:after {
  background-color: #fff;
}

html.dark .radio-custom input[type="radio"]:disabled + label:before {
  background: #242830;
  border-color: #242830;
}

html.dark .radio-primary input[type="radio"]:checked + label:after,
.radio-primary input[type="radio"]:checked + label:after {
  background: #ccc;
  box-shadow: 0px 0px 1px #ccc;
}

html.dark .radio-success input[type="radio"]:checked + label:after,
.radio-success input[type="radio"]:checked + label:after {
  background: #47a447;
  box-shadow: 0px 0px 1px #47a447;
}

html.dark .radio-warning input[type="radio"]:checked + label:after,
.radio-warning input[type="radio"]:checked + label:after {
  background: #ed9c28;
  box-shadow: 0px 0px 1px #ed9c28;
}

html.dark .radio-danger input[type="radio"]:checked + label:after,
.radio-danger input[type="radio"]:checked + label:after {
  background: #d2322d;
  box-shadow: 0px 0px 1px #d2322d;
}

html.dark .radio-info input[type="radio"]:checked + label:after,
.radio-info input[type="radio"]:checked + label:after {
  background: #5a5c63;
  box-shadow: 0px 0px 1px #5a5c63;
}

html.dark .radio-dark input[type="radio"]:checked + label:after,
.radio-dark input[type="radio"]:checked + label:after {
  background: #5a5c63;
  box-shadow: 0px 0px 1px #5a5c63;
}

/* State */
.has-primary .help-block,
.has-primary .control-label,
.has-primary .radio,
.has-primary .checkbox,
.has-primary .radio-inline,
.has-primary .checkbox-inline,
.has-primary.radio label,
.has-primary.checkbox label,
.has-primary.radio-inline label,
.has-primary.checkbox-inline label {
  color: #ccc;
}

.has-primary .form-control {
  border-color: #ccc !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
}

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #47a447;
}

.has-success .form-control {
  border-color: #47a447 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #ed9c28;
}

.has-warning .form-control {
  border-color: #ed9c28 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
}

.has-danger .help-block,
.has-danger .control-label,
.has-danger .radio,
.has-danger .checkbox,
.has-danger .radio-inline,
.has-danger .checkbox-inline,
.has-danger.radio label,
.has-danger.checkbox label,
.has-danger.radio-inline label,
.has-danger.checkbox-inline label {
  color: #d2322d;
}

.has-danger .form-control {
  border-color: #d2322d !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
}

.has-info .help-block,
.has-info .control-label,
.has-info .radio,
.has-info .checkbox,
.has-info .radio-inline,
.has-info .checkbox-inline,
.has-info.radio label,
.has-info.checkbox label,
.has-info.radio-inline label,
.has-info.checkbox-inline label {
  color: #5a5c63;
}

.has-info .form-control {
  border-color: #5a5c63 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
}

.has-dark .help-block,
.has-dark .control-label,
.has-dark .radio,
.has-dark .checkbox,
.has-dark .radio-inline,
.has-dark .checkbox-inline,
.has-dark.radio label,
.has-dark.checkbox label,
.has-dark.radio-inline label,
.has-dark.checkbox-inline label {
  color: #5a5c63;
}

.has-dark .form-control {
  border-color: #5a5c63 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
}

/* Form - Error Container */
.validation-message ul {
  display: none;
  list-style: none;
  margin: -20px -20px 30px -20px;
  padding: 20px 20px 10px 20px;
  border-bottom: 1px solid #ffcbcb;
  background: #ffefef;
}

.validation-message ul label.error {
  display: block;
  padding-left: 22px;
  position: relative;
}

.select2-drop-mask {
  z-index: 10010;
}

.select2-drop {
  z-index: 10011;
}

.select2-search {
  z-index: 10012;
}

.select2-container--bootstrap.select2-container--open {
  z-index: 10013;
}

.select2-container--bootstrap .select2-selection--single {
  height: 38.5px;
  line-height: 1.7;
  padding: 6px 24px 6px 12px;
}

.select2-container--bootstrap .select2-selection--multiple {
  height: 38.5px;
}

.select2-container--bootstrap
  .select2-selection--multiple
  .select2-selection__rendered {
  line-height: 1.8;
}

@media (max-width: 991px) {
  .select2-container--bootstrap {
    width: auto !important;
  }
}

.input-group-select-append .btn-group > .btn:first-child {
  border-radius: 0 0.25rem 0.25rem 0;
}

.bootstrap-tagsinput {
  padding: 7px 6px 6.2px;
}

.spinner-buttons.btn-group-vertical .btn {
  height: 21px !important;
}

.spinner-buttons.btn-group-vertical .btn:last-child {
  height: 20px !important;
}

.bootstrap-maxlength {
  z-index: 999999 !important;
}

html.dark .fileupload .uneditable-input {
  background-color: #282d36;
  border-color: #282d36;
}

html.dark .fileupload-new .input-append .btn-file {
  border-color: #5a5c63;
}

/* Form Group Invisible */
.form-group-invisible {
  position: relative;
}

.form-group-invisible.focus .control-label-invisible {
  color: #db052c;
}

.form-group-invisible .control-label-invisible {
  bottom: 0;
  display: block;
  float: none;
  left: 0;
  line-height: 64px;
  margin: 0;
  padding-left: 50px;
  position: absolute;
  right: 0;
  top: -15px;
  transition: color ease-in-out 0.15s;
  width: auto;
}

.form-group-invisible .form-control-invisible,
.form-group-invisible .form-control-invisible:focus,
.form-group-invisible .form-control-invisible:active,
.form-group-invisible .form-control-invisible + .bootstrap-tagsinput {
  background: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.form-group-invisible .form-control-invisible + .bootstrap-tagsinput {
  margin-bottom: 4px;
}

@media only screen and (max-width: 767px) {
  .form-group-invisible {
    padding-top: 30px;
  }

  .form-group-invisible .control-label-invisible {
    padding-left: 27px;
  }
}

/* Forms Validations */
label.valid {
  display: inline-block;
  text-indent: -9999px;
}

label.error {
  color: #c10000;
  font-size: 0.9em;
  margin-top: -5px;
  padding: 0;
}

/* Spinner */
.spinner-buttons.btn-group-vertical .btn i {
  position: relative;
  top: -6px;
}

/* File Upload */
.fileupload .uneditable-input {
  height: 37px;
}

/* Input Group */
.input-group .btn,
.input-group .btn.active,
.input-group .btn:active {
  box-shadow: none !important;
}

/* Google Maps */
@media only screen and (max-width: 767px) {
  #gmap {
    margin: -40px -15px 0 -15px;
  }

  html.mobile-device #gmap {
    min-height: 100px;
  }
}

@media only screen and (min-width: 768px) {
  #gmap {
    bottom: 0;
    height: auto !important;
    left: 0;
    position: absolute !important;
    right: 0;
    top: 0;
    min-height: 0;
  }
}

/* List Containing Markers */
.list-markers {
  border-bottom: 1px solid #5a5c63;
  padding-bottom: 10px;
}

.list-markers li {
  position: relative;
}

.list-markers p {
  margin: 0 0 2px 0;
  padding: 3px 55px 3px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.list-markers .location-action {
  position: absolute;
  right: 0;
  top: 2px;
}

.list-markers .location-action.location-edit {
  right: 15px;
  top: 3px;
}

.list-markers .location-action.location-center {
  right: 37px;
}

/* Modal Add/Edit Markers */
.marker-modal .modal-dialog {
  max-width: 450px;
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 200;
  letter-spacing: -1px;
}

h1 {
  font-size: 2.7em;
  line-height: 44px;
  font-weight: 500;
}

h2 {
  font-size: 2.25em;
  font-weight: 500;
  line-height: 42px;
}

h3 {
  font-size: 1.6em;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 24px;
}

h4 {
  font-size: 1.3em;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 27px;
}

h5 {
  font-size: 1.1em;
  font-weight: 300;
  letter-spacing: normal;
  line-height: 18px;
}

h6 {
  font-size: 0.9em;
  font-weight: 300;
  letter-spacing: normal;
  line-height: 18px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h1.big {
  font-size: 4.2em;
  line-height: 54px;
}

h1.small {
  font-size: 2.2em;
  line-height: 42px;
  font-weight: 600;
}

h1.short {
  margin-bottom: 15px;
}

h2.short {
  margin-bottom: 15px;
}

h3.short,
h4.short,
h5.short,
h6.short {
  margin-bottom: 9px;
}

h1.shorter,
h2.shorter,
h3.shorter,
h4.shorter,
h5.shorter,
h6.shorter {
  margin-bottom: 0;
}

h1.tall,
h2.tall,
h3.tall,
h4.tall,
h5.tall,
h6.tall {
  margin-bottom: 33px;
}

h1.taller,
h2.taller,
h3.taller,
h4.taller,
h5.taller,
h6.taller {
  margin-bottom: 44px;
}

h1.spaced,
h2.spaced,
h3.spaced,
h4.spaced,
h5.spaced,
h6.spaced {
  margin-top: 22px;
}

h1.more-spaced,
h2.more-spaced,
h3.more-spaced,
h4.more-spaced,
h5.more-spaced,
h6.more-spaced {
  margin-top: 44px;
}

h1.dark,
h2.dark,
h3.dark,
h4.dark,
h5.dark {
  color: #5a5c63;
}

h1.light,
h2.light,
h3.light,
h4.light,
h5.light,
h6.light {
  color: #fff;
}

h1.text-shadow,
h2.text-shadow,
h3.text-shadow,
h4.text-shadow,
h5.text-shadow,
h6.text-shadow {
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
}

/* Icons */
/* Size */
.icon {
  line-height: 1;
}

.icon.bx {
  padding-right: 4px;
  padding-top: 2px;
}

.icon.icon-inline {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.icon.icon-block {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon.icon-sm {
  width: 30px;
  height: 30px;
  font-size: 14.4px;
  font-size: 0.9rem;
}

.icon.icon-md {
  width: 40px;
  height: 40px;
  font-size: 22.4px;
  font-size: 1.4rem;
}

.icon.icon-lg {
  width: 50px;
  height: 50px;
  font-size: 27.2px;
  font-size: 1.7rem;
}

.icon.icon-xl {
  width: 78px;
  height: 78px;
  font-size: 33.6px;
  font-size: 2.1rem;
}

/* Images */
.rounded {
  border-radius: 6px;
}

.rounded-circle {
  border-radius: 50%;
}

.img-thumbnail {
  border-radius: 8px;
  position: relative;
}

.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border 0.2s ease-in-out;
}

.img-thumbnail {
  display: inline-block;
  border-radius: 8px;
  position: relative;
}

.img-thumbnail .zoom {
  display: block;
  position: absolute;
  right: 8px;
  bottom: 8px;
  height: 30px;
  width: 30px;
  padding: 6px;
  font-size: 14px;
  line-height: 18px;
  background: #ccc;
  border-radius: 100%;
  color: #fff;
  text-align: center;
}

.img-thumbnail .zoom i {
  position: relative;
  top: 2px;
  left: 1px;
}

/* Thumbnail Gallery */
.thumbnail-gallery {
  list-style: none;
  margin: 10px 0;
  padding: 0;
}

.thumbnail-gallery .img-thumbnail,
.thumbnail-gallery .thumbnail {
  margin: 10px 10px 0 0;
}

.image-frame .image-frame-wrapper {
  position: relative;
}

.image-frame .image-frame-wrapper .image-frame-badges-wrapper {
  position: absolute;
  top: 15px;
  left: 15px;
}

.image-top-10 {
  display: block;
  margin: 2rem auto;
}

/* IOs Switch */
.switch {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin: 3px 0;
}

.switch .ios-switch {
  height: 35px;
  width: 65px;
}

.switch .ios-switch .handle {
  height: 31px;
  width: 31px;
}

.switch.switch-primary .ios-switch .on-background {
  background: #ccc;
}

.switch.switch-success .ios-switch .on-background {
  background: #47a447;
}

.switch.switch-warning .ios-switch .on-background {
  background: #ed9c28;
}

.switch.switch-danger .ios-switch .on-background {
  background: #d2322d;
}

.switch.switch-info .ios-switch .on-background {
  background: #5a5c63;
}

.switch.switch-dark .ios-switch .on-background {
  background: #5a5c63;
}

.switch.switch-lg .ios-switch {
  height: 45px;
  width: 75px;
}

.switch.switch-lg .ios-switch .handle {
  height: 41px;
  width: 41px;
}

.switch.switch-sm .ios-switch {
  height: 25px;
  width: 55px;
}

.switch.switch-sm .ios-switch .handle {
  height: 21px;
  width: 21px;
}

.ios-switch {
  height: 45px;
  width: 75px;
  position: relative;
  background-color: #e5e5e5;
  border-radius: 100px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.ios-switch .background-fill {
  width: 100%;
  height: 100%;
  border-radius: 100px;
  position: absolute;
  left: 0;
  top: 0;
}

.ios-switch .on-background {
  background-image: linear-gradient(#00e459, #00e158);
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
  z-index: 1;
  transition: 0.3s 0.2s;
}

.ios-switch .state-background {
  border-radius: 100px;
  z-index: 2;
  background-image: linear-gradient(#ffffff, #fdfdfd);
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  transform: scale(1);
  transition: 0.4s;
}

.ios-switch .handle {
  width: 41px;
  height: 41px;
  background-color: white;
  top: 2px;
  left: 2px;
  position: absolute;
  border-radius: 20px;
  box-shadow:
    0 0 3px 1px rgba(0, 0, 0, 0.075),
    0 3px 5px rgba(0, 0, 0, 0.15),
    1px 2px 2px rgba(0, 0, 0, 0.05);
  z-index: 3;
  transition: transform 0.3s 0.25s cubic-bezier(0.455, 0.03, 0.215, 1.33);
  box-sizing: content-box;
}

.ios-switch.off .handle {
  -webkit-animation: expand-off 0.3s 0.2s;
  animation: expand-off 0.3s 0.2s;
  transform: translate(0px, 0);
  transform: translate3d(0px, 0, 0);
}

.ios-switch.off .on-background {
  transition: 0.3s 0s;
}

.ios-switch.off .state-background {
  transition: 0.4s 0.25s;
}

.ios-switch.on .handle {
  -webkit-animation: expand-on 0.3s 0.2s;
  animation: expand-on 0.3s 0.2s;
  transform: translate(30px, 0);
  transform: translate3d(30px, 0, 0);
}

.ios-switch.on .on-background {
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}

.ios-switch.on .state-background {
  transform: scale(0);
}

@-webkit-keyframes expand-on {
  0% {
    padding-right: 0;
  }

  40% {
    padding-right: 9px;
  }

  100% {
    padding-right: 0;
  }
}

@keyframes expand-on {
  0% {
    padding-right: 0;
  }

  40% {
    padding-right: 9px;
  }

  100% {
    padding-right: 0;
  }
}

@-webkit-keyframes expand-off {
  0% {
    padding-right: 0;
    left: 2px;
  }

  40% {
    padding-right: 9px;
    left: -7px;
  }

  100% {
    padding-right: 0;
    left: 2px;
  }
}

@keyframes expand-off {
  0% {
    padding-right: 0;
    left: 2px;
  }

  40% {
    padding-right: 9px;
    left: -7px;
  }

  100% {
    padding-right: 0;
    left: 2px;
  }
}

/* JQVMap */
.jqvmap-zoomin,
.jqvmap-zoomout {
  background: #ccc;
  border-radius: 2px;
  color: #fff;
  height: 20px;
  line-height: 20px;
  padding: 0;
  width: 20px;
  text-align: center;
}

.jqvmap-zoomin:hover,
.jqvmap-zoomout:hover {
  background: #bfbfbf;
}

.jqvmap-zoomout {
  top: 35px;
}

/* Lightboxes */
.mfp-close,
.mfp-close-btn-in .mfp-close {
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 600;
  font-size: 22px;
  color: #838383;
}

/* No Margins */
.mfp-no-margins img.mfp-img {
  padding: 0;
}

.mfp-no-margins .mfp-figure:after {
  top: 0;
  bottom: 0;
}

.mfp-no-margins .mfp-container {
  padding: 0;
}

/* Zoom */
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0.001;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

/* Animnate */
.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}

.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  transform: scale(1);
}

.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  transform: scale(0.8);
  opacity: 0;
}

.my-mfp-zoom-in.mfp-bg {
  opacity: 0.001;
  transition: opacity 0.3s ease-out;
}

.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

.my-mfp-slide-bottom .zoom-anim-dialog {
  opacity: 0;
  transition: all 0.2s ease-out;
  transform: translateY(-20px) perspective(600px) rotateX(10deg);
}

.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  transform: translateY(0) perspective(600px) rotateX(0);
}

.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
  opacity: 0;
  transform: translateY(-10px) perspective(600px) rotateX(10deg);
}

.my-mfp-slide-bottom.mfp-bg {
  opacity: 0.01;
  transition: opacity 0.3s ease-out;
}

.my-mfp-slide-bottom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.my-mfp-slide-bottom.mfp-removing.mfp-bg {
  opacity: 0;
}

/* Dialog */
.dialog {
  background: white;
  padding: 20px 30px;
  text-align: left;
  margin: 40px auto;
  position: relative;
  max-width: 600px;
}

.dialog.dialog-xs {
  max-width: 200px;
}

.dialog.dialog-sm {
  max-width: 400px;
}

.dialog.dialog-md {
  max-width: 600px;
}

.dialog.dialog-lg {
  max-width: 900px;
}

/* White Popup Block */
.white-popup-block {
  background: #fff;
  padding: 20px 30px;
  text-align: left;
  max-width: 600px;
  margin: 40px auto;
  position: relative;
}

.white-popup-block.white-popup-block-xs {
  max-width: 200px;
}

.white-popup-block.white-popup-block-sm {
  max-width: 400px;
}

.white-popup-block.white-popup-block-md {
  max-width: 600px;
}

.white-popup-block.white-popup-block-lg {
  max-width: 900px;
}

/* Dark */
html.dark .white-popup-block,
html.dark .dialog {
  background: #e6e6ea;
}

/* List */
.list li {
  margin-bottom: 13px;
}

.list.pull-left li {
  text-align: left;
}

.list.list-icons {
  list-style: none;
  padding-left: 0;
  padding-right: 0;
}

.list.list-icons li {
  position: relative;
  padding-left: 25px;
}

.list.list-icons li > .fa:first-child,
.list.list-icons li > .fab:first-child,
.list.list-icons li > .fal:first-child,
.list.list-icons li > .far:first-child,
.list.list-icons li > .fas:first-child,
.list.list-icons li > .icons:first-child,
.list.list-icons li a:first-child > .fa:first-child,
.list.list-icons li a:first-child > .fab:first-child,
.list.list-icons li a:first-child > .fal:first-child,
.list.list-icons li a:first-child > .far:first-child,
.list.list-icons li a:first-child > .fas:first-child,
.list.list-icons li a:first-child > .icons:first-child {
  position: absolute;
  left: 0;
  top: 5px;
}

.list.list-icons.list-icons-style-2 li {
  padding-top: 5px;
  padding-left: 36px;
}

.list.list-icons.list-icons-style-2 li > .fa:first-child,
.list.list-icons.list-icons-style-2 li > .fab:first-child,
.list.list-icons.list-icons-style-2 li > .fal:first-child,
.list.list-icons.list-icons-style-2 li > .far:first-child,
.list.list-icons.list-icons-style-2 li > .fas:first-child,
.list.list-icons.list-icons-style-2 li > .icons:first-child,
.list.list-icons.list-icons-style-2 li a:first-child > .fa:first-child,
.list.list-icons.list-icons-style-2 li a:first-child > .fab:first-child,
.list.list-icons.list-icons-style-2 li a:first-child > .fal:first-child,
.list.list-icons.list-icons-style-2 li a:first-child > .far:first-child,
.list.list-icons.list-icons-style-2 li a:first-child > .fas:first-child,
.list.list-icons.list-icons-style-2 li a:first-child > .icons:first-child {
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  padding: 4px;
}

.list.list-icons.list-icons-style-3 li {
  padding-top: 5px;
  padding-left: 36px;
}

.list.list-icons.list-icons-style-3 li > .fa:first-child,
.list.list-icons.list-icons-style-3 li > .fab:first-child,
.list.list-icons.list-icons-style-3 li > .fal:first-child,
.list.list-icons.list-icons-style-3 li > .far:first-child,
.list.list-icons.list-icons-style-3 li > .fas:first-child,
.list.list-icons.list-icons-style-3 li > .icons:first-child,
.list.list-icons.list-icons-style-3 li a:first-child > .fa:first-child,
.list.list-icons.list-icons-style-3 li a:first-child > .fab:first-child,
.list.list-icons.list-icons-style-3 li a:first-child > .fal:first-child,
.list.list-icons.list-icons-style-3 li a:first-child > .far:first-child,
.list.list-icons.list-icons-style-3 li a:first-child > .fas:first-child,
.list.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
  color: #fff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  padding: 5px;
}

.list.list-icons.list-icons-sm li {
  padding-left: 13px;
  margin-bottom: 5px;
}

.list.list-icons.list-icons-sm li > .fa:first-child,
.list.list-icons.list-icons-sm li > .fab:first-child,
.list.list-icons.list-icons-sm li > .fal:first-child,
.list.list-icons.list-icons-sm li > .far:first-child,
.list.list-icons.list-icons-sm li > .fas:first-child,
.list.list-icons.list-icons-sm li > .icons:first-child,
.list.list-icons.list-icons-sm li a:first-child > .fa:first-child,
.list.list-icons.list-icons-sm li a:first-child > .fab:first-child,
.list.list-icons.list-icons-sm li a:first-child > .fal:first-child,
.list.list-icons.list-icons-sm li a:first-child > .far:first-child,
.list.list-icons.list-icons-sm li a:first-child > .fas:first-child,
.list.list-icons.list-icons-sm li a:first-child > .icons:first-child {
  font-size: 0.8em;
  top: 7px;
}

.list.list-icons.list-icons-sm.list-icons-style-2 li {
  padding-left: 32px;
}

.list.list-icons.list-icons-sm.list-icons-style-2 li > .fa:first-child,
.list.list-icons.list-icons-sm.list-icons-style-2 li > .fab:first-child,
.list.list-icons.list-icons-sm.list-icons-style-2 li > .fal:first-child,
.list.list-icons.list-icons-sm.list-icons-style-2 li > .far:first-child,
.list.list-icons.list-icons-sm.list-icons-style-2 li > .fas:first-child,
.list.list-icons.list-icons-sm.list-icons-style-2 li > .icons:first-child,
.list.list-icons.list-icons-sm.list-icons-style-2
  li
  a:first-child
  > .fa:first-child,
.list.list-icons.list-icons-sm.list-icons-style-2
  li
  a:first-child
  > .fab:first-child,
.list.list-icons.list-icons-sm.list-icons-style-2
  li
  a:first-child
  > .fal:first-child,
.list.list-icons.list-icons-sm.list-icons-style-2
  li
  a:first-child
  > .far:first-child,
.list.list-icons.list-icons-sm.list-icons-style-2
  li
  a:first-child
  > .fas:first-child,
.list.list-icons.list-icons-sm.list-icons-style-2
  li
  a:first-child
  > .icons:first-child {
  width: 20px;
  height: 20px;
  padding: 3px;
}

.list.list-icons.list-icons-sm.list-icons-style-3 li {
  padding-left: 32px;
}

.list.list-icons.list-icons-sm.list-icons-style-3 li > .fa:first-child,
.list.list-icons.list-icons-sm.list-icons-style-3 li > .fab:first-child,
.list.list-icons.list-icons-sm.list-icons-style-3 li > .fal:first-child,
.list.list-icons.list-icons-sm.list-icons-style-3 li > .far:first-child,
.list.list-icons.list-icons-sm.list-icons-style-3 li > .fas:first-child,
.list.list-icons.list-icons-sm.list-icons-style-3 li > .icons:first-child,
.list.list-icons.list-icons-sm.list-icons-style-3
  li
  a:first-child
  > .fa:first-child,
.list.list-icons.list-icons-sm.list-icons-style-3
  li
  a:first-child
  > .fab:first-child,
.list.list-icons.list-icons-sm.list-icons-style-3
  li
  a:first-child
  > .fal:first-child,
.list.list-icons.list-icons-sm.list-icons-style-3
  li
  a:first-child
  > .far:first-child,
.list.list-icons.list-icons-sm.list-icons-style-3
  li
  a:first-child
  > .fas:first-child,
.list.list-icons.list-icons-sm.list-icons-style-3
  li
  a:first-child
  > .icons:first-child {
  width: 20px;
  height: 20px;
  padding: 3px;
}

.list.list-icons.list-icons-lg li {
  padding-top: 5px;
  padding-left: 32px;
}

.list.list-icons.list-icons-lg li > .fa:first-child,
.list.list-icons.list-icons-lg li > .fab:first-child,
.list.list-icons.list-icons-lg li > .fal:first-child,
.list.list-icons.list-icons-lg li > .far:first-child,
.list.list-icons.list-icons-lg li > .fas:first-child,
.list.list-icons.list-icons-lg li > .icons:first-child,
.list.list-icons.list-icons-lg li a:first-child > .fa:first-child,
.list.list-icons.list-icons-lg li a:first-child > .fab:first-child,
.list.list-icons.list-icons-lg li a:first-child > .fal:first-child,
.list.list-icons.list-icons-lg li a:first-child > .far:first-child,
.list.list-icons.list-icons-lg li a:first-child > .fas:first-child,
.list.list-icons.list-icons-lg li a:first-child > .icons:first-child {
  font-size: 1.5em;
}

.list.list-icons.list-icons-lg.list-icons-style-2 li {
  padding-top: 8px;
  padding-left: 42px;
}

.list.list-icons.list-icons-lg.list-icons-style-2 li > .fa:first-child,
.list.list-icons.list-icons-lg.list-icons-style-2 li > .fab:first-child,
.list.list-icons.list-icons-lg.list-icons-style-2 li > .fal:first-child,
.list.list-icons.list-icons-lg.list-icons-style-2 li > .far:first-child,
.list.list-icons.list-icons-lg.list-icons-style-2 li > .fas:first-child,
.list.list-icons.list-icons-lg.list-icons-style-2 li > .icons:first-child,
.list.list-icons.list-icons-lg.list-icons-style-2
  li
  a:first-child
  > .fa:first-child,
.list.list-icons.list-icons-lg.list-icons-style-2
  li
  a:first-child
  > .fab:first-child,
.list.list-icons.list-icons-lg.list-icons-style-2
  li
  a:first-child
  > .fal:first-child,
.list.list-icons.list-icons-lg.list-icons-style-2
  li
  a:first-child
  > .far:first-child,
.list.list-icons.list-icons-lg.list-icons-style-2
  li
  a:first-child
  > .fas:first-child,
.list.list-icons.list-icons-lg.list-icons-style-2
  li
  a:first-child
  > .icons:first-child {
  width: 30px;
  height: 30px;
  padding: 4px;
}

.list.list-icons.list-icons-lg.list-icons-style-3 li {
  padding-top: 8px;
  padding-left: 42px;
}

.list.list-icons.list-icons-lg.list-icons-style-3 li > .fa:first-child,
.list.list-icons.list-icons-lg.list-icons-style-3 li > .fab:first-child,
.list.list-icons.list-icons-lg.list-icons-style-3 li > .fal:first-child,
.list.list-icons.list-icons-lg.list-icons-style-3 li > .far:first-child,
.list.list-icons.list-icons-lg.list-icons-style-3 li > .fas:first-child,
.list.list-icons.list-icons-lg.list-icons-style-3 li > .icons:first-child,
.list.list-icons.list-icons-lg.list-icons-style-3
  li
  a:first-child
  > .fa:first-child,
.list.list-icons.list-icons-lg.list-icons-style-3
  li
  a:first-child
  > .fab:first-child,
.list.list-icons.list-icons-lg.list-icons-style-3
  li
  a:first-child
  > .fal:first-child,
.list.list-icons.list-icons-lg.list-icons-style-3
  li
  a:first-child
  > .far:first-child,
.list.list-icons.list-icons-lg.list-icons-style-3
  li
  a:first-child
  > .fas:first-child,
.list.list-icons.list-icons-lg.list-icons-style-3
  li
  a:first-child
  > .icons:first-child {
  width: 30px;
  height: 30px;
  padding: 4px;
}

.list.list-icons.list-icons-reverse li {
  text-align: right;
  padding-right: 25px;
  padding-left: 0;
}

.list.list-icons.list-icons-reverse li > .fa:first-child,
.list.list-icons.list-icons-reverse li > .fab:first-child,
.list.list-icons.list-icons-reverse li > .fal:first-child,
.list.list-icons.list-icons-reverse li > .far:first-child,
.list.list-icons.list-icons-reverse li > .fas:first-child,
.list.list-icons.list-icons-reverse li > .icons:first-child,
.list.list-icons.list-icons-reverse li a:first-child > .fa:first-child,
.list.list-icons.list-icons-reverse li a:first-child > .fab:first-child,
.list.list-icons.list-icons-reverse li a:first-child > .fal:first-child,
.list.list-icons.list-icons-reverse li a:first-child > .far:first-child,
.list.list-icons.list-icons-reverse li a:first-child > .fas:first-child,
.list.list-icons.list-icons-reverse li a:first-child > .icons:first-child {
  position: absolute;
  left: auto;
  right: 0;
  top: 5px;
}

.list.list-icons.list-icons-reverse.list-icons-style-2 li {
  padding-right: 36px;
}

.list.list-icons.list-icons-reverse.list-icons-style-3 li {
  padding-right: 36px;
}

.list.list-icons.list-icons-reverse.list-icons-sm li {
  padding-left: 0;
  padding-right: 13px;
}

.list.list-icons.list-icons-reverse.list-icons-sm.list-icons-style-2 li {
  padding-right: 32px;
}

.list.list-icons.list-icons-reverse.list-icons-sm.list-icons-style-3 li {
  padding-right: 32px;
}

.list.list-icons.list-icons-reverse.list-icons-lg li {
  padding-left: 0;
  padding-right: 32px;
}

.list.list-icons.list-icons-reverse.list-icons-lg.list-icons-style-2 li {
  padding-right: 42px;
}

.list.list-icons.list-icons-reverse.list-icons-lg.list-icons-style-3 li {
  padding-right: 42px;
}

.list.list-borders li {
  border-bottom: 1px solid #dbdbdb;
  margin-top: 9px;
  margin-bottom: 0;
  padding-bottom: 9px;
}

.list.list-borders li:last-child {
  border-bottom: 0;
}

.list.list-side-borders {
  border-left: 1px solid #dbdbdb;
  padding-left: 10px;
}

.list.list-ordened {
  counter-reset: custom-counter;
  list-style: none;
}

.list.list-ordened li {
  position: relative;
  padding-left: 22px;
}

.list.list-ordened li:before {
  position: absolute;
  top: 0;
  left: 0;
  content: counter(custom-counter);
  counter-increment: custom-counter;
  font-weight: bold;
}

.list.list-ordened.list-ordened-style-2 li,
.list.list-ordened.list-ordened-style-3 li {
  padding-left: 32px;
}

.list.list-ordened.list-ordened-style-2 li:before,
.list.list-ordened.list-ordened-style-3 li:before {
  border-radius: 100%;
  border-style: solid;
  border-width: 1px;
  min-width: 26px;
  padding: 0;
  text-align: center;
}

.list.list-ordened.list-ordened-style-3 li:before {
  color: #fff;
  border: 0;
}

.list.list-unstyled {
  padding-left: 0;
  padding-right: 0;
}

.list.list-filter > li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 30px;
  font-weight: 600;
  border: 1px solid #ccc;
}

.list.list-filter > li a:hover,
.list.list-filter > li a:focus,
.list.list-filter > li a:active {
  border-color: #ccc;
}

.list.list-filter > li a.active {
  background: #ccc;
  color: #fff;
}

.list.list-filter > li:not(:last-child) {
  margin-right: 1.6px;
  margin-right: 0.1rem;
  margin-bottom: 6.4px;
  margin-bottom: 0.4rem;
}

.list.list-item-bottom-space-0 > li {
  margin-bottom: 0;
  margin-top: 0;
}

.list.list-item-bottom-space-xs > li {
  margin-bottom: 0;
}

.list.list-item-bottom-space-xs > li + li {
  margin-top: 3px;
}

.list.list-item-bottom-space-sm > li + li {
  margin-top: 6px;
}

.list.list-item-bottom-space-md > li + li {
  margin-top: 10px;
}

.list.list-item-bottom-space-lg > li + li {
  margin-top: 14px;
}

dl dd {
  margin-bottom: 15px;
}

/* Loading Overlay */
.loading-overlay-showing {
  overflow: hidden;
}

.loading-overlay-showing > .loading-overlay {
  opacity: 1;
  visibility: visible;
}

.loading-overlay {
  transition:
    visibility 0s ease-in-out 0.5s,
    opacity 0.5s ease-in-out;
  bottom: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
  visibility: hidden;
  background: #fff;
}

body > .loading-overlay {
  position: fixed;
  z-index: 999999;
}

/* Bounce Loading */
.bounce-loader {
  transition: all 0.2;
  margin: -9px 0 0 -35px;
  text-align: center;
  width: 70px;
  height: 20px;
  line-height: 20px;
  left: 50%;
  position: absolute;
  top: 50%;
  z-index: 10000;
}

.bounce-loader .bounce1,
.bounce-loader .bounce2,
.bounce-loader .bounce3 {
  -webkit-animation: 1.4s ease-in-out 0s normal both infinite running
    bouncedelay;
  animation: 1.4s ease-in-out 0s normal both infinite running bouncedelay;
  background-color: #ccc;
  border-radius: 100%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  display: inline-block;
  height: 18px;
  width: 18px;
}

.bounce-loader .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.bounce-loader .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

/* Loading Progress */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #ccc;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow:
    0 0 10px #ccc,
    0 0 5px #ccc;
  opacity: 1;
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Markdown */
.md-editor {
  border-radius: 4px;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
}

.md-editor > .md-header {
  border-radius: 4px 4px 0 0;
  padding: 10px 10px 4px;
}

.md-editor > .md-preview,
.md-editor > textarea {
  background: #fff;
  border-bottom: none;
  border-radius: 0 0 4px 4px;
  padding: 10px;
  outline: none;
  width: 100% !important;
}

.md-editor .btn-group {
  margin-bottom: 6px;
  margin-right: 6px;
}

html.dark .md-editor {
  border-color: #282d36;
}

html.dark .md-editor > .md-header,
html.dark .md-editor .md-footer {
  background: #242830;
}

html.dark .md-editor > .md-preview,
html.dark .md-editor > textarea {
  background: #282d36;
}

html.dark .md-editor > textarea {
  color: #eee;
  border-color: #e6e6ea;
}

/* Max Length */
@media only screen and (max-width: 767px) {
  .bootstrap-maxlength.bottom-left {
    margin-left: 40px;
  }
}

/* Misc */
body a,
body a:focus,
body a:hover,
body a:active,
body a:visited {
  outline: none !important;
}

.center {
  text-align: center;
}

.popover .btn {
  margin-right: 5px;
}

.popover .btn:last-child {
  margin-right: 0;
}

/* Clearfix */
.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

/* Show Grid */
.show-grid [class*="col"] .show-grid-block {
  background-color: #eee;
  line-height: 40px;
  min-height: 40px;
  text-align: center;
  border: 1px solid #fff;
  display: block;
}

html.dark .show-grid [class*="col"] .show-grid-block {
  background-color: #282d36;
  border-color: #282d36;
}

/* Embed Responsive */
.embed-responsive.embed-soundcloud {
  padding-bottom: 19.25%;
}

.embed-responsive.embed-responsive-borders {
  border: 1px solid #ddd;
  padding: 4px;
  border-radius: 4px;
}

/* Changelog */
.changelog h4 {
  display: inline-block;
  color: #000;
  font-size: 1em;
  font-weight: 600;
}

.changelog .release-date {
  color: #999;
  font-size: 0.9em;
}

.changelog .label {
  display: inline-block;
  min-width: 100px;
}

/* Arrows */
.arrow {
  /* background: transparent url(../img/arrows.png) no-repeat 0 0; */
  width: 47px;
  height: 120px;
  display: inline-block;
  position: relative;
}

.arrow.arrow-light {
  /* background-image: url(../img/arrows-dark.png); */
}

.arrow.vtl {
  background-position: 0 0;
  width: 47px;
  height: 96px;
}

.arrow.vtr {
  background-position: -101px 0;
  width: 47px;
  height: 96px;
}

.arrow.vbl {
  background-position: 0 -144px;
  width: 47px;
  height: 96px;
}

.arrow.vbr {
  background-position: -101px -144px;
  width: 47px;
  height: 96px;
}

.arrow.hlt {
  background-position: -209px 0;
  width: 120px;
  height: 47px;
}

.arrow.hlb {
  background-position: -209px -101px;
  width: 120px;
  height: 47px;
}

.arrow.hrt {
  background-position: -353px 0;
  width: 120px;
  height: 47px;
}

.arrow.hrb {
  background-position: -353px -101px;
  width: 120px;
  height: 47px;
}

/* Dropdown */
.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

/* Shadow Style 1 */
.shadow-style-1 {
  transition: all 0.3s ease;
  box-shadow: 10px 10px 74px -15px rgba(74, 74, 74, 0.1);
}

.shadow-style-1:hover {
  box-shadow: 10px 10px 74px -15px rgba(74, 74, 74, 0.4);
}

/* Shadow Style 2 */
.shadow-style-2 {
  box-shadow: 10px 10px 74px -15px rgba(74, 74, 74, 0.4);
}

/* Popover */
.popover .popover-header {
  margin: 0 !important;
}

/* Tooltip */
.tooltip {
  z-index: 1000000;
}

/* Activity Item */
.activity-item {
  display: flex;
  align-items: center;
}

.activity-item .activity-time {
  display: inline-flex;
  min-width: 66px;
}

.activity-item .activity-time + i {
  margin: 0 8px;
}

.activity-item:first-child .activity-description {
  font-size: 16.8px;
  font-size: 1.05rem;
}

@media (max-width: 1199px) {
  html:not(.sidebar-left-collapsed) .activity-item {
    flex-wrap: wrap;
  }

  html:not(.sidebar-left-collapsed) .activity-item .activity-time {
    width: 100%;
  }

  html:not(.sidebar-left-collapsed) .activity-item .activity-time + i {
    display: none;
  }
}

@media (max-width: 767px) {
  .activity-item {
    flex-wrap: wrap;
  }

  .activity-item .activity-time {
    width: 100%;
  }

  .activity-item .activity-time + i {
    display: none;
  }
}

/* Filters Sidebar Wrapper */
.filters-sidebar-wrapper {
  padding: 30px 15px 30px;
  box-shadow: 0px 0px 37px -36px rgba(0, 0, 0, 0.4);
}

.filters-sidebar-wrapper a {
  color: inherit;
}

.filters-sidebar-wrapper a:hover {
  text-decoration: none;
}

.filters-sidebar-wrapper ul li:last-child {
  margin-bottom: 0;
}

.filters-sidebar-wrapper .card {
  box-shadow: none;
}

.filters-sidebar-wrapper .card .card-header {
  padding-top: 13px;
  padding-bottom: 13px;
}

.filters-sidebar-wrapper .card .card-header + .card-body {
  background: transparent;
  padding-bottom: 0;
  box-shadow: none;
}

.filters-sidebar-wrapper .card .card-header .card-title {
  font-size: 14.4px;
  font-size: 0.9rem;
}

.filters-sidebar-wrapper .card .card-actions {
  top: 11px;
}

/* Gutter SM */
.row-gutter-sm {
  margin-right: -10px;
  margin-left: -10px;
}

.row-gutter-sm .col,
.row-gutter-sm .col-1,
.row-gutter-sm .col-10,
.row-gutter-sm .col-11,
.row-gutter-sm .col-12,
.row-gutter-sm .col-2,
.row-gutter-sm .col-3,
.row-gutter-sm .col-4,
.row-gutter-sm .col-5,
.row-gutter-sm .col-6,
.row-gutter-sm .col-7,
.row-gutter-sm .col-8,
.row-gutter-sm .col-9,
.row-gutter-sm .col-auto,
.row-gutter-sm .col-lg,
.row-gutter-sm .col-lg-1,
.row-gutter-sm .col-lg-10,
.row-gutter-sm .col-lg-11,
.row-gutter-sm .col-lg-12,
.row-gutter-sm .col-lg-2,
.row-gutter-sm .col-lg-3,
.row-gutter-sm .col-lg-4,
.row-gutter-sm .col-lg-5,
.row-gutter-sm .col-lg-6,
.row-gutter-sm .col-lg-7,
.row-gutter-sm .col-lg-8,
.row-gutter-sm .col-lg-9,
.row-gutter-sm .col-lg-auto,
.row-gutter-sm .col-md,
.row-gutter-sm .col-md-1,
.row-gutter-sm .col-md-10,
.row-gutter-sm .col-md-11,
.row-gutter-sm .col-md-12,
.row-gutter-sm .col-md-2,
.row-gutter-sm .col-md-3,
.row-gutter-sm .col-md-4,
.row-gutter-sm .col-md-5,
.row-gutter-sm .col-md-6,
.row-gutter-sm .col-md-7,
.row-gutter-sm .col-md-8,
.row-gutter-sm .col-md-9,
.row-gutter-sm .col-md-auto,
.row-gutter-sm .col-sm,
.row-gutter-sm .col-sm-1,
.row-gutter-sm .col-sm-10,
.row-gutter-sm .col-sm-11,
.row-gutter-sm .col-sm-12,
.row-gutter-sm .col-sm-2,
.row-gutter-sm .col-sm-3,
.row-gutter-sm .col-sm-4,
.row-gutter-sm .col-sm-5,
.row-gutter-sm .col-sm-6,
.row-gutter-sm .col-sm-7,
.row-gutter-sm .col-sm-8,
.row-gutter-sm .col-sm-9,
.row-gutter-sm .col-sm-auto,
.row-gutter-sm .col-xl,
.row-gutter-sm .col-xl-1,
.row-gutter-sm .col-xl-10,
.row-gutter-sm .col-xl-11,
.row-gutter-sm .col-xl-12,
.row-gutter-sm .col-xl-2,
.row-gutter-sm .col-xl-3,
.row-gutter-sm .col-xl-4,
.row-gutter-sm .col-xl-5,
.row-gutter-sm .col-xl-6,
.row-gutter-sm .col-xl-7,
.row-gutter-sm .col-xl-8,
.row-gutter-sm .col-xl-9,
.row-gutter-sm .col-xl-auto,
.row-gutter-sm .col-1-5,
.row-gutter-sm .col-2-5,
.row-gutter-sm .col-3-5,
.row-gutter-sm .col-4-5,
.row-gutter-sm .col-sm-1-5,
.row-gutter-sm .col-sm-2-5,
.row-gutter-sm .col-sm-3-5,
.row-gutter-sm .col-sm-4-5,
.row-gutter-sm .col-md-1-5,
.row-gutter-sm .col-md-2-5,
.row-gutter-sm .col-md-3-5,
.row-gutter-sm .col-md-4-5,
.row-gutter-sm .col-lg-1-5,
.row-gutter-sm .col-lg-2-5,
.row-gutter-sm .col-lg-3-5,
.row-gutter-sm .col-lg-4-5,
.row-gutter-sm .col-xl-1-5,
.row-gutter-sm .col-xl-2-5,
.row-gutter-sm .col-xl-3-5,
.row-gutter-sm .col-xl-4-5 {
  padding-left: 10px;
  padding-right: 10px;
}

/* Modals */
.modal {
  z-index: 1100;
}

.mfp-bg {
  z-index: 10000;
}

.mfp-wrap {
  z-index: 10001;
}

.mfp-wrap .mfp-content {
  z-index: 10001;
}

.modal-block {
  background: transparent;
  padding: 0;
  text-align: left;
  max-width: 600px;
  margin: 40px auto;
  position: relative;
}

.modal-block.modal-block-xs {
  max-width: 200px;
}

.modal-block.modal-block-sm {
  max-width: 400px;
}

.modal-block.modal-block-md {
  max-width: 600px;
}

.modal-block.modal-block-lg {
  max-width: 900px;
}

.modal-block.modal-block-full {
  max-width: 98%;
}

.modal-block.modal-header-color .card-header h2 {
  color: #fff;
}

.modal-block.modal-full-color {
  color: #fff;
}

.modal-block.modal-full-color .card-header {
  border: 0;
}

.modal-block.modal-full-color .card-header h2 {
  color: #fff;
}

.modal-block.modal-full-color .card-footer {
  border: 0;
}

.modal-block.modal-full-color .card-body {
  background-color: transparent;
}

.modal-block.modal-full-color .fa,
.modal-block.modal-full-color .fab,
.modal-block.modal-full-color .fal,
.modal-block.modal-full-color .far,
.modal-block.modal-full-color .fas {
  color: #fff !important;
}

.modal-block.modal-full-color p {
  color: #fff;
}

/* Modal Wrapper */
.modal-wrapper {
  position: relative;
  padding: 25px 0;
}

/* Modal Icon */
.modal-icon {
  float: left;
  width: 20%;
  text-align: center;
}

.modal-icon .fa,
.modal-icon .fab,
.modal-icon .fal,
.modal-icon .far,
.modal-icon .fas {
  font-size: 52px;
  position: relative;
  top: -10px;
  color: #ccc;
}

.modal-icon.center {
  float: none;
  width: auto;
  padding-top: 20px;
}

.modal-icon.center + .modal-text {
  float: none;
  width: auto;
}

.modal-icon + .modal-text {
  float: left;
  width: 80%;
}

/* Modal Text */
.modal-text {
  padding: 0 5px;
}

.modal-text h1,
.modal-text h2,
.modal-text h3,
.modal-text h4,
.modal-text h5,
.modal-text h6 {
  padding: 0;
  margin: -7px 0 4px 0;
}

.modal-block-primary .fa,
.modal-block-primary .fab,
.modal-block-primary .fal,
.modal-block-primary .far,
.modal-block-primary .fas {
  color: #ccc;
}

.modal-block-primary.modal-header-color .card-header {
  background-color: #ccc;
}

.modal-block-primary.modal-full-color .card {
  background-color: #e0e0e0;
}

.modal-block-primary.modal-full-color .card-header {
  background-color: #ccc;
}

.modal-block-primary.modal-full-color .card-footer {
  background-color: #e0e0e0;
}

.modal-block-success .fa,
.modal-block-success .fab,
.modal-block-success .fal,
.modal-block-success .far,
.modal-block-success .fas {
  color: #47a447;
}

.modal-block-success.modal-header-color .card-header {
  background-color: #47a447;
}

.modal-block-success.modal-full-color .card {
  background-color: #5cb85c;
}

.modal-block-success.modal-full-color .card-header {
  background-color: #47a447;
}

.modal-block-success.modal-full-color .card-footer {
  background-color: #5cb85c;
}

.modal-block-warning .fa,
.modal-block-warning .fab,
.modal-block-warning .fal,
.modal-block-warning .far,
.modal-block-warning .fas {
  color: #ed9c28;
}

.modal-block-warning.modal-header-color .card-header {
  background-color: #ed9c28;
}

.modal-block-warning.modal-full-color .card {
  background-color: #f0ad4e;
}

.modal-block-warning.modal-full-color .card-header {
  background-color: #ed9c28;
}

.modal-block-warning.modal-full-color .card-footer {
  background-color: #f0ad4e;
}

.modal-block-danger .fa,
.modal-block-danger .fab,
.modal-block-danger .fal,
.modal-block-danger .far,
.modal-block-danger .fas {
  color: #d2322d;
}

.modal-block-danger.modal-header-color .card-header {
  background-color: #d2322d;
}

.modal-block-danger.modal-full-color .card {
  background-color: #d9534f;
}

.modal-block-danger.modal-full-color .card-header {
  background-color: #d2322d;
}

.modal-block-danger.modal-full-color .card-footer {
  background-color: #d9534f;
}

.modal-block-info .fa,
.modal-block-info .fab,
.modal-block-info .fal,
.modal-block-info .far,
.modal-block-info .fas {
  color: #5a5c63;
}

.modal-block-info.modal-header-color .card-header {
  background-color: #5a5c63;
}

.modal-block-info.modal-full-color .card {
  background-color: #7dcde5;
}

.modal-block-info.modal-full-color .card-header {
  background-color: #5a5c63;
}

.modal-block-info.modal-full-color .card-footer {
  background-color: #7dcde5;
}

.modal-block-dark .fa,
.modal-block-dark .fab,
.modal-block-dark .fal,
.modal-block-dark .far,
.modal-block-dark .fas {
  color: #5a5c63;
}

.modal-block-dark.modal-header-color .card-header {
  background-color: #5a5c63;
}

.modal-block-dark.modal-full-color .card {
  background-color: #2b2b2b;
}

.modal-block-dark.modal-full-color .card-header {
  background-color: #5a5c63;
}

.modal-block-dark.modal-full-color .card-footer {
  background-color: #2b2b2b;
}

html.dark .modal-content {
  background-color: #e6e6ea;
}

html.dark .modal-header,
html.dark .modal-footer {
  border-color: #282d36;
}

/* Multiselect */
html.dark .multiselect-container {
  background-color: #282d36;
}

html.dark .multiselect-container > li > a {
  color: #eee;
}

html.dark .multiselect-container > li > a:hover,
html.dark .multiselect-container > li > a:focus {
  background-color: #e6e6ea;
  color: #fff;
}

/* Nav Pills */
.nav-pills > .active a,
.nav-pills > .active a:hover,
.nav-pills > .active a:focus {
  background-color: #ccc;
}

/* Buttons - States */
.nav-pills-primary > li a:hover,
.nav-pills-primary > li a:focus {
  color: #ccc;
  background-color: white;
}

.nav-pills-primary > li.active > a,
.nav-pills-primary > li.active > a:hover,
.nav-pills-primary > li.active > a:active,
.nav-pills-primary > li.active > a:focus {
  background-color: #ccc;
}

.nav-pills-success > li a:hover,
.nav-pills-success > li a:focus {
  color: #47a447;
  background-color: #bfe2bc;
}

.nav-pills-success > li.active > a,
.nav-pills-success > li.active > a:hover,
.nav-pills-success > li.active > a:active,
.nav-pills-success > li.active > a:focus {
  background-color: #47a447;
}

.nav-pills-warning > li a:hover,
.nav-pills-warning > li a:focus {
  color: #ed9c28;
  background-color: #fbe4cd;
}

.nav-pills-warning > li.active > a,
.nav-pills-warning > li.active > a:hover,
.nav-pills-warning > li.active > a:active,
.nav-pills-warning > li.active > a:focus {
  background-color: #ed9c28;
}

.nav-pills-danger > li a:hover,
.nav-pills-danger > li a:focus {
  color: #d2322d;
  background-color: #f2c0c3;
}

.nav-pills-danger > li.active > a,
.nav-pills-danger > li.active > a:hover,
.nav-pills-danger > li.active > a:active,
.nav-pills-danger > li.active > a:focus {
  background-color: #d2322d;
}

.nav-pills-info > li a:hover,
.nav-pills-info > li a:focus {
  color: #5a5c63;
  background-color: #f0fafc;
}

.nav-pills-info > li.active > a,
.nav-pills-info > li.active > a:hover,
.nav-pills-info > li.active > a:active,
.nav-pills-info > li.active > a:focus {
  background-color: #5a5c63;
}

.nav-pills-dark > li a:hover,
.nav-pills-dark > li a:focus {
  color: #5a5c63;
  background-color: #707070;
}

.nav-pills-dark > li.active > a,
.nav-pills-dark > li.active > a:hover,
.nav-pills-dark > li.active > a:active,
.nav-pills-dark > li.active > a:focus {
  background-color: #5a5c63;
}

/* Primary */
ul.nav-list.primary > li {
  margin: 0;
  padding: 0;
}

ul.nav-list.primary > li:last-child a {
  border-bottom: transparent !important;
}

ul.nav-list.primary > li a {
  transition: all 0.3s;
  background-position: 9px 16px;
  background-repeat: no-repeat;
  border-bottom: 1px solid #ededde;
  padding: 8px 20px;
}

/* Nestable */
.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 13px;
  line-height: 20px;
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list .dd-list {
  padding-left: 30px;
}

.dd-collapsed .dd-list {
  display: none;
}

.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
}

.dd-handle {
  display: block;
  height: 34px;
  margin: 5px 0;
  padding: 6px 10px;
  color: #333;
  text-decoration: none;
  font-weight: 600;
  border: 1px solid #ccc;
  background: #f6f6f6;
  border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-handle:hover {
  color: #ccc;
  background: #fff;
}

.dd-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 7px 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: bold;
}

.dd-item > button:before {
  content: "+";
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
}

.dd-item > button[data-action="collapse"]:before {
  content: "-";
}

.dd-placeholder {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: white;
  border: 1px dashed #ccc;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #f2fbff;
  border: 1px dashed #b6bcbf;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #e5e5e5;
  background-image: linear-gradient(
      45deg,
      white 25%,
      transparent 25%,
      transparent 75%,
      white 75%,
      white
    ),
    linear-gradient(
      45deg,
      white 25%,
      transparent 25%,
      transparent 75%,
      white 75%,
      white
    );
  background-size: 60px 60px;
  background-position:
    0 0,
    30px 30px;
}

.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999;
}

.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}

.dd-dragel .dd-handle {
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
}

/* dark */
html.dark .dd-handle {
  background: #282d36;
  border-color: #5a5c63;
  color: #808697;
}

html.dark .dd-handle:hover {
  background: #5a5c63;
}

/* Notifications */
.notifications {
  display: inline-block;
  list-style: none;
  margin: 4px -10px 0 0;
  padding: 0;
  vertical-align: middle;
}

.notifications > li {
  float: left;
  margin: 0 10px 0 0;
  position: relative;
}

.notifications > li .notification-icon {
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
  display: inline-block;
  height: 30px;
  position: relative;
  width: 30px;
  text-align: center;
  cursor: pointer;
}

.notifications > li .notification-icon.dropdown-toggle:after {
  content: none;
}

.notifications > li .notification-icon i {
  color: #777;
  font-size: 16.8px;
  font-size: 1.05rem;
  line-height: 30px;
  vertical-align: middle;
}

.notifications > li .notification-icon i.fa-tasks {
  line-height: 32px;
}

.notifications > li .notification-icon .badge {
  background: #d2312d;
  color: #fff;
  font-size: 10px;
  font-weight: normal;
  height: 16px;
  padding: 3px 5px 3px 5px;
  position: absolute;
  right: -8px;
  top: -3px;
  border-radius: 100%;
}

.notifications > li > a {
  border: none;
  display: inline-block;
}

.notifications .notification-menu {
  border: none;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  margin: 10px 0 0 0;
  padding: 0;
  right: -5px;
  width: 245px;
  transform: translate3d(0, 32px, 0) !important;
}

@media only screen and (min-width: 768px) {
  .notifications .notification-menu {
    left: auto !important;
  }
}

.notifications .notification-menu.large {
  width: 325px;
}

.notifications .notification-menu .notification-title {
  background: #ccc;
  border-radius: 3px 3px 0 0;
  color: #fff;
  font-size: 11.2px;
  font-size: 0.7rem;
  line-height: 24px;
  line-height: 1.5rem;
  padding: 8px 6px 8px 12px;
  text-transform: uppercase;
}

.notifications .notification-menu .notification-title .badge {
  font-size: 10.4px;
  font-size: 0.65rem;
  font-weight: 200;
  line-height: 14px;
  margin-left: 10px;
  margin-top: 2px;
  min-width: 35px;
}

.notifications .notification-menu .notification-title .badge-default {
  background: #006697;
  color: #fff;
}

.notifications .notification-menu .content {
  padding: 12px;
}

.notifications .notification-menu hr {
  background: #e6e6e6;
  height: 1px;
  margin: 12px 0;
}

.notifications .notification-menu .view-more {
  color: #acacac;
  font-size: 11.2px;
  font-size: 0.7rem;
  line-height: 17.6px;
  line-height: 1.1rem;
  text-transform: uppercase;
}

/* notification menu - pin */
.notifications .notification-menu:before,
.notifications .notification-icon:before {
  border-bottom: 6px solid #ccc;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  content: "";
  height: 0;
  margin-right: -3px;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.notifications .notification-menu:before {
  bottom: 100%;
  right: 16px;
}

.notifications .notification-icon:before {
  display: none;
  right: 11px;
  top: 35px;
  z-index: 9999;
}

/* notification menu - emails */
.notification-menu {
  color: #acacac;
}

.notification-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.notification-menu li {
  margin: 0 0 12px;
}

.notification-menu li:last-child {
  margin-bottom: 0;
}

.notification-menu a {
  display: block;
  text-decoration: none;
}

.notification-menu .image {
  float: left;
  margin: 0 10px 0 0;
}

.notification-menu .image i {
  border-radius: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  width: 35px;
}

.notification-menu .truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notification-menu .title {
  color: #000011;
  display: block;
  font-size: 12.8px;
  font-size: 0.8rem;
  line-height: 17.6px;
  line-height: 1.1rem;
  padding: 2px 0 0;
}

.notification-menu .message {
  color: #acacac;
  display: block;
  font-size: 11.2px;
  font-size: 0.7rem;
  line-height: 17.6px;
  line-height: 1.1rem;
  padding: 0;
}

html.dark .notifications > li .notification-icon,
html.header-dark .notifications > li .notification-icon {
  background: #282d36;
}

html.dark .notifications > li .notification-icon i,
html.header-dark .notifications > li .notification-icon i {
  color: #c3c3c3;
}

html.dark .notifications .notification-menu hr,
html.header-dark .notifications .notification-menu hr {
  background: #8d8f91;
}

/* Notifications Mobile */
@media only screen and (max-width: 767px) {
  .notifications {
    float: right;
    margin: 16px 8px 0 0;
  }

  .notifications > li {
    position: static;
  }

  .notifications > li.show .notification-icon:before {
    display: block;
  }

  .notifications > li .notification-menu {
    left: 15px;
    top: auto;
    width: 94vw !important;
    left: 50% !important;
    transform: translate3d(-50%, 109px, 0px) !important;
  }

  .notifications > li .notification-menu:before {
    display: none;
  }
}

/* Notification */
.ui-pnotify {
  right: 15px;
  top: 15px;
}

.ui-pnotify .notification {
  border-radius: 5px;
  box-shadow: none;
  padding: 15px 15px 15px 75px;
}

.ui-pnotify .notification .ui-pnotify-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 75px;
  text-align: center;
}

.ui-pnotify .notification .ui-pnotify-icon > span {
  border: 2px solid #fff;
  border-radius: 50%;
  display: inline-block;
  float: none;
  font-size: 35px;
  height: 50px;
  line-height: 48px;
  margin: 0;
  padding: 0;
  width: 50px;
  text-align: center;
}

.ui-pnotify .notification .ui-pnotify-title {
  font-size: 14px;
  letter-spacing: 0;
  color: inherit;
  font-weight: inherit;
}

.ui-pnotify .notification .ui-pnotify-text {
  font-size: 12px;
  line-height: 1.3em;
  line-height: 1.5;
}

.ui-pnotify .notification.notification-danger .ui-pnotify-icon > span.fa-times {
  line-height: 47px;
}

.ui-pnotify .ui-pnotify-shadow {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.4);
}

.ui-pnotify.ui-pnotify-no-icon .notification {
  padding-left: 15px;
}

.ui-pnotify .ui-pnotify-sharp {
  border-radius: 0;
}

body .ui-pnotify.icon-nb .notification .ui-pnotify-icon > span {
  border-color: transparent;
  border-radius: 0;
}

.ui-pnotify.stack-bar-top {
  right: 0;
  top: 0;
}

.ui-pnotify.stack-bar-top .notification {
  border-radius: 0;
}

.ui-pnotify.stack-bar-top .notification .ui-pnotify-icon > span {
  margin-top: 7px;
}

.ui-pnotify.stack-bar-bottom {
  bottom: 0;
  left: auto;
  margin-left: 15%;
  right: auto;
  top: auto;
}

.ui-pnotify.stack-bar-bottom .notification {
  border-radius: 0;
}

.ui-pnotify.stack-bar-bottom .notification .ui-pnotify-icon > span {
  margin-top: 9px;
}

.ui-pnotify.click-2-close {
  cursor: pointer;
}

/* Notification States */
.ui-pnotify .notification-primary {
  background: rgba(204, 204, 204, 0.95);
  color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify .notification-primary .ui-pnotify-icon > span {
  border-color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify.stack-bar-top .notification-primary,
.ui-pnotify.stack-bar-bottom .notification-primary {
  background: #ccc;
}

.ui-pnotify.notification-primary .notification,
.ui-pnotify.notification-primary .notification-primary {
  background: rgba(204, 204, 204, 0.95);
  color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify.notification-primary .notification .ui-pnotify-icon > span,
.ui-pnotify.notification-primary .notification-primary .ui-pnotify-icon > span {
  border-color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify.notification-primary.stack-bar-top .notification,
.ui-pnotify.notification-primary.stack-bar-top .notification-primary,
.ui-pnotify.notification-primary.stack-bar-bottom .notification,
.ui-pnotify.notification-primary.stack-bar-bottom .notification-primary {
  background: #ccc;
}

.ui-pnotify .notification-success {
  background: rgba(71, 164, 71, 0.95);
  color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify .notification-success .ui-pnotify-icon > span {
  border-color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify.stack-bar-top .notification-success,
.ui-pnotify.stack-bar-bottom .notification-success {
  background: #47a447;
}

.ui-pnotify.notification-success .notification,
.ui-pnotify.notification-success .notification-success {
  background: rgba(71, 164, 71, 0.95);
  color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify.notification-success .notification .ui-pnotify-icon > span,
.ui-pnotify.notification-success .notification-success .ui-pnotify-icon > span {
  border-color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify.notification-success.stack-bar-top .notification,
.ui-pnotify.notification-success.stack-bar-top .notification-success,
.ui-pnotify.notification-success.stack-bar-bottom .notification,
.ui-pnotify.notification-success.stack-bar-bottom .notification-success {
  background: #47a447;
}

.ui-pnotify .notification-warning {
  background: rgba(237, 156, 40, 0.95);
  color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify .notification-warning .ui-pnotify-icon > span {
  border-color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify.stack-bar-top .notification-warning,
.ui-pnotify.stack-bar-bottom .notification-warning {
  background: #ed9c28;
}

.ui-pnotify.notification-warning .notification,
.ui-pnotify.notification-warning .notification-warning {
  background: rgba(237, 156, 40, 0.95);
  color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify.notification-warning .notification .ui-pnotify-icon > span,
.ui-pnotify.notification-warning .notification-warning .ui-pnotify-icon > span {
  border-color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify.notification-warning.stack-bar-top .notification,
.ui-pnotify.notification-warning.stack-bar-top .notification-warning,
.ui-pnotify.notification-warning.stack-bar-bottom .notification,
.ui-pnotify.notification-warning.stack-bar-bottom .notification-warning {
  background: #ed9c28;
}

.ui-pnotify .notification-danger {
  background: rgba(210, 50, 45, 0.95);
  color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify .notification-danger .ui-pnotify-icon > span {
  border-color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify.stack-bar-top .notification-danger,
.ui-pnotify.stack-bar-bottom .notification-danger {
  background: #d2322d;
}

.ui-pnotify.notification-danger .notification,
.ui-pnotify.notification-danger .notification-danger {
  background: rgba(210, 50, 45, 0.95);
  color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify.notification-danger .notification .ui-pnotify-icon > span,
.ui-pnotify.notification-danger .notification-danger .ui-pnotify-icon > span {
  border-color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify.notification-danger.stack-bar-top .notification,
.ui-pnotify.notification-danger.stack-bar-top .notification-danger,
.ui-pnotify.notification-danger.stack-bar-bottom .notification,
.ui-pnotify.notification-danger.stack-bar-bottom .notification-danger {
  background: #d2322d;
}

.ui-pnotify .notification-info {
  background: rgba(91, 192, 222, 0.95);
  color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify .notification-info .ui-pnotify-icon > span {
  border-color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify.stack-bar-top .notification-info,
.ui-pnotify.stack-bar-bottom .notification-info {
  background: #5a5c63;
}

.ui-pnotify.notification-info .notification,
.ui-pnotify.notification-info .notification-info {
  background: rgba(91, 192, 222, 0.95);
  color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify.notification-info .notification .ui-pnotify-icon > span,
.ui-pnotify.notification-info .notification-info .ui-pnotify-icon > span {
  border-color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify.notification-info.stack-bar-top .notification,
.ui-pnotify.notification-info.stack-bar-top .notification-info,
.ui-pnotify.notification-info.stack-bar-bottom .notification,
.ui-pnotify.notification-info.stack-bar-bottom .notification-info {
  background: #5a5c63;
}

.ui-pnotify .notification-dark {
  background: rgba(23, 23, 23, 0.95);
  color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify .notification-dark .ui-pnotify-icon > span {
  border-color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify.stack-bar-top .notification-dark,
.ui-pnotify.stack-bar-bottom .notification-dark {
  background: #5a5c63;
}

.ui-pnotify.notification-dark .notification,
.ui-pnotify.notification-dark .notification-dark {
  background: rgba(23, 23, 23, 0.95);
  color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify.notification-dark .notification .ui-pnotify-icon > span,
.ui-pnotify.notification-dark .notification-dark .ui-pnotify-icon > span {
  border-color: rgba(255, 255, 255, 0.7);
}

.ui-pnotify.notification-dark.stack-bar-top .notification,
.ui-pnotify.notification-dark.stack-bar-top .notification-dark,
.ui-pnotify.notification-dark.stack-bar-bottom .notification,
.ui-pnotify.notification-dark.stack-bar-bottom .notification-dark {
  background: #5a5c63;
}

/* Notification Responsive */
@media only screen and (max-width: 767px) {
  html > body > .ui-pnotify {
    bottom: auto !important;
    left: 0 !important;
    margin: 0 !important;
    right: 0 !important;
    top: 60px !important;
    width: auto !important;
  }

  html > body > .ui-pnotify .notification {
    border-radius: 0 !important;
    height: auto !important;
    position: static !important;
    width: 100%;
  }

  html > body > .ui-pnotify .notification .ui-pnotify-title,
  html > body > .ui-pnotify .notification .ui-pnotify-text {
    padding-right: 35px !important;
  }

  html > body > .ui-pnotify .notification .ui-pnotify-sticker {
    display: none !important;
  }

  html > body > .ui-pnotify .notification .ui-pnotify-closer {
    display: block !important;
    font-size: 24px !important;
    visibility: visible !important;
  }
}

/* Pagination */
.pagination > li a {
  color: #ccc;
  box-shadow: none !important;
}

.pagination > li a:hover,
.pagination > li a:focus {
  color: #d9d9d9;
}

.pagination > li.active a,
.pagination > li.active a:hover,
.pagination > li.active a:focus,
.pagination > li.active span,
.pagination > li.active span:hover,
.pagination > li.active span:focus {
  background-color: #ccc;
  border-color: #ccc;
}

.pagination > li.active a {
  background-color: #ccc;
}

.pagination.pagination-modern > li a {
  border: 0;
  font-weight: 600;
  color: #444;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination.pagination-modern > li a[disabled] {
  pointer-events: none;
  background: transparent;
}

.pagination.pagination-modern > li a.prev,
.pagination.pagination-modern > li a.next {
  color: #999;
  background: transparent;
}

.pagination.pagination-modern.pagination-modern-spacing > li a[disabled] {
  width: 25px;
  letter-spacing: 2.5px;
  padding-bottom: 14px;
}

.pagination.pagination-modern.pagination-modern-spacing > li + li {
  margin-left: 10px;
}

/* Pager */
.pager {
  padding-left: 0;
  margin: 20px 0;
  list-style: none;
  text-align: center;
}

.pager li {
  display: inline;
}

.pager li > a,
.pager li > span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
}

.pager li > a:hover,
.pager li > a:focus {
  text-decoration: none;
  background-color: #eeeeee;
}

.pager .next > a,
.pager .next > span {
  float: right;
}

.pager .previous > a,
.pager .previous > span {
  float: left;
}

.pager .disabled > a {
  color: #777777;
  background-color: #fff;
  cursor: not-allowed;
}

.pager .disabled > a:hover,
.pager .disabled > a:focus {
  color: #777777;
  background-color: #fff;
  cursor: not-allowed;
}

.pager .disabled > span {
  color: #777777;
  background-color: #fff;
  cursor: not-allowed;
}

/* Popover */
.popover .arrow {
  background: none;
}

/* Portlets */
.portlet-handler {
  cursor: move;
}

.portlet-placeholder {
  margin-bottom: 15px;
  padding: 0;
  border: 1px dashed #dddddd;
  background: #fafafa;
  color: #444444;
}

/* Pricing Tables */
.pricing-table {
  margin: 0;
  padding: 0;
  text-align: center;
}

.pricing-table ul {
  list-style: none;
  margin: 20px 0 0 0;
  padding: 0;
}

.pricing-table li {
  border-top: 1px solid #ddd;
  padding: 10px 0;
  color: #777;
}

.pricing-table h3 {
  background-color: #eee;
  border-radius: 2px 2px 0 0;
  font-size: 1.3em;
  font-weight: normal;
  margin: -20px -20px 50px -20px;
  padding: 20px;
}

.pricing-table h3 .desc {
  font-size: 0.7em;
  margin-bottom: 0;
  padding-bottom: 0;
  color: #777;
  font-style: normal;
  text-transform: none;
  display: block;
}

.pricing-table h3 span {
  background: #fff;
  border: 5px solid #fff;
  border-radius: 100px;
  box-shadow:
    0 5px 20px #ddd inset,
    0 3px 0 #999 inset;
  color: #777;
  display: block;
  font:
    bold 25px / 100px Georgia,
    Serif;
  height: 100px;
  margin: 20px auto -65px;
  width: 100px;
}

.pricing-table .most-popular {
  border: 3px solid #ccc;
  box-shadow:
    11px 0 10px -10px rgba(0, 0, 0, 0.1),
    -11px 0 10px -10px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  top: -10px;
  z-index: 2;
}

.pricing-table .most-popular h3 {
  background-color: #666;
  color: #fff;
  padding-top: 30px;
}

.pricing-table .most-popular h3 .desc {
  color: #fff;
}

.pricing-table .plan-ribbon-wrapper {
  height: 88px;
  overflow: hidden;
  position: absolute;
  right: -3px;
  top: -5px;
  width: 85px;
}

.pricing-table .plan-ribbon {
  transform: rotate(45deg);
  background-image: linear-gradient(to top, #bfdc7a, #8ebf45);
  background-color: #bfdc7a;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  color: #333;
  font-size: 14px;
  left: -5px;
  padding: 7px 0;
  position: relative;
  text-align: center;
  text-shadow: rgba(255, 255, 255, 0.5) 0 1px 0;
  top: 15px;
  width: 120px;
}

.pricing-table .plan-ribbon:before {
  left: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  bottom: -3px;
  content: "";
  position: absolute;
}

.pricing-table .plan-ribbon:after {
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  bottom: -3px;
  content: "";
  position: absolute;
  right: 0;
}

.pricing-table [class*="col-lg-"] {
  padding-left: 0;
  padding-right: 0;
}

.pricing-table .plan {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #333;
  margin-bottom: 35px;
  margin-right: 0;
  padding: 20px;
  position: relative;
}

.pricing-table .btn {
  margin-top: 5px;
}

.pricing-table.pricing-table-sm h3 .desc {
  font-size: 0.7em;
}

.pricing-table.pricing-table-sm h3 span {
  border: 3px solid #fff;
  box-shadow:
    0 5px 20px #ddd inset,
    0 3px 0 #999 inset;
  font:
    bold 19px/65px Georgia,
    serif;
  height: 70px;
  margin: 10px auto -65px;
  width: 70px;
}

.pricing-table.pricing-table-sm .most-popular {
  padding-bottom: 30px;
}

.pricing-table.princig-table-flat .plan {
  padding-left: 0;
  padding-right: 0;
  margin: -1px;
}

.pricing-table.princig-table-flat .plan h3 {
  padding-left: 0;
  padding-right: 0;
  margin: -20px 0 0;
  background-color: #666;
  color: #fff;
  padding-top: 30px;
  text-shadow: 0 1px #555;
}

.pricing-table.princig-table-flat .plan h3 .desc {
  color: #fff;
}

.pricing-table.princig-table-flat .plan h3 span {
  border-radius: 0;
  border: 0;
  background: #666;
  color: #fff;
  font-family: "Poppins", Arial, sans-serif;
  font-size: 600;
  box-shadow: none;
  width: 100%;
  font-size: 1.5em;
  height: auto;
  line-height: 38px;
  margin: 0;
}

.pricing-table.princig-table-flat .plan ul {
  margin-top: 0;
  margin-bottom: -15px;
}

.pricing-table.princig-table-flat .plan ul li {
  padding: 8px 0;
}

.pricing-table.princig-table-flat .plan.most-popular ul li {
  padding: 10px 0;
}

.pricing-table.spaced [class*="col-lg-"] {
  padding-left: 2px;
  padding-right: 2px;
}

.pricing-table.no-borders .plan {
  border: 0 !important;
}

/* Pricing Tables Boxed */
html.boxed .pricing-table .plan,
html.boxed .pricing-table h3 {
  border-radius: 0;
}

/* Responsive */
@media (max-width: 767px) {
  /* Pricing Tables */
  .pricing-table {
    margin-left: 0;
  }

  .pricing-table .plan {
    margin-right: 0;
  }

  html.boxed .pricing-table {
    margin-left: 0;
  }
}

/* dark */
html.dark .pricing-table li {
  border-top-color: #5a5c63;
}

html.dark .pricing-table h3 {
  background-color: #5a5c63;
  text-shadow: none;
}

html.dark .pricing-table h3 span {
  background: #2e353e;
  border-color: #242830;
  box-shadow:
    0 5px 20px #242830 inset,
    0 3px 0 #282d36 inset;
  color: #ebebeb;
}

html.dark .pricing-table .most-popular {
  border-color: #282d36;
}

html.dark .pricing-table .most-popular h3 {
  background-color: #282d36;
  color: #808697;
  text-shadow: none;
}

html.dark .pricing-table .plan-ribbon {
  background-color: #282d36;
}

html.dark .pricing-table .plan {
  background: #282d36;
  border: 1px solid #282d36;
  color: #808697;
  text-shadow: none;
}

/* Progress Bars */
.progress-bar {
  background: #ccc;
  height: 19.2px;
  height: 1.2rem;
}

.progress .progress-bar {
  box-shadow: none;
  border-radius: 4px;
  height: auto;
}

.progress-bar-primary {
  background-color: #ccc;
}

.progress-bar.progress-without-number[aria-valuenow="1"],
.progress-bar.progress-without-number[aria-valuenow="2"] {
  min-width: 0;
}

.progress-bar.progress-bar-primary[aria-valuenow="0"] {
  background: transparent;
}

/* Progress bar default style */
.progress {
  background: #474453;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4) inset;
}

/* Progress bar light style */
.progress.light {
  background: #f6f7f8;
  background-image: linear-gradient(#f6f7f8, #f6f7f8 10%, #f5f5f5 11%);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}

html.dark .progress.light {
  background: #2e353e;
  background-image: linear-gradient(#2e353e, #2e353e 10%, #282d36 11%);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}

/* Progress bar roundness generic */
.progress-squared,
.progress-squared .progress-bar {
  border-radius: 0 !important;
}

/* Progress bar sizes */
.progress-xs {
  height: 7px;
}

.progress-xs,
.progress-xs .progress-bar {
  border-radius: 7px;
}

.progress-xs .progress-bar {
  direction: ltr !important;
  text-indent: -9999px;
}

.progress-xs.progress-half-rounded,
.progress-xs.progress-half-rounded .progress-bar {
  border-radius: 2px;
}

.progress-xs.progress-striped .progress-bar {
  background-size: 15px 15px;
}

.progress-sm {
  border-radius: 12px;
  height: 12px;
}

.progress-sm,
.progress-sm .progress-bar {
  border-radius: 12px;
}

.progress-sm .progress-bar {
  font-size: 10px;
  line-height: 12px;
}

.progress-sm.progress-half-rounded,
.progress-sm.progress-half-rounded .progress-bar {
  border-radius: 4px;
}

.progress-sm.progress-striped .progress-bar {
  background-size: 20px 20px;
}

.progress-md {
  border-radius: 14px;
  height: 14px;
}

.progress-md,
.progress-md .progress-bar {
  border-radius: 14px;
}

.progress-md .progress-bar {
  font-size: 11px;
  line-height: 14px;
}

.progress-md.progress-half-rounded,
.progress-md.progress-half-rounded .progress-bar {
  border-radius: 4px;
}

.progress-md.progress-striped .progress-bar {
  background-size: 25px 25px;
}

.progress-lg {
  border-radius: 16px;
  height: 16px;
}

.progress-lg,
.progress-lg .progress-bar {
  border-radius: 16px;
}

.progress-lg .progress-bar {
  line-height: 16px;
}

.progress-lg.progress-half-rounded,
.progress-lg.progress-half-rounded .progress-bar {
  border-radius: 5px;
}

.progress-lg.progress-striped .progress-bar {
  background-size: 30px 30px;
}

.progress-xl {
  border-radius: 18px;
  height: 18px;
}

.progress-xl,
.progress-xl .progress-bar {
  border-radius: 18px;
}

.progress-xl .progress-bar {
  line-height: 18px;
}

.progress-xl.progress-half-rounded,
.progress-xl.progress-half-rounded .progress-bar {
  border-radius: 6px;
}

.progress-xl.progress-striped .progress-bar {
  background-size: 35px 35px;
}

/* Progress bar states */
.progress .progress-bar-primary {
  background-color: #ccc;
}

.progress .progress-bar-success {
  background-color: #47a447;
}

.progress .progress-bar-warning {
  background-color: #ed9c28;
}

.progress .progress-bar-danger {
  background-color: #d2322d;
}

.progress .progress-bar-info {
  background-color: #5a5c63;
}

.progress .progress-bar-dark {
  background-color: #5a5c63;
}

.circular-bar {
  margin-bottom: 25px;
}

.circular-bar .circular-bar-chart {
  position: relative;
}

.circular-bar strong {
  display: block;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  position: absolute;
  top: 35%;
  width: 80%;
  left: 10%;
  text-align: center;
}

.circular-bar label {
  display: block;
  font-weight: 100;
  font-size: 17px;
  line-height: 20px;
  position: absolute;
  top: 50%;
  width: 80%;
  left: 10%;
  text-align: center;
}

/* Scroll to Top */
html .scroll-to-top {
  transition: all 0.3s;
  background: #282d36;
  border-radius: 7px 7px 0 0;
  bottom: 0px;
  color: #fff;
  display: block;
  height: 9px;
  opacity: 0;
  padding: 5px 10px 25px;
  position: fixed;
  right: 10px;
  text-align: center;
  text-decoration: none;
  min-width: 39px;
  z-index: 1040;
}

html .scroll-to-top:hover {
  opacity: 1;
}

html .scroll-to-top.visible {
  opacity: 0.75;
}

html .scroll-to-top span {
  display: inline-block;
  padding: 0 5px;
}

html.ie11 .scroll-to-top {
  right: 25px;
}

/* Responsive */
@media (max-width: 991px) {
  html .scroll-to-top.hidden-mobile {
    display: none !important;
  }
}

/* Scrollable */
.scrollable {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.scrollable .scrollable-content {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 37px 0 0;
  overflow-x: hidden;
  overflow-y: scroll;
  outline: none;
}

.scrollable .scrollable-content::-webkit-scrollbar {
  visibility: hidden;
}

.scrollable .scrollable-pane {
  bottom: 0;
  opacity: 0.01;
  position: absolute;
  right: 5px;
  top: 0;
  transition: all 0.2s ease 0s;
  width: 4px;
}

.scrollable .scrollable-slider {
  border-radius: 5px;
  background: none repeat scroll 0 0 #ccc;
  margin: 0;
  position: relative;
  transition: opacity 0.2s ease 0s;
  opacity: 0;
}

.scrollable.scrollable-padding .scrollable-content {
  padding: 10px 24px 10px 10px;
}

.scrollable:hover .scrollable-slider,
.scrollable.visible-slider .scrollable-slider {
  opacity: 1;
}

/* Search Input */
.search-style-1 {
  width: 190px;
}

.search-style-1 .input-group > input {
  border-radius: 35px 0px 0px 35px;
  padding: 8px 10px 8px 20px;
  border-width: 0;
  background: #f4f4f4;
}

.search-style-1 .input-group > span button {
  display: flex;
  align-items: center;
  font-size: 17.6px;
  font-size: 1.1rem;
  border-radius: 0px 35px 35px 0px;
  padding: 8px 15px 8px 10px;
  border-width: 0;
  background: #f4f4f4;
}

.search-style-1 .input-group > span button i[class*="fa-"] {
  font-size: 12.8px;
  font-size: 0.8rem;
}

.search-style-1.search-style-1-lg {
  width: 240px;
}

.search-style-1.search-style-1-lg .input-group > input {
  line-height: 2.3;
}

.search-style-1.search-style-1-light .input-group > input {
  background: #fff;
}

.search-style-1.search-style-1-light .input-group > input:focus {
  box-shadow: none;
}

.search-style-1.search-style-1-light .input-group > span button {
  color: #777;
  background: #fff;
}

.search-style-1.search-style-1-light
  .input-group
  > span
  button::-webkit-input-placeholder {
  color: #777;
}

.search-style-1.search-style-1-light
  .input-group
  > span
  button::-moz-placeholder {
  color: #777;
}

.search-style-1.search-style-1-light
  .input-group
  > span
  button:-ms-input-placeholder {
  color: #777;
}

.search-style-1.search-style-1-light
  .input-group
  > span
  button::-ms-input-placeholder {
  color: #777;
}

.search-style-1.search-style-1-light .input-group > span button::placeholder {
  color: #777;
}

.search-style-1.search-style-1-light
  .input-group
  > span
  button:-ms-input-placeholder {
  color: #777;
}

.search-style-1.search-style-1-light
  .input-group
  > span
  button::-ms-input-placeholder {
  color: #777;
}

/* Select 2 */
html.dark .select2-container--bootstrap .select2-selection,
html.dark .select2-container--bootstrap .select2-dropdown,
html.dark
  .select2-container--bootstrap
  .select2-choices
  .select2-search-field
  input,
html.dark .select2-container--bootstrap .select2-choice,
html.dark .select2-container--bootstrap .select2-choices {
  color: #eee;
  background-color: #282d36;
  border-color: #282d36;
}

html.dark
  .select2-container--bootstrap
  .select2-selection--single
  .select2-selection__rendered {
  color: #eee;
}

html.dark
  .select2-container--bootstrap
  .select2-results__option[aria-selected="true"],
html.dark
  .select2-container--bootstrap
  .select2-search--dropdown
  .select2-search__field {
  color: #eee;
  background-color: #2e353e;
  border-color: #2e353e;
}

/* Sidebar Widgets */
.sidebar-right .sidebar-widget {
  margin: 0;
}

.sidebar-widget {
  margin: 0 30px 0 25px;
}

.sidebar-widget .widget-header {
  position: relative;
  margin: 0;
}

.sidebar-widget .widget-header h6 {
  font-size: 11.2px;
  font-size: 0.7rem;
  color: #465162;
  font-weight: 600;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

.sidebar-widget .widget-header .btn-widget-act {
  position: relative;
  top: -2px;
  border-color: #0a0a0a;
  background-color: #0a0a0a;
  border-color: #0a0a0a black black;
}

.sidebar-widget .widget-header .btn-widget-act:hover {
  border-color: #121212;
  background-color: #121212;
}

.sidebar-widget .widget-header .btn-widget-act:active,
.sidebar-widget .widget-header .btn-widget-act:focus {
  border-color: black;
  background-color: black;
}

.sidebar-widget .widget-header .btn-widget-act.dropdown-toggle {
  border-left-color: black;
}

.sidebar-widget .widget-header .widget-toggle {
  font-size: 27.2px;
  font-size: 1.7rem;
  line-height: 20.8px;
  line-height: 1.3rem;
  color: #465162;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  text-align: center;
  transform: rotate(45deg);
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: linear;
}

.sidebar-widget .widget-content {
  padding: 15px 0 0;
}

.sidebar-widget.widget-collapsed .widget-header .widget-toggle {
  transform: none;
}

.sidebar-widget.widget-collapsed .widget-content {
  display: none;
}

.sidebar-widget.widget-tasks ul li {
  position: relative;
}

.sidebar-widget.widget-tasks ul li a {
  color: #5a5c63;
  display: block;
  margin: 0 -25px 0 -30px;
  padding: 10px 0 10px 55px;
  font-size: 12.8px;
  font-size: 0.8rem;
}

.sidebar-widget.widget-tasks ul li a:hover {
  background: #d5d5d8;
  text-decoration: none;
  color: #db052c;
}

.sidebar-widget.widget-tasks ul li:before {
  border: 5px solid #ccc;
  border-radius: 100px;
  content: "";
  display: inline-block;
  left: 0;
  margin: -5px 0 0;
  position: absolute;
  top: 50%;
  z-index: 999;
}

.sidebar-widget.widget-tasks ul li:nth-child(6n + 1):before {
  border-color: #db052c;
}

.sidebar-widget.widget-tasks ul li:nth-child(6n + 2):before {
  border-color: #0090d9;
}

.sidebar-widget.widget-tasks ul li:nth-child(6n + 3):before {
  border-color: #4dd79c;
}

.sidebar-widget.widget-tasks ul li:nth-child(6n + 4):before {
  border-color: #d9a300;
}

.sidebar-widget.widget-tasks ul li:nth-child(6n + 5):before {
  border-color: #c926ff;
}

.sidebar-widget.widget-tasks ul li:nth-child(6n + 6):before {
  border-color: #ffff26;
}

.sidebar-widget.widget-stats ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar-widget.widget-stats .stats-title {
  color: #a6a6a6;
  font-size: 11.2px;
  font-size: 0.7rem;
}

.sidebar-widget.widget-stats .stats-complete {
  float: right;
  font-size: 11.2px;
  font-size: 0.7rem;
  color: #666;
}

.sidebar-widget.widget-stats .progress {
  height: 7px;
  background: #474453;
  box-shadow: 0 1px 0 #585564 inset;
  margin-bottom: 25px;
}

.sidebar-widget.widget-calendar {
  margin: 0 0 25px;
}

.sidebar-widget.widget-calendar .datepicker {
  background: transparent;
}

.sidebar-widget.widget-calendar ul {
  list-style: none;
  margin: 0;
  padding: 20px 0;
  border-top: 1px solid #000;
}

.sidebar-widget.widget-calendar ul time {
  display: block;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.3em;
  font-weight: 600;
  color: #777;
  margin-bottom: 0.1em;
}

.sidebar-widget.widget-calendar ul span {
  font-size: 13.6px;
  font-size: 0.85rem;
  line-height: 1.3em;
  color: #fff;
}

.sidebar-widget.widget-friends ul {
  list-style: none;
  margin: 0;
  padding: 10px 2px;
}

.sidebar-widget.widget-friends ul li {
  padding: 10px 0;
}

.sidebar-widget.widget-friends ul li .profile-info,
.sidebar-widget.widget-friends ul li .profile-picture {
  display: inline-block;
  vertical-align: middle;
}

.sidebar-widget.widget-friends ul li .profile-picture {
  position: relative;
  width: 35px;
  margin: 0;
}

.sidebar-widget.widget-friends ul li .profile-picture img {
  width: 100%;
  height: auto;
}

.sidebar-widget.widget-friends ul li .profile-info {
  margin: 0 35px 0 10px;
}

.sidebar-widget.widget-friends ul li span.name {
  display: block;
  color: #fff;
  font-weight: 600;
  vertical-align: top;
  line-height: 1.3em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0.2em;
}

.sidebar-widget.widget-friends ul li span.title {
  display: block;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.3em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sidebar-widget.widget-friends ul li.status-online .profile-picture:after,
.sidebar-widget.widget-friends ul li.status-offline .profile-picture:after {
  display: none;
  position: absolute;
  top: -4px;
  right: -4px;
  content: "";
  width: 15px;
  height: 15px;
  border: 2px solid #000;
  border-radius: 50%;
}

.sidebar-widget.widget-friends ul li.status-online .profile-picture:after {
  display: block;
  background-color: #1aae88;
}

.sidebar-widget.widget-friends ul li.status-offline .profile-picture:after {
  display: block;
  background-color: #d2312d;
}

/* Sidebar Light */
html.sidebar-light:not(.dark) .sidebar-widget .widget-header .btn-widget-act {
  border-color: #f2f2f2;
  background-color: #f2f2f2;
  border-color: #e6e6e6;
  color: #777;
  text-shadow: none;
}

html.sidebar-light:not(.dark)
  .sidebar-widget
  .widget-header
  .btn-widget-act:hover {
  border: 1px solid #d2d2d2 !important;
  background-color: #fafafa;
}

html.sidebar-light:not(.dark)
  .sidebar-widget
  .widget-header
  .btn-widget-act:active,
html.sidebar-light:not(.dark)
  .sidebar-widget
  .widget-header
  .btn-widget-act:focus {
  border: 1px solid #d2d2d2 !important;
  background-color: #e6e6e6;
}

html.sidebar-light:not(.dark)
  .sidebar-widget
  .widget-header
  .btn-widget-act.dropdown-toggle {
  border-left-color: #e6e6e6;
}

html.sidebar-light:not(.dark) .sidebar-widget.widget-tasks ul li a {
  color: #777;
}

html.sidebar-light:not(.dark) .sidebar-widget.widget-tasks ul li a:hover {
  background: #fafafa;
}

html.sidebar-light:not(.dark) .sidebar-widget.widget-stats .stats-title {
  color: #777;
}

html.sidebar-light:not(.dark) .sidebar-widget.widget-stats .progress {
  background: #d8d8d8;
  box-shadow: 0 1px 0 #bfbfbf inset;
}

html.sidebar-light:not(.dark) .sidebar-widget.widget-calendar ul {
  border-top: 1px solid #ddd;
}

html.sidebar-light:not(.dark) .sidebar-widget.widget-calendar ul time {
  color: #777;
}

html.sidebar-light:not(.dark) .sidebar-widget.widget-calendar ul span {
  color: #777;
}

html.sidebar-light:not(.dark) .sidebar-widget.widget-friends ul li span.name {
  color: #777;
}

html.sidebar-light:not(.dark)
  .sidebar-widget.widget-friends
  ul
  li.status-online
  .profile-picture:after {
  background-color: #1aae88;
}

html.sidebar-light:not(.dark)
  .sidebar-widget.widget-friends
  ul
  li.status-offline
  .profile-picture:after {
  background-color: #d2312d;
}

/*	Slider - */
.ui-slider.ui-widget-content {
  background: #ebebeb;
  border: none;
}

/*	Sliders - Horizontal */
.ui-slider-horizontal {
  height: 6px;
}

.ui-slider-horizontal .ui-slider-handle {
  top: -7px;
}

/*	Sliders - Vertical */
.ui-slider-vertical {
  display: inline-block;
  width: 6px;
}

.ui-slider-vertical .ui-slider-handle {
  left: -7px;
}

/*	Sliders - Modern Style */
.slider-modern.ui-slider {
  height: 3px;
  margin-bottom: 15px;
}

.slider-modern.ui-slider .ui-slider-handle {
  top: -4px;
  width: 10px;
  height: 10px;
  border: none;
}

.slider-modern.ui-slider .ui-slider-range {
  height: 3px;
}

/*	Sliders - UI Handle */
.ui-slider .ui-slider-handle {
  background: #d9d9d9;
  border: 6px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4);
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.ui-slider .ui-slider-handle:hover {
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.3);
}

/*	Sliders - UI Handle (Fix Windows Mobile Devices) */
.ui-slider .ui-slider-handle {
  touch-action: none;
}

/*	Sliders - Range */
.ui-slider .ui-slider-range {
  background: #d9d9d9;
}

/*	Sliders - Contextual */
.slider-primary .ui-slider-range,
.slider-primary .ui-slider-handle {
  background: #ccc;
}

.slider-gradient.slider-primary .ui-slider-range,
.slider-gradient.slider-primary .ui-slider-handle {
  background-image: linear-gradient(#e6e6e6 0, #ccc 50%, #b3b3b3 100%);
}

.slider-gradient.ui-slider-vertical.slider-primary .ui-slider-range,
.slider-gradient.ui-slider-vertical.slider-primary .ui-slider-handle {
  background-image: linear-gradient(
    to right,
    #e6e6e6 0,
    #ccc 50%,
    #b3b3b3 100%
  );
}

.slider-success .ui-slider-range,
.slider-success .ui-slider-handle {
  background: #47a447;
}

.slider-gradient.slider-success .ui-slider-range,
.slider-gradient.slider-success .ui-slider-handle {
  background-image: linear-gradient(#63bb63 0, #47a447 50%, #388038 100%);
}

.slider-gradient.ui-slider-vertical.slider-success .ui-slider-range,
.slider-gradient.ui-slider-vertical.slider-success .ui-slider-handle {
  background-image: linear-gradient(
    to right,
    #63bb63 0,
    #47a447 50%,
    #388038 100%
  );
}

.slider-warning .ui-slider-range,
.slider-warning .ui-slider-handle {
  background: #ed9c28;
}

.slider-gradient.slider-warning .ui-slider-range,
.slider-gradient.slider-warning .ui-slider-handle {
  background-image: linear-gradient(#f1b257 0, #ed9c28 50%, #d18211 100%);
}

.slider-gradient.ui-slider-vertical.slider-warning .ui-slider-range,
.slider-gradient.ui-slider-vertical.slider-warning .ui-slider-handle {
  background-image: linear-gradient(
    to right,
    #f1b257 0,
    #ed9c28 50%,
    #d18211 100%
  );
}

.slider-danger .ui-slider-range,
.slider-danger .ui-slider-handle {
  background: #d2322d;
}

.slider-gradient.slider-danger .ui-slider-range,
.slider-gradient.slider-danger .ui-slider-handle {
  background-image: linear-gradient(#db5b57 0, #d2322d 50%, #a82824 100%);
}

.slider-gradient.ui-slider-vertical.slider-danger .ui-slider-range,
.slider-gradient.ui-slider-vertical.slider-danger .ui-slider-handle {
  background-image: linear-gradient(
    to right,
    #db5b57 0,
    #d2322d 50%,
    #a82824 100%
  );
}

.slider-info .ui-slider-range,
.slider-info .ui-slider-handle {
  background: #5a5c63;
}

.slider-gradient.slider-info .ui-slider-range,
.slider-gradient.slider-info .ui-slider-handle {
  background-image: linear-gradient(#85d0e7 0, #5a5c63 50%, #31b0d5 100%);
}

.slider-gradient.ui-slider-vertical.slider-info .ui-slider-range,
.slider-gradient.ui-slider-vertical.slider-info .ui-slider-handle {
  background-image: linear-gradient(
    to right,
    #85d0e7 0,
    #5a5c63 50%,
    #31b0d5 100%
  );
}

.slider-dark .ui-slider-range,
.slider-dark .ui-slider-handle {
  background: #5a5c63;
}

.slider-gradient.slider-dark .ui-slider-range,
.slider-gradient.slider-dark .ui-slider-handle {
  background-image: linear-gradient(#313131 0, #5a5c63 50%, black 100%);
}

.slider-gradient.ui-slider-vertical.slider-dark .ui-slider-range,
.slider-gradient.ui-slider-vertical.slider-dark .ui-slider-handle {
  background-image: linear-gradient(
    to right,
    #313131 0,
    #5a5c63 50%,
    black 100%
  );
}

html.dark .ui-slider.ui-widget-content {
  background: #5a5c63;
}

/* Spinners */
.spinner-buttons.btn-group-vertical .btn {
  height: 18px;
  margin: 0 0 0 -1px;
  padding-left: 6px;
  padding-right: 6px;
  text-align: center;
  width: 22px;
  line-height: 14px;
}

.spinner-buttons.btn-group-vertical .btn i {
  margin-top: -2px;
}

.spinner-buttons.btn-group-vertical .btn:first-child {
  border-radius: 0 4px 0 0 !important;
}

.spinner-buttons.btn-group-vertical .btn:last-child {
  border-radius: 0 0 4px !important;
  margin-top: -1px;
  height: 17px;
}

/* Summernote */
.note-editor {
  border-radius: 4px;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
}

.note-editor,
.note-editor.note-frame {
  border: 1px solid #ddd;
  margin-top: 0 !important;
}

.note-editor.active {
  border-color: #66afe9;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.note-editor .note-title {
  padding-top: 0 !important;
}

.note-editor .btn-light {
  border-color: #ccc !important;
}

.note-editor .note-toolbar {
  background-color: #f5f5f5;
  border-bottom: 1px dashed #ddd;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 4px 4px 0 0;
  padding: 3px 10px 7px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.note-editor .note-toolbar i {
  margin: 0;
}

.note-editor .note-toolbar i:before {
  margin: 0;
}

.note-editor .note-toolbar .btn-group {
  float: none !important;
}

.note-editor
  .note-toolbar
  .btn-group
  > .btn-group:last-child
  > .btn:first-child {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.note-editor
  .note-toolbar
  .btn-group
  > .btn-group:first-child
  > .btn:last-child,
.note-editor
  .note-toolbar
  .btn-group
  > .btn-group:first-child
  > .dropdown-toggle {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

@media only screen and (max-width: 767px) {
  .note-editor .note-toolbar {
    text-align: center;
  }
}

.note-editor .note-editable {
  clear: both;
  background: #fff;
  border: none;
  border-radius: 0 0 4px 4px;
  font-family: Arial, Helvetica, Sans-serif;
}

.note-editor .note-statusbar {
  background: #fff;
  border-radius: 0 0 4px 4px;
}

.note-editor .note-statusbar .note-resizebar {
  border-color: #ddd;
  display: block;
}

html.dark .note-editor {
  border-color: #282d36;
  color: #eee;
}

html.dark .note-editor .note-toolbar,
html.dark .note-editor .note-statusbar {
  background: #242830;
  border-color: #e6e6ea;
}

html.dark .note-editor .note-toolbar .note-btn,
html.dark .note-editor .note-statusbar .note-btn {
  background: #282d36;
  border-color: #2e353e !important;
}

html.dark .note-editor .note-editable {
  background: #282d36;
  border-color: #e6e6ea;
}

html.dark .note-editor .note-statusbar .note-resizebar {
  border-color: #e6e6ea;
}

html.dark .note-editor .note-statusbar .note-resizebar .note-icon-bar {
  border-color: #444;
}

html.dark .note-editor .note-editing-area .note-editable {
  color: #eee;
  background: #282d36;
}

html.dark .note-editor .caret {
  border-color: #fff transparent transparent;
}

/* Tables - Basic */
.table {
  width: 100%;
}

.table .table {
  background: transparent;
}

/* Tables - Responsive */
.table-responsive > .table-bordered {
  border: 1px solid #dee2e6;
}

/* Bootstrap uses important, we need to force it here */
.table.mb-0 {
  margin-bottom: 0 !important;
}

/* In case you dont want a border in some row */
.table .b-top-0 td {
  border-top: none;
}

/* Tables - Actions */
.table .actions,
.table .actions-hover {
  vertical-align: middle;
}

.table .actions a,
.table .actions-hover a {
  display: inline-block;
  margin-right: 5px;
  color: #666;
}

.table .actions a:last-child,
.table .actions-hover a:last-child {
  margin-right: 0;
}

.table .actions a:hover,
.table .actions-hover a:hover {
  color: #333;
}

.table .actions-hover a {
  opacity: 0;
}

.table tr:hover .actions-hover a {
  opacity: 1;
}

.table .actions-fade a {
  transition: all 0.2s linear;
}

.table.table-no-more thead tr {
  color: #5a5b63;
}

.table.table-no-more thead tr {
  color: #5a5b63;
}

/* Tables - No More Tables technique (991px is the bootstrap SM max-width) */
@media only screen and (max-width: 991px) {
  .table.table-no-more,
  .table.table-no-more thead,
  .table.table-no-more tbody,
  .table.table-no-more tr,
  .table.table-no-more th,
  .table.table-no-more td {
    display: block;
  }

  .table.table-no-more thead tr {
    left: -9999px;
    position: absolute;
    top: -9999px;
  }

  .table.table-no-more tr {
    border-bottom: 1px solid #ddd;
  }

  .table.table-no-more td {
    border: none;
    position: relative;
    padding-left: 50%;
    text-align: left;
    white-space: normal;
  }

  .table.table-no-more td:before {
    content: attr(data-title);
    font-weight: bold;
    left: 6px;
    padding-right: 10px;
    position: absolute;
    text-align: left;
    top: 8px;
    white-space: nowrap;
    width: 45%;
  }

  .table.table-no-more.table-bordered td {
    border-bottom: 1px solid #dedede;
  }

  .table.table-no-more.table-sm td:before {
    top: 5px;
  }
}

.table.table-ecommerce-simple {
  display: table;
}

.table.table-ecommerce-simple thead th {
  border-bottom-width: 1px;
  border-top: 0;
}

.table.table-ecommerce-simple tbody td {
  vertical-align: middle;
}

.table.table-ecommerce-simple tbody tr:first-child td {
  border-top: 0;
}

.table.table-ecommerce-simple.table-striped > tbody > tr:nth-child(2n + 1) > td,
.table.table-ecommerce-simple.table-striped
  > tbody
  > tr:nth-child(2n + 1)
  > th {
  background-color: #f9f9f9;
}

.table.table-ecommerce-simple.table-striped th,
.table.table-ecommerce-simple.table-striped td {
  border-top: 0;
}

.table.table-ecommerce-simple.table-ecomerce-simple-border-bottom {
  border-bottom: 1px solid #dee2e6;
}

/* Dark - Tables */
html.dark .table > thead > tr > th,
html.dark .table > tbody > tr > th,
html.dark .table > tfoot > tr > th,
html.dark .table > thead > tr > td,
html.dark .table > tbody > tr > td,
html.dark .table > tfoot > tr > td,
html.dark .table-bordered {
  border-color: #262b33;
  color: #808697;
}

html.dark .table-striped > tbody > tr:nth-child(2n + 1) > td,
html.dark .table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: #282d36;
}

html.dark .table-hover > tbody > tr:hover > td,
html.dark .table-hover > tbody > tr:hover > th {
  background-color: #272c34;
}

html.dark .table .actions a,
html.dark .table .actions-hover a {
  color: #808697;
}

@media screen and (max-width: 991px) {
  html.dark .table-responsive {
    border-color: #262b33;
  }
}

@media only screen and (max-width: 991px) {
  html.dark .table.table-no-more tr,
  html.dark .table.table-no-more.table-bordered td {
    border-bottom-color: #262b33;
  }
}

/* Tables - States */
.table > thead > tr > td.primary,
.table > tbody > tr > td.primary,
.table > tfoot > tr > td.primary,
.table > thead > tr > th.primary,
.table > tbody > tr > th.primary,
.table > tfoot > tr > th.primary,
.table > thead > tr.primary > td,
.table > tbody > tr.primary > td,
.table > tfoot > tr.primary > td,
.table > thead > tr.primary > th,
.table > tbody > tr.primary > th,
.table > tfoot > tr.primary > th {
  color: #fff;
  background-color: #ccc !important;
}

.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  color: #fff;
  background-color: #47a447 !important;
}

.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
  color: #fff;
  background-color: #ed9c28 !important;
}

.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  color: #fff;
  background-color: #d2322d !important;
}

.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  color: #fff;
  background-color: #5a5c63 !important;
}

.table > thead > tr > td.dark,
.table > tbody > tr > td.dark,
.table > tfoot > tr > td.dark,
.table > thead > tr > th.dark,
.table > tbody > tr > th.dark,
.table > tfoot > tr > th.dark,
.table > thead > tr.dark > td,
.table > tbody > tr.dark > td,
.table > tfoot > tr.dark > td,
.table > thead > tr.dark > th,
.table > tbody > tr.dark > th,
.table > tfoot > tr.dark > th {
  color: #fff;
  background-color: #5a5c63 !important;
}

.table > thead > tr > td.dark,
.table > tbody > tr > td.dark,
.table > tfoot > tr > td.dark,
.table > thead > tr > th.dark,
.table > tbody > tr > th.dark,
.table > tfoot > tr > th.dark,
.table > thead > tr.dark > td,
.table > tbody > tr.dark > td,
.table > tfoot > tr.dark > td,
.table > thead > tr.dark > th,
.table > tbody > tr.dark > th,
.table > tfoot > tr.dark > th {
  background-color: #4a4a4a;
  color: #fff;
}

/* Tabs */
.tabs {
  border-radius: 4px;
  margin-bottom: 20px;
}

.nav-tabs {
  margin: 0;
  padding: 0;
  border-bottom-color: #eee;
}

.nav-tabs li:last-child .nav-link {
  margin-right: 0;
}

.nav-tabs li .nav-link {
  border-radius: 5px 5px 0 0;
  font-size: 14px;
  margin-right: 1px;
}

.nav-tabs li .nav-link,
.nav-tabs li .nav-link:hover {
  background: #f4f4f4;
  border-bottom: none;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-top: 3px solid #eee;
  color: #db052c;
}

.nav-tabs li .nav-link:hover {
  border-bottom-color: transparent;
  border-top: 3px solid #ccc;
  box-shadow: none;
  color: #db052c;
}

.nav-tabs li .nav-link:active,
.nav-tabs li .nav-link:focus {
  border-bottom: 0;
}

.nav-tabs li .nav-link .badge {
  border-radius: 100%;
  padding: 6.4px 8.8px;
  padding: 0.4rem 0.55rem;
  margin-right: 5px;
}

.nav-tabs li.active .nav-link,
.nav-tabs li.active .nav-link:hover,
.nav-tabs li.active .nav-link:focus {
  background: #fff;
  border-left-color: #eee;
  border-right-color: #eee;
  border-top: 3px solid #ccc;
  color: #db052c;
}

.tab-content {
  border-radius: 0 0 4px 4px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
  background-color: #fff;
  border: 1px solid #eee;
  border-top: 0;
  padding: 15px;
}

/* Bottom Tabs */
.tabs.tabs-bottom {
  margin: 0 0 20px 0;
  padding: 0;
}

.tabs.tabs-bottom .tab-content {
  border-radius: 4px 4px 0 0;
  border-bottom: 0;
  border-top: 1px solid #eee;
}

.tabs.tabs-bottom .nav-tabs {
  border-bottom: none;
  border-top: 1px solid #eee;
}

.tabs.tabs-bottom .nav-tabs li {
  margin-bottom: 0;
  margin-top: -1px;
}

.tabs.tabs-bottom .nav-tabs li:last-child .nav-link {
  margin-right: 0;
}

.tabs.tabs-bottom .nav-tabs li .nav-link {
  border-radius: 0 0 5px 5px;
  font-size: 14px;
  margin-right: 1px;
  border-top-color: transparent !important;
}

.tabs.tabs-bottom .nav-tabs li .nav-link,
.tabs.tabs-bottom .nav-tabs li .nav-link:hover {
  border-bottom: 3px solid #eee;
  border-top: 1px solid #eee;
  color: #db052c;
}

.tabs.tabs-bottom .nav-tabs li .nav-link:hover {
  border-bottom: 3px solid #ccc;
  border-top: 1px solid #eee;
  color: #db052c;
}

.tabs.tabs-bottom .nav-tabs li.active .nav-link,
.tabs.tabs-bottom .nav-tabs li.active .nav-link:hover,
.tabs.tabs-bottom .nav-tabs li.active .nav-link:focus {
  border-bottom: 3px solid #ccc;
  border-top-color: transparent !important;
  color: #db052c;
}

/* Vertical */
.tabs-vertical {
  display: table;
  width: 100%;
  padding: 0;
  border-top: 1px solid #eee;
}

.tabs-vertical .tab-content {
  display: table-cell;
  vertical-align: top;
}

.tabs-vertical .nav-tabs {
  border-bottom: none;
  display: table-cell;
  height: 100%;
  float: none;
  padding: 0;
  vertical-align: top;
  width: 25%;
}

.tabs-vertical .nav-tabs > li {
  display: block;
}

.tabs-vertical .nav-tabs > li .nav-link {
  border-radius: 0;
  display: block;
  padding-top: 10px;
}

.tabs-vertical .nav-tabs > li .nav-link,
.tabs-vertical .nav-tabs > li .nav-link:hover,
.tabs-vertical .nav-tabs > li .nav-link:focus {
  border-bottom: none;
  border-top: none;
  color: #db052c;
}

.tabs-vertical .nav-tabs > li.active .nav-link,
.tabs-vertical .nav-tabs > li.active .nav-link:hover,
.tabs-vertical .nav-tabs > li.active .nav-link:focus {
  border-top: none;
  color: #db052c;
}

/* Vertical - Left Side */
.tabs-left {
  padding: 0;
}

.tabs-left .tab-content {
  border-radius: 0 5px 5px 5px;
  border-left: none;
}

.tabs-left .nav-tabs > li {
  margin-right: -1px;
}

.tabs-left .nav-tabs > li:first-child .nav-link {
  border-radius: 5px 0 0 0;
}

.tabs-left .nav-tabs > li:last-child .nav-link {
  border-radius: 0 0 0 5px;
  border-bottom: 1px solid #eee;
}

.tabs-left .nav-tabs > li .nav-link {
  border-right: 1px solid #eee;
  border-left: 3px solid #eee;
  margin-right: 1px;
  margin-left: -3px;
}

.tabs-left .nav-tabs > li .nav-link:hover {
  border-left-color: #ccc;
  color: #db052c;
}

.tabs-left .nav-tabs > li.active .nav-link,
.tabs-left .nav-tabs > li.active .nav-link:hover,
.tabs-left .nav-tabs > li.active .nav-link:focus {
  border-left: 3px solid #ccc;
  border-right-color: #fff;
  color: #db052c;
}

/* Vertical - Right Side */
.tabs-right {
  padding: 0;
}

.tabs-right .tab-content {
  border-radius: 5px 0 5px 5px;
  border-right: none;
}

.tabs-right .nav-tabs > li {
  margin-left: -1px;
}

.tabs-right .nav-tabs > li:first-child .nav-link {
  border-radius: 0 5px 0 0;
}

.tabs-right .nav-tabs > li:last-child .nav-link {
  border-radius: 0 0 5px 0;
  border-bottom: 1px solid #eee;
}

.tabs-right .nav-tabs > li .nav-link {
  border-right: 3px solid #eee;
  border-left: 1px solid #eee;
  margin-right: 1px;
  margin-left: 1px;
}

.tabs-right .nav-tabs > li .nav-link:hover {
  border-right-color: #ccc;
  color: #db052c;
}

.tabs-right .nav-tabs > li.active .nav-link,
.tabs-right .nav-tabs > li.active .nav-link:hover,
.tabs-right .nav-tabs > li.active .nav-link:focus {
  border-right: 3px solid #ccc;
  border-left: 1px solid #fff;
  color: #db052c;
}

/* Justified */
.nav-tabs.nav-justified {
  padding: 0;
  margin-bottom: -1px;
}

.nav-tabs.nav-justified li {
  margin-bottom: 0;
}

.nav-tabs.nav-justified li:first-child .nav-link,
.nav-tabs.nav-justified li:first-child .nav-link:hover {
  border-radius: 5px 0 0 0;
  color: #db052c;
}

.nav-tabs.nav-justified li:last-child .nav-link,
.nav-tabs.nav-justified li:last-child .nav-link:hover {
  border-radius: 0 5px 0 0;
  color: #db052c;
}

.nav-tabs.nav-justified li .nav-link {
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  margin-right: 0;
}

.nav-tabs.nav-justified li .nav-link:hover,
.nav-tabs.nav-justified li .nav-link:focus {
  border-bottom: 1px solid #ddd;
}

.nav-tabs.nav-justified li.active .nav-link,
.nav-tabs.nav-justified li.active .nav-link:hover,
.nav-tabs.nav-justified li.active .nav-link:focus {
  background: #fff;
  border-left-color: #eee;
  border-right-color: #eee;
  border-top-width: 3px;
}

.nav-tabs.nav-justified li.active .nav-link {
  border-bottom: 1px solid #fff;
}

.nav-tabs.nav-justified li.active .nav-link,
.nav-tabs.nav-justified li.active .nav-link:hover,
.nav-tabs.nav-justified li.active .nav-link:focus {
  border-top-width: 3px;
}

.nav-tabs.nav-justified li.active .nav-link:hover {
  border-bottom: 1px solid #fff;
}

/* Bottom Tabs with Justified Nav */
.tabs.tabs-bottom {
  padding: 0;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified {
  border-top: none;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li .nav-link {
  margin-right: 0;
  border-top-color: transparent;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:first-child .nav-link {
  border-radius: 0 0 0 5px;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:last-child .nav-link {
  margin-right: 0;
  border-radius: 0 0 5px 0;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li.active .nav-link,
.tabs.tabs-bottom .nav.nav-tabs.nav-justified li.active .nav-link:hover,
.tabs.tabs-bottom .nav.nav-tabs.nav-justified li.active .nav-link:focus {
  border-top-color: transparent;
}

/* Center */
.tabs-center .nav-tabs {
  margin: 0 auto;
  text-align: center;
}

/* Navigation */
.tabs-navigation {
  border-radius: 5px;
}

.tabs-navigation .nav-tabs > li {
  margin-bottom: 1px;
}

.tabs-navigation .nav-tabs > li .nav-link {
  color: #777;
  padding: 18px;
  line-height: 100%;
  position: relative;
}

.tabs-navigation .nav-tabs > li .nav-link .fa,
.tabs-navigation .nav-tabs > li .nav-link .fab,
.tabs-navigation .nav-tabs > li .nav-link .fal,
.tabs-navigation .nav-tabs > li .nav-link .far,
.tabs-navigation .nav-tabs > li .nav-link .fas,
.tabs-navigation .nav-tabs > li .nav-link .icons {
  margin-right: 4px;
}

.tabs-navigation .nav-tabs li:first-child .nav-link {
  border-radius: 5px 5px 0 0;
}

.tabs-navigation .nav-tabs li.active .nav-link,
.tabs-navigation .nav-tabs li.active .nav-link:hover,
.tabs-navigation .nav-tabs li.active .nav-link:focus {
  border-right-color: #eee;
}

.tabs-navigation .nav-tabs li:last-child .nav-link {
  border-radius: 0 0 5px 5px;
}

.tab-pane-navigation {
  display: none;
}

.tab-pane-navigation.active {
  display: block;
}

/* Simple */
.tabs.tabs-simple .nav-tabs {
  justify-content: center;
  border: 0;
  margin-bottom: 10px;
}

.tabs.tabs-simple .nav-tabs > li {
  margin-left: 0;
  margin-bottom: 20px;
}

.tabs.tabs-simple .nav-tabs > li .nav-link,
.tabs.tabs-simple .nav-tabs > li .nav-link:hover,
.tabs.tabs-simple .nav-tabs > li .nav-link:focus {
  padding: 15px 30px;
  background: none;
  border: 0;
  border-bottom: 3px solid #eee;
  border-radius: 0;
  color: #777;
  font-size: 16px;
}

.tabs.tabs-simple .tab-content {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: none;
}

/* Tabs Modern */
.tabs-modern > div[class*="col"]:first-child {
  padding: 0 !important;
}

.tabs-modern .nav .nav-link {
  display: flex;
  align-items: center;
  color: #222529;
  font-weight: 700;
  font-size: 14.4px;
  font-size: 0.9rem;
  padding: 19.2px 22.4px;
  padding: 1.2rem 1.4rem;
  border-bottom: 1px solid #efefef;
}

.tabs-modern .nav .nav-link.active {
  background: #fff;
  border-radius: 0 4px 4px 0;
}

.tabs-modern .nav .nav-link:last-child {
  border-bottom: 0;
}

.tabs-modern .nav .nav-link > i {
  font-size: 24px;
  font-size: 1.5rem;
  color: #e7e7e7;
}

.tabs-modern .tab-content {
  background: transparent;
  border: none;
  box-shadow: none;
}

/* Responsive */
@media (max-width: 575px) {
  .tabs .nav.nav-tabs.nav-justified li {
    display: block;
    margin-bottom: -5px;
  }

  .tabs .nav.nav-tabs.nav-justified li .nav-link {
    border-top-width: 3px !important;
    border-bottom-width: 0 !important;
  }

  .tabs .nav.nav-tabs.nav-justified li:first-child .nav-link,
  .tabs .nav.nav-tabs.nav-justified li:first-child .nav-link:hover {
    border-radius: 5px 5px 0 0;
  }

  .tabs .nav.nav-tabs.nav-justified li:last-child .nav-link,
  .tabs .nav.nav-tabs.nav-justified li:last-child .nav-link:hover {
    border-radius: 0;
  }

  .tabs.tabs-bottom .nav.nav-tabs.nav-justified li {
    margin-bottom: 0;
    margin-top: -5px;
  }

  .tabs.tabs-bottom .nav.nav-tabs.nav-justified li .nav-link {
    border-bottom-width: 3px !important;
    border-top-width: 0 !important;
  }

  .tabs.tabs-bottom .nav.nav-tabs.nav-justified li:first-child .nav-link,
  .tabs.tabs-bottom .nav.nav-tabs.nav-justified li:first-child .nav-link:hover {
    border-radius: 0;
  }

  .tabs.tabs-bottom .nav.nav-tabs.nav-justified li:last-child .nav-link,
  .tabs.tabs-bottom .nav.nav-tabs.nav-justified li:last-child .nav-link:hover {
    border-radius: 0 0 5px 5px;
  }
}

/* Dark */
html.dark .tabs.tabs-bottom .tab-content,
html.dark .tab-content {
  background-color: #5a5c63;
  border-color: #282d36;
}

html.dark .tabs.tabs-bottom .nav-tabs,
html.dark .nav-tabs {
  border-bottom-color: #282d36;
  border-top-color: #282d36;
}

html.dark .tabs.tabs-bottom .nav-tabs li .nav-link,
html.dark .nav-tabs li .nav-link {
  background: #282d36;
  border-left-color: #282d36;
  border-right-color: #282d36;
  border-top-color: #2e353e;
}

html.dark .tabs.tabs-bottom .nav-tabs li.active .nav-link,
html.dark .nav-tabs li.active .nav-link {
  background: #5a5c63;
  border-left-color: #282d36;
  border-right-color: #282d36;
}

html.dark .tabs.tabs-bottom .nav-tabs li .nav-link {
  border-bottom-color: #2e353e;
}

html.dark .tabs.tabs-bottom .nav-tabs li.active .nav-link {
  border-top-width: 1px;
}

html.dark .tabs.tabs-bottom .nav-tabs li.active .nav-link:hover,
html.dark .tabs.tabs-bottom .nav-tabs li.active .nav-link:focus,
html.dark .tabs.tabs-bottom .nav-tabs li.active .nav-link:active {
  border-top-width: 1px;
}

html.dark .tabs .nav-tabs.nav-justified li .nav-link {
  border-bottom-color: #5a5c63;
}

html.dark .tabs .nav-tabs.nav-justified li.active .nav-link:hover,
html.dark .tabs .nav-tabs.nav-justified li.active .nav-link:focus,
html.dark .tabs .nav-tabs.nav-justified li.active .nav-link:active {
  background-color: #5a5c63;
  border-left-color: #282d36;
  border-right-color: #282d36;
}

html.dark .tabs.tabs-vertical {
  border-top-color: #282d36;
}

html.dark .tabs.tabs-vertical .nav-tabs li .nav-link {
  border-bottom-color: #282d36;
}

html.dark .tabs.tabs-vertical .nav-tabs li.active .nav-link {
  border-right-color: #5a5c63;
}

html.dark .tabs.tabs-vertical .nav-tabs li.active .nav-link:hover,
html.dark .tabs.tabs-vertical .nav-tabs li.active .nav-link:focus,
html.dark .tabs.tabs-vertical .nav-tabs li.active .nav-link:active {
  border-left-color: #2e353e;
  border-right-color: #5a5c63;
  border-bottom-color: #282d36;
}

html.dark .tabs.tabs-vertical.tabs-right .nav-tabs li.active .nav-link {
  border-left-color: #5a5c63;
}

html.dark .tabs.tabs-vertical.tabs-right .nav-tabs li.active .nav-link:hover,
html.dark .tabs.tabs-vertical.tabs-right .nav-tabs li.active .nav-link:focus,
html.dark .tabs.tabs-vertical.tabs-right .nav-tabs li.active .nav-link:active {
  border-left-color: #5a5c63;
}

html.dark .tabs.tabs-dark .nav-tabs li .nav-link {
  color: #777;
}

html.dark .tabs.tabs-dark .nav-tabs li .nav-link:hover,
html.dark .tabs.tabs-dark .nav-tabs li .nav-link:focus,
html.dark .tabs.tabs-dark .nav-tabs li .nav-link:active {
  color: #777;
}

html.dark .tabs.tabs-dark .nav-tabs li.active .nav-link {
  color: #777;
}

html.dark .tabs.tabs-dark .nav-tabs li.active .nav-link:hover,
html.dark .tabs.tabs-dark .nav-tabs li.active .nav-link:focus,
html.dark .tabs.tabs-dark .nav-tabs li.active .nav-link:active {
  color: #777;
}

/* Tags Input */
.bootstrap-tagsinput {
  width: 100%;
}

.form-group-invisible .bootstrap-tagsinput {
  border: 0 none;
  box-shadow: none;
  background-color: transparent;
}

.bootstrap-tagsinput .badge {
  border: 0;
}

html.dark .bootstrap-tagsinput {
  background: #282d36;
  border-color: #282d36;
}

/* Timepicker */
html.dark .bootstrap-timepicker-widget {
  background-color: #282d36;
}

html.dark .bootstrap-timepicker-widget:before {
  border-bottom-color: #e6e6ea;
}

html.dark .bootstrap-timepicker-widget:after {
  border-bottom-color: #282d36;
}

html.dark .bootstrap-timepicker-widget.timepicker-orient-bottom:before {
  border-top-color: #e6e6ea;
}

html.dark .bootstrap-timepicker-widget.timepicker-orient-bottom:after {
  border-top-color: #282d36;
}

html.dark .bootstrap-timepicker-widget table td a {
  color: #fff;
}

html.dark .bootstrap-timepicker-widget table td a:hover {
  border-color: #282d36;
}

html.dark .bootstrap-timepicker-widget table td input {
  background-color: #5a5c63;
  border-color: #282d36;
  color: #eee;
}

/* Toggles */
.toggle {
  margin: 10px 0 20px;
  position: relative;
  clear: both;
}

.toggle .toggle {
  margin: 10px 0 0;
}

.toggle > input {
  cursor: pointer;
  filter: alpha(opacity=0);
  height: 45px;
  margin: 0;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.toggle > label {
  transition: all 0.15s ease-out;
  background: #f4f4f4;
  border-left: 3px solid #ccc;
  border-radius: 5px;
  color: #ccc;
  display: block;
  font-size: 1.1em;
  min-height: 20px;
  padding: 12px 20px 12px 10px;
  position: relative;
  cursor: pointer;
  font-weight: 400;
}

.toggle > label:-moz-selection {
  background: none;
}

.toggle > label i.fa-minus {
  display: none;
}

.toggle > label i.fa-plus {
  display: inline;
}

.toggle > label:selection {
  background: none;
}

.toggle > label:before {
  border: 6px solid transparent;
  border-left-color: inherit;
  content: "";
  margin-top: -6px;
  position: absolute;
  right: 4px;
  top: 50%;
}

.toggle > label:hover {
  background: #f5f5f5;
}

.toggle > label + p {
  color: #999;
  display: block;
  overflow: hidden;
  padding-left: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 25px;
}

.toggle > label i {
  font-size: 0.7em;
  margin-right: 8px;
  position: relative;
  top: -1px;
}

.toggle > .toggle-content {
  display: none;
}

.toggle > .toggle-content > p {
  margin-bottom: 0;
  padding: 10px 0;
}

.toggle.active i.fa-minus {
  display: inline;
}

.toggle.active i.fa-plus {
  display: none;
}

.toggle.active > label {
  background: #f4f4f4;
  border-color: #ccc;
}

.toggle.active > label:before {
  border: 6px solid transparent;
  border-top-color: #fff;
  margin-top: -3px;
  right: 10px;
}

.toggle.active > p {
  white-space: normal;
}

.toggle > p.preview-active {
  height: auto;
  white-space: normal;
}

.toggle.toggle-sm .toggle > label {
  font-size: 0.9em;
  padding: 6px 20px 6px 10px;
}

.toggle.toggle-sm .toggle > .toggle-content > p {
  font-size: 0.9em;
}

.toggle.toggle-lg .toggle > label {
  font-size: 1.1em;
  padding: 16px 20px 16px 20px;
}

.toggle.toggle-lg .toggle > .toggle-content > p {
  font-size: 1.1em;
}

.toggle.toggle-simple .toggle > label {
  border-radius: 0;
  border: 0;
  background: transparent;
  color: #777;
  padding-left: 32px;
  margin-bottom: -10px;
  font-size: 1em;
  font-weight: 600;
  color: #5a5c63;
}

.toggle.toggle-simple .toggle > label:after {
  content: "";
  position: absolute;
  left: 0;
  display: block;
  width: 24px;
  height: 24px;
  background: #ccc;
  top: 23px;
  margin-top: -12px;
  border-radius: 3px;
  z-index: 1;
}

.toggle.toggle-simple .toggle.active > label {
  background: transparent;
  color: #5a5c63;
}

/* Dark */
html.dark .toggle > label {
  background: #282d36;
}

/* Toolbar */
.inner-toolbar {
  background: #e6e6ea;
  margin: -40px -40px 30px;
  padding: 0;
  border: 1px solid transparent;
  border-left: 1px solid #121418;
}

.inner-toolbar > ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.inner-toolbar > ul > li {
  display: inline-block;
  padding: 15px;
  font-size: 13px;
  border-right: 1px solid #121418;
}

.inner-toolbar > ul > li > a {
  display: inline-block;
  padding: 0;
  color: #5a5c63;
}

.inner-toolbar > ul > li > a:hover,
.inner-toolbar > ul > li > a:focus {
  color: #fff;
  text-decoration: none;
}

.inner-toolbar > ul > li.right {
  float: right;
  padding-right: 10px;
}

.inner-toolbar > ul > li i.fa,
.inner-toolbar > ul > li .fab,
.inner-toolbar > ul > li .fal,
.inner-toolbar > ul > li .far,
.inner-toolbar > ul > li .fas {
  font-size: 14px;
}

.inner-toolbar > ul > li > .btn {
  margin-top: -9px;
}

.inner-toolbar .nav-pills {
  margin-top: -8px;
}

.inner-toolbar .nav-pills > li {
  margin-right: 1px;
}

.inner-toolbar .nav-pills > li > label {
  margin-bottom: 0;
  margin-right: 12px;
  margin-top: 8px;
}

.inner-toolbar .nav-pills a {
  color: #5a5c63;
  padding-top: 8px;
  padding-bottom: 8px;
}

.inner-toolbar .nav-pills a:hover {
  background: #5a5c63;
  color: #fff;
}

.inner-toolbar .nav-pills .active a {
  color: #fff;
}

html.sidebar-light:not(.dark) .inner-toolbar {
  background: #e2e3e6;
  border: 1px solid #d5d6d7;
}

html.sidebar-light:not(.dark) .inner-toolbar > ul > li {
  border-right: 1px solid #d5d6d7;
}

html.sidebar-light:not(.dark) .inner-toolbar > ul > li > a {
  color: #777;
}

html.sidebar-light:not(.dark) .inner-toolbar > ul > li > a:hover,
html.sidebar-light:not(.dark) .inner-toolbar > ul > li > a:focus {
  color: #999;
}

html.sidebar-light:not(.dark) .inner-toolbar .nav-pills li:not(.active) a {
  color: #777;
}

html.sidebar-light:not(.dark)
  .inner-toolbar
  .nav-pills
  li:not(.active)
  a:hover {
  background: #e2e3e6;
  color: #999;
}

/* Toolbar - Responsive */
@media only screen and (max-width: 767px) {
  .inner-toolbar {
    clear: both;
    margin: -40px -15px 30px;
    padding: 0 15px;
  }

  .inner-toolbar ul > li {
    border-right: 0;
  }

  .inner-toolbar ul > li:first-child {
    padding-left: 0;
  }

  .inner-toolbar ul > li.right {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 480px) {
  .inner-toolbar .nav-pills a {
    padding-left: 10px;
    padding-right: 10px;
  }

  .inner-toolbar ul > li.right {
    clear: both;
    float: none;
    vertical-align: top;
  }
}

/* Toolbar + Layout Fixed */
@media only screen and (min-width: 768px) {
  html.fixed .inner-toolbar {
    left: 300px;
    right: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    top: 114px;
    z-index: 1001;
  }
}

/* dark */
html.dark .inner-toolbar {
  border-left: none;
  border-bottom: 1px solid #242830;
}

html.dark .inner-toolbar > ul > li {
  border-color: #242830;
}

/* Treeview */
.jstree-default .jstree-checkbox {
  /* background-image: url(../img/jstree.png); */
}

.jstree-default .jstree-icon {
  color: #333;
}

.jstree-default .jstree-hovered {
  background-color: white;
}

.jstree-default .jstree-clicked {
  background-color: white;
}

.jstree-default .jstree-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.jstree-default .colored {
  color: #ccc;
}

.jstree-default .colored .jstree-icon {
  color: #ccc;
}

.jstree-default .colored-icon .jstree-icon {
  color: #ccc;
}

.jstree-default .folder .jstree-icon {
  color: #ddc03f !important;
}

/* dark */
html.dark body .jstree-default .jstree-checkbox {
  /* background-image: url(../img/jstree-dark.png); */
}

html.dark body .jstree-default .jstree-hovered {
  background-color: #5a5c63 !important;
  box-shadow: none;
}

html.dark body .jstree-default .jstree-clicked {
  background-color: #e6e6ea !important;
  box-shadow: none;
}

html.dark body .jstree-default .jstree-icon {
  color: #808697;
}

/* Blockquotes */
blockquote {
  border-left: 5px solid #eee;
  margin: 0 0 16px 0;
  margin: 0 0 1rem 0;
  padding: 8px 16px;
  padding: 0.5rem 1rem;
  font-size: 1em;
}

blockquote.with-borders {
  border-radius: 6px;
  border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
  padding: 20.8px;
  padding: 1.3rem;
}

blockquote.primary {
  border-color: #ccc;
}

blockquote.success {
  border-color: #47a447;
}

blockquote.warning {
  border-color: #ed9c28;
}

blockquote.danger {
  border-color: #d2322d;
}

blockquote.info {
  border-color: #5a5c63;
}

blockquote.dark {
  border-color: #5a5c63;
}

/* Paragraphs */
p {
  color: #777;
  line-height: 24px;
  margin: 0 0 20px;
}

p.featured {
  font-size: 1.6em;
  line-height: 1.5em;
}

p.lead {
  line-height: 25.6px;
  line-height: 1.6rem;
}

p .alternative-font {
  display: inline-block;
  margin-top: -15px;
  position: relative;
  top: 3px;
  margin-bottom: -6px;
}

p.color-alert {
  color: #d2312d;
  line-height: 24px;
  margin: 0 0 20px;
}

/* Links */
a,
a:hover,
a:focus {
  color: #ccc;
}

/* Drop Caps */
p.drop-caps:first-letter {
  float: left;
  font-size: 75px;
  line-height: 60px;
  padding: 4px;
  margin-right: 5px;
  margin-top: 5px;
  font-family: Georgia;
}

p.drop-caps.drop-caps-style-2:first-letter {
  background-color: #ccc;
  color: #fff;
  padding: 6px;
  margin-right: 5px;
  border-radius: 4px;
}

/* Default Font Style */
.default-font {
  font-family: "Poppins", Arial, sans-serif;
}

/* Alternative Font Style */
.alternative-font {
  color: #ccc;
  font-family: "Shadows Into Light", cursive;
  font-size: 1.6em;
}

/* Highlight */
.highlight {
  color: #fff;
  padding: 3px 6px;
}

/* Userbox */
.userbox {
  display: inline-block;
  margin: 3px 17px 0 0;
  position: relative;
  vertical-align: middle;
}

.userbox > a {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

.userbox a:hover {
  text-decoration: none;
}

.userbox .profile-info,
.userbox .profile-picture {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}

.userbox .profile-picture img {
  width: 35px;
  color: transparent;
}

.userbox .profile-info {
  margin: -3px 25px 0 10px;
}

.userbox .name,
.userbox .role {
  display: block;
}

.userbox .name {
  color: #000011;
  font-size: 12.8px;
  font-size: 0.8rem;
  line-height: 17.6px;
  line-height: 1.1rem;
}

.userbox .role {
  color: #acacac;
  font-size: 11.2px;
  font-size: 0.7rem;
  line-height: 11.2px;
  line-height: 0.7rem;
}

.userbox .custom-caret {
  color: #000011;
  font-size: 16px;
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .userbox .name,
  .userbox .role {
    max-width: 68px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/* Userbox - Open */
.userbox.show > a {
  position: relative;
  z-index: 993;
}

.userbox.show .dropdown-menu {
  border: none;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
  box-sizing: content-box;
  left: -11px !important;
  padding: 45px 10px 0;
  top: -10px !important;
  transform: none !important;
  width: 100%;
  min-width: 0;
  z-index: 992;
}

.userbox.show .dropdown-menu li {
  margin-bottom: 5px;
}

.userbox.show .dropdown-menu a {
  border-radius: 2px;
  color: #7d7d7d;
  display: block;
  line-height: 20.8px;
  line-height: 1.3rem;
  padding: 4px 10px;
  font-size: 12px;
  font-size: 0.75rem;
}

.userbox.show .dropdown-menu a i {
  font-size: 17.6px;
  font-size: 1.1rem;
}

.userbox.show .dropdown-menu a:hover {
  background: #ccc;
  color: #fff;
}

.userbox.show .dropdown-menu i {
  margin-right: 3px;
  vertical-align: middle;
}

/* Userbox - Mobile */
@media only screen and (max-width: 767px) {
  .userbox {
    float: left;
    margin: 16px 0 0 12px;
    position: relative;
  }

  .userbox:after {
    background: #e9e9e6;
    content: "";
    height: 63px;
    margin: 0;
    position: absolute;
    right: -21px;
    top: -18px;
    width: 1px;
  }

  .userbox .profile-picture {
    display: none;
  }

  .userbox.show .dropdown-menu {
    left: -5px !important;
    padding: 43px 0 0 0;
  }
}

/* Header Dark - Userbox */
html.dark .userbox:after,
html.header-dark .userbox:after {
  background: #121518;
}

html.dark .userbox .name,
html.dark .userbox .custom-caret,
html.header-dark .userbox .name,
html.header-dark .userbox .custom-caret {
  color: #c3c3c3;
}

html.dark .userbox.show .dropdown-menu .name,
html.dark .userbox.show .dropdown-menu .custom-caret,
html.header-dark .userbox.show .dropdown-menu .name,
html.header-dark .userbox.show .dropdown-menu .custom-caret {
  color: #c3c3c3;
}

html.dark .userbox.show .dropdown-menu a,
html.header-dark .userbox.show .dropdown-menu a {
  color: #c3c3c3;
}

html.dark .userbox.show .dropdown-menu a:hover,
html.header-dark .userbox.show .dropdown-menu a:hover {
  color: #fff;
}

html.dark .userbox .dropdown-menu,
html.header-dark .userbox .dropdown-menu {
  background: #282d36;
}

html.dark .userbox .dropdown-menu .divider,
html.header-dark .userbox .dropdown-menu .divider {
  background: #e6e6ea;
}

html.dark .userbox .dropdown-menu a,
html.header-dark .userbox .dropdown-menu a {
  color: #c3c3c3;
}

/* Widgets */
.thumb-info {
  position: relative;
}

.thumb-info .thumb-info-title {
  transition: all 0.3s;
  background: rgba(36, 27, 28, 0.9);
  bottom: 10%;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  left: 0;
  letter-spacing: -1px;
  padding: 9px 11px 9px;
  position: absolute;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  z-index: 1;
}

.thumb-info .thumb-info-inner {
  transition: all 0.3s;
  display: block;
  white-space: nowrap;
}

.thumb-info .thumb-info-type {
  background-color: #ccc;
  border-radius: 2px;
  display: inline-block;
  float: left;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  margin: 8px -2px -15px -2px;
  padding: 2px 9px;
  text-transform: none;
}

/* Widget - Widget Toggle/Expand */
.widget-toggle-expand .widget-header {
  position: relative;
  margin: 0;
  padding: 5px 0;
}

.widget-toggle-expand .widget-header h6 {
  font-size: 12.8px;
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
}

.widget-toggle-expand .widget-header .widget-toggle {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  text-align: center;
  color: #b4b4b4;
  transform: rotate(45deg);
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: linear;
}

.widget-toggle-expand.widget-collapsed .widget-content-expanded {
  display: none;
}

.widget-toggle-expand.widget-collapsed .widget-header .widget-toggle {
  transform: none;
}

/* Widget - Simple User List */
ul.simple-user-list {
  list-style: none;
  padding: 0;
}

ul.simple-user-list li {
  margin: 0 0 20px;
}

ul.simple-user-list li .image {
  float: left;
  margin: 0 10px 0 0;
}

ul.simple-user-list li .title {
  color: #000011;
  display: block;
  line-height: 1.334;
}

ul.simple-user-list li .message {
  display: block;
  font-size: 11.2px;
  font-size: 0.7rem;
  line-height: 1.334;
}

/* Widget - Simple Post List */
ul.simple-post-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.simple-post-list li {
  border-bottom: 1px dotted #e2e2e2;
  padding: 15px 0;
}

ul.simple-post-list li::after {
  clear: both;
  content: "";
  display: block;
}

ul.simple-post-list li::last-child {
  border-bottom: 0;
}

ul.simple-post-list li .post-image {
  float: left;
  margin-right: 12px;
}

ul.simple-post-list li .post-meta {
  color: #888;
  font-size: 0.8em;
}

ul.simple-post-list li:last-child {
  border-bottom: none;
}

/* Widget - Simple Todo List */
.simple-todo-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.simple-todo-list li {
  position: relative;
  padding: 0 0 4px 20px;
}

.simple-todo-list li.completed {
  color: #a7a7a7;
}

/* Widget - Social Icons */
.social-icons-list {
  display: block;
  margin: 0;
  padding: 0;
}

.social-icons-list a {
  background: #ccc;
  border-radius: 25px;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 30px;
}

.social-icons-list a:hover {
  text-decoration: none;
}

.social-icons-list a span {
  display: none;
}

.social-icons-list a i {
  color: #fff;
  font-weight: normal;
}

/* Widget - Simple Compose Box */
.simple-compose-box {
  border: 1px solid #d1d1d1;
  border-radius: 3px;
  background-color: #fff;
}

.simple-compose-box textarea {
  background-color: transparent;
  display: block;
  width: 100%;
  padding: 10px 10px 5px;
  border: 0;
  resize: none;
  border-radius: 3px;
}

.simple-compose-box textarea:focus {
  border: 0 none;
  outline: none;
}

.simple-compose-box .compose-box-footer {
  background-color: #f6f7f8;
  border-radius: 0 0 5px 5px;
}

.simple-compose-box .compose-box-footer::after {
  clear: both;
  content: "";
  display: block;
}

.simple-compose-box .compose-box-footer .compose-toolbar {
  list-style: none;
  margin: 0;
  padding: 0 3px;
  float: left;
}

.simple-compose-box .compose-box-footer .compose-toolbar li {
  display: inline-block;
}

.simple-compose-box .compose-box-footer .compose-toolbar li a {
  display: block;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  width: 30px;
  color: #b3b7bd;
}

.simple-compose-box .compose-box-footer .compose-toolbar li a:hover {
  background-color: #e8eaed;
}

.simple-compose-box .compose-box-footer .compose-btn {
  list-style: none;
  margin: 0;
  padding: 3px;
  float: right;
}

/* Widget - Simple Card List */
ul.simple-card-list {
  list-style: none;
  padding: 0;
}

ul.simple-card-list li {
  padding: 10px 15px;
  margin: 15px 0;
  border-radius: 7px;
}

ul.simple-card-list li h3 {
  font-weight: 600;
  margin: 0;
}

ul.simple-card-list li p {
  margin: 0;
  opacity: 0.7;
}

.simple-card-list li.primary {
  background: #ccc;
  color: #fff;
}

.simple-card-list li.success {
  background: #47a447;
  color: #fff;
}

.simple-card-list li.warning {
  background: #ed9c28;
  color: #fff;
}

.simple-card-list li.danger {
  background: #d2322d;
  color: #fff;
}

.simple-card-list li.info {
  background: #5a5c63;
  color: #fff;
}

.simple-card-list li.dark {
  background: #5a5c63;
  color: #fff;
}

div.simple-card-list {
  display: table;
  width: 100%;
}

div.simple-card-list .card {
  display: table-cell;
}

div.simple-card-list .card .card-content {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin: 0 7px;
  padding: 5px;
}

div.simple-card-list .card h3 {
  font-weight: 600;
  margin: 0;
}

div.simple-card-list .card p {
  margin: 0;
  opacity: 0.7;
}

/* Widget - Simple Button List */
ul.simple-bullet-list {
  list-style: none;
  padding: 0;
}

ul.simple-bullet-list li {
  position: relative;
  padding: 0 0 0 20px;
  margin: 0 0 10px;
}

ul.simple-bullet-list li:before {
  border: 6px solid #ccc;
  border-radius: 100px;
  content: "";
  display: inline-block;
  left: 0;
  margin: 0;
  position: absolute;
  top: 5px;
  z-index: 2;
}

ul.simple-bullet-list li .title {
  display: block;
  font-weight: 700;
  font-size: 13.6px;
  font-size: 0.85rem;
  line-height: 1.4;
  color: #5a5c63;
}

ul.simple-bullet-list li .description {
  display: block;
  color: #999;
  font-size: 11.2px;
  font-size: 0.7rem;
  line-height: 1.334;
}

ul.simple-bullet-list li.red:before {
  border-color: #d64b4b;
}

ul.simple-bullet-list li.green:before {
  border-color: #4dd79c;
}

ul.simple-bullet-list li.blue:before {
  border-color: #0090d9;
}

ul.simple-bullet-list li.orange:before {
  border-color: #e2a917;
}

/* Widget - Summary */
.widget-summary {
  display: table;
  width: 100%;
}

.widget-summary::after {
  clear: both;
  content: "";
  display: block;
}

.widget-summary .widget-summary-col {
  display: table-cell;
  vertical-align: top;
  width: 100%;
}

.widget-summary .widget-summary-col.widget-summary-col-icon {
  width: 1%;
}

.widget-summary .summary-icon {
  margin-right: 15px;
  width: 90px;
  height: 90px;
  line-height: 90px;
  font-size: 51.2px;
  font-size: 3.2rem;
  text-align: center;
  color: #fff;
  border-radius: 55px;
}

.widget-summary .summary {
  min-height: 65px;
  word-break: break-all;
}

.widget-summary .summary .title {
  margin: 0;
  font-size: 14.4px;
  font-size: 0.9rem;
  color: #333;
  font-weight: 500;
  line-height: 1.5;
}

.widget-summary .summary .info {
  font-size: 13.6px;
  font-size: 0.85rem;
}

.widget-summary .summary .info span {
  vertical-align: middle;
}

.widget-summary .summary .amount {
  margin-right: 0.2em;
  font-weight: 600;
  color: #333;
  vertical-align: middle;
  font-size: 22.4px;
  font-size: 1.4rem;
}

.widget-summary .summary-footer {
  padding: 5px 0 0;
  border-top: 1px dotted #ddd;
  text-align: right;
}

.bg-primary .widget-summary .summary .title,
.bg-primary .widget-summary .summary .amount {
  color: #fff !important;
}

.bg-primary .widget-summary .summary-icon {
  background-color: rgba(0, 0, 0, 0.1);
}

.bg-primary .widget-summary .summary-footer {
  border-top: 1px solid #fff;
  border-top-color: rgba(255, 255, 255, 0.2);
}

.bg-primary .widget-summary .summary-footer a {
  color: #fff;
  opacity: 0.6;
}

.bg-secondary .widget-summary .summary .title,
.bg-secondary .widget-summary .summary .amount {
  color: #fff !important;
}

.bg-secondary .widget-summary .summary-icon {
  background-color: rgba(0, 0, 0, 0.1);
}

.bg-secondary .widget-summary .summary-footer {
  border-top: 1px solid #fff;
  border-top-color: rgba(255, 255, 255, 0.2);
}

.bg-secondary .widget-summary .summary-footer a {
  color: #fff;
  opacity: 0.6;
}

.bg-tertiary .widget-summary .summary .title,
.bg-tertiary .widget-summary .summary .amount {
  color: #fff !important;
}

.bg-tertiary .widget-summary .summary-icon {
  background-color: rgba(0, 0, 0, 0.1);
}

.bg-tertiary .widget-summary .summary-footer {
  border-top: 1px solid #fff;
  border-top-color: rgba(255, 255, 255, 0.2);
}

.bg-tertiary .widget-summary .summary-footer a {
  color: #fff;
  opacity: 0.6;
}

.bg-quaternary .widget-summary .summary .title,
.bg-quaternary .widget-summary .summary .amount {
  color: #fff !important;
}

.bg-quaternary .widget-summary .summary-icon {
  background-color: rgba(0, 0, 0, 0.1);
}

.bg-quaternary .widget-summary .summary-footer {
  border-top: 1px solid #fff;
  border-top-color: rgba(255, 255, 255, 0.2);
}

.bg-quaternary .widget-summary .summary-footer a {
  color: #fff;
  opacity: 0.6;
}

.bg-success .widget-summary .summary .title,
.bg-success .widget-summary .summary .amount {
  color: #fff !important;
}

.bg-success .widget-summary .summary-icon {
  background-color: rgba(0, 0, 0, 0.1);
}

.bg-success .widget-summary .summary-footer {
  border-top: 1px solid #fff;
  border-top-color: rgba(255, 255, 255, 0.2);
}

.bg-success .widget-summary .summary-footer a {
  color: #fff;
  opacity: 0.6;
}

.bg-warning .widget-summary .summary .title,
.bg-warning .widget-summary .summary .amount {
  color: #fff !important;
}

.bg-warning .widget-summary .summary-icon {
  background-color: rgba(0, 0, 0, 0.1);
}

.bg-warning .widget-summary .summary-footer {
  border-top: 1px solid #fff;
  border-top-color: rgba(255, 255, 255, 0.2);
}

.bg-warning .widget-summary .summary-footer a {
  color: #fff;
  opacity: 0.6;
}

.bg-danger .widget-summary .summary .title,
.bg-danger .widget-summary .summary .amount {
  color: #fff !important;
}

.bg-danger .widget-summary .summary-icon {
  background-color: rgba(0, 0, 0, 0.1);
}

.bg-danger .widget-summary .summary-footer {
  border-top: 1px solid #fff;
  border-top-color: rgba(255, 255, 255, 0.2);
}

.bg-danger .widget-summary .summary-footer a {
  color: #fff;
  opacity: 0.6;
}

.bg-info .widget-summary .summary .title,
.bg-info .widget-summary .summary .amount {
  color: #fff !important;
}

.bg-info .widget-summary .summary-icon {
  background-color: rgba(0, 0, 0, 0.1);
}

.bg-info .widget-summary .summary-footer {
  border-top: 1px solid #fff;
  border-top-color: rgba(255, 255, 255, 0.2);
}

.bg-info .widget-summary .summary-footer a {
  color: #fff;
  opacity: 0.6;
}

.bg-dark .widget-summary .summary .title,
.bg-dark .widget-summary .summary .amount {
  color: #fff !important;
}

.bg-dark .widget-summary .summary-icon {
  background-color: rgba(0, 0, 0, 0.1);
}

.bg-dark .widget-summary .summary-footer {
  border-top: 1px solid #fff;
  border-top-color: rgba(255, 255, 255, 0.2);
}

.bg-dark .widget-summary .summary-footer a {
  color: #fff;
  opacity: 0.6;
}

@media (min-width: 480px) {
  .widget-summary.widget-summary-xlg {
    padding: 5px 0;
  }

  .widget-summary.widget-summary-xlg .summary-icon {
    width: 110px;
    height: 110px;
    line-height: 110px;
    font-size: 3.2rem;
  }

  .widget-summary.widget-summary-xlg .summary {
    min-height: 80px;
  }

  .widget-summary.widget-summary-xlg .summary .title {
    font-size: 1.4rem;
  }

  .widget-summary.widget-summary-xlg .summary .info {
    font-size: 0.9rem;
  }
}

.widget-summary.widget-summary-lg {
  padding: 0;
}

.widget-summary.widget-summary-lg .summary-icon {
  width: 90px;
  height: 90px;
  line-height: 90px;
  font-size: 48px;
  font-size: 3rem;
}

.widget-summary.widget-summary-lg .summary {
  min-height: 65px;
}

.widget-summary.widget-summary-lg .summary .title {
  font-size: 14.4px;
  font-size: 0.9rem;
}

.widget-summary.widget-summary-lg .summary .info {
  font-size: 13.6px;
  font-size: 0.85rem;
}

.widget-summary.widget-summary-md {
  padding: 0;
}

.widget-summary.widget-summary-md .summary-icon {
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 38.4px;
  font-size: 2.4rem;
}

.widget-summary.widget-summary-md .summary {
  min-height: 0;
  margin-top: 12px;
}

.widget-summary.widget-summary-md .summary .title {
  font-size: 12px;
  font-size: 0.75rem;
}

.widget-summary.widget-summary-md .summary .info {
  font-size: 11.2px;
  font-size: 0.7rem;
}

.widget-summary.widget-summary-md .summary .amount {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
}

.widget-summary.widget-summary-md .summary-footer {
  display: none;
}

.widget-summary.widget-summary-sm {
  padding: 0;
}

.widget-summary.widget-summary-sm .summary-icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 27.2px;
  font-size: 1.7rem;
}

.widget-summary.widget-summary-sm .summary {
  min-height: 0;
  margin-top: 4px;
}

.widget-summary.widget-summary-sm .summary .title {
  font-size: 12px;
  font-size: 0.75rem;
}

.widget-summary.widget-summary-sm .summary .info {
  font-size: 11.2px;
  font-size: 0.7rem;
}

.widget-summary.widget-summary-sm .summary .amount {
  font-size: 14.4px;
  font-size: 0.9rem;
  font-weight: 700;
}

.widget-summary.widget-summary-sm .summary-footer {
  display: none;
}

.widget-summary.widget-summary-xs {
  padding: 0;
}

.widget-summary.widget-summary-xs .summary-icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-size: 1rem;
}

.widget-summary.widget-summary-xs .summary {
  min-height: 0;
}

.widget-summary.widget-summary-xs .summary .title {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 40px;
}

.widget-summary.widget-summary-xs .summary .info {
  display: none;
}

.widget-summary.widget-summary-xs .summary-footer {
  display: none;
}

/* Widget - Todo List */
ul.widget-todo-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}

ul.widget-todo-list li {
  border-bottom: 1px dotted #ddd;
  padding: 15px 15px 15px 0;
  position: relative;
}

ul.widget-todo-list li label.line-through span {
  text-decoration: line-through;
}

ul.widget-todo-list li .checkbox-custom {
  margin-bottom: 0;
}

ul.widget-todo-list li .checkbox-custom label {
  padding-left: 10px;
}

ul.widget-todo-list li .todo-actions {
  position: absolute;
  top: 14px;
  right: 0;
  bottom: 14px;
}

ul.widget-todo-list li .todo-actions .todo-remove {
  font-size: 10px;
  vertical-align: middle;
  color: #999;
}

ul.widget-todo-list li:last-child {
  border-bottom: 0 none;
}

/* Widget - Profile Info */
.widget-profile-info {
  display: table;
  width: 100%;
}

.widget-profile-info .profile-picture {
  display: table-cell;
  vertical-align: middle;
  width: 1%;
}

.widget-profile-info .profile-picture img {
  display: block;
  width: 100px;
  height: 100px;
  margin-right: 15px;
  border: 4px solid #fff;
  border-radius: 50px;
}

.widget-profile-info .profile-info {
  display: table-cell;
  vertical-align: bottom;
  width: 100%;
  color: #fff;
}

.widget-profile-info .profile-info .profile-footer {
  padding: 5px 0 0;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  text-align: right;
}

.widget-profile-info .profile-info .profile-footer a {
  color: #fff;
  opacity: 0.6;
}

/* Widget - Twitter Profile */
.widget-twitter-profile {
  background-color: #ccc;
  border-radius: 5px;
  color: #fff;
}

.widget-twitter-profile .top-image img {
  width: 100%;
  border-radius: 5px 5px 0 0;
}

.widget-twitter-profile .profile-info {
  padding: 15px;
  min-height: 75px;
}

.widget-twitter-profile .profile-info::after {
  clear: both;
  content: "";
  display: block;
}

.widget-twitter-profile .profile-info .profile-picture {
  float: left;
  margin-right: 15px;
  position: relative;
}

.widget-twitter-profile .profile-info .profile-picture img {
  display: block;
  width: 100px;
  height: 100px;
  margin: -25px 0;
  border: 4px solid #fff;
  border-radius: 50px;
}

.widget-twitter-profile .profile-info .profile-account {
  float: left;
}

.widget-twitter-profile .profile-info .profile-account .name {
  margin: 0;
}

.widget-twitter-profile .profile-info .profile-account .account {
  color: white;
  margin: 0;
  line-height: 1.4;
}

.widget-twitter-profile .profile-info .profile-stats {
  float: right;
  list-style: none;
  padding: 5px 0;
  margin: 0;
}

.widget-twitter-profile .profile-info .profile-stats li {
  float: left;
  padding: 0 10px;
}

.widget-twitter-profile .profile-info .profile-stats li .stat {
  margin: 0;
}

.widget-twitter-profile .profile-info .profile-stats li .count {
  display: block;
  margin: 0;
  line-height: 1.4;
  font-weight: 600;
}

.widget-twitter-profile .profile-quote {
  background-color: #d6d6d6;
  border-radius: 0 0 5px 5px;
  padding: 25px 15px 15px 150px;
}

.widget-twitter-profile .profile-quote blockquote {
  padding: 0;
  margin: 0;
  border: 0;
}

.widget-twitter-profile .profile-quote blockquote p {
  position: relative;
  font-style: italic;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.6;
  padding: 15px 0;
  margin: 0 0 10px;
  font-family: Georgia, serif;
  color: #fff;
}

.widget-twitter-profile .profile-quote .quote-footer {
  border-top: 1px solid #e6e6e6;
  padding: 5px 0;
  text-align: right;
  color: white;
}

.widget-twitter-profile .profile-quote .quote-footer a {
  color: white;
}

/* Widget - Twitter Profile Responsive */
@media only screen and (max-width: 479px) {
  .widget-twitter-profile .profile-info .profile-stats {
    clear: both;
    float: none;
    padding: 45px 0 0;
    text-align: center;
  }

  .widget-twitter-profile .profile-info .profile-stats li {
    display: inline-block;
    float: none;
  }
}

@media only screen and (max-width: 767px) {
  .widget-twitter-profile .profile-quote {
    padding-left: 10px;
  }

  .widget-twitter-profile .profile-quote blockquote {
    padding-left: 45px;
  }
}

/* Widget - User Info */
.widget-user-info .widget-user-info-header {
  position: relative;
  background: #e7e7e9;
  padding: 30.4px 24px;
  padding: 1.9rem 1.5rem;
}

.widget-user-info .widget-user-info-header h1,
.widget-user-info .widget-user-info-header h2,
.widget-user-info .widget-user-info-header h3,
.widget-user-info .widget-user-info-header h4,
.widget-user-info .widget-user-info-header h5,
.widget-user-info .widget-user-info-header h6 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.4;
}

.widget-user-info .widget-user-info-header p {
  line-height: 1.4;
}

.widget-user-info .widget-user-info-header .widget-user-acrostic {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  right: 0;
  font-size: 40px;
  font-size: 2.5rem;
  color: #fff;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 5px solid #fff;
  transform: translate3d(-20px, 50%, 0);
}

.widget-user-info .widget-user-info-body {
  padding: 30.4px 24px;
  padding: 1.9rem 1.5rem;
}

.widget-user-info .widget-user-info-body h3 {
  margin-top: 0;
  margin-bottom: 0;
}

html.dark {
  /* Widget Summary */
  /* Panel Footer - Button Group */
  /* To-do List */
  /* Simple Post List */
  /* Simple User List */
  /* Simple Bullet List */
  /* Simple Compose Box */
}

html.dark .widget-summary .summary .title,
html.dark .widget-summary .summary .amount {
  color: #eee;
}

html.dark .widget-summary .summary-footer {
  border-color: #4c4c4c;
}

html.dark .card-footer-btn-group a {
  background-color: #282d36;
  border-color: #5a5c63;
}

html.dark .card-footer-btn-group a:hover {
  background-color: #2e353e;
}

html.dark ul.widget-todo-list li {
  border-color: #242830;
}

html.dark ul.simple-post-list li {
  border-color: #4c4c4c;
}

html.dark ul.simple-user-list li .title {
  color: #eee;
}

html.dark ul.simple-bullet-list li .title {
  color: #eee;
}

html.dark .simple-compose-box {
  background-color: #282d36;
  border-color: #282d36;
}

html.dark .simple-compose-box .compose-box-footer {
  background-color: #5a5c63;
}

/* Wizard */
.wizard-steps.wizard-steps-style-2.nav-tabs li .nav-link .badge {
  border-radius: 100%;
  padding: 6.4px 8.8px;
  padding: 0.4rem 0.55rem;
  margin-right: 5px;
}

.card-body .wizard-tabs {
  margin: -15px -15px 15px;
}

.card-body-nopadding .wizard-tabs {
  margin: 0;
}

.wizard-tabs ul {
  display: table;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}

.wizard-tabs ul > li {
  display: table-cell;
  border-bottom: 1px solid #eee;
}

.wizard-tabs ul > li.active {
  border-bottom-color: transparent;
}

.wizard-tabs ul > li.active > a,
.wizard-tabs ul > li.active > a:hover,
.wizard-tabs ul > li.active > a:focus {
  background: #fff;
  color: #555;
}

.wizard-tabs ul > li.active > a:after {
  border-left-color: #fff;
}

.wizard-tabs ul > li.active .badge {
  background-color: #ccc;
}

.wizard-tabs ul > li.disabled > a,
.wizard-tabs ul > li.disabled > a:hover,
.wizard-tabs ul > li.disabled > a:focus {
  color: #ccc;
}

.wizard-tabs ul > li.disabled .badge {
  background-color: #ccc;
}

.wizard-tabs ul > li > a {
  position: relative;
  display: block;
  padding: 5px;
  font-size: 13.6px;
  font-size: 0.85rem;
  text-decoration: none;
  color: #555;
}

.wizard-tabs ul > li > a .badge {
  border-radius: 100%;
  color: #fff;
  background: #ccc;
  padding: 6.4px 8.8px;
  padding: 0.4rem 0.55rem;
  margin-right: 5px;
  position: relative;
  top: -1px;
}

.wizard-tabs ul > li > a,
.wizard-tabs ul > li > a:hover {
  background: #f4f4f4;
  color: #555;
}

.wizard-tabs ul > li > a:hover {
  box-shadow: none;
}

.wizard-tabs ul > li > a:active,
.wizard-tabs ul > li > a:focus {
  border-bottom: 0;
}

.wizard-tabs ul > li > a:before,
.wizard-tabs ul > li > a:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  right: -10px;
  z-index: 1;
  border: 17px solid transparent;
  border-right: 0;
  border-left: 10px solid transparent;
  width: 0;
  height: 0;
}

.wizard-tabs ul > li > a:before {
  z-index: 1;
  right: -11px;
  border-left-color: #e0e0e0;
}

.wizard-tabs ul > li > a:after {
  z-index: 2;
  border-left-color: #f4f4f4;
}

.wizard-tabs ul > li:last-child > a:before,
.wizard-tabs ul > li:last-child > a:after {
  display: none;
}

html.dark .wizard-tabs ul > li {
  border-color: #282d36;
}

html.dark .wizard-tabs ul > li.active {
  border-color: #2e353e;
}

html.dark .wizard-tabs ul > li.active a,
html.dark .wizard-tabs ul > li.active a:hover,
html.dark .wizard-tabs ul > li.active a:active {
  background: #2e353e;
  color: #eee;
}

html.dark .wizard-tabs ul > li.active a:before {
  border-left-color: #5a5c63;
}

html.dark .wizard-tabs ul > li.active a:after {
  border-left-color: #2e353e;
}

html.dark .wizard-tabs ul > li a,
html.dark .wizard-tabs ul > li a:hover {
  background: #282d36;
  color: #eee;
}

html.dark .wizard-tabs ul > li a:before {
  border-left-color: #5a5c63;
}

html.dark .wizard-tabs ul > li a:after {
  border-left-color: #282d36;
}

html .wizard-progress,
html.dark .wizard-progress {
  margin: 0 15px;
}

html .wizard-progress .steps-progress,
html.dark .wizard-progress .steps-progress {
  height: 2px;
  margin: 0 38px;
  position: relative;
  top: 15px;
  background: #ccc;
}

html .wizard-progress .steps-progress .progress-indicator,
html.dark .wizard-progress .steps-progress .progress-indicator {
  height: 100%;
  width: 0;
  background: #ccc;
  transition: width 0.2s ease-in;
}

html .wizard-progress .wizard-steps,
html.dark .wizard-progress .wizard-steps {
  list-style: none;
  margin: 0;
  padding: 15px 0 0;
  display: inline-block;
  width: 100%;
  font-size: 0;
  text-align: justify;
}

html .wizard-progress .wizard-steps:after,
html.dark .wizard-progress .wizard-steps:after {
  display: inline-block;
  width: 100%;
  content: ".";
  font-size: 0;
  height: 0;
  line-height: 0;
  visibility: hidden;
}

html .wizard-progress .wizard-steps li,
html.dark .wizard-progress .wizard-steps li {
  display: inline-block;
  vertical-align: top;
  min-width: 50px;
  max-width: 100px;
}

html .wizard-progress .wizard-steps li a,
html.dark .wizard-progress .wizard-steps li a {
  position: relative;
  display: block;
  padding: 25px 8px 0;
  font-size: 11px;
  color: #5a5b63;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  background-color: transparent;
}

html .wizard-progress .wizard-steps li a span,
html.dark .wizard-progress .wizard-steps li a span {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  background: #ccc;
  color: #fff;
  line-height: 26px;
  text-align: center;
  margin-top: -15px;
  margin-left: -15px;
  width: 30px;
  height: 30px;
  border-radius: 35px;
  font-size: 13px;
  text-indent: -1px;
  border: 2px solid #ccc;
  transition: all 0.2s ease-in;
}

html .wizard-progress .wizard-steps li.completed a span,
html.dark .wizard-progress .wizard-steps li.completed a span {
  background: #ccc;
  color: #fff;
}

html .wizard-progress .wizard-steps li.active a span,
html.dark .wizard-progress .wizard-steps li.active a span {
  background: #fff;
  color: #ccc;
  border-color: #ccc;
}

html .wizard-progress .wizard-steps li.completed.active a span,
html.dark .wizard-progress .wizard-steps li.completed.active a span {
  color: #fff;
}

html .wizard-progress.wizard-progress-lg,
html.dark .wizard-progress.wizard-progress-lg {
  margin: 0 auto 30px;
  width: 80%;
}

html .wizard-progress.wizard-progress-lg .steps-progress,
html.dark .wizard-progress.wizard-progress-lg .steps-progress {
  margin: 0 52px;
  height: 4px;
  top: 34px;
}

html .wizard-progress.wizard-progress-lg .wizard-steps,
html.dark .wizard-progress.wizard-progress-lg .wizard-steps {
  padding-top: 30px;
}

html .wizard-progress.wizard-progress-lg ul li,
html.dark .wizard-progress.wizard-progress-lg ul li {
  max-width: 135px;
}

html .wizard-progress.wizard-progress-lg ul li a,
html.dark .wizard-progress.wizard-progress-lg ul li a {
  padding-top: 40px;
  font-size: 14px;
}

html .wizard-progress.wizard-progress-lg ul li a span,
html.dark .wizard-progress.wizard-progress-lg ul li a span {
  width: 60px;
  height: 60px;
  margin-top: -30px;
  margin-left: -30px;
  border-radius: 60px;
  line-height: 52px;
  font-size: 22px;
  border-width: 4px;
}

html.dark .wizard-progress .wizard-steps li a {
  color: #808697;
}

html.dark .wizard-progress.wizard-progress ul li a span {
  background: #242830;
  border-color: #242830;
}

html.dark .wizard-progress .wizard-steps li.active a span {
  background: #242830;
}

html.dark .wizard-progress .steps-progress {
  background: #242830;
}

@media only screen and (max-width: 991px) {
  html .wizard-progress,
  html .wizard-progress.wizard-progress-lg,
  html.dark .wizard-progress,
  html.dark .wizard-progress.wizard-progress-lg {
    width: auto;
    margin-bottom: 0;
    margin-left: -15px;
    margin-right: -15px;
  }

  html .wizard-progress .steps-progress,
  html.dark .wizard-progress .steps-progress {
    display: none;
  }

  html .wizard-progress .wizard-steps,
  html.dark .wizard-progress .wizard-steps {
    display: block;
    font-size: 0;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    width: 100%;
  }

  html .wizard-progress .wizard-steps li,
  html.dark .wizard-progress .wizard-steps li {
    transition: margin 0.5s linear;
    display: inline-block;
    float: none;
    position: relative;
    width: 100%;
    min-width: 0;
    max-width: none;
  }

  html .wizard-progress .wizard-steps li a,
  html.dark .wizard-progress .wizard-steps li a {
    position: relative;
    z-index: 2;
  }

  html .wizard-progress .wizard-steps li:before,
  html .wizard-progress .wizard-steps li:after,
  html.dark .wizard-progress .wizard-steps li:before,
  html.dark .wizard-progress .wizard-steps li:after {
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    top: 0;
    width: 50%;
  }

  html .wizard-progress .wizard-steps li:before,
  html.dark .wizard-progress .wizard-steps li:before {
    background: #ccc;
    left: 0;
  }

  html .wizard-progress .wizard-steps li:after,
  html.dark .wizard-progress .wizard-steps li:after {
    background: #ccc;
    right: 0;
  }

  html .wizard-progress .wizard-steps li.active,
  html.dark .wizard-progress .wizard-steps li.active {
    margin-left: 0;
  }

  html .wizard-progress .wizard-steps li.completed,
  html.dark .wizard-progress .wizard-steps li.completed {
    margin-left: -100%;
  }

  html .wizard-progress .wizard-steps li.completed:after,
  html.dark .wizard-progress .wizard-steps li.completed:after {
    background: #ccc;
  }

  html .wizard-progress .wizard-steps li.completed.active,
  html.dark .wizard-progress .wizard-steps li.completed.active {
    margin-left: 0;
  }

  html .wizard-progress .wizard-steps li:first-child:before,
  html.dark .wizard-progress .wizard-steps li:first-child:before {
    display: none;
  }

  html .wizard-progress .wizard-steps li:last-child:after,
  html.dark .wizard-progress .wizard-steps li:last-child:after {
    display: none;
  }

  html .wizard-progress.wizard-progress-lg .wizard-steps li,
  html.dark .wizard-progress.wizard-progress-lg .wizard-steps li {
    min-width: 0;
    max-width: none;
  }

  html .wizard-progress.wizard-progress-lg .wizard-steps li:before,
  html .wizard-progress.wizard-progress-lg .wizard-steps li:after,
  html.dark .wizard-progress.wizard-progress-lg .wizard-steps li:before,
  html.dark .wizard-progress.wizard-progress-lg .wizard-steps li:after {
    height: 0px;
  }
}

.form-wizard {
  margin-bottom: 20px;
}

.form-wizard .tabs {
  margin-bottom: 0;
}

.form-wizard .tab-content {
  background: #fdfdfd;
  border: 0 none;
  box-shadow: none;
}

.form-wizard ul.pager .next a,
.form-wizard ul.pager .previous a,
.form-wizard ul.pager .first a,
.form-wizard ul.pager .last a,
.form-wizard ul.pager .finish a {
  cursor: pointer;
}

.form-wizard ul.pager .disabled a {
  cursor: not-allowed;
}

.form-wizard ul.pager .next.disabled {
  display: none;
}

html.dark .form-wizard .pager li > a,
html.dark .form-wizard .pager li > a:hover,
html.dark .form-wizard .pager li > a:focus,
html.dark .form-wizard .pager li > span {
  background: #2e353e;
  border-color: #282d36;
  color: #eee;
}

html.dark .form-wizard .pager .disabled > a,
html.dark .form-wizard .pager .disabled > a:hover,
html.dark .form-wizard .pager .disabled > a:focus,
html.dark .form-wizard .pager .disabled > span {
  background: #282d36;
  border-color: #282d36;
  color: #eee;
}

/* Word Rotator */
.wort-rotator {
  visibility: hidden;
  width: 100px;
  height: 0px;
  margin-bottom: -7px;
  display: inline-block;
  overflow: hidden;
  text-align: center;
  position: relative;
  top: -1px;
}

.wort-rotator.active {
  visibility: visible;
  width: auto;
}

.wort-rotator .wort-rotator-items {
  position: relative;
  top: 0;
  width: 100%;
}

.wort-rotator .wort-rotator-items span {
  display: block;
  white-space: nowrap;
}

.wort-rotator.highlight {
  top: 1px;
}

/* Word Rotator - Titles */
h1 .wort-rotator {
  margin-bottom: -12px;
}

h2 .wort-rotator {
  margin-bottom: -12px;
}

h2.word-rotator-title {
  line-height: 54px;
}

.fc .fc-toolbar h2 {
  color: #5a5c63;
  font-size: 20.8px;
  font-size: 1.3rem;
  font-weight: normal;
}

.fc .fc-toolbar .btn {
  background: #fff;
  box-shadow: none;
  text-shadow: none;
  font-size: 0.9em;
  padding: 7px 1.1em 6px;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: #212121;
}

.fc .fc-toolbar .btn.fc-state-active {
  color: #fff;
}

.fc .fc-day-grid-container {
  overflow: visible !important;
  height: auto !important;
}

.fc .fc-widget-header th {
  line-height: 35px;
}

.fc .fc-event {
  color: #fff !important;
}

.fc .table-bordered a {
  text-decoration: none;
}

.fc .table-bordered thead th {
  border-bottom-width: 1px !important;
}

.fc .fc-daygrid-event {
  line-height: 16px;
  padding-left: 4px;
  padding-right: 4px;
}

.fc .fc-daygrid-event .fc-event-title {
  font-weight: 400;
}

.fc .fc-daygrid-event .fc-event-time {
  font-weight: 800;
}

/* Fullcalendar - Event States */
/* Buttons - States */
.fc-event.fc-event-default {
  background: #ebebeb;
  border-color: #ebebeb;
}

.fc-event.fc-event-default .fc-event-inner {
  color: #777;
}

a.fc-event.fc-event-default:hover {
  color: #777;
}

.fc-event.fc-event-primary {
  background: #ccc;
  border-color: #ccc;
}

.fc-event.fc-event-primary .fc-event-inner {
  color: #fff;
}

a.fc-event.fc-event-primary:hover {
  color: #fff;
}

.fc-event.fc-event-success {
  background: #47a447;
  border-color: #47a447;
}

.fc-event.fc-event-success .fc-event-inner {
  color: #fff;
}

a.fc-event.fc-event-success:hover {
  color: #fff;
}

.fc-event.fc-event-warning {
  background: #ed9c28;
  border-color: #ed9c28;
}

.fc-event.fc-event-warning .fc-event-inner {
  color: #fff;
}

a.fc-event.fc-event-warning:hover {
  color: #fff;
}

.fc-event.fc-event-danger {
  background: #d2322d;
  border-color: #d2322d;
}

.fc-event.fc-event-danger .fc-event-inner {
  color: #fff;
}

a.fc-event.fc-event-danger:hover {
  color: #fff;
}

.fc-event.fc-event-info {
  background: #5a5c63;
  border-color: #5a5c63;
}

.fc-event.fc-event-info .fc-event-inner {
  color: #fff;
}

a.fc-event.fc-event-info:hover {
  color: #fff;
}

.fc-event.fc-event-dark {
  background: #5a5c63;
  border-color: #5a5c63;
}

.fc-event.fc-event-dark .fc-event-inner {
  color: #fff;
}

a.fc-event.fc-event-dark:hover {
  color: #fff;
}

/* Fullcalendar - External Events */
.external-event {
  cursor: move;
  display: inline-block;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: normal;
  margin: 5px;
  padding: 10px;
  text-align: left;
}

/* dark */
html.dark .fc .fc-toolbar h2 {
  color: #fff;
}

html.dark .fc .fc-toolbar .fc-button {
  background: #5a5c63;
  color: #fff;
}

html.dark .fc-unthemed th,
html.dark .fc-unthemed td,
html.dark .fc-unthemed thead,
html.dark .fc-unthemed tbody,
html.dark .fc-unthemed .fc-divider,
html.dark .fc-unthemed .fc-row,
html.dark .fc-unthemed .fc-popover {
  border-color: rgba(0, 0, 0, 0.3);
}

html.dark .fc-unthemed .fc-today {
  background: #5a5c63;
}

/* Invoice */
.invoice {
  padding: 0 15px 15px;
}

/* Invoice Address Tag */
.invoice address {
  color: #7f8597;
  line-height: 1.5em;
}

/* Invoice header */
.invoice header {
  border-bottom: 1px solid #dadada;
  margin-bottom: 15px;
}

.invoice header .h2,
.invoice header .h4 {
  letter-spacing: 0;
}

/* Invoice Billing Information */
.invoice .bill-to,
.invoice .bill-data {
  padding: 15px 0;
}

.invoice .bill-data .value {
  display: inline-block;
  margin-left: 10px;
  width: 90px;
}

/* Invoice table */
.invoice table.table {
  table-layout: fixed;
}

.invoice table.table > thead:first-child > tr > th {
  background-color: #f8f8f8;
  border-bottom: 1px solid #dadada;
  border-top: 1px solid #dadada;
}

.invoice table.table > tbody tr > td {
  border-color: #dadada;
}

/* Invoice table items */
.invoice .invoice-items > tbody tr:last-child > td {
  border-bottom: 1px solid #dadada;
}

.invoice .invoice-items #cell-id {
  width: 10%;
}

.invoice .invoice-items #cell-item {
  width: 20%;
}

.invoice .invoice-items #cell-desc {
  width: 20%;
}

.invoice .invoice-items #cell-price {
  width: 10%;
}

.invoice .invoice-items #cell-qty {
  width: 10%;
}

.invoice .invoice-items #cell-total {
  width: 10%;
}

/* Invoice summary */
.invoice-summary .col-sm-4 {
  padding-left: 0;
}

/* Invoice Responsiveness */
@media only screen and (max-width: 991px) {
  .invoice .table-responsive > table.table {
    table-layout: auto;
  }

  .invoice-summary .col-sm-4 {
    padding-left: 15px;
  }
}

/* Invoice Print */
@media print {
  .invoice .table-responsive {
    border: none !important;
    overflow: visible !important;
    width: auto !important;
  }

  .invoice table.table.invoice-items {
    table-layout: auto;
  }

  .invoice header .col-sm-6:first-child,
  .invoice header .col-sm-6:last-child,
  .invoice .bill-info .col-md-6 {
    float: left !important;
  }

  .invoice header .col-sm-6:first-child {
    width: 25% !important;
  }

  .invoice header .col-sm-6:last-child {
    width: 75% !important;
  }

  .invoice .bill-info .col-md-6 {
    width: 50% !important;
  }

  .invoice .invoice-summary .col-sm-4 {
    float: right;
    padding: 0;
    width: 40%;
  }
}

/* dark */
html.dark .invoice header {
  border-bottom-color: #282d36;
}

html.dark .invoice table.table > thead:first-child > tr > th {
  background-color: #282d36;
  border-bottom-color: #282d36;
  border-top-color: #282d36;
}

html.dark .invoice table.table > tbody tr > td {
  border-color: #282d36;
}

/* Turns Container With Sidebar Fluid when layout is Boxed */
.container-with-sidebar {
  max-width: none;
}

html.boxed .container-with-sidebar {
  width: 100% !important;
}

html.boxed:not(.sidebar-left-collapsed)
  .container-with-sidebar
  [class*="col-"]:not(.isotope-item) {
  width: 100%;
}

/* Container With Sidebar - Sidebar Collapsed */
@media (min-width: 768px) and (max-width: 991px) {
  .container-with-sidebar {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .container-with-sidebar {
    width: calc(970px - 144px);
  }
}

@media (min-width: 1200px) {
  .container-with-sidebar {
    width: calc(1170px - 144px);
  }
}

@media (min-width: 1600px) {
  .container-with-sidebar {
    width: calc(1570px - 144px) !important;
  }
}

/* Container With Sidebar */
@media (min-width: 768px) and (max-width: 1199px) {
  html:not(.sidebar-left-collapsed):not(.boxed) .container-with-sidebar {
    width: 100%;
  }

  html:not(.sidebar-left-collapsed):not(.boxed)
    .container-with-sidebar
    [class*="col-"]:not(.isotope-item) {
    width: 100%;
  }
}

@media (min-width: 992px) {
  html:not(.sidebar-left-collapsed):not(.boxed) .container-with-sidebar {
    width: calc(970px - 300px);
  }
}

@media (min-width: 1200px) {
  html:not(.sidebar-left-collapsed):not(.boxed) .container-with-sidebar {
    width: calc(1170px - 300px);
  }
}

@media (min-width: 1600px) {
  html:not(.sidebar-left-collapsed):not(.boxed) .container-with-sidebar {
    width: calc(1570px - 300px) !important;
  }
}

/* Custom Padding Bottom - When Boxed layout */
html.boxed .custom-padding {
  padding-bottom: 0;
  padding-top: 50px;
}

/* Custom Padding Bottom - With Sidebar */
@media (max-width: 1470px) {
  html:not(.sidebar-left-collapsed) .custom-padding {
    padding-bottom: 0;
    padding-top: 30px;
  }
}

/* Custom Padding Bottom - Without Sidebar */
@media (max-width: 1599px) {
  .custom-padding {
    padding-bottom: 0;
    padding-top: 30px;
  }
}

/* Custom Padding Bottom - Without Sidebar */
@media (max-width: 767px) {
  .custom-padding {
    padding-bottom: 0;
    padding-top: 0px;
  }
}

/* Custom Padding Bottom - Without Sidebar */
@media (min-width: 1600px) {
  .custom-padding {
    padding-bottom: 0;
    padding-top: 50px;
  }
}

/* Custom Position For Porto Front-End Landing Dashboard */
.custom-pos {
  position: relative;
  top: 20px;
}

@media (max-width: 1599px) {
  .custom-pos {
    top: 70px;
  }

  .custom-pos .abs-bottom-left {
    left: 0;
  }
}

@media (max-width: 1199px) {
  .custom-pos .abs-bottom-left {
    width: 30%;
    bottom: 120px;
  }
}

@media (max-width: 991px) {
  .custom-pos {
    top: 10px;
  }

  .custom-pos .abs-bottom-left {
    width: initial;
    left: 8vw;
    bottom: -10px;
    transform: translateX(-50%);
  }
}

/* Custom Position For Porto Front-End Landing Dashboard - With Sidebar */
@media (max-width: 1599px) {
  html:not(.sidebar-left-collapsed) .custom-pos {
    top: 100px;
  }

  html:not(.sidebar-left-collapsed) .custom-pos .abs-bottom-left {
    width: 30%;
    bottom: 80px;
  }
}

@media (max-width: 1199px) {
  html:not(.sidebar-left-collapsed) .custom-pos {
    top: 10px;
  }

  html:not(.sidebar-left-collapsed) .custom-pos .abs-bottom-left {
    width: 30%;
    bottom: 190px;
  }
}

@media (max-width: 991px) {
  html:not(.sidebar-left-collapsed) .custom-pos .abs-bottom-left {
    width: 35%;
    left: 8vw;
    bottom: 0;
  }
}

/* Custom Position For Porto Front-End Landing Dashboard - Layout Boxed */
html.boxed .custom-pos {
  top: 100px;
}

html.boxed .custom-pos .abs-bottom-left {
  left: -5px;
}

@media (max-width: 991px) {
  html.boxed .custom-pos .abs-bottom-left {
    width: 35%;
    left: 9vw;
  }
}

html.boxed:not(.sidebar-left-collapsed) .custom-pos {
  top: 10px;
}

html.boxed:not(.sidebar-left-collapsed) .custom-pos .abs-bottom-left {
  left: 6vw;
}

@media (max-width: 1599px) {
  html.boxed:not(.sidebar-left-collapsed) .custom-pos .abs-bottom-left {
    width: 24%;
  }
}

@media (max-width: 1199px) {
  html.boxed:not(.sidebar-left-collapsed) .custom-pos .abs-bottom-left {
    width: 27%;
    left: 2vw;
  }
}

@media (max-width: 991px) {
  html.boxed:not(.sidebar-left-collapsed) .custom-pos .abs-bottom-left {
    width: 35%;
    left: 9vw;
  }
}

/* Section Padding */
.section-padding {
  padding: 90px 0 75px;
}

/* Section Full Width Background Light */
.section-full-width-bg-light {
  position: relative;
  background-color: #fff;
}

.section-full-width-bg-light:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  height: 100%;
  background-color: #fff;
  z-index: 0;
  transform: translateX(-50%);
}

html.dark .section-full-width-bg-light {
  background-color: #5a5c63;
}

html.dark .section-full-width-bg-light:before {
  background-color: #5a5c63;
}

/* Absolute Position Bottom Left */
.abs-bottom-left {
  position: absolute;
  bottom: -3px;
  left: 40px;
}

/* Overflow Hidden */
.overflow-hidden {
  overflow: hidden;
}

/* List */
.list.list-icons > li {
  line-height: 2.2;
}

/* Testimonial */
.testimonial {
  background: #ecedf0;
  padding: 40px;
}

.testimonial blockquote {
  position: relative;
  border-left: none;
  font-family: Georgia, serif;
  padding: 10px 60px;
  margin-bottom: 0;
}

.testimonial blockquote p {
  font-family: Georgia, serif;
  font-size: 18px;
  line-height: 1.8;
}

html.dark .testimonial {
  background: #282d36;
}

.log-viewer {
  line-height: 41.6px;
  line-height: 2.6rem;
  font-family: monospace;
  font-size: 13.6px;
  font-size: 0.85rem;
}

.mailbox .content-with-menu-container {
  background: #fff;
}

.mailbox .mailbox-bullets .ball {
  border: 5px solid red;
  border-radius: 100px;
  display: block;
  float: right;
  margin-top: 6px;
}

.mailbox .mailbox-bullets .ball.pink {
  border-color: #ea4c89;
}

.mailbox .mailbox-bullets .ball.green {
  border-color: #9ae14f;
}

.mailbox .mailbox-bullets .ball.blue {
  border-color: #1bc3e1;
}

.mailbox .mailbox-bullets .ball.orange {
  border-color: #e2a917;
}

.mailbox .mailbox-folder {
  height: auto !important;
  padding: 0 0 40px;
}

/* mailbox - main header */
.mailbox .mailbox-folder .mailbox-header {
  padding: 38px 40px 43px;
}

@media only screen and (max-width: 767px) {
  .mailbox .mailbox-folder .mailbox-header {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .mailbox .mailbox-folder .mailbox-header .mailbox-title {
    margin-bottom: 10px !important;
  }
}

.mailbox .mailbox-folder .mailbox-header .search {
  float: right;
  margin-top: 3px;
  max-width: 100px;
}

.mailbox .mailbox-folder .mailbox-header .search input[type="text"] {
  transition-property: width, margin;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.05, 0.91, 0.25, 0.99);
}

.mailbox .mailbox-folder .mailbox-header .search input[type="text"]:focus {
  margin-left: -150%;
  width: 250%;
}

@media only screen and (max-width: 767px) {
  .mailbox .mailbox-folder .mailbox-header .search input[type="text"]:focus {
    margin-left: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .mailbox .mailbox-folder .mailbox-header .search {
    max-width: none;
    width: 100%;
  }
}

/* mailbox - actions */
.mailbox .mailbox-actions {
  border-top: 1px solid #efefef;
  padding-left: 40px;
  padding-right: 40px;
}

@media only screen and (max-width: 767px) {
  .mailbox .mailbox-actions {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }
}

.mailbox .mailbox-actions ul a {
  color: #5a5c63;
  text-decoration: none;
  font-size: 14.4px;
  font-size: 0.9rem;
}

.mailbox .mailbox-actions ul a.item-action {
  background: #fff;
  border-radius: 100px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  color: #b4bbc1;
  display: inline-block;
  height: 30px;
  line-height: 30.4px;
  line-height: 1.9rem;
  position: relative;
  width: 30px;
  text-align: center;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.2, 0.6, 0.25, 1);
  transition-delay: 0.1s;
}

.mailbox .mailbox-actions ul a.item-action:hover {
  color: #57636c;
  text-decoration: none;
}

.mailbox .mailbox-actions ul a.item-action.text-primary:hover {
  color: #b3b3b3 !important;
}

.mailbox .mailbox-actions ul a.item-action.text-success:hover {
  color: #388038 !important;
}

.mailbox .mailbox-actions ul a.item-action.text-warning:hover {
  color: #d18211 !important;
}

.mailbox .mailbox-actions ul a.item-action.text-danger:hover {
  color: #a82824 !important;
}

.mailbox .mailbox-actions ul a.item-action.text-info:hover {
  color: #31b0d5 !important;
}

.mailbox .mailbox-actions ul a.item-action.text-dark:hover {
  color: black !important;
}

/* mailbox - mail list */
.mailbox .mailbox-email-list {
  border-top: 1px solid #f7f7f7;
  font-weight: 300;
}

@media only screen and (max-width: 767px) {
  .mailbox .mailbox-email-list {
    position: static !important;
  }
}

.mailbox .mailbox-email-list li {
  border-bottom: 1px solid #f7f7f7;
  height: 50px;
  line-height: 50px;
  padding: 0 40px;
  position: relative;
  font-size: 16px;
  font-size: 1rem;
}

@media only screen and (max-width: 767px) {
  .mailbox .mailbox-email-list li {
    height: 75px;
    padding: 0 20px;
  }
}

.mailbox .mailbox-email-list li a {
  color: #777;
}

.mailbox .mailbox-email-list li:hover {
  background: #fafafa;
}

.mailbox .mailbox-email-list li.unread a {
  color: #555;
  font-weight: 500;
}

.mailbox .mailbox-email-list li.active {
  background: #ccc;
}

.mailbox .mailbox-email-list li.active a {
  color: #fff;
}

.mailbox .mailbox-email-list .mail-label {
  border: 4px solid transparent;
  border-radius: 10px;
  display: inline-block;
  left: 16px;
  position: absolute;
  top: 21px;
}

@media only screen and (max-width: 767px) {
  .mailbox .mailbox-email-list .mail-label {
    border-radius: 0;
    border-width: 1px;
    bottom: 0;
    left: 0;
    top: 0;
  }
}

.mailbox .mailbox-email-list .col-sender {
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  .mailbox .mailbox-email-list .col-sender {
    width: 100%;
  }
}

.mailbox .mailbox-email-list .col-sender p {
  left: 80px;
  overflow: hidden;
  position: absolute;
  right: 0;
  text-overflow: ellipsis;
  top: 13px;
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  .mailbox .mailbox-email-list .col-sender p {
    left: 60px;
  }
}

.mailbox .mailbox-email-list .col-mail {
  bottom: 0;
  left: 290px;
  position: absolute;
  right: 40px;
  top: 0;
}

@media only screen and (max-width: 767px) {
  .mailbox .mailbox-email-list .col-mail {
    left: 60px;
    right: 20px;
  }
}

.mailbox .mailbox-email-list .col-mail .mail-content,
.mailbox .mailbox-email-list .col-mail .mail-date,
.mailbox .mailbox-email-list .col-mail .mail-attachment {
  position: absolute;
  top: 0;
}

.mailbox .mailbox-email-list .col-mail .mail-content {
  left: 0;
  right: 140px;
  top: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 767px) {
  .mailbox .mailbox-email-list .col-mail .mail-content {
    right: 0;
    top: 25px;
    line-height: 2.9;
  }
}

.mailbox .mailbox-email-list .col-mail .mail-attachment {
  color: #bbb;
  right: 100px;
  line-height: 50px;
}

@media only screen and (max-width: 767px) {
  .mailbox .mailbox-email-list .col-mail .mail-attachment {
    font-size: 1.1rem;
    line-height: 1.4rem;
    right: 55px;
    top: 6px;
  }
}

.mailbox .mailbox-email-list .col-mail .mail-date {
  padding-left: 80px;
  right: 0;
  top: 13px;
  width: 150px;
}

@media only screen and (max-width: 767px) {
  .mailbox .mailbox-email-list .col-mail .mail-date {
    font-size: 0.7rem;
    line-height: 1.4rem;
    padding-left: 0;
    text-align: right;
    top: 5px;
    width: 130px;
  }
}

/* Mailbox Mail List - Unstyle nano for non fixed layouts and responsive */
html.scroll .mailbox .mailbox-email-list .nano,
html.boxed .mailbox .mailbox-email-list .nano {
  position: static;
  height: auto;
  overflow: visible;
  width: auto;
}

html.scroll .mailbox .mailbox-email-list .nano .nano-content,
html.boxed .mailbox .mailbox-email-list .nano .nano-content {
  position: static;
  overflow: visible;
}

@media only screen and (max-width: 767px) {
  .mailbox .mailbox-email-list .nano {
    position: static;
    height: auto;
    overflow: visible;
    width: auto;
  }

  .mailbox .mailbox-email-list .nano .nano-content {
    position: static;
    overflow: visible;
  }
}

/* Mailbox Mail List + Fixed Layout */
@media only screen and (min-width: 768px) {
  html.fixed .mailbox .mailbox-email-list {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    overflow: hidden;
    top: 191px;
  }
}

/* Mailbox - Email */
.mailbox .mailbox-email {
  background: #fafafa;
}

.mailbox .mailbox-email .mailbox-email-header {
  background: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  margin: -40px -40px 0 -40px;
  padding: 30px;
}

@media only screen and (max-width: 767px) {
  .mailbox .mailbox-email .mailbox-email-header {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.mailbox .mailbox-email .mailbox-close-mail {
  color: #ccc;
  cursor: pointer;
  font-weight: 300;
  float: left;
}

.mailbox .mailbox-email .mailbox-close-mail:hover {
  color: #d9d9d9;
}

.mailbox .mailbox-email .mailbox-close-mail,
.mailbox .mailbox-email .mailbox-close-mail:focus {
  text-decoration: none;
}

.mailbox .mailbox-email .card .card-header {
  background: #fff;
  border-bottom-color: #efefef;
}

.mailbox .mailbox-email .card .card-header .card-actions {
  top: 17px;
}

.mailbox .mailbox-email .card .card-header .card-actions a:hover {
  background: #fff;
  color: #ccc;
}

.mailbox .mailbox-email .card .card-footer {
  background: #fff;
  border-top-color: #efefef;
  color: #bbb;
}

/* Mailbox - Showing Menu Toggle */
@media only screen and (min-width: 768px) and (max-width: 1365px) {
  .mailbox .mailbox-folder .mailbox-header .mailbox-title {
    position: relative;
    top: 28px;
  }

  .mailbox .mailbox-email .mailbox-email-header {
    padding-top: 80px;
  }

  html.inner-menu-opened
    .mailbox
    .mailbox-folder
    .mailbox-header
    .mailbox-title {
    position: static;
  }

  html.inner-menu-opened .mailbox .mailbox-email .mailbox-email-header {
    padding-top: 30px;
  }
}

/* Mailbox Compose */
.mailbox-compose {
  margin: -20px -40px 0 -40px;
  padding: 0 15px;
}

.mailbox-compose .form-bordered .form-group {
  min-height: 55px;
}

.mailbox-compose label {
  top: -11px !important;
}

.mailbox-compose .mailbox-compose-field .bootstrap-tagsinput,
.mailbox-compose .mailbox-compose-field .form-control {
  padding-left: 120px;
}

.mailbox-compose .compose {
  margin: 0 40px;
}

.mailbox-compose .compose .note-editable {
  min-height: 250px;
  border: 1px solid #eff2f7;
}

.mailbox-compose .compose .note-editor,
.mailbox-compose .compose .note-toolbar {
  border: none;
}

.mailbox-compose .compose .note-resizebar {
  display: none;
}

@media only screen and (max-width: 767px) {
  .mailbox-compose {
    margin: -20px -15px 0 -15px;
  }

  .mailbox-compose .compose {
    margin-left: 5px;
    margin-right: 5px;
  }
}

/* dark */
html.dark .mailbox .mailbox-email,
html.dark .mailbox .content-with-menu-container {
  background: #e6e6ea;
}

html.dark .mailbox .mailbox-actions {
  border-top-color: #282d36;
}

html.dark .mailbox .mailbox-actions ul a.item-action {
  background: #2e353e;
}

html.dark .mailbox .mailbox-email-list {
  border-top-color: #282d36;
}

html.dark .mailbox .mailbox-email-list li {
  border-bottom-color: #242830;
}

html.dark .mailbox .mailbox-email-list li:hover {
  background: #161a1e;
}

html.dark .mailbox .mailbox-email-list li a {
  color: #808697;
}

html.dark .mailbox .mailbox-email-list li.unread a {
  color: #9ca1ae;
}

html.dark .mailbox .mailbox-email .mailbox-email-header {
  background: #5a5c63;
}

html.dark .mailbox .mailbox-email .card .card-header,
html.dark .mailbox .mailbox-email .card .card-footer {
  background-color: #282d36;
  border-color: #5a5c63;
  color: #808697;
}

html.dark .mailbox .mailbox-email .card .card-header .card-title,
html.dark .mailbox .mailbox-email .card .card-footer .card-title {
  color: #808697;
}

html.dark .mailbox .mailbox-email .card .card-header .card-actions a:hover {
  background: #e6e6ea;
}

html.dark .mailbox .mailbox-compose .note-editor .note-toolbar,
html.dark .mailbox .mailbox-compose .note-editor .note-statusbar,
html.dark .mailbox .mailbox-compose .note-editor .note-editable {
  background: #e6e6ea;
}

html.dark .mailbox .mailbox-compose .note-editor .note-editable {
  color: #808697;
}

.media-gallery ul.mg-folders {
  list-style: none;
  padding: 0;
  margin: 0;
}

.media-gallery ul.mg-folders > li {
  display: block;
  position: relative;
}

.media-gallery ul.mg-folders > li a.menu-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 80px;
}

.media-gallery ul.mg-folders > li a.menu-item i {
  margin-right: 5px;
  width: 16px;
}

.media-gallery ul.mg-folders > li .item-options {
  position: absolute;
  padding: 10px 0;
  right: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.1s ease-in;
}

.media-gallery ul.mg-folders > li .item-options a:hover {
  text-decoration: none;
}

.media-gallery ul.mg-folders > li:hover .item-options {
  opacity: 1;
}

.media-gallery ul.mg-tags {
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
}

.media-gallery ul.mg-tags::after {
  clear: both;
  content: "";
  display: block;
}

.media-gallery ul.mg-tags > li {
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
}

.media-gallery ul.mg-tags > li a {
  display: block;
  padding: 2px 7px;
  font-size: 11px;
  background-color: #5a5c63;
  border-radius: 5px;
  color: #666;
}

.media-gallery ul.mg-tags > li a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #ccc;
}

.media-gallery .mg-files {
  padding: 5px 0 30px;
}

.media-gallery .mg-files .thumbnail {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 30px;
  transform: translate3d(0, 0, 0);
}

.media-gallery .mg-files .thumbnail .thumb-preview {
  position: relative;
  z-index: 2;
}

.media-gallery .mg-files .thumbnail .thumb-preview .thumb-image {
  display: block;
  overflow: hidden;
}

.media-gallery .mg-files .thumbnail .thumb-preview img {
  width: 100%;
  border-radius: 5px;
}

.media-gallery .mg-files .thumbnail .thumb-preview .mg-thumb-options {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  overflow: hidden;
}

.media-gallery .mg-files .thumbnail .thumb-preview .mg-thumb-options .mg-zoom {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 15px 15px;
  color: #fff;
  background-color: #ccc;
  border-radius: 0 0 0 15px;
  cursor: pointer;
  transition: transform 0.1s linear 0.1s;
  transform: translate(100%, 0);
}

.media-gallery
  .mg-files
  .thumbnail
  .thumb-preview
  .mg-thumb-options
  .mg-zoom
  > i {
  position: relative;
  top: 3px;
}

.media-gallery
  .mg-files
  .thumbnail
  .thumb-preview
  .mg-thumb-options
  .mg-toolbar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ccc;
  color: #fff;
  padding: 6px 10px;
  transition: transform 0.1s linear 0.1s;
  transform: translate(0, 100%);
}

.media-gallery
  .mg-files
  .thumbnail
  .thumb-preview
  .mg-thumb-options
  .mg-toolbar::after {
  clear: both;
  content: "";
  display: block;
}

.media-gallery
  .mg-files
  .thumbnail
  .thumb-preview
  .mg-thumb-options
  .mg-toolbar
  .mg-option {
  margin: 0;
}

.media-gallery
  .mg-files
  .thumbnail
  .thumb-preview
  .mg-thumb-options
  .mg-toolbar
  .mg-group
  > a {
  color: #fff;
  padding: 0 5px;
}

.media-gallery
  .mg-files
  .thumbnail
  .thumb-preview
  .mg-thumb-options
  .mg-toolbar
  .mg-group
  .mg-toggle {
  color: #fff;
  background: none;
  border: none;
  padding: 2px 2px 2px 10px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  cursor: pointer;
}

.media-gallery
  .mg-files
  .thumbnail
  .thumb-preview
  .mg-thumb-options
  .mg-toolbar
  .mg-group
  .mg-toggle:focus {
  outline: 0 !important;
}

.media-gallery
  .mg-files
  .thumbnail
  .thumb-preview
  .mg-thumb-options
  .mg-toolbar
  .mg-group
  .mg-dropdown {
  transform: none !important;
  top: 32px !important;
  left: auto !important;
  right: 0 !important;
}

.media-gallery .mg-files .thumbnail .mg-title {
  margin: 13px 0 2px;
  padding-bottom: 2px;
  display: inline-block;
  font-size: 16px;
  font-size: 1rem;
}

.media-gallery .mg-files .thumbnail .mg-title small {
  position: relative;
  top: 0;
  left: 0;
  color: #9e9e9e;
  opacity: 0;
  z-index: 1;
  font-size: 16px;
  font-size: 1rem;
}

.media-gallery .mg-files .thumbnail .mg-description::after {
  clear: both;
  content: "";
  display: block;
}

.media-gallery .mg-files .thumbnail.thumbnail-selected {
  border-color: #fff;
  box-shadow: 0 0 8px -1px #ccc;
}

.media-gallery .mg-files .thumbnail.thumbnail-selected .mg-title small {
  opacity: 1;
}

.media-gallery
  .mg-files
  .thumbnail.thumbnail-selected
  .thumb-preview
  .mg-thumb-options {
  visibility: visible;
}

.media-gallery
  .mg-files
  .thumbnail.thumbnail-selected
  .thumb-preview
  .mg-thumb-options
  .mg-toolbar {
  transform: translate(0, 0);
}

.media-gallery .mg-files .thumbnail:hover .mg-title small {
  opacity: 1;
}

.media-gallery .mg-files .thumbnail:hover .thumb-preview img {
  transform: scale(1.1);
}

.media-gallery .mg-files .thumbnail:hover .thumb-preview .mg-thumb-options {
  visibility: visible;
}

.media-gallery
  .mg-files
  .thumbnail:hover
  .thumb-preview
  .mg-thumb-options
  .mg-zoom {
  transform: translate(0, 0);
}

.media-gallery
  .mg-files
  .thumbnail:hover
  .thumb-preview
  .mg-thumb-options
  .mg-toolbar {
  transform: translate(0, 0);
}

html.sidebar-light:not(.dark) .media-gallery ul.mg-tags > li a {
  background-color: #f2f2f2;
  color: #777;
}

html.dark .media-gallery .mg-files .thumbnail.thumbnail-selected {
  border-color: #242830;
}

html.dark .media-gallery .mg-files .thumbnail .mg-title small {
  color: #808697;
}

/* Change Content Background Color */
html.search-results body {
  background: #fff;
}

/* Search Results Wrapper */
.search-content {
  margin: -40px;
}

.search-content .search-control-wrapper {
  background: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  margin-top: 3px;
  padding: 20px;
}

/* Search Results Tabs */
.search-content .search-toolbar {
  border-bottom: 1px solid #ebebeb;
  margin: 0 0 40px;
}

.search-content .search-toolbar .nav-pills {
  margin: 0 40px;
}

.search-content .search-toolbar .nav-pills li {
  margin: 0 15px 0 0;
}

.search-content .search-toolbar .nav-pills li:last-child {
  margin-right: 0;
}

.search-content .search-toolbar .nav-pills li a {
  padding-left: 5px;
  padding-right: 5px;
  border-left: 0;
  border-right: 0;
  margin-bottom: -1px;
}

.search-content .search-toolbar .nav-pills li a,
.search-content .search-toolbar .nav-pills li a:hover,
.search-content .search-toolbar .nav-pills li a:focus {
  background: none;
  border-radius: 0;
  border-bottom: 2px solid #fff;
  border-top: 2px solid #fff;
  color: #777;
}

.search-content .search-toolbar .nav-pills li a:hover,
.search-content .search-toolbar .nav-pills li a:focus {
  background: none;
  border-radius: 0;
  border-bottom: 2px solid #fff;
  border-top: 2px solid #fff;
  color: #555;
}

.search-content .search-toolbar .nav-pills li.active a {
  color: #ccc;
  border-bottom-color: #ccc;
}

.search-content > .tab-content {
  border: none;
  box-shadow: none;
  padding: 0 40px;
}

/* Search Result Totals */
.search-content .total-results {
  margin-top: -25px;
}

/* Search Results List */
.search-results-list {
  max-width: 750px;
}

.search-results-list li {
  border-bottom: 1px solid #eee;
  margin-bottom: 15px;
  padding-bottom: 15px;
  position: relative;
}

.search-results-list li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.search-results-list .result-type {
  right: 5px;
  position: absolute;
  top: 5px;
}

.search-results-list a {
  border-radius: 4px;
  display: block;
  padding: 25px;
  text-decoration: none;
}

.search-results-list a .title {
  margin-top: 0;
}

.search-results-list a .description {
  color: #777;
}

.search-results-list a p:last-child {
  margin-bottom: 0;
}

.search-results-list a:hover {
  background: #f7f7f7;
}

.search-results-list .has-thumb {
  display: table;
  width: 100%;
}

.search-results-list .has-thumb .result-thumb,
.search-results-list .has-thumb .result-data {
  display: table-cell;
  vertical-align: top;
}

.search-results-list .result-thumb {
  padding-right: 25px;
}

.search-results-list .result-thumb img,
.search-results-list .result-thumb .fa,
.search-results-list .result-thumb .fab,
.search-results-list .result-thumb .fal,
.search-results-list .result-thumb .far,
.search-results-list .result-thumb .fas {
  height: 75px;
  width: 75px;
}

.search-results-list .result-thumb .fa,
.search-results-list .result-thumb .fab,
.search-results-list .result-thumb .fal,
.search-results-list .result-thumb .far,
.search-results-list .result-thumb .fas {
  background: #ccc;
  color: #fff;
  font-size: 57.6px;
  font-size: 3.6rem;
  line-height: 120px;
  line-height: 7.5rem;
  text-align: center;
}

/* Search Results Responsive */
@media only screen and (max-width: 767px) {
  .search-content {
    margin: -20px;
  }
}

@media only screen and (max-width: 480px) {
  .search-results-list .has-thumb {
    display: block;
  }

  .search-results-list .has-thumb::after {
    clear: both;
    content: "";
    display: block;
  }

  .search-results-list .has-thumb .result-thumb,
  .search-results-list .has-thumb .result-data {
    display: block;
  }

  .search-results-list .has-thumb .result-thumb {
    float: left;
  }

  .search-results-list .has-thumb .result-data .title {
    margin-top: 3px;
  }

  .search-results-list .result-thumb img,
  .search-results-list .result-thumb .fa,
  .search-results-list .result-thumb .fab,
  .search-results-list .result-thumb .fal,
  .search-results-list .result-thumb .far,
  .search-results-list .result-thumb .fas {
    height: 35px;
    width: 35px;
  }

  .search-results-list .result-thumb .fa,
  .search-results-list .result-thumb .fab,
  .search-results-list .result-thumb .fal,
  .search-results-list .result-thumb .far,
  .search-results-list .result-thumb .fas {
    font-size: 0.9rem;
    line-height: 3.5rem;
  }
}

/* dark */
html.dark .search-content .search-control-wrapper {
  background: #e6e6ea;
  border-bottom-color: #282d36;
}

html.dark .search-content .tab-content {
  background: transparent;
}

html.dark .search-content .search-toolbar {
  background: #5a5c63;
  border-bottom-color: #5a5c63;
}

html.dark .search-content .search-toolbar .nav-pills a,
html.dark .search-content .search-toolbar .nav-pills a:hover,
html.dark .search-content .search-toolbar .nav-pills a:focus {
  border-bottom-color: #5a5c63;
  border-top-color: #5a5c63;
  color: #808697;
}

html.dark .search-content .search-toolbar .nav-pills a:hover,
html.dark .search-content .search-toolbar .nav-pills a:focus {
  border-bottom-color: #5a5c63;
  border-top-color: #5a5c63;
  color: #555;
}

html.dark .search-content .search-toolbar .nav-pills li.active a,
html.dark .search-content .search-toolbar .nav-pills li.active a:hover,
html.dark .search-content .search-toolbar .nav-pills li.active a:focus {
  color: #ccc;
  border-bottom-color: #ccc;
}

html.dark .search-content .search-results-list li {
  border-bottom-color: #282d36;
}

html.dark .search-content .search-results-list a .description {
  color: #808697;
}

html.dark .search-content .search-results-list a:hover {
  background: #282d36;
}

.ml-3 {
  margin-left: 3px;
}

/* Sign Screens - Wrappers */
.body-sign {
  display: table;
  height: 100vh;
  margin: 0 auto;
  max-width: 500px;
  padding: 0 15px;
  width: 100%;
}

.body-sign .center-sign {
  display: table-cell;
  padding-top: 20px;
  vertical-align: middle;
}

.body-sign .card-sign {
  background: transparent;
}

.body-sign .card-sign .card-title-sign .title {
  background-color: #ccc;
  border-radius: 5px 5px 0 0;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  font-size: 0.75rem;
  padding: 13px 17px;
  vertical-align: bottom;
}

.body-sign .card-sign .card-body {
  background: #fff;
  border-top: 5px solid #ccc;
  border-radius: 5px 0 5px 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 33px 33px 15px;
}

/* Sign Screens - Elements */
.body-sign .checkbox-custom {
  margin-top: 8px;
}

.body-sign .line-thru {
  display: block;
  font-size: 12px;
  font-size: 0.75rem;
  position: relative;
}

.body-sign .line-thru span {
  color: #ccc;
  position: relative;
  z-index: 3;
}

.body-sign .line-thru:before {
  background-color: #fff;
  content: "";
  height: 10px;
  left: 50%;
  position: absolute;
  margin: -5px 0 0 -20px;
  top: 50%;
  width: 40px;
  z-index: 2;
}

.body-sign .line-thru:after {
  border-bottom: 1px solid #dadada;
  content: "";
  display: block;
  left: 10%;
  position: absolute;
  top: 47%;
  width: 81%;
  z-index: 1;
}

/* Lock Screen */
.body-locked {
  /* background: url(../img/patterns/noisy_net.png) repeat; */
  max-width: none;
  min-height: 400px;
}

.body-locked.body-locked-inline {
  background: none;
  bottom: 0;
  height: 100%;
  left: 0;
  min-height: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
}

.body-locked .card-sign {
  margin: 0 auto;
  max-width: 400px;
  padding-top: 40px;
}

.body-locked .card-sign .card-body {
  border-radius: 5px;
  position: relative;
}

.body-locked .current-user {
  margin-top: 60px;
  margin-bottom: 35px;
}

.body-locked .current-user .user-image {
  border: 5px solid #ccc;
  border-radius: 150px;
  height: 150px;
  left: 50%;
  position: absolute;
  margin-left: -75px;
  top: -75px;
  width: 150px;
}

.body-locked .current-user .user-name {
  font-size: 32px;
  font-size: 2rem;
}

.body-locked .current-user .user-email {
  font-size: 11.2px;
  font-size: 0.7rem;
}

/* Locked Screen - Responsive Landscape */
@media only screen and (max-width: 767px) and (orientation: landscape) {
  .body-locked .card-sign,
  .body-locked.body-locked-inline .card-sign {
    padding-top: 0;
  }

  .body-locked .center-sign,
  .body-locked.body-locked-inline .center-sign {
    padding-top: 0;
  }

  .body-locked .center-sign .current-user,
  .body-locked.body-locked-inline .center-sign .current-user {
    margin-bottom: 45px;
    margin-left: 100px;
    margin-top: 10px;
  }

  .body-locked .center-sign .current-user .user-image,
  .body-locked.body-locked-inline .center-sign .current-user .user-image {
    height: 100px;
    left: 35px;
    margin-left: 0;
    margin-top: 0;
    top: 15px;
    width: 100px;
  }
}

/* Lock Screen - Modal */
.mfp-lock-screen.mfp-bg {
  /* background: #000 url(../img/patterns/noisy_net.png) repeat; */
  opacity: 0.99;
  z-index: 9998;
}

.mfp-lock-screen.mfp-wrap {
  background: none;
  z-index: 9999;
}

/* dark */
html.dark .body-sign .card-sign .card-body {
  background-color: #2e353e;
}

html.dark .body-sign .line-thru:before {
  background-color: #2e353e;
}

html.dark .body-sign .line-thru:after {
  border-bottom-color: #282d36;
}

.timeline .tm-body {
  position: relative;
  padding: 30px 0;
}

.timeline .tm-body:after {
  background: linear-gradient(
    to bottom,
    rgba(80, 80, 80, 0) 0%,
    #505050 8%,
    #505050 92%,
    rgba(80, 80, 80, 0) 100%
  );
  content: "";
  display: block;
  height: 100%;
  left: 140px;
  margin-left: -2px;
  position: absolute;
  top: 0;
  width: 3px;
  z-index: 0;
  opacity: 0.35;
}

.timeline .tm-title {
  position: relative;
  display: inline-block;
  text-align: center;
  min-width: 200px;
  background-color: #fff;
  padding: 4px 5px;
  margin: 0 40px;
  z-index: 1;
  border-radius: 3px;
}

.timeline .tm-items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.timeline .tm-items > li {
  position: relative;
  margin: 30px 0;
  padding: 0 0 0 190px;
  min-height: 65px;
  z-index: 1;
}

.timeline .tm-items > li .tm-datetime {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100px;
  height: 48px;
  margin-top: -24px;
  text-align: right;
  z-index: 3;
}

.timeline .tm-items > li .tm-datetime .tm-datetime-time {
  color: #ccc;
  font-size: 22.4px;
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0;
  white-space: nowrap;
}

.timeline .tm-items > li .tm-icon {
  position: absolute;
  top: 50%;
  left: 140px;
  background-color: #ecedf0;
  border: 3px solid #ccc;
  color: #ccc;
  font-size: 28px;
  padding: 10px;
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 29px;
  margin-top: -28px;
  margin-left: -28px;
  z-index: 2;
  border-radius: 28px;
}

.timeline .tm-items > li .tm-box {
  position: relative;
  background: #fff;
  min-height: 65px;
  padding: 10px 20px;
  border: 1px solid #e9e9e9;
  border-radius: 6px;
}

.timeline .tm-items > li .tm-box:after {
  right: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-right-color: #fff;
  border-width: 8px;
  top: 50%;
  margin-top: -8px;
  z-index: 2;
}

.timeline .tm-items > li .tm-box p:last-child {
  margin-bottom: 0;
}

.timeline .tm-items > li .tm-box .tm-meta {
  margin: 10px 0 0;
}

.timeline .tm-items > li .tm-box .tm-meta span {
  display: inline-block;
  padding-right: 8px;
}

.timeline .tm-items > li .tm-box .tm-meta span:last-child,
.timeline .tm-items > li .tm-box .tm-meta span:last-of-type {
  padding-right: 0;
}

.timeline.timeline-simple .tm-body:after {
  left: 30px;
}

.timeline.timeline-simple .tm-body .tm-title {
  border: 1px solid #e9e9e9;
  margin: 0 10px;
}

.timeline.timeline-simple .tm-body .tm-items > li {
  padding: 0 0 0 55px;
}

.timeline.timeline-simple .tm-body .tm-items > li:before {
  display: block;
  position: absolute;
  content: " ";
  background: none repeat scroll 0 0 #ccc;
  border-radius: 50%;
  box-shadow:
    0 0 0 3px #fff,
    0 0 0 6px #ccc;
  height: 7px;
  left: 30px;
  top: 50%;
  width: 8px;
  margin-left: -4px;
  margin-top: -4px;
}

.timeline.timeline-simple .tm-body .tm-items > li .tm-box:before {
  left: -17px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-right-color: #e9e9e9;
  border-width: 8px;
  top: 50%;
  margin-top: -8px;
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .timeline .tm-body:after {
    left: 20px;
  }

  .timeline .tm-title {
    margin: 0;
  }

  .timeline .tm-items > li {
    padding-left: 50px;
  }

  .timeline .tm-items > li .tm-info {
    margin: 0 0 15px;
  }

  .timeline .tm-items > li .tm-info::after {
    clear: both;
    content: "";
    display: block;
  }

  .timeline .tm-items > li .tm-icon {
    border-width: 2px;
    float: left;
    font-size: 22px;
    height: 40px;
    line-height: 36px;
    margin: 0 15px 0 0;
    padding: 0;
    position: static;
    width: 40px;
  }

  .timeline .tm-items > li .tm-datetime {
    margin: 0;
    position: static;
    text-align: left;
  }

  .timeline .tm-items > li .tm-datetime .tm-datetime-date {
    font-size: 0.75rem;
    line-height: 1.3;
  }

  .timeline .tm-items > li .tm-datetime .tm-datetime-time {
    font-size: 1rem;
    line-height: 1.3;
  }
}

@media only screen and (max-width: 767px) {
  .timeline .tm-items > li .tm-box .tm-meta span {
    display: block;
  }
}

html.dark .timeline .tm-items > li .tm-box {
  background: #282d36;
  border-color: #5a5c63;
}

html.dark .timeline .tm-items > li .tm-box:after {
  border-right-color: #282d36;
}

html.dark .timeline .tm-items > li .tm-box:before {
  border-right-color: #5a5c63;
}

html.dark .timeline .tm-items > li .tm-icon {
  background-color: #e6e6ea;
}

html.dark .timeline .tm-title {
  background-color: #282d36;
  border-color: #5a5c63;
}

html.dark .timeline.timeline-simple .tm-body .tm-title {
  background-color: #282d36;
  border-color: #5a5c63;
}

html.dark .timeline.timeline-simple .tm-body .tm-items > li:before {
  box-shadow:
    0 0 0 3px #2e353e,
    0 0 0 6px #ccc;
}

html.dark .timeline.timeline-simple .tm-body .tm-items > li .tm-box:after {
  border-right-color: #282d36;
}

html.dark .timeline.timeline-simple .tm-body .tm-items > li .tm-box:before {
  border-right-color: #5a5c63;
}

/* Dark - Background */
html.dark,
html.dark body {
  background-color: #e6e6ea;
}

html.dark.boxed .content-body {
  background-color: #e6e6ea;
}

html.dark body {
  color: #808697;
}

html.dark .hidden-on-dark {
  display: none !important;
}

html.dark .bg-white {
  background-color: #5a5c63 !important;
}

/* Dark - Titles */
html.dark h1,
html.dark .h1,
html.dark h2,
html.dark .h2,
html.dark h3,
html.dark .h3,
html.dark h4,
html.dark .h4,
html.dark h5,
html.dark .h5,
html.dark h6,
html.dark .h6 {
  color: #fff;
}

/* Dark - Alerts */
html.dark .alert h1,
html.dark .alert .h1,
html.dark .alert h2,
html.dark .alert .h2,
html.dark .alert h3,
html.dark .alert .h3,
html.dark .alert h4,
html.dark .alert .h4,
html.dark .alert h5,
html.dark .alert .h5,
html.dark .alert h6,
html.dark .alert .h6 {
  color: #111;
}

/* Dark - Blockquote */
html.dark
  blockquote:not(.primary):not(.success):not(.danger):not(.warning):not(
    .info
  ):not(.dark) {
  border-color: #282d36;
}

/* Dark - Helpers */
html.dark .text-dark {
  color: #fff !important;
}

html.dark ul.nav-list.primary > li a {
  border-bottom-color: #282d36;
}

html.dark ul.nav-list.primary > li a:hover {
  background-color: #282d36;
}

html.dark .pagination > li > a,
html.dark .pagination > li > span {
  background-color: #282d36;
  border-color: #242830;
}

html.dark .pagination > li.active > a,
html.dark .pagination > li.active > span {
  background-color: #ccc;
  border-color: #242830;
}

html.dark .pagination > li.disabled > a,
html.dark .pagination > li.disabled > span {
  background-color: #282d36;
  border-color: #242830;
}

html.dark .dropdown-menu {
  background-color: #282d36;
}

html.dark .dropdown-menu > li > a {
  color: #eee;
}

html.dark .dropdown-menu > li > a:hover,
html.dark .dropdown-menu > li > a:focus {
  background-color: #e6e6ea;
  color: #fff;
}

html.dark hr.dotted,
html.dark hr.solid {
  border-color: #4c4c4c;
}

html.dark .img-thumbnail,
html.dark .thumbnail {
  background-color: #5a5c63;
  border-color: #282d36;
}

html.dark .notification-menu .title {
  color: #c3c3c3;
}

html.dark .input-group-text {
  background-color: #5a5c63;
  border-color: #5a5c63;
  color: #808697;
}

html.dark .mailbox-compose .bootstrap-tagsinput {
  background: transparent;
}

html.dark .mailbox-compose .bootstrap-tagsinput > input {
  color: #fff;
}

img,
svg {
  vertical-align: auto !important;
}

.input-disabled-rma {
  color: #dee2e6;
}

.input-disabled-rma-nb {
  background-color: #fdfdfd;
}

.text-promo-precio {
  line-height: 10px;
  margin-left: 12px;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-size: 0.6rem;
}

.tooltip {
  background-color: #fef7db;
  padding: 2px 10px;
  color: #69461c;
  border-radius: 4px;
  border-color: #faebc0;
}

.gray-help {
  color: #94969d;
}

#recommended-images {
  display: flex;
  padding-bottom: 20px;
}

.area-image-container {
  display: flex;
  flex-direction: column;
  max-width: 120px;
  padding-right: 20px;
}

.area-img {
  width: auto;
  height: 70px;
}

.flex-container-span {
  display: flex;
  align-items: center;
}

.flex-container-space {
  display: flex;
  justify-content: space-between;
}

.span-rank {
  font-size: 30px;
  font-weight: 700;
  width: 20px;
  margin: 20px;
}

.reduced-width {
  padding: 3%;
  width: 90%;
  margin: 0 auto;
}

.with-p {
  width: 20px;
}

.special-color {
  color: #94969d;
}
