@import "../../../assets/css/vars.scss";

.components-ui-carousel {
  .carousel-item {
    img {
      object-fit: contain;
      aspect-ratio: 1/1;
      width: 100%;
      height: 100%;
    }
  }
  .carousel-thumbnails {
    display: flex;
    overflow: none;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0.75rem;
    margin-top: 0.75rem;
    padding-bottom: 0.75rem;

    > figure {
      flex: 0 0 6.25rem;
      margin-bottom: 0;

      > img {
        object-fit: contain;
        width: 6.25rem;
        height: 6.25rem;
        transition: border 100ms linear;
      }

      &.active {
        > img {
          border: solid 2px $brand-red;
        }
      }
    }
  }
}
