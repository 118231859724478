html.dark .checkbox-primary label:before,
.checkbox-primary label:before {
  background: #db052c;
  border-color: #0077b3;
}

html.dark .checkbox-text-primary input[type="checkbox"]:checked + label:after,
.checkbox-text-primary input[type="checkbox"]:checked + label:after {
  color: #db052c;
}

html.dark .radio-primary input[type="radio"]:checked + label:after,
.radio-primary input[type="radio"]:checked + label:after {
  background: #db052c;
  box-shadow: 0px 0px 1px #db052c;
}

.switch.switch-primary .ios-switch .on-background {
  background: #db052c;
}

.progress-bar {
  background-color: #db052c;
}

.progress .progress-bar-primary {
  background-color: #db052c;
}

.slider-primary .ui-slider-range,
.slider-primary .ui-slider-handle {
  background: #db052c;
}

.slider-gradient.slider-primary .ui-slider-range,
.slider-gradient.slider-primary .ui-slider-handle {
  background-image: linear-gradient(
    to left,
    #00aaff 0,
    #db052c 50%,
    #006699 100%
  );
}

.slider-gradient.ui-slider-vertical.slider-primary .ui-slider-range,
.slider-gradient.ui-slider-vertical.slider-primary .ui-slider-handle {
  background-image: linear-gradient(
    to right,
    #00aaff 0,
    #db052c 50%,
    #006699 100%
  );
}

.datepicker thead tr:first-child th:hover,
.datepicker tfoot tr th:hover,
.datepicker table tr td span:hover {
  background: #db052c;
}

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover {
  background: #db052c;
}

.datepicker table thead tr:last-child th:hover {
  color: #db052c;
}

.datepicker table tbody tr td.day:hover {
  background: #db052c;
}

.datepicker table tbody tr td.day.active {
  background: #006699;
}

html:not(.sidebar-light) .datepicker.datepicker-dark {
  background: transparent;
}

html:not(.sidebar-light)
  .datepicker.datepicker-dark
  table
  tbody
  tr
  td.day:hover {
  background: #db052c;
}

html:not(.sidebar-light)
  .datepicker.datepicker-dark
  table
  tbody
  tr
  td.day.active {
  background: #006699;
}

.datepicker.datepicker-primary table thead tr:first-child {
  background-color: #db052c;
}

.datepicker.datepicker-primary table thead tr:first-child th:hover {
  background-color: #006699;
}

.datepicker.datepicker-primary table thead tr:last-child {
  background-color: #5a5c63;
}

.datepicker.datepicker-primary table thead tr:last-child th:hover {
  background-color: #db052c;
  color: #fff;
}

.select2-container-multi .select2-choices .select2-search-choice {
  background: #db052c;
}

.wizard-steps > li.active .badge {
  background-color: #db052c;
}

.wizard-steps > li.active a,
.wizard-steps > li.active a:hover,
.wizard-steps > li.active a:focus {
  border-top-color: #db052c;
}

.wizard-tabs ul > li.active .badge {
  background-color: #db052c;
}

html .wizard-progress .steps-progress .progress-indicator,
html.dark .wizard-progress .steps-progress .progress-indicator {
  background: #db052c;
}

html .wizard-progress .wizard-steps li.completed a span,
html.dark .wizard-progress .wizard-steps li.completed a span {
  border-color: #db052c;
  background: #db052c;
}

html .wizard-progress .wizard-steps li.active a span,
html.dark .wizard-progress .wizard-steps li.active a span {
  color: #db052c;
  border-color: #db052c;
}

.table > thead > tr > td.primary,
.table > tbody > tr > td.primary,
.table > tfoot > tr > td.primary,
.table > thead > tr > th.primary,
.table > tbody > tr > th.primary,
.table > tfoot > tr > th.primary,
.table > thead > tr.primary > td,
.table > tbody > tr.primary > td,
.table > tfoot > tr.primary > td,
.table > thead > tr.primary > th,
.table > tbody > tr.primary > th,
.table > tfoot > tr.primary > th {
  background-color: #db052c !important;
}

.dataTables_processing {
  background-color: #db052c;
}

.liquid-meter-wrapper .liquid-meter-selector a.active {
  color: #db052c;
}

.multiselect-container > .active > a,
.multiselect-container > .active > a:hover,
.multiselect-container > .active > a:focus {
  background-color: #db052c;
}
