@import "../../../assets/css/vars.scss";

.components-ui-multi-carousel-dot {
  height: 0.6rem;
  width: 0.6rem;
  display: inline-block;
  background-color: $brand-gray;
  border-radius: 0.75rem;
  transition: all 50ms ease-in-out;

  &.active {
    width: 1.5rem;
    background-color: $brand-gray-dark;
  }
}
