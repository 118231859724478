@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";

/* COLORES DE MARCA */
/* **************** */
$brand-red: hsl(349, 96%, 44%);
$brand-red-dark: hsl(349, 96%, 37%);
$brand-gray-light: hsl(240, 9%, 89%);
$brand-gray: hsl(227, 4%, 60%);
$brand-gray-dark: hsl(227, 5%, 37%);

/* BREAKPOINTS DE BOOTSTRAP 5 */
/* ************************** */
$bp-xs: map-get($grid-breakpoints, "xs");
$bp-sm: map-get($grid-breakpoints, "sm");
$bp-md: map-get($grid-breakpoints, "md");
$bp-lg: map-get($grid-breakpoints, "lg");
$bp-xl: map-get($grid-breakpoints, "xl");
$bp-xxl: map-get($grid-breakpoints, "xxl");

/* COLORES */
/* ******* */

// Mantener en sincronía con /src/custom.scss
$fn-theme-colors: (
  "primary": hsl(349, 96%, 44%),
  "secondary": hsl(208, 7%, 46%),
  "success": hsl(134, 61%, 41%),
  "info": hsl(190, 90%, 50%),
  "warning": hsl(39, 45%, 48%),
  "danger": hsl(349, 96%, 44%),
  "light": hsl(210, 17%, 98%),
  "dark": hsl(227, 5%, 37%),
) !default;

$fn-bg-colors: (
  "primary": hsl(354, 67%, 88%),
  "secondary": hsl(220, 5%, 89%),
  "success": hsl(118, 31%, 83%),
  "info": hsl(191, 88%, 90%),
  "warning": hsl(40, 85%, 82%),
  "danger": hsl(354, 67%, 88%),
  "light": hsl(0, 0%, 100%),
  "dark": hsl(250, 11%, 89%),
) !default;
