.components-ui-search-form {
  #search-header {
    .dropdown-item {
      white-space: unset;

      svg {
        margin-right: 0.25rem;
      }
    }
  }
  .input-group {
    .btn-goto {
      position: absolute;
      top: 7px;
      right: 10px;
      z-index: 10;
    }
  }
}
