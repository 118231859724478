@import "vars.scss";
@import "fonts.scss";

/*
 * Barras de scroll.
 */
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  background-color: $brand-gray-light;
}

::-webkit-scrollbar-thumb {
  background-color: $brand-red;
  border-radius: 5px;

  &:hover {
    background-color: $brand-red-dark;
  }
}

html {
  /*
   * Elimina el scroll vertical que aparece siempre en la página.
   */
  overflow-y: auto !important;

  body {
    font-size: 0.9rem;

    color: #777;

    .content-body {
      > .page-header {
        background-color: $brand-gray;
      }

      > .card {
        color: #777 !important;
        > .card-header {
          background-color: $brand-gray-light;

          h2 {
            font-size: 2.5rem;
            margin-bottom: 0.25rem;
            margin-top: 0.5rem;
            color: $brand-gray-dark;
            font-weight: 900;
          }
        }
      }
    }
  }

  a {
    color: $brand-red;

    &:hover {
      color: $brand-red-dark;
    }
  }

  .sidebar-left {
    background-color: $brand-gray-light;

    .sidebar-header {
      /*
       * Asegura la accesibilidad AA.
       * See: https://color.a11y.com/ContrastPair/?bgcolor=E6E6EA&fgcolor=5A5C63
       */
      .sidebar-title {
        background-color: $brand-gray-light;
        color: $brand-gray-dark;
      }

      /*
       * Icono de navegación (menú hamburguesa) situado en el menú de navegación
       * lateral.
       */
      .sidebar-toggle {
        background-color: $brand-gray;

        svg {
          fill: $brand-gray-light;
          height: 50px;
          transition: fill 0.15s ease-in-out;
        }

        &:hover {
          svg {
            fill: $brand-red;
          }
        }
      }
    }
  }

  /*
   * Menú de navegación lateral.
   */
  ul {
    &.nav-main {
      margin-right: 0;

      li {
        a {
          display: flex;
          align-items: center;

          > svg {
            margin-left: auto;
          }
        }
      }
    }
  }

  .header {
    /*
     * Buscador de la cabecera.
     */
    .search {
      width: 18rem;
    }

    /* Lineas separadoras */
    .separator {
      margin: 0 15px 0;
    }

    /*
   * Caja de usuario de la cabecera.
   */
    .userbox {
      @media (min-width: #{$bp-md}) {
        .dropdown-toggle {
          min-width: 13rem;
        }
      }

      /*
     * Asegura la accesibilidad AA.
     * See: https://color.a11y.com/ContrastPair/?bgcolor=ffffff&fgcolor=5A5C63
     */
      .role {
        color: $brand-gray-dark;
      }
    }

    /* Notificaciones de la cabecera */
    .notifications {
      .notification-menu,
      .notification-icon {
        &::before {
          border-bottom-color: map-get($fn-theme-colors, "primary");
        }
      }

      .notification-menu {
        .notification-title {
          background-color: map-get($fn-theme-colors, "primary");
        }

        .image {
          i {
            font-style: normal;
          }
        }
      }
    }

    @media (max-width: #{$bp-md - 1}) {
      .notifications {
        > li {
          .notification-menu {
            width: 245px !important;
            left: auto !important;
            transform: translate3d(0, 32px, 0) !important;
          }
        }
      }
    }
  }

  /*
   * Tags personalizados de fonestar, por ejemplo los de los filtros de productos.
   */
  .badge {
    &.badge-link {
      text-decoration: none;
      padding: 0.75rem 1rem;
      background-color: $brand-gray;
      font-size: 0.75rem;

      &.outlined {
        background-color: transparent;
        color: #ffffff;
        border: solid 1px $brand-gray;
      }

      &.hoverable {
        &:hover {
          border-color: $brand-gray-light;
          background-color: $brand-gray-light;
          color: $brand-gray-dark;
        }
      }

      &.active {
        border-color: $brand-gray-light;
        background-color: $brand-gray-light;
        color: $brand-gray-dark;
      }
    }
  }

  @media (min-width: #{$bp-lg}) {
    .wizard-progress {
      .wizard-steps {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  /*
   * Grid para las páginas que tienen las columnas estilo Masonry.
   */
  .masonry-custom {
    display: flex;
    gap: 1.5rem;
  }

  /*
   * Títulos con el embellecedor de Fonestar por encima.
   */
  .title-with-marker {
    &::before {
      content: " ";
      display: block;
      width: 4.5rem;
      height: 0.6rem;
      margin-bottom: 0.5rem;
      background-color: $brand-red;
    }
  }

  /*
   * Widget de resumen, como en el listado de pedidos.
   */
  @each $variant, $color in $fn-bg-colors {
    .card-featured-#{$variant} {
      .card-body {
        background-color: $color;

        .widget-summary {
          .summary-icon {
            color: $color;
            width: 4.25rem;
            height: 4.25rem;
            line-height: 4.25rem;
            font-size: 2.5rem;
          }

          .summary {
            word-break: break-word;
            .title {
              font-size: 0.8rem;
            }
          }

          .summary-footer {
            border-top: dotted 1px map-get($fn-theme-colors, $variant);
          }
        }
      }
    }
  }

  /* Burbuja de color que aparece junto al estado de un pedido, una cotización, rma... */
  @each $variant, $color in $fn-theme-colors {
    .bubble-color-#{$variant} {
      background-color: $color;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      display: inline-block;
      margin-right: 5px;
    }
  }

  /* Cuadro amarillo de la pantalla de gracias al generar un pedido */
  .message-working-on-your-order {
    display: inline-block;
    background-color: map-get($fn-bg-colors, "warning");
    color: map-get($fn-theme-colors, "warning");
    padding: 10px 30px;
    margin-top: 20px;
  }

  // .text-success {
  //   color: $success-color;
  // }

  // .bg-success {
  //   color: $success-color;
  // }

  // .text-dark {
  //   color: $dark-color !important;
  // }

  // .bg-dark {
  //   background: $dark-color !important;
  // }

  // .text-warning {
  //   color: $warning-color !important;
  // }
  // .bg-warning {
  //   background: $warning-color !important;
  // }

  /*
   * Carrusel multi-items.
   */
  .react-multi-carousel-list {
    margin-bottom: 2rem;
  }

  .react-multi-carousel-dot-list {
    gap: 0.5rem;
    margin: 1rem 0;
  }

  /*
   * Botón primario.
   */
  .btn-primary {
    background-color: $brand-red;
    border-color: $brand-red;

    &:hover,
    &.hover {
      background-color: $brand-red-dark;
      border-color: $brand-red-dark;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 3px rgba(220, 4, 44, 0.5);
    }

    &:active,
    &.active,
    .show > &.dropdown-toggle {
      background-color: $brand-red-dark !important;
      border-color: $brand-red-dark !important;
      box-shadow: 0 0 0 3px rgba(220, 4, 44, 0.5) !important;
    }
  }

  /*
   * Mezcla de botón `lighy` y botón `primary`.
   */
  .btn-light-primary {
    --bs-btn-color: #000;
    --bs-btn-bg: #{map-get($fn-theme-colors, "light")};
    --bs-btn-border-color: #{map-get($fn-theme-colors, "light")};
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #{map-get($fn-theme-colors, "primary")};
    --bs-btn-hover-border-color: #{map-get($fn-theme-colors, "primary")};
    --bs-btn-focus-shadow-rgb: 212, 213, 213;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #{map-get($fn-theme-colors, "primary")};
    --bs-btn-active-border-color: #{map-get($fn-theme-colors, "primary")};
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #{map-get($fn-theme-colors, "light")};
    --bs-btn-disabled-border-color: #{map-get($fn-theme-colors, "light")};
    --bs-border-color: #{$brand-gray-light};

    border: solid 1px var(--bs-border-color);
    font-weight: 600;
  }

  /* ******* */
  /* HELPERS */
  /* ******* */

  .shine {
    background: #f6f7f8;
    background-image: linear-gradient(
      to right,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%
    );
    background-repeat: no-repeat;
    background-size: cover;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholder-shimmer;
    animation-timing-function: linear;
  }

  /*
   * Letter spacing helper.
   */
  .tracking-widest {
    letter-spacing: 0.1em;
  }

  /*
   * Uppercase helper.
   */
  .uppercase {
    text-transform: uppercase;
  }

  /*
   * Color helpers.
   */
  .text-brand-red {
    color: $brand-red !important;
  }

  .text-brand-white {
    color: #fff !important;
  }
  .text-gray {
    color: #bdbdbd;
  }
  .text-brand-gray-light {
    color: $brand-gray-light !important;
  }

  .text-brand-gray {
    color: $brand-gray !important;
  }

  .text-brand-gray-dark {
    color: $brand-gray-dark !important;
  }

  .bg-brand-red {
    background-color: $brand-red !important;
  }

  .bg-brand-gray-light {
    background-color: $brand-gray-light !important;
  }

  .bg-brand-gray {
    background-color: $brand-gray !important;
  }

  .bg-brand-gray-dark {
    background-color: $brand-gray-dark !important;
  }

  .bg-brand-promo {
    background-color: #d2312d !important;
  }

  .border-brand-red {
    border-color: $brand-red !important;
  }

  .border-brand-gray-light {
    border-color: $brand-gray-light !important;
  }

  .border-brand-gray {
    border-color: $brand-gray !important;
  }

  .border-brand-gray-dark {
    border-color: $brand-gray-dark !important;
  }

  /*
   * Gap helpers.
   */
  .row-gap-0 {
    row-gap: 0;
  }

  .row-gap-1 {
    row-gap: calc(1rem * 0.25);
  }

  .row-gap-2 {
    row-gap: calc(1rem * 0.5);
  }

  .row-gap-3 {
    row-gap: 1rem;
  }

  .row-gap-4 {
    row-gap: calc(1rem * 1.5);
  }

  .row-gap-5 {
    row-gap: calc(1rem * 3);
  }

  /*
   * Card header.
   */
  // .card-header {
  //   background-color: $brand-gray-light !important;

  //   div.col:first-child .tracking-widest {
  //     letter-spacing: 3px !important;
  //   }

  //   div.col:first-child h2 {
  //     color: $brand-gray-dark !important;
  //     margin-top: 10px !important;
  //     line-height: 1 !important;
  //   }

  //   div.col:first-child p {
  //     margin-bottom: 0px !important;
  //   }

  //   div.col:nth-child(2) svg {
  //     fill: $brand-gray-dark !important;
  //   }
  //   div.col:nth-child(2) strong {
  //     color: $brand-gray-dark !important;
  //   }

  //   div.row {
  //     div.col:first-child {
  //       margin-top: 8px;
  //     }
  //   }

  //   div.row {
  //     div.col:nth-child(2) .components-ui-extra-title {
  //       display: flex !important;
  //       flex-direction: column !important;
  //       justify-content: center !important;

  //       p {
  //         margin-bottom: 0px !important;
  //       }
  //     }
  //   }
  // }

  /* Ajustes de los paddings generales para adecuarse al diseño*/
  // @media screen and (min-width: 1366px) {
  //   .content-body {
  //     padding: 40px;

  //     .card-header {
  //       padding: 30px 40px;
  //     }

  //     > div > div:nth-child(2).card-body {
  //       padding: 30px 40px;
  //     }
  //   }
  // }

  // .font-size-08 {
  //   font-size: 0.8rem;
  // }

  // Scroll para el pop-up de carrito y otras notificaciones de la cabecera.
  .header .notifications .notification-menu .content {
    padding: 12px;
    max-height: 380px;
    overflow-y: scroll;
  }

  // Alinea las notificaciones y controla su ancho.
  @media screen and (max-width: 420px) {
    .header .notifications > li .notification-menu {
      width: 235px !important;
      left: -190px !important;
      //transform: translate3d(0, 32px, 0) !important;
    }
  }
}
