@import "../../../assets/css/vars.scss";

.components-ui-subsection-title {
  &::before {
    content: "";
    height: 4rem;
    width: 1px;
    display: block;
    margin: 0 auto;
    background-color: $brand-gray-light;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $brand-gray-light;
    padding: 1rem;
    margin-bottom: 1rem;

    > h2 {
      color: $brand-gray-dark;
      font-weight: bold;
      text-transform: uppercase;
      margin: 0;
    }

    > div {
      display: flex;
      gap: 0.5rem;
    }
  }
}
