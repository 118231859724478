$primary: rgb(220, 4, 44) !default;
$secondary: rgb(109, 118, 126) !default;
$success: rgb(41, 168, 71) !default;
$info: rgb(13, 204, 242) !default;
$warning: rgb(177, 139, 67) !default;
$danger: $primary !default;
$light: rgb(249, 250, 251) !default;
$dark: rgb(90, 92, 99) !default;

// $secondary: #53565a;
// $font-family-sans-serif: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI",
//   Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
//   "Segoe UI Emoji", "Segoe UI Symbol";

@import "~bootstrap/scss/bootstrap.scss";

.emptyField {
  color: #db052c;
  font-style: italic;
  font-weight: bold;
}

.italic {
  font-style: italic;
}
