.components-ui-extra-title {
  max-width: 16rem;
  margin-left: auto;

  > div {
    display: flex;
    flex-wrap: nowrap;
    gap: 0.5rem;

    > div {
      &:first-of-type {
        flex: 0 0 3rem;
      }

      &:last-of-type {
        > p {
          font-size: 0.65rem;
          line-height: 1rem;
        }
      }
      img {
        max-height: 100px;
        margin-left: -100px;

        @media screen and (max-width: 1200px) {
          margin-left: 0px;
        }
      }
    }
  }

  @media screen and (max-width: 890px) {
    max-width: auto;
    margin-top: 20px;
    margin-left: 0px;
  }
}
