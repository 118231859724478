.components-ui-product-card {
  a.card-body {
    text-decoration: none;

    .image-frame {
      aspect-ratio: 1/1;

      .image-frame-wrapper {
        height: 100%;
        display: flex;

        img {
          aspect-ratio: 1/1;
          object-fit: scale-down;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .footer-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0.5rem;

    > div {
      &:first-of-type {
        flex: 0 0 8rem;

        input {
          text-align: center;
        }
      }
    }
  }
}
