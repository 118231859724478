@import "../../../assets/css/vars.scss";

.components-ui-product-simple-card {
  width: 100%;

  > .card-body {
    text-decoration: none;
    display: flex;
    padding: 0;
    box-shadow: unset;
    border-radius: 0;
    flex-direction: column;

    > .image-frame {
      background-color: white;
      height: 100%;

      img {
        aspect-ratio: 1/1;
        object-fit: scale-down;
        width: 100%;
        height: 100%;
      }
    }

    > .info {
      padding: 0 0.75rem;
      background-color: $brand-gray-light;
    }
  }

  &.horizontal {
    > .card-body {
      flex-direction: row;

      > .image-frame {
        border: solid 1px $brand-gray-light;
        max-width: 6rem;
        height: 100%;
      }

      > .info {
        background-color: transparent;
        flex-grow: 1;
      }
    }
  }
}
