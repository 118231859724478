@import "../../../../assets/css/vars.scss";

.components-ui-loaders-product-card-skeleton {
  .footer-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0.5rem;

    > div {
      &:first-of-type {
        flex: 0 0 8rem;

        input {
          text-align: center;
        }
      }

      &:last-of-type {
        flex-grow: 1;
      }
    }
  }
}
