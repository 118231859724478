@import "../../../assets/css/vars.scss";

.components-ui-pagination {
  .pagination > li > a,
  .pagination > li > span,
  .pagination > li > a:hover,
  .pagination > li > span:hover,
  .pagination > li > a:focus,
  .pagination > li > span:focus {
    color: map-get($fn-theme-colors, "dark");
    font-weight: bold;
  }

  .pagination > .active > a,
  .pagination > .active > span,
  .pagination > .active > a:hover,
  .pagination > .active > span:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span:focus,
  .pagination .page-item.active .page-link {
    background-color: #ffffff !important;
    border-color: map-get($fn-theme-colors, "primary");
  }

  .pagination .page-item .page-link {
    border-radius: 0;
  }
}
