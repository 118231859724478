/* s */

.input-position div {
  transform: none !important;
}

.input-position div img {
  aspect-ratio: 1/1;
  object-fit: contain;
}

/*
#resolution div img div {
  height: 100%;
} */
