@import "../../../../assets/css/vars.scss";

.components-ui-loaders-product-simple-card-skeleton {
  > .card-body {
    text-decoration: none;
    display: flex;
    padding: 0;
    box-shadow: unset;
    border-radius: 0;
    flex-direction: column;

    > .info {
      padding: 0 0.75rem;
      background-color: $brand-gray-light;
    }
  }

  &.horizontal {
    > .card-body {
      flex-direction: row;

      > .image-frame {
        border: solid 1px $brand-gray-light;
        width: 6rem;
        height: 100%;
      }

      > .info {
        background-color: transparent;
        flex-grow: 1;
      }
    }
  }
}
