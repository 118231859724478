@import "../../../assets/css/vars.scss";

.card-featured-fonestar.card-featured-left {
  border-left-color: $brand-gray-dark;

  .card-body {
    background-color: $brand-gray-light;
    padding: 0.75rem;

    .summary-footer {
      a {
        text-decoration: none;
        font-size: 0.8rem;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .text-fonestar {
      color: $brand-gray-dark;
    }
  }
}
