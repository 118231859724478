@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/Poppins/Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/Poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../fonts/Poppins/Poppins-ExtraBold.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../fonts/Poppins/Poppins-Black.ttf");
}

@font-face {
  font-family: "Shadows Into Light";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Shadows_Into_Light/ShadowsIntoLight-Regular.ttf");
}
